import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get layover details
 * 
 * @param {*} layoverId   Layover id
 * @param {*} request     Request
 * @returns 
 */
export const getLayoverDetails = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/details`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Send Mail To All Passengers
 * 
 * @param {*} request     Request
 * @returns 
 */
export const sendMailToAllPassengers = async(layoverId, request = {}) => {
  try {
    const response = await api.post(`/layover-passenger-bookings/${layoverId}/sent-mail-to-all-passengers`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get layover details for hotel
 * 
 * @param {*} layoverId   Layover id
 * @param {*} hotelId   hotel id
 * @param {*} request     Request
 * @returns 
 */
export const getLayoverDetailsForHotel = async(layoverId, hotelId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/hotel/${hotelId}/details`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};
