import { useContext, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Dropdown } from '../../components';
import { KeyboardArrowDown } from '@mui/icons-material';
import { AirlineContext } from '../../context/airline';
import { isMobile, isTablet } from 'react-device-detect';

const HeaderLeftAppbar = (props) => {
  const { airlines, onAirlineChange, airports, onAirportChange } = props;
  const { airlineId, airline, isAirlineDropdownDisabled, setIsAirlineDropdownDisabled, airportId, airport, isAirportDropdownDisabled, setIsAirportDropdownDisabled } = useContext(AirlineContext);
  const theme = useTheme();

  useEffect(() => {
    if (airlines && airlines.length === 1) {
      setIsAirlineDropdownDisabled(true);
    }
  }, [airlines])

  useEffect(() => {
    if (airports && airports.length === 1) {
      setIsAirportDropdownDisabled(true);
    }
  }, [airports])

  // Get the UI of dropdown item
  const getListItem = (airline) => {
    if (airline) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left'
        }}>
          <Typography sx={{
            ...theme.typography.textLarge,
          }} variant='caption'>{airline.name}</Typography>
        </Box>
      );
    }
    return null;
  };

  /**
   * Prepare the list of airlines to show in dropdown list
   * 
   * @param {*} airlines  Array of airlines 
   * @returns 
   */
  const prepareList = (accessedAirlineId, airlines) => {
    if (airlines && airlines.length) {
      return airlines.filter(function(airline) {
        return (airline.id !== accessedAirlineId);
      }).map((airline) => {
        return {
          key: airline.id,
          name: getListItem(airline),
          onClickOption: onAirlineChange
        };
      });
    }
    return [];
  };

  /**
   * Prepare the list of airports to show in dropdown list
   * 
   * @param {*} airports  Array of airports 
   * @returns 
   */
  const prepareAirportList = (accessedAirportId, airports) => {
    if (airports && airports.length) {
      return airports.filter(function(airport) {
        return (airport.id !== accessedAirportId);
      }).map((airport) => {
        return {
          key: airport.id,
          name: getListItem(airport),
          onClickOption: onAirportChange
        };
      });
    }
    return [];
  };

  return ((airlineId && airline) ? (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      py: '6px',
      gap: 2,
      width: '100%'
    }}>
      <Box sx={{width: ((isMobile || isTablet) && (airportId && airport)) ? '50%' : 'auto'}}>
        <Dropdown
          endIcon={isAirlineDropdownDisabled ? '' : <KeyboardArrowDown sx={{color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}} />}
          text={
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              textAlign: 'left'
            }}>
            {getListItem(airline)}
            </Box>
          }
          isReadOnly={isAirlineDropdownDisabled ? true : false }
          options={prepareList(airlineId, airlines)}
          btnSx={{p: 0}}
          boxSx={{mr: theme.spacing(2)}}
          itemSx={{py: theme.spacing(2)}}
          isDivider
        />
      </Box>

      {airportId && airport ? (
        <Box sx={{width: isMobile || isTablet ? '50%' : 'auto'}}>
          <Dropdown
            endIcon={isAirportDropdownDisabled ? '' : <KeyboardArrowDown sx={{color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}}/>}
            text={
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left'
              }}>
              {getListItem(airport)}
              </Box>
            }
            isReadOnly={isAirportDropdownDisabled ? true : false }
            options={prepareAirportList(airportId, airports)}
            btnSx={{p: 0}}
            boxSx={{mr: theme.spacing(2)}}
            itemSx={{py: theme.spacing(2)}}
            isDivider
          />
        </Box>
      ) : ('')}
    </Box>
  ) : (''));
};

export default HeaderLeftAppbar;