import { createContext, useCallback, useContext, useMemo, useRef } from 'react';
import Loader from '../components/loader';

const LoaderContext = createContext();

export function LoaderProvider({children}) {
  const ref = useRef();
  const startLoader = useCallback(() => {ref.current.start()}, []);
  const stopLoader = useCallback(() => {ref.current.stop()}, []);
  const value = useMemo(
    () => ({ref, startLoader, stopLoader}),
    [ref, startLoader, stopLoader]
  );

  return (
    <LoaderContext.Provider value={value}>
      {children}
      <Loader ref={ref} />
    </LoaderContext.Provider>
  );
}

export const useLoader = () => useContext(LoaderContext);