import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Divider, Grid, IconButton, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import Input from '../../../components/input';
import { InputEndAdornment } from '../components/endAdornment';
import { name as getCurrencyName } from 'better-currency-codes';
import { useLoader } from '../../../providers/loader';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { toast } from 'react-toastify';
import { CheckBox, Radio, Timepicker } from '../../../components';
import dayjs from 'dayjs';
import { getFoodBeverages, createFoodBeverages } from '../../../services/hotel/hotelFoodBeverages';
import moment from 'moment';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import { HotelContext } from '../../../context/hotel';
import InfoIcon from '@mui/icons-material/Info';

const timingFields = [
  'weekday_breakfast_from',
  'weekday_breakfast_to',
  'weekend_breakfast_from',
  'weekend_breakfast_to',
  'weekday_early_bird_breakfast_from',
  'weekday_early_bird_breakfast_to',
  'weekend_early_bird_breakfast_from',
  'weekend_early_bird_breakfast_to',
  'weekday_lunch_from',
  'weekday_lunch_to',
  'weekend_lunch_from',
  'weekend_lunch_to',
  'weekday_dinner_from',
  'weekday_dinner_to',
  'weekend_dinner_from',
  'weekend_dinner_to',
  'weekday_late_dinner_from',
  'weekday_late_dinner_to',
  'weekend_late_dinner_from',
  'weekend_late_dinner_to'
];

const availableFoods = [
  'Vegetarian',
  'Vegan',
  'Halal',
  'Kosher'
];

const StyledGridWeekdayInner = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey['A900'],
  borderRadius: '5px',
  justifyContent: 'center',
  alignItems: 'center'
}));

const StyledWeekdayName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[600],
  textTransform: 'uppercase'
}));

const FoodBeverages = forwardRef((props, ref) => {
  const { hotel, permission } = props;
  const { setIsFormDirty } = useContext(HotelContext);
  const theme = useTheme();
  const formRef = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  const [currency, setCurrency] = useState('');
  const [resetFormData, setResetFormData] = useState({});
  const [foodBeverages, setFoodBeverages] = useState({});
  const [isBreakfastAllowed, setIsBreakfastAllowed] = useState(0);
  const [isEarlyBirdBreakfastAllowed, setIsEarlyBirdBreakfastAllowed] = useState(0);
  const [isLunchAllowed, setIsLunchAllowed] = useState(0);
  const [isSnacksAllowed, setIsSnacksAllowed] = useState(0);
  const [isDinnerAllowed, setIsDinnerAllowed] = useState(0);
  const [isLateDinnerAllowed, setIsLateDinnerAllowed] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setFocus,
    reset
  } = useForm();

  // checking is form having any unsaved changes or not
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [dirtyFields,setIsFormDirty]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  /**
   * Get food & beverages from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getFoodBeveragesFromApi = useCallback(async (hotelId) => {
    const response = await getFoodBeverages(hotelId);
    if (response?.data && response.data.length) {
      setFoodBeverages(response.data[0]);
    } else {
      setFoodBeverages({});
    }
  }, []);

  /**
   * Update hotel timings from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateFoodBeveragesViaApi = useCallback(async (hotelId, request) => {
    try {
      if (hotelId && request) {
        const response = await createFoodBeverages(hotelId, request);
        if (response?.data) {
          setFoodBeverages({...foodBeverages, ...response.data});
        }
      }
    } catch (e) {
      throw e
    }
  }, [foodBeverages]);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (hotel && hotel.id && data) {
      setIsFormDirty(false); // if user clicks on Save changes then Confirmation box will not shown
      
      let promises = [];

      const formFoodBeveragesObj = data.formFoodBeverages;

      if (formFoodBeveragesObj.breakfast_price === '' || formFoodBeveragesObj.breakfast_price === null || formFoodBeveragesObj.breakfast_price === undefined) {
        formFoodBeveragesObj.breakfast_price = 0;
      }

      if (formFoodBeveragesObj.early_bird_breakfast_price === '' || formFoodBeveragesObj.early_bird_breakfast_price === null || formFoodBeveragesObj.early_bird_breakfast_price === undefined) {
        formFoodBeveragesObj.early_bird_breakfast_price = 0;
      }

      if (formFoodBeveragesObj.lunch_price === '' || formFoodBeveragesObj.lunch_price === null || formFoodBeveragesObj.lunch_price === undefined) {
        formFoodBeveragesObj.lunch_price = 0;
      }

      if (formFoodBeveragesObj.snacks_price === '' || formFoodBeveragesObj.snacks_price === null || formFoodBeveragesObj.snacks_price === undefined) {
        formFoodBeveragesObj.snacks_price = 0;
      }

      if (formFoodBeveragesObj.dinner_price === '' || formFoodBeveragesObj.dinner_price === null || formFoodBeveragesObj.dinner_price === undefined) {
        formFoodBeveragesObj.dinner_price = 0;
      }

      if (data.formFoodBeverages) {
        for (const field of timingFields) {
          if (data.formFoodBeverages?.[field] && data.formFoodBeverages?.[field]?.$d) {
            data.formFoodBeverages[field] = moment(data.formFoodBeverages[field].$d).format('HH:mm:ss.SSS')
          }
        }
        if (data.formFoodBeverages?.available_foods && Object.keys(data.formFoodBeverages.available_foods).length > 0) {
          let tempAvailableFoods = [];
          for (const food in data.formFoodBeverages.available_foods) {
            if (data.formFoodBeverages.available_foods[food]) {
              tempAvailableFoods.push(food);
            }
          }
          data.formFoodBeverages.available_foods = tempAvailableFoods;
        }
        promises.push(updateFoodBeveragesViaApi(hotel.id, data.formFoodBeverages));
      }

      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          setIsFormDirty(false)
          toast.success(toastMessages.hotel.updateHotelFoodBeverages.success, {
            autoClose: maxToastTime,
            toastId: 'update-food-beverages',
          })
        }).catch((value) => {
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [updateFoodBeveragesViaApi, startLoader, stopLoader, setIsFormDirty]);

  // Call function to submit the form on click of form submit button
  useImperativeHandle(ref, () => ({
    saveChanges: () => {
      handleSubmit(onSubmit)()
    }
  }), [handleSubmit, onSubmit]);

  useEffect(() => {
    if (hotel.id) {
      startLoader();
      Promise.allSettled([
        getFoodBeveragesFromApi(hotel.id)
      ]).then(() => {
        stopLoader();
      });
    }
  }, [hotel, getFoodBeveragesFromApi, startLoader, stopLoader]);

  // Prepare values to reset the form when API calls completed
  useEffect(() => {
    let tempResetFormData = {};
    if (foodBeverages && Object.keys(foodBeverages).length > 0) {
      tempResetFormData['formFoodBeverages'] = foodBeverages;
      // Convert checkbox & radio values into binary value
      tempResetFormData['formFoodBeverages']['is_breakfast'] = foodBeverages?.is_breakfast ? 1 : 0;
      tempResetFormData['formFoodBeverages']['is_early_bird_breakfast'] = foodBeverages?.is_early_bird_breakfast ? 1 : 0;
      tempResetFormData['formFoodBeverages']['is_lunch'] = foodBeverages?.is_lunch ? 1 : 0;
      tempResetFormData['formFoodBeverages']['is_snacks'] = foodBeverages?.is_snacks ? 1 : 0;
      tempResetFormData['formFoodBeverages']['is_dinner'] = foodBeverages?.is_dinner ? 1 : 0;
      tempResetFormData['formFoodBeverages']['is_late_dinner'] = foodBeverages?.is_late_dinner ? 1 : 0;
      // Convert time values in timepicker supported values
      for (const field of timingFields) {
        if (tempResetFormData.formFoodBeverages?.[field]) {
          tempResetFormData.formFoodBeverages[field] = dayjs(tempResetFormData.formFoodBeverages[field], 'HH:mm:ss')
        }
      }
      // Convert available_foods value in supported values
      if (foodBeverages?.available_foods && Object.keys(foodBeverages?.available_foods || {}).length > 0) {
        let tempAvailableFoods = {};
        for (const food of foodBeverages.available_foods) {
          tempAvailableFoods[food] = 1;
        }
        tempResetFormData.formFoodBeverages.available_foods = tempAvailableFoods;
      }

      // prefilling radio btn values in states to manage ternary conditions in FORM
      setIsBreakfastAllowed(foodBeverages?.is_breakfast ? 1 : 0);
      setIsEarlyBirdBreakfastAllowed(foodBeverages?.is_early_bird_breakfast ? 1 : 0);
      setIsLunchAllowed(foodBeverages?.is_lunch ? 1 : 0);
      setIsSnacksAllowed(foodBeverages?.is_snacks ? 1 : 0);
      setIsDinnerAllowed(foodBeverages?.is_dinner ? 1 : 0);
      setIsLateDinnerAllowed(foodBeverages?.late_dinner_price ? 1 : 0);
    }
    setResetFormData(tempResetFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodBeverages]);

  // Provide values to reset the form when API calls completed
  useEffect(() => {
    if (Object.keys(resetFormData).length > 0) {
      reset({});
      reset(resetFormData);
    } else {
      reset({});
      reset({});
    }
  }, [reset, resetFormData]);

  useEffect(() => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencyName = getCurrencyName({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencyName?.unit?.plural ? currencyName.unit.plural : hotel.currency.alphabetic_code;
    }
    setCurrency(hotelCurrency);
  }, [hotel?.currency?.alphabetic_code]);

  return (
    <Box sx={{pt: 3}}>
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Controller
          name={'hotelId'}
          control={control}
          defaultValue={hotel.id}
          render={({ field }) => (
            <Input
              type='hidden'
              formControlProps={{sx: {m:0}}}
              sx={{border:0}}
              {...field}
            />
          )}
        />
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Is breakfast available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[is_breakfast]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formFoodBeverages?.is_breakfast}
                            errorMessage={errors?.formFoodBeverages?.is_breakfast && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsBreakfastAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Breakfast price (if not included in the rate) *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[breakfast_price]`}
                        control={control}
                        disabled={(Number(isBreakfastAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={Number(isBreakfastAllowed) === 1 && !!errors?.formFoodBeverages?.breakfast_price}
                            errorMessage={Number(isBreakfastAllowed) === 1 && errors?.formFoodBeverages?.breakfast_price && 'Please enter valid price'}
                            inputRef={field.ref}
                            {...field}
                            value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            onChange={(e) => {
                              const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                              field.onChange(value);
                            }}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: (Number(isBreakfastAllowed) === 0) || (!permission?.edit) ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <HeadingInput title='Breakfast timings' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4} sx={{color:theme.palette.time.main}}>
                        <Controller
                          name={`formFoodBeverages[weekday_breakfast_from]`}
                          control={control}
                          disabled={(Number(isBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_breakfast_to]`}
                          control={control}
                          disabled={(Number(isBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_breakfast_from]`}
                          control={control}
                          disabled={(Number(isBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_breakfast_to]`}
                          control={control}
                          disabled={(Number(isBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Is early bird breakfast available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[is_early_bird_breakfast]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formFoodBeverages?.is_early_bird_breakfast}
                            errorMessage={errors?.formFoodBeverages?.is_early_bird_breakfast && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsEarlyBirdBreakfastAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Early bird breakfast price *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[early_bird_breakfast_price]`}
                        control={control}
                        disabled={(Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={Number(isEarlyBirdBreakfastAllowed) === 1 && !!errors?.formFoodBeverages?.early_bird_breakfast_price}
                            errorMessage={Number(isEarlyBirdBreakfastAllowed) === 1 && errors?.formFoodBeverages?.early_bird_breakfast_price && 'Please enter valid price'}
                            inputRef={field.ref}
                            {...field}
                            value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            onChange={(e) => {
                              const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                              field.onChange(value);
                            }}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: (Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit) ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <HeadingInput title='Early bird breakfast timings' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_early_bird_breakfast_from]`}
                          control={control}
                          disabled={(Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_early_bird_breakfast_to]`}
                          control={control}
                          disabled={(Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_early_bird_breakfast_from]`}
                          control={control}
                          disabled={(Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_early_bird_breakfast_to]`}
                          control={control}
                          disabled={(Number(isEarlyBirdBreakfastAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Is lunch available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[is_lunch]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formFoodBeverages?.is_lunch}
                            errorMessage={errors?.formFoodBeverages?.is_lunch && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsLunchAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Lunch price *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[lunch_price]`}
                        control={control}
                        disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={Number(isLunchAllowed) === 1 && !!errors?.formFoodBeverages?.lunch_price}
                            errorMessage={Number(isLunchAllowed) === 1 && errors?.formFoodBeverages?.lunch_price && 'Please enter valid price'}
                            inputRef={field.ref}
                            {...field}
                            value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            onChange={(e) => {
                              const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                              field.onChange(value);
                            }}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: (Number(isLunchAllowed) === 0) || (!permission?.edit) ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='What is the max. people capacity? *' />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Lunch timings *' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekday_lunch_max_people]`}
                        control={control}
                        disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekday_lunch_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekday_lunch_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_lunch_from]`}
                          control={control}
                          disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_lunch_to]`}
                          control={control}
                          disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekend_lunch_max_people]`}
                        control={control}
                        disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekend_lunch_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekend_lunch_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_lunch_from]`}
                          control={control}
                          disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_lunch_to]`}
                          control={control}
                          disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: .5, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HeadingInput title='Please specify the lunch' />
                        <Tooltip title='Are you offering a buffet, a 2 course menu, including or excluding drinks.' arrow>
                          <IconButton sx={{ml:1, p:0}}>
                            <InfoIcon sx={{color: theme.palette.icon.light}} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[lunch_list]`}
                        control={control}
                        defaultValue={''}
                        disabled={(Number(isLunchAllowed) === 0) || (!permission?.edit)}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            multiline
                            rows={4}
                            placeholder='List the items offered in lunch'
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Are snacks available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[is_snacks]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formFoodBeverages?.is_snacks}
                            errorMessage={errors?.formFoodBeverages?.is_snacks && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsSnacksAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title="Snack's price *" />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[snacks_price]`}
                        control={control}
                        disabled={(Number(isSnacksAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={Number(isSnacksAllowed) === 1 && !!errors?.formFoodBeverages?.snacks_price}
                            errorMessage={Number(isSnacksAllowed) === 1 && errors?.formFoodBeverages?.snacks_price && 'Please enter valid price'}
                            inputRef={field.ref}
                            {...field}
                            value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            onChange={(e) => {
                              const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                              field.onChange(value);
                            }}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: (Number(isSnacksAllowed) === 0) || (!permission?.edit) ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Please specify the snacks' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[snacks_list]`}
                        control={control}
                        defaultValue={''}
                        disabled={(Number(isSnacksAllowed) === 0) || (!permission?.edit)}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            multiline
                            rows={4}
                            placeholder='List the items offered in snacks'
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Is dinner available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[is_dinner]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formFoodBeverages?.is_dinner}
                            errorMessage={errors?.formFoodBeverages?.is_dinner && 'Please select a value'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsDinnerAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Dinner price *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[dinner_price]`}
                        control={control}
                        disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={Number(isDinnerAllowed) === 1 && !!errors?.formFoodBeverages?.dinner_price}
                            errorMessage={Number(isDinnerAllowed) === 1 && errors?.formFoodBeverages?.dinner_price && 'Please enter valid price'}
                            inputRef={field.ref}
                            {...field}
                            value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                            onChange={(e) => {
                              const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                              field.onChange(value);
                            }}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: (Number(isDinnerAllowed) === 0) || (!permission?.edit) ? false : true,
                          pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='What is the max. people capacity? *' />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Dinner timings *' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekday_dinner_max_people]`}
                        control={control}
                        disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekday_dinner_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekday_dinner_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_dinner_from]`}
                          control={control}
                          disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_dinner_to]`}
                          control={control}
                          disabled={Number(isDinnerAllowed) === 1? false : true}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekend_dinner_max_people]`}
                        control={control}
                        disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekend_dinner_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekend_dinner_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_dinner_from]`}
                          control={control}
                          disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_dinner_to]`}
                          control={control}
                          disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: .5, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <HeadingInput title='Please specify the dinner' />
                        <Tooltip title='Are you offering a buffet, a 2 course menu, including or excluding drinks.' arrow>
                          <IconButton sx={{ml:1, p:0}}>
                            <InfoIcon sx={{color: theme.palette.icon.light}} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formFoodBeverages[dinner_list]`}
                        control={control}
                        defaultValue={''}
                        disabled={(Number(isDinnerAllowed) === 0) || (!permission?.edit)}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            multiline
                            rows={4}
                            placeholder='List the items offered in dinner'
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              sx={{ justifyContent: 'center', alignItems: 'center', mb: 5 }}
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <HeadingInput title='Is late dinner available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{ py: 1 }}>
                      <Controller
                        name={`formFoodBeverages[is_late_dinner]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              },
                            ]}
                            isError={
                              !!errors?.formFoodBeverages?.is_late_dinner
                            }
                            errorMessage={
                              errors?.formFoodBeverages?.is_late_dinner &&
                              'Please select a value'
                            }
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            onChange={(e) => {
                              setIsLateDinnerAllowed(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                    <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                      <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                        <HeadingInput title='Late Dinner price *' />
                      </Grid>
                      <Grid item xs={12} sx={{py: 1}}>
                        <Controller
                          name={`formFoodBeverages[late_dinner_price]`}
                          control={control}
                          disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                          defaultValue={0}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              isError={Number(isLateDinnerAllowed) === 1 && !!errors?.formFoodBeverages?.dinner_price}
                              errorMessage={Number(isLateDinnerAllowed) === 1 && errors?.formFoodBeverages?.dinner_price && 'Please enter valid price'}
                              inputRef={field.ref}
                              {...field}
                              value={field.value?.toString()?.replace(/\./g, ',')?.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                              onChange={(e) => {
                                const value = e.target.value?.replace(/\./g, '')?.replace(/,/g, '.');
                                field.onChange(value);
                              }}
                              endAdornment={
                                <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                  In {currency}
                                </InputEndAdornment>
                              }
                            />
                          )}
                          rules={{
                            required: (Number(isDinnerAllowed) === 0) || (!permission?.edit) ? false : true,
                            pattern: /^(0|[1-9]\d*)(\.\d+)?$/
                          }}
                        />
                      </Grid>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ justifyContent: 'center', alignItems: 'center', mb: 2 }}
            >
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid
                    item
                    xs={6}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        pt: 1,
                        pb: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                      }}
                    >
                      <HeadingInput title='Late dinner timings' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>

              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{ px: 2 }}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_late_dinner_from]`}
                          control={control}
                          disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0,
                                  },
                                },
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ px: 2 }}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekday_late_dinner_to]`}
                          control={control}
                          disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0,
                                  },
                                },
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>

              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{ px: 2 }}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_late_dinner_from]`}
                          control={control}
                          disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0,
                                  },
                                },
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{ px: 2 }}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formFoodBeverages[weekend_late_dinner_to]`}
                          control={control}
                          disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0,
                                  },
                                },
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='What is the minimum people capacity for late dinner? *' />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='What is the maximum people capacity for late dinner? *' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekday_late_dinner_min_people]`}
                        control={control}
                        disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekday_late_dinner_min_people}
                            errorMessage={errors?.formFoodBeverages?.weekday_late_dinner_min_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekday_late_dinner_max_people]`}
                        control={control}
                        disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekday_late_dinner_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekday_late_dinner_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekend_late_dinner_min_people]`}
                        control={control}
                        disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekend_late_dinner_min_people}
                            errorMessage={errors?.formFoodBeverages?.weekend_late_dinner_min_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formFoodBeverages[weekend_late_dinner_max_people]`}
                        control={control}
                        disabled={(Number(isLateDinnerAllowed) === 0) || (!permission?.edit)}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formFoodBeverages?.weekend_late_dinner_max_people}
                            errorMessage={errors?.formFoodBeverages?.weekend_late_dinner_max_people && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of People
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /(^([1-9]\d*)$|^0$)/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6}>
                    <Grid item xs={12} sx={{py: 1}}>
                      <HeadingInput title='What kind of food is available in larger quantities?' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Grid container>
                        {availableFoods.map((food, index) => (
                          <Grid item xs={6} key={`failq-${index}`} sx={{py:1}}>
                            <Controller
                              name={`formFoodBeverages[available_foods][${food}]`}
                              control={control}
                              defaultValue={0}
                              disabled={!permission?.edit}
                              render={({ field }) => (
                                <CheckBox
                                  inputRef={field.ref}
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e);
                                  }}
                                />
                              )}
                            />
                            <Typography variant='caption' sx={{
                              ...theme.typography.textMedium,
                              ml: 1
                            }}>
                              {food}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid item xs={12} sx={{py: 1}}>
                  <HeadingInput title='Extras (hotel upsell opportunities)' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formFoodBeverages[extras]`}
                    control={control}
                    defaultValue={''}
                    disabled={!permission?.edit}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        placeholder='Ex: Free Welcome Drink from Airline X or Free Dessert'
                        inputRef={field.ref}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </form>
    </Box>
  );
});

export default FoodBeverages;