import React from 'react';
import { Box, Button, Typography, styled, useTheme, Card, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { arrayColumn } from '../../../utils';
import { isMobile, isTablet } from 'react-device-detect';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '3px',
  '& .MuiCardContent-root': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  color: theme.palette.text.primary,
  padding: 0,
}));

const AirlineGroundStaffCard = ({ airlineGroundStaffMember, removeUser }) => {
  const theme = useTheme();

  return (
    <StyledCard>
      <Grid container xs={12} sx={{p: 2.5, backgroundColor: theme.mode === 'light' ? theme.palette.sidebarBg : 'inherit'}} >
        <Grid item xs={(isMobile || isTablet) ? 12 : 6} >
          <Typography
            sx={{
              ...theme.typography.textLarge,
              ...theme.typography.fontMedium500,
              color: theme.palette.menu.main
            }}
          >
            {airlineGroundStaffMember?.airlineName[0]}
          </Typography>
        </Grid>
        <Grid item xs={(isMobile || isTablet) ? 12 : 6} sx={{mt: (isMobile || isTablet) ? 2 : 0}}>
          <Typography
            sx={{
              ...theme.typography.textLarge,
              ...theme.typography.fontMedium500,
              color: theme.palette.menu.main
            }}
          >
            {airlineGroundStaffMember?.airportName}
          </Typography>
        </Grid>
      </Grid>
      <Divider />
      <Box sx={{ p: 3 }}>
        <Typography
          sx={{
            ...theme.typography.textLarge,
            ...theme.typography.fontMedium500,
            textTransform: 'capitalize'
          }}
        >
          {airlineGroundStaffMember.first_name} {airlineGroundStaffMember.last_name}
        </Typography>

        <Typography
          sx={{
            ...theme.typography.textMedium,
            color: theme.palette.grey[500],
            mt: 1,
          }}
        >
          {airlineGroundStaffMember.email}
        </Typography>

        <StyledButton sx={{ mt: 2.5 }} onClick={() => removeUser(arrayColumn(airlineGroundStaffMember.airlines, 'id'), airlineGroundStaffMember.id)}>
          <RemoveCircleOutlineIcon
            sx={{ mr: 1, color: theme.palette.roseGold[100] }}
          />
          <Typography variant='caption' sx={theme.typography.dashboardSmall}>
            Remove User
          </Typography>
        </StyledButton>
      </Box>
    </StyledCard>
  );
};

export default AirlineGroundStaffCard;
