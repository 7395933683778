import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Box, Chip, Tab, Tabs, styled, useTheme } from '@mui/material';
import LayoverTable from './layoverTable';
import { useLoader } from '../../../../providers/loader';
import { AirlineContext } from '../../../../context/airline';
import { getRecentLayovers } from '../../../../services/layover/list';
import { isMobile, isTablet } from 'react-device-detect';
import { maxToastTime } from '../../../../helper/constant';
import { toast } from 'react-toastify';

const StyledTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.body1,
  paddingRight: 0,
  paddingLeft: 0,
  color: theme.palette.table.header,
  marginRight: theme.spacing(4),
  '&.Mui-selected': {
    borderBottomColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    '& .MuiChip-root':{
      background: theme.palette.info.dark,
    }
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  background: theme.palette.grey[800],
  color: theme.palette.common.white,
  height: 'auto',
  paddingTop: theme.spacing(.5),
  paddingRight: theme.spacing(1),
  paddingBottom: theme.spacing(.5),
  paddingLeft: theme.spacing(1),
  '& .MuiChip-label': {
    padding: 0
  }
}));

const StyledTabLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'none',
  '& .MuiChip-root': {
    marginLeft: theme.spacing(1)
  }
}));

const LayoverList = () => {
  const { setIsCommonTopbar, airlineId, airportId } = useContext(AirlineContext);
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [recentPage, setRecentPage] = useState(0);
  const [allPage, setAllPage] = useState(0);
  const [layoversList, setLayoversList] = useState({recentLayovers: [], allLayovers: []});
  const [layoversLength, setLayoversLength] = useState({recent:0, all:0});
  const { startLoader, stopLoader } = useLoader();
  let isCurrentDeviceMobileOrTab = isMobile || isTablet ? true : false;

  useEffect(() => {
    setIsCommonTopbar(false);
  }, [setIsCommonTopbar]);

  /**
   * Call API to get the recent layovers from API
   * 
   * @param {*} request   Request
   */
  const getRecentLayoversFromApi = useCallback(async (request) => {
    try {
      const response = await getRecentLayovers(request);
      return response;
    } catch (e) {
      throw e
    }
  }, [])

  const handleTabChange = (e, newValue) => {
    setSelectedTab(newValue);
    if (newValue === 0) {
      setRecentPage(0);
    } else {
      setAllPage(0);
    }
  };

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        // Get recent layovers
        const responseRecentLayovers = await getRecentLayoversFromApi({
          hour: 24,
          filter: 'recent',
          airlineId: airlineId,
          airportId: airportId
        });
        // Get all layovers
        const responseAllLayovers = await getRecentLayoversFromApi({
          airlineId: airlineId,
          airportId: airportId
        });
        resolve({
          recentLayovers: (responseRecentLayovers && responseRecentLayovers.length > 0) ? responseRecentLayovers : [],
          allLayovers: (responseAllLayovers && responseAllLayovers.length > 0) ? responseAllLayovers : []
        });
      } catch (error) {
        reject(error);
      }
    }).then((response) => {
      setLayoversList(response)
      setLayoversLength({
        recent: response.recentLayovers.length || 0,
        all: response.allLayovers.length || 0
      })
    }).catch((e) => {
      toast.error(e?.response?.data?.error?.message, {
        autoClose: maxToastTime,
        toastId: 'airline-layovers-list-flag',
      });
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, getRecentLayoversFromApi, airlineId, airportId]);

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.primary.light
          }
        }}
        sx={{ 
          ml: theme.spacing(isCurrentDeviceMobileOrTab ? 1 : 2),
          mt: theme.spacing(isCurrentDeviceMobileOrTab ? 4 : 1),
          mb: 2,
        }}
      >
        <StyledTab
          label={
            <StyledTabLabel as='span'>
              Recent layovers <StyledChip label={layoversLength?.recent || 0} />
            </StyledTabLabel>
          }
        />
        <StyledTab
          label={
            <StyledTabLabel as='span'>
              All layovers <StyledChip label={layoversLength?.all || 0} />
            </StyledTabLabel>
          }
        />
      </Tabs>
      <LayoverTable
        noLayoverFoundMessage={selectedTab === 0 ? 'There are no recent layovers for this airline.' : 'There are no layovers for this airline.'}
        isCurrentDeviceMobileOrTab={isCurrentDeviceMobileOrTab}
        layovers={selectedTab === 0 ? layoversList?.recentLayovers : layoversList?.allLayovers}
        selectedTab={selectedTab}
        recentPage={recentPage}
        setRecentPage={setRecentPage}
        allPage={allPage}
        setAllPage={setAllPage}
      />
    </Box>
  );
};

export default LayoverList;
