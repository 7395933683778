import { menus } from '../helper/menus';

/**
 * Get menus to render after user login (according to their role)
 */
function getMenus(role) {
  if(role && menus[role]) {
    return menus[role];
  }
  return null;
}

export default getMenus;