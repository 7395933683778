import { useContext, useState } from 'react';
import { Box, Typography, useTheme, styled } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { AlertDialog, CheckBox } from '../../components';
import { TermsConditionContext } from '../../context/termsCondition';

const Para = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.textSmall.fontSize,
  lineHeight: theme.lineHeight[1.37],
}));

const TermsConditionDialogue = (props) => {
  const {confirmStatus} = props;
  const { termsDialogOpen, setTermsDialogOpen } = useContext(TermsConditionContext);
  const [isCheckedEmailService, setIsCheckedEmailService] = useState(false);
  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const { control, handleSubmit } = useForm();
  const theme = useTheme();

  const onSubmit = () => {
    setTermsDialogOpen(false);
    confirmStatus()
  };

  const onTermsgAgree = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <Box>
      {/* Render Terms and Conditions Dialog */}
      <AlertDialog
        title='Croowy Terms of Services'
        open={termsDialogOpen}
        onAgree={onTermsgAgree}
        yesBtnText='Accept terms'
        fullWidth={true}
        maxWidth={'xs'}
        agreeButtonProps={{
          disabled: !isCheckedEmailService || !isCheckedTerms,
        }}
        stretchBtn
        BackdropProps={{
          style: {
            backgroundColor: theme.palette.secondary.contrastText
          }
        }}
      >
        <Box sx={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography sx={{ lineHeight: '1.37rem', py: theme.spacing(1) }} variant='subtitle2'>
              To start using Croowy Companion please accept the Terms of Services as well as the Croowy Privacy Policy below.
            </Typography>
            <Box sx={{
              marginBottom: theme.spacing(2),
              marginLeft: theme.spacing(1.2),
              marginTop: theme.spacing(2),
            }}>
              <Controller
                name='checkedEmailService'
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckBox
                    {...field}
                    checked={isCheckedEmailService}
                    onChange={(e) => {
                      setIsCheckedEmailService(e.target.checked);
                      field.onChange(e);
                    }}
                    label={
                      <Typography sx={{ lineHeight: theme.lineHeight[1.5], mt: theme.spacing(2), pl: theme.spacing(0.5) }}>
                        I agree to receive commercial notifications from Croowy by Email.
                      </Typography>
                    }
                  />
                )}
              />
              <Controller
                name='checkedTerms'
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckBox
                    {...field}
                    checked={isCheckedTerms}
                    onChange={(e) => {
                      setIsCheckedTerms(e.target.checked);
                      field.onChange(e);
                    }}
                    sx={{mt: theme.spacing(2)}}
                    label={
                      <Para sx={{ pl: theme.spacing(0.5), mt: theme.spacing(2)}}>
                        I agree to the{' '}
                        <span
                          style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                          onClick={() => {
                            window.open('https://croowy.de/terms', '_blank');
                          }}
                        >
                          Croowy Terms and Privacy Policy.
                        </span>
                      </Para>
                    }
                  />
                )}
              />
            </Box>
          </form>
        </Box>
      </AlertDialog>
    </Box>
  );
};

export default TermsConditionDialogue;
