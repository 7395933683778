const BorderRadius = {
  1: '1px',
  2: '2px',
  3: '3px',
  4: '4px',
  5: '5px',
  6: '6px',
  8: '8px',
  10: '10px',
  12: '12px',
  32: '32px',
};

export default BorderRadius;