import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, Rating, Typography, styled, useTheme } from '@mui/material';
import { HotelContext } from '../../context/hotel';
import { pageTitles } from '../../helper/constant';
import { useLoader } from '../../providers/loader';
import { getHotelDetails } from '../../services/hotel';
import { SubHeading } from './components/heading';
import { GoogleMap, SwiperCarousel } from '../../components';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import HotelIcon from '@mui/icons-material/Hotel';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { prepareImageUrlByPath } from '../../utils';
import { checkPermissions } from '../../utils/checkPermissions';

const StyledNavigateButton = styled(Button)(({ theme }) => ({
  marginTop: 'auto',
  marginLeft: 'auto',
  padding: 0,
  '& .MuiTypography-root': {
    ...theme.typography.textxSmall,
    textTransform: 'capitalize',
    color: theme.palette.text.primary
  },
  '& .label-ico': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light
  }
}));

const StyledFacility = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxSmall,
  backgroundColor: theme.palette.alert.light,
  borderRadius: theme.borderRadius[5],
  paddingTop: '14px',
  paddingRight: '16px',
  paddingBottom: '14px',
  paddingLeft: '16px',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const HotelDetails = () => {
  let { hotelId } = useParams();
  hotelId = Number(hotelId);
  const navigate = useNavigate();
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const { setIsCommonTopbar, setHeaderPageTitle } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const [hotel, setHotel] = useState(null);
  const [isHotelAccessible, setIsHotelAccessible] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [currentHighlighted, setCurrentHighlighted] = useState({});
  const editHotelInfoButton = checkPermissions('editHotelInfoButton')

  useEffect(() => {
    setIsCommonTopbar(true)
    setHeaderPageTitle(pageTitles.hotel.details);
  }, [setIsCommonTopbar, setHeaderPageTitle]);

  // Navigate to hotel edit page
  const navigateHotelEditPage = (hotelId) => {
    navigate(`/hotel/${hotelId}/edit`);
  };

  // Prepare array of images for the carousel
  const prepareCarouselImagesArray = (images) => {
    let imgArr = [];
    if (images && images.length > 0) {
      for (const img of images) {
        if (img?.formats?.medium?.url) {
          imgArr.push({
            url: process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(img.formats.medium.url) : img.formats.medium.url
          })
        }
      }
    }
    return imgArr;
  };

  /**
   * Get hotel details from API
   * 
   * @param {*} id  Hotel Id
   */
  const getHotelDetailsFromApi = useCallback(async (hotelId) => {
    const request = {
      'populate': '*'
    };
    const hotelDetails = await getHotelDetails(hotelId, {params: request});
    if (hotelDetails) {
      setHotel(hotelDetails);
    }
  }, [setHotel]);

  useEffect(() => {
    if (user.user?.hotels && user.user.hotels.length > 0) {
      const accessibleHotelIds = user.user.hotels.map(i => Number(i.id));
      setIsHotelAccessible(accessibleHotelIds.includes(hotelId))
    }
  }, [user.user, hotelId])

  useEffect(() => {
    if (hotelId && isHotelAccessible === true) {
      startLoader();
      Promise.allSettled([
        getHotelDetailsFromApi(hotelId)
      ]).then(() => {
        stopLoader();
      });
    }
  }, [hotelId, isHotelAccessible, getHotelDetailsFromApi, startLoader, stopLoader])

  useEffect(() => {
    if (hotel && hotel?.lat && hotel?.lng) {
      setMapCenter((prev) => ({
        ...prev,
        lat: hotel.lat,
        lng: hotel.lng
      }));
      setMarkerData([{
        hotelId: hotel.id,
        name: hotel.name,
        address: `${hotel.city.city_name}${hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : ''}`,
        lat: hotel.lat,
        lng: hotel.lng
      }]);
      setCurrentHighlighted({
        hotelId: hotel.id,
        name: hotel.name,
        address: `${hotel.city.city_name}${hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : ''}`,
        lat: hotel.lat,
        lng: hotel.lng
      });
    }
  }, [hotel])

  return (
    <Box sx={{
      py: 1
    }}>
      <Box>
        <Box sx={{
          display: 'flex',
          px: 2
        }}>
          {isHotelAccessible === false ? (
            <Grid container>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>
                  You do not have access to this hotel. Please contact the administrator if you believe this is incorrect.
                </Typography>
              </Grid>
            </Grid>
          ) : (
            hotel ? (
              <Grid container spacing={8} sx={{mt:0}}>
                <Grid item sm={7} xs={12} sx={{pt:'0 !important'}}>
                  <Grid container>
                    <Grid item xs={12} sx={{mb:2}}>
                      <Grid container>
                        <Grid item sm={8} xs={12}>
                          <Box sx={{display: 'flex'}}>
                            <Box>
                              <Box>
                                <Typography sx={{
                                  flexGrow: 1,
                                  ...theme.typography.textLarge
                                }} variant='caption'>{hotel.name}</Typography>
                              </Box>
                              <Box>
                                <Rating value={hotel.rating} size='small' readOnly sx={{mt: 1, mr: 1, color: theme.palette.rating.light}} />
                                <Typography sx={{
                                  flexGrow: 1,
                                  ...theme.typography.textSmall,
                                  verticalAlign: 'text-top'
                                }} variant='caption'>{hotel.rating} Star</Typography>
                              </Box>
                            </Box>
                            <Box sx={{flexGrow: 1}}>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Box sx={{display: 'flex', width: '100%', height: '100%'}}>
                            { editHotelInfoButton?.view ? (
                              <StyledNavigateButton onClick={() => navigateHotelEditPage(hotelId)}>
                                <BorderColorIcon fontSize='small' className='label-ico' />
                                <Typography variant='caption'>Edit Hotel Info</Typography>
                              </StyledNavigateButton>
                            ):(null)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      {(hotel?.images && hotel.images.length > 0) ? (
                        <SwiperCarousel
                          images={prepareCarouselImagesArray(hotel.images)}
                          swiperSlideProps={{
                            style: {
                              minHeight: '305px'
                            }
                          }}
                        />
                      ) : (
                        <Typography sx={{...theme.typography.textxSmall, color: theme.palette.error.dark}}>
                          Please attach images
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                        <Box sx={{display: 'inline-flex', alignItems: 'center', mr: 2}}>
                          <HotelIcon sx={{mr:1}} />
                          <Typography sx={{
                            ...theme.typography.textSmall,
                            ...theme.typography.fontMedium600
                          }} variant='caption'>
                            {hotel?.total_rooms ? (
                              `${hotel.total_rooms} Rooms`
                            ) : (
                              <Typography sx={{color: theme.palette.error.dark}}>
                                0 Rooms
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                        <Box sx={{display: 'inline-flex', alignItems: 'center', mr: 2}}>
                          <FlightTakeoffIcon sx={{mr:1}} />
                          <Typography sx={{
                            ...theme.typography.textSmall,
                            ...theme.typography.fontMedium600
                          }} variant='caption'>
                            {(hotel?.airportDistance?.distance_min) ? (
                              `${hotel.airportDistance.distance_min} min drive to Airport`
                            ) : (
                              <Typography sx={{color: theme.palette.error.dark}}>
                                0 min drive to Airport
                              </Typography>
                            )}
                          </Typography>
                        </Box>
                        {(hotel?.airportDistance?.is_by_foot) ? (
                          <Box sx={{display: 'inline-flex', alignItems: 'center'}}>
                            <DirectionsWalkIcon sx={{mr:1}} />
                            <Typography sx={{
                              ...theme.typography.textSmall,
                              ...theme.typography.fontMedium600
                            }} variant='caption'>    
                              Hotel Is Accessible By Foot From Airport
                            </Typography>
                          </Box>
                        ) : (
                          ''
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      <SubHeading title='Short Description' sx={{mb:2}} />
                      {hotel?.description ? (
                        <Typography
                          sx={{lineHeight: '1.37rem'}}
                          dangerouslySetInnerHTML={{
                            __html: (hotel.description).replaceAll(/(\n)/g, '<br/>')
                          }}
                        />
                      ) : (
                        <Typography sx={{color: theme.palette.error.dark}}>
                          Please provide description
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      <SubHeading title='Location' sx={{mb:2}} />
                      {mapCenter ? (
                        <Box sx={{width:'100%', height:'250px'}}>
                          <GoogleMap
                            markerData={markerData}
                            mapCenter={mapCenter}
                            setMapCenter={setMapCenter}
                            currentHighlighted={currentHighlighted}
                            setCurrentHighlighted={setCurrentHighlighted}
                            zoomValue={11}
                          />
                        </Box>
                      ) : (
                        <Typography sx={{color: theme.palette.error.dark}}>
                          Please provide lattitude and longitude of the hotel
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={5} xs={12} sx={{pt:'0 !important'}}>
                  <Grid container>
                    <Grid item xs={12} sx={{mb:4}}>
                      <SubHeading title='Facilities' sx={{mb:2}} />
                      {(hotel?.facilities && hotel?.facilities.length > 0) ? (
                        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                          {hotel?.facilities.map((facility, index) => (
                            facility?.active ? (
                              <StyledFacility>{facility.name}</StyledFacility>
                            ) : (null)
                          ))}
                        </Box>
                      ) : (
                        <Typography sx={{color: theme.palette.error.dark}}>
                          Please select the facilities
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      <SubHeading title='Other Services Include' sx={{mb:2}} />
                      {(hotel?.other_services && hotel?.other_services.length > 0) ? (
                        <Box>
                          {hotel?.other_services.map((service, index) => (
                            service?.active ? (
                              <Box sx={{display: 'flex', alignItems: 'normal', mb: 1}}>
                                <CheckIcon fontSize='small' sx={{color: theme.palette.primary.light, mr:1}} />
                                <Typography
                                  sx={{
                                    ...theme.typography.textxSmall,
                                    lineHeight: '1.25rem'
                                  }}
                                >{service.name}</Typography>
                              </Box>
                            ) : (null)
                          ))}
                        </Box>
                      ) : (
                        <Typography sx={{color: theme.palette.error.dark}}>
                          Please select the other services
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{mb:4}}>
                      <SubHeading title='Contact Details' sx={{mb:2}} />
                      <Box sx={{mb:3}}>
                        <Box sx={{display: 'flex', alignItems: 'center', mb:1}}>
                          <EmailIcon sx={{mr:1, color: theme.palette.menu.light}} />
                          <Typography sx={{
                            ...theme.typography.textMedium,
                            color: theme.palette.menu.light
                          }} variant='caption'>Email Address</Typography>
                        </Box>
                        <Box>
                          {hotel?.contact_email ? (
                            <Typography sx={{
                              ...theme.typography.textMedium,
                              color: theme.palette.grey[500]
                            }} variant='caption'>{hotel.contact_email}</Typography>
                          ) : (
                            <Typography sx={{color: theme.palette.error.dark}}>
                              Please provide the email for contact
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        <Box sx={{display: 'flex', alignItems: 'center', mb:1}}>
                          <PhoneIcon sx={{mr:1, color: theme.palette.menu.light}} />
                          <Typography sx={{
                            ...theme.typography.textMedium,
                            color: theme.palette.menu.light
                          }} variant='caption'>Contact Number</Typography>
                        </Box>
                        <Box>
                          {hotel?.contact_phone ? (
                            <Typography sx={{
                              ...theme.typography.textMedium,
                              color: theme.palette.grey[500]
                            }} variant='caption'>{hotel.contact_phone}</Typography>
                          ) : (
                            <Typography sx={{color: theme.palette.error.dark}}>
                              Please provide the phone for contact
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (null)
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default HotelDetails;