import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { getLayoverDetailsForHotel } from '../../../../../services/layover/details';
import { useLoader } from '../../../../../providers/loader';
import formatTimestamp from '../../../../../utils/formatTimestamp';
import { HotelContext } from '../../../../../context/hotel';
import formatPrice from '../../../../../utils/formatPrice';
import { isMobile, isTablet } from 'react-device-detect';
import { Box, useTheme, Grid, Typography } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Document as DocumentView, Page as PageView } from 'react-pdf';
import { ActionButton } from '../../../../../components';
import downloadPDF from '../../../../../utils/downloadPDF';

// Styles required for cost assumption pdf
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    padding: 40
  },
  section: {
    flexGrow: 1,
  },
  fontSizeForText: { fontSize: 10 },
  header: {
    textAlign: 'center',
    fontWeight: 'bolder',
    fontSize: 14,
    marginBottom: 10
  },
  address: {
    display: 'flex',
    justifyContent:'space-between',
    flexDirection: 'row',
    gap: 3,
    marginBottom: 2,
    fontSize: 10
  },
  screenRight: { 
    width: '50%', 
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'flex-end'
  },
  centerTextBet: {
    position: 'absolute',
    left: '51%',
    top: 66
  },
  centerTextAnd: {
    top: 76,
    left: '52.5%',
  },
  disclaimer: {
    wordSpacing: 1,
    marginTop: 60,
    marginBottom: 15,
    lineHeight: 1.2
  },
  paragraph: {
    lineHeight: 1.3,
    wordSpacing: 1
  },
  heading: {
    fontSize: 12,
    fontWeight: 'heavy',
    margin: 8,
    marginLeft: 0
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    marginBottom: 15,
    borderWidth: 1,
  },
  tableRow: { flexDirection: 'row' },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 11,
    borderWidth: 1,
    padding: 3,
    flex: 1,
    margin: -0.5,
    textAlign: 'center'
  },
  tableCell: {
    margin: -0.5,
    padding: 5,
    flex: 1,
    borderWidth: 1,
    fontSize: 10,
    textAlign: 'center'
  },
  hr: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginVertical: 10,
  },
});

const CostAssumptionPdf = () => {
  const { hotel } = useContext(HotelContext);
  const { layoverId } = useParams();
  const { startLoader, stopLoader } = useLoader();
  const [layoverInfo, setLayoverInfo] = useState([])
  const [tableDataMeals, setTableDataMeals] = useState({Price: [], Quantity: []})
  const [numPages, setNumPages] = useState(null);
  const theme = useTheme();
  
  /**
   * Get booking details from layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getLayoverDetailsForHotelFromApi = useCallback(async (layoverId, hotelId) => {
    try {
      const response = await getLayoverDetailsForHotel(layoverId, hotelId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // download invoice
  const handleDownloadInvoice = async (pdfUrl) => {
    await downloadPDF(pdfUrl);
  };

  useEffect(()=>{
    if (hotel && layoverId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getLayoverDetailsForHotelFromApi(layoverId, hotel?.id)
          resolve(response)
        } catch (e){
          reject(e)
        }
      }).then(response => {
        if (response) {
          setLayoverInfo(response?.bookingInfo)
          setTableDataMeals({ 
            ...tableDataMeals,
            Quantity:[
              response?.bookingInfo?.meals?.isEarlyBirdBreakfastQty || '-',
              response?.bookingInfo?.meals?.breakFastQty || '-',
              response?.bookingInfo?.meals?.lunchQty || '-',
              response?.bookingInfo?.meals?.DinnerQty || '-',
              response?.bookingInfo?.meals?.snacksQty || '-'
            ],
            Price: [
              response?.bookingInfo?.meals?.isEarlyBirdBreakfastPrice || '-',
              response?.bookingInfo?.meals?.breakfastPrice || '-',
              response?.bookingInfo?.meals?.lunchPrice || '-',
              response?.bookingInfo?.meals?.DinnerPrice || '-',
              response?.bookingInfo?.meals?.snacksPrice || '-'
            ]
          })
        }
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLoader, stopLoader, hotel, layoverId, getLayoverDetailsForHotelFromApi])
  
  return (
    <Box sx={{my: 2}}>
      {!(isMobile || isTablet) ? (
        <PDFViewer fileName='costAssumption.pdf' style={{ width: '100%', height: '800px' }}>
          <CostAssumptionDocument layoverInfo={layoverInfo} hotel={hotel} tableDataMeals={tableDataMeals}/>
        </PDFViewer>
      ):(
        <BlobProvider 
          document={<CostAssumptionDocument layoverInfo={layoverInfo} hotel={hotel} tableDataMeals={tableDataMeals}/>} 
          fileName='costAssumption.pdf'
        >
          {({ url, loading }) =>
            loading ? 'loading...' : (
              <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', px: 1}}>
                <Grid sx={{ overflow: 'hidden' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}
                  >
                    <Typography sx={{
                      ...theme.typography.textxLarge, 
                      color: theme.palette.text.light, 
                      textTransform: 'capitalize'
                    }}>
                      Cost Assumption PDF 
                    </Typography>
                    <ActionButton
                      startIcon={<FileDownloadIcon sx={{ color: theme.palette.roseGold[100] }} />}
                      onClick={()=>handleDownloadInvoice(url)}
                      sx={{
                        ...theme.typography.textLarge
                      }}
                    >
                      Download PDF
                    </ActionButton>
                  </Box>
                </Grid>
                <DocumentView file={url} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <PageView
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={0.75}
                      devicePixelRatio={1}
                    />
                  ))}
                </DocumentView>
              </Box>
            )
          }
        </BlobProvider>
      )}
    </Box>
  );
};

export default CostAssumptionPdf;

const CostAssumptionDocument = ({layoverInfo, hotel, tableDataMeals}) => {
  const tableHeaderDataMeals = ['Meal', 'Early Bird Breakfast', 'Breakfast', 'Lunch', 'Dinner', 'Snacks']
  return(
    <Document title={'Cost assumption declaration'}>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Cost assumption declaration</Text>
          <View style={styles.address}>
            <View style={{width: '50%'}}>
              <Text>{layoverInfo?.airlineName}</Text>
            </View>
            <View style={styles.screenRight}>
              <Text>{hotel?.name}</Text>
            </View>
          </View>
          <View style={styles.address}>
            <View style={{width: '50%'}}>
              <Text>{layoverInfo?.airlineAddress}</Text>
            </View>
            <View style={styles.screenRight}>
              <Text>{hotel?.add1}</Text>
            </View>
          </View>
          <View style={styles.address}>
            <View style={{width: '50%'}}>
              <Text>{layoverInfo?.airlineCity}</Text>
            </View>
            <View style={styles.screenRight}>
              <Text>{hotel?.city?.city_name}</Text>
            </View>
          </View>
          <View style={styles.address}>
            <View style={{width: '50%'}}>
              <Text>{layoverInfo?.airlineCountry}</Text>
            </View>
            <View style={styles.screenRight}>
              <Text>{hotel?.city?.country_id?.name}, {hotel?.postal_code}</Text>
            </View>
          </View>
          <View style={styles.address}>
            <Text>(in the following stated as „Airline”)</Text>
            <Text>(in the following stated as „Hotel“)</Text>
          </View>
          <Text style={[styles.disclaimer, styles.fontSizeForText]}>Disclaimer: This cost assumption declaration represents a good-faith estimate of potential expenses. The actual invoice amount may differ. The Airline agrees to cover costs within a reasonable variance from the estimated
          amounts, taking into consideration the dynamic nature of airline operations.
          </Text>
          <View style={styles.heading}>
            <Text>1. Flight information</Text>
          </View>
          <View style={styles.fontSizeForText}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
            <Text style={styles.tableHeader}>Flight number</Text>
            <Text style={styles.tableHeader}>Passengers</Text>
            <Text style={styles.tableHeader}>Estimated arrival in the hotel</Text>
            <Text style={styles.tableHeader}>Latest arrival at the airport</Text>
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>{layoverInfo?.flightCode}</Text>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>{layoverInfo?.passengerDetails?.totalPassengers}</Text>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>{layoverInfo?.estimatedArrivalAtHotel}</Text>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>
                {formatTimestamp(
                  null, // Exclude row?.bookingDate
                  layoverInfo?.isDifferentReturnTimes,
                  layoverInfo?.passangerAirportReturnDate,
                  layoverInfo?.passangerAirportReturnTime,
                  layoverInfo?.latestReturnToAirport
                )}
              </Text>
            </View>
          </View>
          <Text style={{marginBottom: 3}}>Booking employee of the airline: {`${layoverInfo?.airlineRepresentativeFirstName} ${layoverInfo?.airlineRepresentativeLastName}`}</Text>
          <Text style={{marginBottom: 3}}>The billing address corresponds to the company address above.</Text>
          </View>
          {layoverInfo?.roomsInfo ? (
            <>
              <View style={styles.hr} />
              <View style={styles.heading}>
                <Text>2. Rooms booked</Text>
              </View>
              <Text style={{marginBottom: 3, fontSize: 10}}>Nightly rate:</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Night No.</Text>
                <Text style={styles.tableHeader}>1</Text>
                <Text style={styles.tableHeader}>2</Text>
                <Text style={styles.tableHeader}>Rooms Booked Night 1</Text>
                <Text style={styles.tableHeader}>Rooms Booked Night 2</Text>
                </View>
                {layoverInfo.roomsInfo.map((room, i) => (
                  <View key={`h-r-${i}`} style={styles.tableRow}>
                    <Text style={[styles.tableCell, styles.fontSizeForText, {textTransform: 'capitalize'}]}>{room.name}</Text>
                    <Text style={[styles.tableCell, styles.fontSizeForText]}>{formatPrice(room.total_rate_without_meal)}</Text>
                    <Text style={[styles.tableCell, styles.fontSizeForText]}>-</Text>
                    <Text style={[styles.tableCell, styles.fontSizeForText]}>{room.total}</Text>
                    <Text style={[styles.tableCell, styles.fontSizeForText]}>-</Text>
                  </View>
                ))}
              </View>
            </>
          ) : (null)}
          <View style={[styles.paragraph, styles.fontSizeForText]}>
            <Text>
            Both parties acknowledge that the number of rooms booked may be subject to change on short  notice due to the nature of the business. The Hotel agrees to work flexible with any room adjustments, provided such adjustments are communicated to the Hotel within 4 hours.
            </Text>
            <Text style={{marginTop: 1}}>
            In the case of a room reduction, the Airline will be responsible for any cancellation fees associated with the reduced number of rooms, according to the Hotel's cancellation policy. If the hotels agreed to waive no show charges in their Croowy Companion profile, the cancellation policy does not apply.
            </Text>
          </View>
          <View style={styles.heading}>
            <Text>3. Meals for booked guests</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
            {tableHeaderDataMeals.map((headData) => (
              <Text style={styles.tableHeader}>{headData}</Text>
            ))}
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>Price</Text>
              {tableDataMeals?.Price?.map((tableDataRow, ind) => (
                  <Text key={ind} style={[styles.tableCell, styles.fontSizeForText]}>{formatPrice(tableDataRow)}</Text>
              ))}
            </View>
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.fontSizeForText]}>Quantity</Text>
              {tableDataMeals?.Quantity?.map((tableDataRow, ind) => (
                  <Text key={ind} style={[styles.tableCell, styles.fontSizeForText]}>{tableDataRow}</Text>
              ))}
            </View>
          </View>
          <Text style={[styles.paragraph, styles.fontSizeForText]}>The meals are produced for the number of people booked. All additional meals are prepared on demand.</Text>
          {( layoverInfo?.additionalService && layoverInfo.additionalService.length > 0) ? (
            <>
              <View style={styles.hr} />
              <View style={[styles.heading, {marginTop: 35}]}>
                <Text>4. Additional services</Text>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <Text style={styles.tableHeader}>Service</Text>
                  {layoverInfo?.additionalService.map((service, i) => (
                    <Text key={`h-os-${i}`} style={styles.tableHeader}>{service.name}</Text>
                  ))}
                </View>
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>-</Text>
                  {layoverInfo?.additionalService.map((service, i) => (
                    <Text style={[styles.tableCell, styles.fontSizeForText]}>Yes</Text>
                  ))}
                </View>
              </View>
            </>
          ) : (null)}
          <Text style={[styles.paragraph, styles.fontSizeForText, {marginTop: 5}]}>
            Guests are responsible for any additional services consumed by them.
          </Text>
          <View style={[styles.paragraph, styles.fontSizeForText]}>
          <View style={[styles.hr, {borderBottomWidth: .6}]} />
          <Text>
            5. Croowy GmbH is merely an intermediary and not a provider of the offers displayed on its platform and therefore has no control over them. This agreement is concluded exclusively between the airline and the hotel.
          </Text>
          <View style={[styles.hr, {borderBottomWidth: .6}]} />
          <Text>
            6. The General Terms of Use of Croowy GmbH apply, as well as German law to the exclusion of the UN Convention on Contracts for the International Sale of Goods.
          </Text>
          </View>
        </View>
      </Page>
    </Document>
  )
};