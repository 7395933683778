import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, Divider, Typography, styled, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { AlertDialog, InnerSwitch } from '../../../../components';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { createMapLink } from '../../../../utils';
import { updateHotelDetails } from '../../../../services/hotel';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../redux/actions/user';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../helper/constant';
import { useLoader } from '../../../../providers/loader';
import { checkPermissions } from '../../../../utils/checkPermissions';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .MuiCardContent-root': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  '& .hotel-link-map': {
    color: theme.palette.primary.light
  }
}));

const StyledNavigateButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  marginBottom: theme.spacing(2),
  padding: 0,
  '& .MuiTypography-root': {
    ...theme.typography.textxSmall,
    textTransform: 'capitalize',
    color: theme.palette.text.primary
  },
  '& .label-ico': {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.light
  },
  '& .navigate-ico': {
    color: theme.palette.grey[900]
  }
}));

const HotelCard = (props) => {
  const { user, hotel, lastAccessedHotelId, onLastAccessedHotelChange } = props;
  const theme = useTheme();
  const { startLoader, stopLoader } = useLoader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isBookingsOpen, setIsBookingsOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const userPermissionEditHotelInfoButton = checkPermissions('editHotelInfoButton');

  useEffect(() => {
    setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
  }, [hotel]);

  // Navigate to hotel page
  const navigateHotelPage = (hotelId) => {
    navigate(`/hotel/${hotelId}`);
  };

  // Update is open for layover flag on hotel level
  const onChangeLayoverBookingSwitch = (checked) => {
    setDialogOpen(true);
    setIsBookingsOpen(checked);
  };

  const onDialogAgree = useCallback(async (hotelId) => {
    setDialogOpen(false);
    startLoader();
    const response = await updateHotelDetails(hotelId, { is_open_for_layover: hotel.is_open_for_layover === isBookingsOpen ? !isBookingsOpen : isBookingsOpen });
    const errorResponse = response?.response?.data?.error?.details?.errorDetails;

    if (response === undefined || (errorResponse && Object.keys(errorResponse).length > 0)) {
      let msg = Object.keys(errorResponse).map((errorMessage, errorIndex) => {
        return `<p style="margin-bottom: 5px;"><b>${errorIndex + 1}.</b> ${errorResponse[errorMessage]}</p>`;
      }).join('');

      toast.warning(<div dangerouslySetInnerHTML={{ __html: msg }} />, {
        autoClose: maxToastTime,
        toastId: 'layover-bookings-flag',
      });
      setIsBookingsOpen(false);
      if (hotel.is_open_for_layover === true) {
        await updateHotelDetails(hotelId, { is_open_for_layover: false });
      }
      stopLoader();
    } else {
      stopLoader();
      try {
        let userHotels = null;
        if (user.user?.hotels) {
          userHotels = user.user.hotels.map((hotel, i) => (
            hotel.id === hotelId ? {
              ...hotel,
              is_open_for_layover: isBookingsOpen
            } : hotel
          ));
        }
        dispatch(
          setUser({
            ...user,
            user: {
              ...user.user,
              hotels: userHotels
            }
          })
        );
        const msg = isBookingsOpen ? toastMessages.layoverBookings.open.success : toastMessages.layoverBookings.close.success;
        toast.success(msg, {
          autoClose: maxToastTime,
          toastId: 'layover-bookings-flag',
        })
        if (lastAccessedHotelId === hotelId) {
          onLastAccessedHotelChange(hotelId);
        }
      } catch (error) {
        const msg = isBookingsOpen ? toastMessages.layoverBookings.open.error : toastMessages.layoverBookings.close.error;
        toast.error(msg, {
          autoClose: maxToastTime,
          toastId: 'layover-bookings-flag',
        })
      }
    }
    stopLoader();
  }, [user, startLoader, stopLoader, dispatch, isBookingsOpen, lastAccessedHotelId, onLastAccessedHotelChange]);

  const onDialogDisagree = () => {
    setDialogOpen(false);
    setIsBookingsOpen(hotel.is_open_for_layover);
  };

  // Get the UI of dropdown item
  const getListItem = (hotel) => {
    if (hotel) {
      return (
        <Box sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          padding: theme.spacing(2)
        }}>
          <Typography sx={{
            flexGrow: 1,
            ...theme.typography.textLarge,
            pb: 1
          }} variant='caption'>{hotel.name}</Typography>
          {hotel?.city?.city_name ? (
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end'
            }}>
              <Typography
                sx={{
                  ...theme.typography.textxSmall,
                  flexGrow: 1
                }}
                variant='caption'
              >
                {hotel.city.city_name}{hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : ''}
              </Typography>
              <Link className='hotel-link-map' to={createMapLink(hotel.name, hotel?.lat, hotel?.lng)} target='__blank'>
                <Typography sx={{...theme.typography.textxxSmall, color: theme.palette.menu.light}} variant='caption'>View on map</Typography>
              </Link>
            </Box>
          ) : ('')}
        </Box>
      );
    }
    return null;
  };

  return (
    <StyledCard>
      <CardContent sx={{p: 0}}>
        {getListItem(hotel)}
        <Divider />
          <Box sx={{p: 2}}>
            <InnerSwitch
              checked={isBookingsOpen}
              onChange={(e) => onChangeLayoverBookingSwitch(e.target.checked)}
              formControlLabelSx={{mr: 1}}
            />
            <Typography variant='caption' sx={theme.typography.textxxSmall}>Open for Layover Booking</Typography>
          </Box>
        <Divider />
      </CardContent>
      <CardActions sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 2
      }}>
        <StyledNavigateButton
          style={{marginLeft: 0}}
          onClick={() => navigateHotelPage(hotel.id)}
        >
          <BorderColorIcon fontSize='small' className='label-ico' />
          <Typography variant='caption'>
            {userPermissionEditHotelInfoButton.edit ?  'Edit hotel info': 'Open hotel info'}
          </Typography>
          <Box sx={{flexGrow: 1, textAlign: 'right'}}>
            <OpenInNewIcon fontSize='small' className='navigate-ico' />
          </Box>
        </StyledNavigateButton>
      </CardActions>

      {/* Render Confirm Dialog */}
      <AlertDialog
        title='Are you sure?'
        open={dialogOpen}
        onAgree={() => onDialogAgree(hotel.id)}
        onDisagree={onDialogDisagree}
        yesBtnText={`Yes, ${hotel?.is_open_for_layover === true ? 'Close' : 'Open'}`}
        noBtnText='No, Go back'
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          Do you really want to {hotel?.is_open_for_layover === true ? 'close' : 'open'} the hotel <Typography sx={{...theme.typography.textMedium, ...theme.typography.fontMedium600}} variant='caption'>{hotel?.name}</Typography> for layovers?
        </Typography>
      </AlertDialog>
    </StyledCard>
  );
};

export default HotelCard;
