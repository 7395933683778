import { api } from '..';
import { toastMessages } from '../../helper/constant';
import { handleApiError } from '../handleApiError';

/**
 * User login
 * 
 * @param {*} request 
 * @returns 
 */
export const login = async(request) => {
  try {
    const response = await api.post('/auth/local?populate=role', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      let message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      if (e.response.status === 400) {
        message = toastMessages.login.failed;
      }
      handleApiError(e.response.status, message)
    }
    throw e
  }
};