import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled, Box } from '@mui/material';
import { LargeHeading } from '../components/heading';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset'
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: theme.palette.table.divider
}));

const BookingsTable = ({ layovers }) => {
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      {layovers && layovers?.length > 0 ?
       <Table sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell sx={{ width: '20%' }}>Booking Date & Time</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ width: '20%', textAlign: 'center' }}>Airline</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ width: '15%', textAlign: 'center' }}>Layover Id</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ width: '10%', textAlign: 'center' }}>No. of Rooms</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ width: '20%', textAlign: 'center' }}>Meals</StyledTableHeaderCell>
            <StyledTableHeaderCell sx={{ textAlign: 'center' }}>Registered Pax</StyledTableHeaderCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {layovers?.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer'}}
              onClick={() => navigate(`/layovers/${row?.layoverId}/details`)}
            >
              <StyledTableCell component='th' scope='row' sx={{ width: '20%' }}>
                {moment(row?.bookingDate).format('DD.MM.YY, ') + moment(row?.bookingTime, 'HH:mm:ss.SSS').format('HH:mm a')}
              </StyledTableCell>
              <StyledTableCell sx={{ width: '20%', textAlign: 'center' }}>
                {row?.airlineName}
              </StyledTableCell>
              <StyledTableCell sx={{ width: '15%', textAlign: 'center' }}>{row?.flightCode}</StyledTableCell>
              <StyledTableCell sx={{ width: '10%', textAlign: 'center' }}>{row?.totalRooms}</StyledTableCell>
              <StyledTableCell sx={{ width: '20%', textAlign: 'center' }}>
                {(typeof row.meals !== 'object' || (row.meals.isBreakfast === false && row.meals.isLunch === false && row.meals.isDinner === false && row.meals.isEarlyBreakfast === false && row.meals.isSnacks === false))
                  ? '-'
                  :  [
                    row.meals.isEarlyBreakfast && 'Early bird breakfast',
                    row.meals.isBreakfast && 'Breakfast',
                    row.meals.isLunch && 'Lunch',
                    row.meals.isDinner && 'Dinner',
                    row.meals.isSnacks && 'Snacks'
                  ].filter(Boolean).join(', ')
                }
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'center' }}>{row.registeredPax ? row.registeredPax : '-'}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      :
      <Box sx={{ width:'100%', py: 25, display: 'flex', justifyContent: 'center' }}>
        <LargeHeading title='There haven’t been bookings in the last 48 hours.' style={{ textAlign: 'center' }} />
      </Box>}
    </TableContainer>
  );
};

export default BookingsTable;
