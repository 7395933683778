import { memo, useEffect, useState } from 'react';
import { Box, Typography, styled, useTheme } from '@mui/material';
import { prepareImageUrlByPath } from '../../../../../utils';
import HotelDefaultImg from '../../../../../assets/images/hotel_default.png';

const StyledHotelImgBox = styled(Box, {
  shouldForwardProp: (prop) => ['img'].indexOf(prop) === -1
})(({ theme, img }) => ({
  backgroundImage: `url(${img})`,
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'auto 100%',
  width: '100%',
  height: '100%',
  minHeight: '150px',
  maxHeight: '350px',
  position: 'relative'
}))

const StyledImgBottomOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  padding: theme.spacing(2),
  backgroundColor: `${theme.palette.common.black}90`,
  backdropFilter: 'blur(6px)'
}))

const useProgressiveImage = (src) => {  
  const [sourceLoaded, setSourceLoaded] = useState(null)

  useEffect(() => {
    const img = new Image()
    img.src = src
    img.onload = () => setSourceLoaded(src)
  }, [src])

  return sourceLoaded 
}

const HotelSelectionHotelImg = (props) => {
  const { hotelImgUrl, hotelName, cityName, countryName } = props;
  const theme = useTheme();
  const url = hotelImgUrl ? (process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(hotelImgUrl) : hotelImgUrl) : HotelDefaultImg
  const loaded = useProgressiveImage(url);

  return (
    <StyledHotelImgBox img={loaded}>
      <StyledImgBottomOverlay>
        <Typography variant='body1' sx={{
          ...theme.typography.fontMedium600,
          flexGrow: 1,
          pr: 1,
          color: theme.mode === 'light' ? theme.palette.primary.main :theme.palette.common.white
        }}>
          {hotelName}
        </Typography>
        {cityName ? (
          <Typography sx={{...theme.typography.textxSmall, color: theme.mode === 'light' ? theme.palette.primary.main :theme.palette.common.white}} variant='caption'>{cityName}{countryName ? `, ${countryName}` : ''}</Typography>
        ) : ('')}
      </StyledImgBottomOverlay>
    </StyledHotelImgBox>
  );
};

export default memo(HotelSelectionHotelImg);