import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel details
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getHotelDetails = async(hotelId, request = {}) => {
  try {
    const response = await api.get(`/hotels/${hotelId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Update hotel details
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const updateHotelDetails = async(hotelId, request = {}) => {
  try {
    if (!request?.hotelId) {
      request.hotelId = hotelId
    }
    const response = await api.post(`/hotels`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e.response.status !== 412 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Delete image from the server
 * 
 * @param {*} imageId   Image id to be deleted
 * @returns 
 */
export const deleteImage = async(imageId) => {
  try {
    const response = await api.delete(`/upload/files/${imageId}`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Get hotel dashboard data
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getHotelDashboard = async(hotelId, request = {}) => {
  try {
    const response = await api.get(`/hotels/${hotelId}/getDashboard`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message && e.response.status !== 404 && e.response.status !== 412) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};