/**
 * Get image URL from filepath
 * 
 * @param {*} filepath  Filepath
 * @returns String
 */

function prepareImageUrlByPath(filepath) {
  return `${process.env.REACT_APP_BACKEND_URL}${filepath}`;
}

export default prepareImageUrlByPath;
