import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel rates conditions
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getHotelRatesConditions = async(hotelId, request = {}) => {
  try {
    if (!request?.params) {
      request.params = {};
    }
    request.params['filters[hotel]'] = hotelId;
    const response = await api.get('/hotel-rates-conditions', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Create hotel rates conditions
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const createHotelRatesConditions = async(hotelId, request = {}) => {
  try {
    if (!request) {
      request = {};
    }
    request.hotel = hotelId;
    const response = await api.post('/hotel-rates-conditions', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update hotel rates conditions
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const updateHotelRatesConditions = async(id, request = {}) => {
  try {
    const response = await api.put(`/hotel-rates-conditions/${id}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};