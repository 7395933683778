import axios from 'axios';

const downloadPDF = async (pdfUrl, fileName) => {
  try {
    const response = await axios.get(pdfUrl, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data]);
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName || 'file.pdf');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};

export default downloadPDF;
