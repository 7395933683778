import React from 'react';
import { Box, styled, useTheme } from '@mui/material';
import { capitalizeFirstLetter } from '../../utils';

const StagingBannerLeftCorner = styled(Box)(({ theme }) => ({
  background: theme.palette.error.main,
  width: '8px',
  height: '10px',
  position: 'relative',
  top: '0',
  right: '8px',
  '&::before': {
    content: '""',
    backgroundImage: `radial-gradient(circle at 0% 100%, rgba(204, 0, 0, 0) 30px, ${theme.palette.error.main} 30px)`,
    position: 'absolute',
    top: '0',
    right: '100%',
    width: '26px',
    height: '30px',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '10px',
    height: '8px',
    background: theme.palette.error.main,
    borderRadius: '0 0 0 100% / 0 0 0 100%',
    top: '100%',
    right: '0',
  }
}));

const StagingBannerRightCorner = styled(Box)(({ theme }) => ({
  background: theme.palette.error.main,
  width: '8px',
  height: '10px',
  position: 'absolute',
  top: '0',
  left: '100%',
  '&::before': {
    content: '""',
    backgroundImage: `radial-gradient(circle at 100% 100%, rgba(204, 0, 0, 0) 30px, ${theme.palette.error.main} 30px)`,
    position: 'absolute',
    top: '0',
    left: '100%',
    width: '26px',
    height: '30px',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '10px',
    height: '8px',
    background: theme.palette.error.main,
    borderRadius: '0 0 100% 0 / 0 0 100% 0',
    top: '100%',
    left: '0',
  }
}));

const StagingBanner = () => {
  const theme = useTheme();

  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        zIndex: '1501'
      }}>
        <div style={{
          backgroundColor: theme.palette.error.main,
          color: 'white',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          top: '0'
        }}>
          <StagingBannerLeftCorner />
          <div style={{
            paddingRight: '10px',
            paddingBottom: '2px',
            paddingLeft: '10px',
            fontSize: '12px'
          }}>
            <b>{capitalizeFirstLetter(process.env.REACT_APP_ENVIRONMENT, true)} Mode</b>
          </div>
          <StagingBannerRightCorner />
        </div>
      </div>
    </>
  );
};

export default StagingBanner;