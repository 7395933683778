import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function RoomLeft({ rooms }) {
    const { t } = useTranslation()
    const theme = useTheme();
    const notRoomsAvailable = rooms?.filter(room => room?.availableRooms === 0);
    const notEnoughroomNames = notRoomsAvailable?.length > 0
      ? t('notEnough') + ' ' + notRoomsAvailable?.map(room => room?.roomTypeName).join(', ') + ' ' + t('left')
      : '';
    const singleRoomsAvailable = rooms?.filter(room => room?.availableRooms === 1);
    const singleroomNames = singleRoomsAvailable?.length > 0
      ? t('only') + ' 1 ' + singleRoomsAvailable?.map(room => room?.roomTypeName).join(', ') + ' ' + t('left')
      : '';

    const message = [singleroomNames, notEnoughroomNames].filter(Boolean).join(' And ');

    return (
      <Typography variant='body2' sx={{lineHeight: theme.lineHeight[1.2], ml: 1, color: theme.palette.red[100]}}>
        {message}
      </Typography> 
    );
}
