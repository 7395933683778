import React, { lazy } from 'react';
import { useSelector } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import {
  HotelLayovers,
  HotelDashboard,
  HotelDetails,
  LoginPage,
  HotelBilling,
  HotelInvoiceDetails,
  AirlineBilling,
  AirlineInvoiceDetails,
  AcceptInvite,
  AirlineLayoverList,
  VerifyLayoverToken,
  VerifyLayoverAirlineToken,
  Welcome,
  NotFound as PassengerLayoverNotFound,
  PassengerInfo,
  RoomSelection as PassengerHotelRoomSelection,
  HotelSelection as PassengerHotelSelection,
  Booking as PassengerLayoverBooking,
  HotelCreateLayoverDetailsPDF,
  AirlineCreateCostPDF,
  CostAssumptionForAirlinePdf,
  CostAssumptionForHotelPdf,
  BookingPage as PassengerLayoverBookingPage
} from '../pages';
import { userRoles } from '../helper/constant';
import AuthenticatedRoute from './authenticated';
import Error404 from '../pages/error/404';

const AirlineDashboard = lazy(() => (isMobile || isTablet) ? import('../pages/airline/dashboard/mobile') : import('../pages/airline/dashboard'));
const AirlineLayoverHotelsAvailability = lazy(() => (isMobile || isTablet) ? import('../pages/airline/layover/hotelsAvailability/mobile') : import('../pages/airline/layover/hotelsAvailability'));
const AirlineLayoverHotelsBooking = lazy(() => (isMobile || isTablet) ? import('../pages/airline/layover/hotelBooking/mobile') : import('../pages/airline/layover/hotelBooking'));
const AirlineLayoverDetails = lazy(() => (isMobile || isTablet) ? import('../pages/airline/layover/mobile') : import('../pages/airline/layover'));
const HotelLayoverDetails = lazy(() => (isMobile || isTablet) ? import('../pages/hotel/layovers/details/mobile') : import('../pages/hotel/layovers/details'));
const AirlineHotelAvailability = lazy(() => (isMobile || isTablet) ? import('../pages/airline/hotelAvailability/mobile') : import('../pages/airline/hotelAvailability'));
const AirlineAccountSettings = lazy(() => (isMobile || isTablet) ? import('../pages/airline/account/settings/mobile') : import('../pages/airline/account/settings'));
const HotelAccountSettings = lazy(() => (isMobile || isTablet) ? import('../pages/hotel/account/settings/mobile') : import('../pages/hotel/account/settings'));
const HotelEditDetails = lazy(() => (isMobile || isTablet) ? import('../pages/hotel/edit/mobile') : import('../pages/hotel/edit'));
const HotelAvailability = lazy(() => (isMobile || isTablet) ? import('../pages/hotel/availability/mobile') : import('../pages/hotel/availability'));
const HotelRatesConditions = lazy(() => (isMobile || isTablet) ? import('../pages/hotel/ratesConditions/mobile') : import('../pages/hotel/ratesConditions'));

const RouterWrapper = () => {
  const user = useSelector((state) => state.user);
  const userRole = user.user?.role?.type;

  return (
    <Routes>
      {/* Public Routes */}
      <Route path='/user/login' element={<LoginPage />} />
      <Route path='/user/accept-invite/' element={<AcceptInvite />} />
      <Route path='/l/t/:token' element={<VerifyLayoverToken />} />
      <Route path='/l/al/:token' element={<VerifyLayoverAirlineToken />} />
      <Route path='/passenger'>
        <Route path=':iata/:token' element={<Welcome />} />
        <Route path=':iata/:code/layover-not-found' element={<PassengerLayoverNotFound />} />
        <Route path=':iata/:code/passenger-information' element={<PassengerInfo />} />
        <Route path=':iata/:code/hotel-rooms' element={<PassengerHotelRoomSelection />} />
        <Route path=':iata/:code/hotels' element={<PassengerHotelSelection />} />
        <Route path=':iata/:code/booking' element={<PassengerLayoverBooking />} />
        <Route path=':iata/:code/booking/:bookingId' element={<PassengerLayoverBookingPage />} />
        {/* <Route path='*' element={< />} /> */}
      </Route>

      {/* Authenticated Routes for Airline */}
      {[userRoles.airlineAdmin].includes(userRole) && (
        <Route element={<AuthenticatedRoute userRole={userRole} />}>
          <Route path='/' element={<AirlineDashboard />} />
          <Route path='/layover/:layoverId/hotels' element={<AirlineLayoverHotelsAvailability />} />
          <Route path='/layover/:layoverId/booking' element={<AirlineLayoverHotelsBooking />} />
          <Route path='/layovers' element={<AirlineLayoverList />} />
          <Route path='/layovers/:layoverId/details' element={<AirlineLayoverDetails />} />
          <Route path='/layover/:layoverId/hotel/:hotelId/cost' element={<AirlineCreateCostPDF />} />
          <Route path='/layover-detail/:layoverId/hotel/:hotelId/cost' element={<CostAssumptionForAirlinePdf />} />
          <Route path='/hotel/availability' element={<AirlineHotelAvailability />} />
          <Route path='/account/settings' element={<AirlineAccountSettings />} />
          <Route path='/billing' element={<AirlineBilling />} />
          <Route path='/billing/:invoiceId' element={<AirlineInvoiceDetails />} />
          <Route path='*' element={<Error404 role={userRole} />} />
        </Route>
      )}
      
      {/* Authenticated Routes for Hotel */}
      {[userRoles.hotelAdmin].includes(userRole) && (
        <Route element={<AuthenticatedRoute userRole={userRole} />}>
          <Route path='/' element={<HotelDashboard />} />
          <Route path='/account/settings' element={<HotelAccountSettings />} />
          <Route path='/hotel/:hotelId' element={<HotelDetails />} />
          <Route path='/hotel/:hotelId/edit' element={<HotelEditDetails />} />
          <Route path='/availability' element={<HotelAvailability />} />
          <Route path='/layovers' element={<HotelLayovers  />} />
          <Route path='/layovers/:layoverId/details' element={<HotelLayoverDetails />} />
          <Route path='/layover/:layoverId/hotel/:hotelId/cost' element={<CostAssumptionForHotelPdf />} />
          <Route path='/layover/:layoverId/details/pdf' element={<HotelCreateLayoverDetailsPDF />} />
          <Route path='/billing' element={<HotelBilling />} />
          <Route path='/billing/:invoiceId' element={<HotelInvoiceDetails />} />
          <Route path='/rates-conditions' element={<HotelRatesConditions />} />
          <Route path='*' element={<Error404 role={userRole} />} />
        </Route>
      )}

      {/* Authenticated Routes for Hotel frontdesk */}
      {[userRoles.hotelFrontDesk].includes(userRole) && (
        <Route element={<AuthenticatedRoute userRole={userRole} />}>
          <Route path='/' element={<HotelDashboard />} />          
          <Route path='/account/settings' element={<HotelAccountSettings />} />
          <Route path='/hotel/:hotelId' element={<HotelDetails />} />
          <Route path='/availability' element={<HotelAvailability />} />
          <Route path='/layovers' element={<HotelLayovers />} />
          <Route path='/layovers/:layoverId/details' element={<HotelLayoverDetails />} />
          <Route path='/billing' element={<HotelBilling />} />
          <Route path='/billing/:invoiceId' element={<HotelInvoiceDetails />} />
          <Route path='/layover/:layoverId/hotel/:hotelId/cost' element={<CostAssumptionForHotelPdf />} />
          <Route path='/rates-conditions' element={<HotelRatesConditions />} />
          <Route path='*' element={<Error404 role={userRole} />} />
        </Route>
      )}

      {/* Authenticated Routes for Airline Ground staff */}
      {[userRoles.airlineGroundStaff].includes(userRole) && (
        <Route element={<AuthenticatedRoute userRole={userRole} />}>
          <Route path='/' element={<AirlineDashboard />} />
          <Route path='/layover/:layoverId/hotels' element={<AirlineLayoverHotelsAvailability />} />
          <Route path='/layover/:layoverId/booking' element={<AirlineLayoverHotelsBooking />} />
          <Route path='/layovers' element={<AirlineLayoverList />} />
          <Route path='/layovers/:layoverId/details' element={<AirlineLayoverDetails />} />
          <Route path='/layover/:layoverId/hotel/:hotelId/cost' element={<AirlineCreateCostPDF />} />
          <Route path='/layover-detail/:layoverId/hotel/:hotelId/cost' element={<CostAssumptionForAirlinePdf />} />
          <Route path='/hotel/availability' element={<AirlineHotelAvailability />} />
          <Route path='/account/settings' element={<AirlineAccountSettings />} />
          <Route path='/billing' element={<AirlineBilling />} />
          <Route path='/billing/:invoiceId' element={<AirlineInvoiceDetails />} />
          <Route path='*' element={<Error404 role={userRole} />} />
        </Route>
      )}

      <Route path='*' element={<Navigate to='/user/login' replace />} />
    </Routes>
  );
};

const MainRouter = () => {
  return (
    <>
      <Router>
        <RouterWrapper />
      </Router>
    </>
  );
};

export default MainRouter;