import { memo, useEffect, useState } from 'react';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, styled } from '@mui/material';

const StyledRadioGroup = styled(RadioGroup, {
  shouldForwardProp: (prop) => ['direction'].indexOf(prop) === -1
})(({ theme, direction = 'horizontal' }) => ({
  display: 'flex',
  flexDirection: (direction === 'horizontal') ? 'row' : 'column'
}));

const StyledRadio = styled(Radio, {
  shouldForwardProp: (prop) => ['isError'].indexOf(prop) === -1
})(({ theme, isError = false }) => ({
  '&': {
    color: isError ? theme.palette.error.main : theme.palette.menu.light
  },
  '&.Mui-checked': {
    color: theme.palette.menu.light
  }
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const CustomRadioGroup = (props) => {
  const { value, label, options, onChange, isError, errorMessage, formControlLabelProps } = props;
  const [radioValue, setRadioValue] = useState(null);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  useEffect(() => {
    setRadioValue(value ? value : false);
    if (isError) { setValidationErrorMessage(errorMessage ? errorMessage : '') }
  }, [value, isError, errorMessage]);

  const handleChange = (event) => {
    setRadioValue(event.target.value);
    onChange(event.target.value);
  }

  return (options ? (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <StyledRadioGroup
        value={radioValue}
        onChange={handleChange}
        {...props}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={`radio-${option.value}-${option.label}-${index}`}
            value={option.value}
            label={option.label}
            control={<StyledRadio isError={isError} />}
            disabled={option?.disabled}
            {...formControlLabelProps}
          />
        ))}
      </StyledRadioGroup>
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControl>
  ) : (''));
};

export default memo(CustomRadioGroup);