import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { GoogleMap as GoogleMapComponent, InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Button, CircularProgress, Tooltip, Typography, styled, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useGoogleMap } from '../../providers/googleMap';

const ProgressContainer = styled(Box)(({ theme }) => ({
  border: 0,
  background: theme.palette.charcoal.main,
  color: 'white',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  right: 0,
  borderRadius: '4px',
  width: '60px',
  height: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const BtnHideMap = styled(Button)(({ theme }) => ({
  ...theme.typography.textSmall,
  textTransform: 'capitalize',
  border: 0,
  backgroundColor: theme.palette.grey[800],
  color: 'white',
  cursor: 'pointer',
  position: 'absolute',
  bottom: 0,
  right: 0,
  borderRadius: '4px'
}));

const StyledCustomMarker = styled(Box, {
  shouldForwardProp: (prop) => ['isCurrentHighlighted'].indexOf(prop) === -1
})(({ theme, isCurrentHighlighted }) => ({
  backgroundColor: isCurrentHighlighted ? theme.palette.secondary.main : theme.palette.primary.main
}));

const DetailBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 'none',
  order: 0,
  flexGrow: 0,
  outline: 'none',
}));

const ImageBox = styled(Box, {
  shouldForwardProp: (prop) => ['smallControlIcon'].indexOf(prop) === -1,
})(({ theme, smallControlIcon }) => ({
  display: 'flex',
  width: smallControlIcon ? '160px' : '260px',
  height: smallControlIcon ? '75px' : '115px',
  backgroundColor: theme.palette.primary.main
}));

const HotelName = styled(Box, {
  shouldForwardProp: (prop) => ['smallControlIcon'].indexOf(prop) === -1,
})(({ smallControlIcon = false }) => ({
  width: smallControlIcon ? '160px' : '240px',
}));

const StyledHotelName = styled(Typography, {
  shouldForwardProp: (prop) => ['clickableLoctionPin'].indexOf(prop) === -1,
})(({ theme, clickableLoctionPin = false }) => ({
  ...theme.typography.textxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.text.dark,
  marginBottom: '4px',
  '&:hover': {
    cursor: 'pointer',
    color: clickableLoctionPin ? theme.palette.info.main : theme.palette.text.dark
  }
}));

const StyledHotelAddress = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  color: theme.palette.text.dark
}));

const GoogleMap = ({
  markerData,
  mapCenter,
  setMapCenter,
  handleBtnHideMap,
  hideMap,
  zoomValue = 12,
  smallControlIcon = false,
  showCurrentLocation = false,
  clickableLoctionPin = false,
  currentHighlighted,
  setCurrentHighlighted,
  isInfoWindow = true,
  ...props
}) => {
  const { isGoogleMapLoaded } = useGoogleMap();
  const theme = useTheme();
  const [map, setMap] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showInfoWindow, setShowInfoWindow] = useState([]);
  const [showInfoWindowHover, setShowInfoWindowHover] = useState([]);

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
    borderRadius: theme.borderRadius[3],
    outline: 'none',
    ...props,
  };

  const onLoad = useCallback((map) => {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    setMap(map);
  }, []);

  const handleCenterChanged = useMemo(() => debounce(() => {
    if (map) {
      const newCenter = map.getCenter();
      setMapCenter((prev) => ({
        ...prev,
        lat: newCenter.lat(),
        lng: newCenter.lng(),
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, 500), []);

  useEffect(() => {
    setIsLoaded(isGoogleMapLoaded() ? true : false)
  }, [isGoogleMapLoaded])

  useEffect(() => {
    let tempMarkerData = markerData?.slice(0, 50)?.map((value) => {
      return {
        hotelId: value.hotelId,
        value: false
      };
    })
    setShowInfoWindow(tempMarkerData);
    setShowInfoWindowHover(tempMarkerData);
  }, [markerData])

  return (isLoaded ? (
    <GoogleMapComponent
      mapContainerStyle={containerStyle}
      center={mapCenter}
      zoom={zoomValue}
      onLoad={onLoad}
      onCenterChanged={handleCenterChanged}
      options={{
        ...(smallControlIcon && {
          controlSize: 30
        })
      }}
      style={{ position: 'relative' }}
    >
      {markerData?.slice(0, 50)?.map((item, index) => (
        <CustomMarker
          item={item}
          iconSize={showCurrentLocation && index === 0 ? 'large' : 'small'}
          clickableLoctionPin={clickableLoctionPin}
          setCurrentHighlighted={setCurrentHighlighted}
          currentHighlighted={currentHighlighted}
          showInfoWindow={showInfoWindow}
          setShowInfoWindow={setShowInfoWindow}
          smallControlIcon={smallControlIcon}
          showInfoWindowHover={showInfoWindowHover}
          setShowInfoWindowHover={setShowInfoWindowHover}
          isInfoWindow={isInfoWindow}
        />
      ))}

      {hideMap ? (hideMap?.loading ? (
        <ProgressContainer>
          <CircularProgress
            size={15}
            sx={{ color: theme.palette.neutral[10] }}
          />
        </ProgressContainer>
      ) : (
        <BtnHideMap onClick={handleBtnHideMap} >
          {hideMap?.status ? `Move map with scroll ?` : `Hide this Map?`}
        </BtnHideMap>
      )) : (null)}
    </GoogleMapComponent>
  ) : (null));
};

export default memo(GoogleMap);

const CustomMarker = ({
  item,
  iconSize,
  clickableLoctionPin,
  setCurrentHighlighted,
  currentHighlighted,
  showInfoWindow,
  setShowInfoWindow,
  smallControlIcon,
  showInfoWindowHover,
  setShowInfoWindowHover,
  isInfoWindow = true
}) => {
  const theme = useTheme();
  const [imageUrlFailed, setImageUrlFailed] = useState({image1: true});

  const onOpenInfoWindow = (hotelId, showInfoWindow) => {
    const infoWindowData = [...showInfoWindow];
    const changeInfoIndex = infoWindowData.map((info) => {
      return info.hotelId === hotelId
        ? {
            ...info,
            value: true,
          }
        : {
            ...info,
            value: false,
          };
    });
    setShowInfoWindow(changeInfoIndex);
    setCurrentHighlighted(item);
    setShowInfoWindowHover(
      [...showInfoWindowHover].map((info) => ({ ...info, hoverValue: false }))
    );
  };

  const onCloseInfoWindow = (hotelId, showInfoWindow) => {
    setShowInfoWindow(
      [...showInfoWindow].map((info) => ({ ...info, value: false }))
    );
    setShowInfoWindowHover(
      [...showInfoWindowHover].map((info) => ({ ...info, hoverValue: false }))
    );
    setCurrentHighlighted({});
  };

  const setMarker = () => {
    // let highlightCurrent = currentHighlighted.hotelId == item.hotelId;
    // if (iconSize == 'large' && highlightCurrent) {
    //   icon = PinLocationSmall;
    // } else if (iconSize == 'large' && !highlightCurrent) {
    //   icon = PinLocationIcon2;
    // } else if (iconSize != 'large' && highlightCurrent) {
    //   icon = PinLocationSmall;
    // } else {
    //   icon = PinLocationIcon2;
    // }
    // return icon;
    return null
  };

  useEffect(() => {
    setMarker();
  });

  const onMouseOverOpen = (hotelId, showInfoWindowHover) => {
    if (!showInfoWindow.some((li) => li.value === true)) {
      const changeInfoIndex = showInfoWindowHover.map((info) => {
        return info.hotelId === hotelId
          ? {
              ...info,
              hoverValue: true,
            }
          : {
              ...info,
              hoverValue: false,
            };
      });
      setShowInfoWindowHover(changeInfoIndex);
    }
  };

  const onMouseOverClose = (showInfoWindowHover) => {
    if (!showInfoWindow.some((li) => li.value === true)) {
      setShowInfoWindowHover(
        [...showInfoWindowHover].map((info) => ({ ...info, hoverValue: false }))
      );
    }
  };

  return (
    <StyledCustomMarker isCurrentHighlighted={(currentHighlighted && currentHighlighted.hotelId === item.hotelId) ? true : false}>
      <Marker
        key={`marker_${item.lat}`}
        position={{ lat: parseFloat(item.lat), lng: parseFloat(item.lng) }}
        onClick={(e) => isInfoWindow ? onOpenInfoWindow(item.hotelId, showInfoWindow) : null}
        onMouseOver={() => isInfoWindow ? onMouseOverOpen(item.hotelId, showInfoWindowHover) : null}
        onMouseOut={() => isInfoWindow ? onMouseOverClose(showInfoWindowHover) : null}
        icon={{
          url: setMarker()
        }}
      >
        {isInfoWindow ? (
          showInfoWindow.find((li) => li.hotelId === item.hotelId)?.value ? (
            <InfoWindow
              options={{ disableAutoPan: true }}
              onCloseClick={() =>
                onCloseInfoWindow(item.hotelId, showInfoWindow)
              }
            >
              <DetailBox>
                {imageUrlFailed.image1 && item?.map_image?.length > 0 ? (
                  <>
                    <ImageBox smallControlIcon={smallControlIcon}>
                      <img
                        src={
                          item?.map_image?.length > 0
                            ? `${window.location.origin}${item?.map_image[0]?.actual_path}`
                            : null
                        }
                        onError={() =>
                          setImageUrlFailed((prev) => ({
                            ...prev,
                            image1: false,
                          }))
                        }
                        onClick={() =>
                          clickableLoctionPin &&
                          window.open(
                            item?.link,
                            '_blank'
                          )
                        }
                        alt=''
                        style={{
                          width: '100%',
                          height: smallControlIcon ? '80px' : '115px',
                          borderRadius: '5px',
                          cursor: 'pointer',
                        }}
                      />
                    </ImageBox>
                  </>
                ) : item?.map_image?.length > 0 ? (
                  <i
                    className='ri-file-damage-line ri-3x --fa-border-radius'
                    style={{
                      display: 'flex',
                      width: '100%',
                      height: smallControlIcon ? '80px' : '115px',
                      borderRadius: '5px',
                      color: theme.palette.primary.border,
                      background: theme.palette.darkblue.surface,
                      justifyContent: 'center',
                      alignItems: 'center',

                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      clickableLoctionPin &&
                      window.open(
                        item?.link,
                        '_blank'
                      )
                    }
                  ></i>
                ) : null}
                {smallControlIcon ? (
                  <Tooltip
                    title={item?.name}
                    arrow
                    placement='top'
                  >
                    <HotelName
                      onClick={() =>
                        clickableLoctionPin &&
                        window.open(
                          item?.link,
                          '_blank'
                        )
                      }
                      smallControlIcon
                      clickableLoctionPin
                    >
                      <StyledHotelName>
                        {`${item?.name?.slice(0, 15)}${
                          item?.name?.length > 15 ? '...' : ''
                        }`}
                      </StyledHotelName>
                      <StyledHotelAddress>
                        {`${item?.address?.slice(0, 15)}${
                          item?.address?.length > 15 ? '...' : ''
                        }`}
                      </StyledHotelAddress>
                    </HotelName>
                  </Tooltip>
                ) : (
                  <HotelName
                    onClick={() =>
                      clickableLoctionPin &&
                      window.open(
                        item?.link,
                        '_blank'
                      )
                    }
                    clickableLoctionPin
                  >
                    <StyledHotelName>
                      {item?.name}
                    </StyledHotelName>
                    <StyledHotelAddress>
                      {item?.address}
                    </StyledHotelAddress>
                  </HotelName>
                )}
              </DetailBox>
            </InfoWindow>
          ) : (
            showInfoWindowHover.find((li) => li.hotelId === item.hotelId)
              ?.hoverValue && (
                <InfoWindow
                  options={{
                    disableAutoPan: true,
                  }}
                >
                  <DetailBox>
                    {imageUrlFailed.image1 && item?.map_image?.length > 0 ? (
                      <>
                        <ImageBox smallControlIcon={smallControlIcon} >
                          <img
                            src={
                              item?.map_image?.length > 0
                                ? `${window.location.origin}${item?.map_image[0]?.actual_path}`
                                : ''
                            }
                            onError={() =>
                              setImageUrlFailed((prev) => ({
                                ...prev,
                                image1: false,
                              }))
                            }
                            onClick={() =>
                              clickableLoctionPin &&
                              window.open(
                                item?.link,
                                '_blank'
                              )
                            }
                            alt=''
                            style={{
                              width: '100%',
                              height: smallControlIcon ? '80px' : '115px',
                              borderRadius: '5px',
                              cursor: 'pointer'
                            }}
                          />
                        </ImageBox>
                      </>
                    ) : item?.map_image?.length > 0 ? (
                      <i
                        className='ri-file-damage-line ri-3x --fa-border-radius'
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: smallControlIcon ? '80px' : '115px',
                          borderRadius: '5px',
                          color: theme.palette.primary.border,
                          background: theme.palette.darkblue.surface,
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          clickableLoctionPin &&
                          window.open(
                            item?.link,
                            '_blank'
                          )
                        }
                      ></i>
                    ) : null}
                    {smallControlIcon ? (
                      <Tooltip
                        title={item?.name}
                        arrow
                        placement='top'
                      >
                        <HotelName
                          onClick={() =>
                            clickableLoctionPin &&
                            window.open(
                              item?.link,
                              '_blank'
                            )
                          }
                          smallControlIcon
                          clickableLoctionPin
                        >
                          <StyledHotelName>
                            {`${item?.name?.slice(0, 15)}${
                              item?.name?.length > 15 ? '...' : ''
                            }`}
                          </StyledHotelName>
                          <StyledHotelAddress>
                            {`${item?.address?.slice(0, 15)}${
                              item?.address?.length > 15 ? '...' : ''
                            }`}
                          </StyledHotelAddress>
                        </HotelName>
                      </Tooltip>
                    ) : (
                      <HotelName
                        onClick={() =>
                          clickableLoctionPin &&
                          window.open(
                            item?.link,
                            '_blank'
                          )
                        }
                        clickableLoctionPin
                      >
                        <StyledHotelName>
                          {item?.name}
                        </StyledHotelName>
                        <StyledHotelAddress>
                          {item?.address}
                        </StyledHotelAddress>
                      </HotelName>
                    )}
                  </DetailBox>
                </InfoWindow>
              )
          )
        ) : (null)}
      </Marker>
    </StyledCustomMarker>
  );
};