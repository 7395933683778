import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { AirlineContext } from '../../../../context/airline';
import { useLoader } from '../../../../providers/loader';
import { getLayoverDetails } from '../../../../services/layover';
import { addHotelsToLayover, getHotelsForLayover, getHotelsInLayover, removeHotelsFromLayover } from '../../../../services/layover/hotels';
import Filters from './filters';
import { Input } from '../../../../components';
import { InputEndAdornment } from '../../components/endAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Sorting from './sorting';
import { SubHeading } from '../../components/heading';
import HotelCard from './hotelCard';
import _ from 'lodash';
import Sticky from 'react-stickynode';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../helper/constant';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import Button from '../../../../components/button';
import { LargeHeading } from '../../../hotel/components/heading';
import formatPrice from '../../../../utils/formatPrice';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const sortingOptions = [
  {
    key: 'DEFAULT',
    name: 'Default'
  },
  {
    key: 'PRICE_HIGH_TO_LOW',
    name: 'Highest price to lowest price'
  },
  {
    key: 'PRICE_LOW_TO_HIGH',
    name: 'Lowest price to highest price'
  },
  {
    key: 'DRIVING_TIME',
    name: 'Driving time'
  }
];

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[600],
  textTransform: 'uppercase'
}))

const StyledSelectedHotelRooms = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}))

const StyledSelectedRoomRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: theme.spacing(1)
}))

const StyledSelectedRoomCount = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  marginRight: theme.spacing(1),
  border: '.5px solid',
  borderRadius: theme.borderRadius[3]
}))

const StyledSelectedRoomPrice = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontMedium600,
  flexGrow: 1,
  textAlign: 'center'
}))

const StyledSelectedHotelName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge,
  ...theme.typography.fontMedium600,
  lineHeight: theme.lineHeight[1.5],
  flexGrow: 1
}))

const StyledSelectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.bg[400]}99`,
  backdropFilter: 'blur(6px)',
  display: 'flex',
  marginTop: theme.spacing(3),
  position: 'sticky',
  bottom: theme.spacing(3),
  zIndex: 3,
  border: `1px solid ${theme.palette.checkoutBoxBorder}`,
  borderRadius: theme.borderRadius[6],
  boxShadow: theme.boxShadow.checkoutBoxShadow
}))

const StyledGridContainer = styled(Grid)(({ theme }) => ({
  flexWrap: 'wrap',
  overflowX: 'auto',
  padding: theme.spacing(2),
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  flex: '0 0 auto',
  width: '25%',
  maxWidth: 'unset !important',
  flexBasis: 'auto !important',
  borderRight: `1px solid ${theme.palette.primary.light}4D`,
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column'
}))

const StyledCheckoutBtnContainer = styled(Box)(({ theme }) => ({
  width: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const StyledCheckoutBtn = styled(Button)(({ theme }) => ({
  '&.MuiButton-contained': {
    ...theme.typography.h6,
    ...theme.typography.fontMedium600
  }
}))

const StyledDeleteIcon = styled(IconButton)(({ theme }) => ({
  padding: 2,
  cursor: 'pointer',
  backgroundColor: theme.palette.red[500]
}));

const StyledAttentionNote = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmall,
  lineHeight: theme.lineHeight[1.2],
  backgroundColor: theme.palette.red[800],
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(.8),
  paddingBottom: theme.spacing(.8),
  marginBottom: theme.spacing(.5)
}));

const LayoverHotelsAvailability = () => {
  let { layoverId } = useParams();
  const { setIsCommonTopbar, setIsSidebarOpened, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled, setAirlineId, setAirportId } = useContext(AirlineContext);
  const theme = useTheme();
  const formRef = useRef(null);
  const form2Ref = useRef(null);
  const form3Ref = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const checkoutDeleteAll = location?.state;
  const { startLoader, stopLoader } = useLoader();
  const [ hotelLoader, setHotelLoader ] = useState(true);
  const [airportCurrency, setAirportCurrency] = useState(null);
  const [layover, setLayover] = useState(null);
  const [isLayoverAccessible, setIsLayoverAccessible] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [selectedHotels, setSelectedHotels] = useState({});
  const [totalSelectedRoomOccupants, setTotalSelectedRoomOccupants] = useState(0);
  const [availableHotels, setAvailableHotels] = useState([]);
  const [unavailableHotels, setUnavailableHotels] = useState([]);
  const [checkoutRemoveHotelId, setCheckoutRemoveHotelId] = useState(null)
  const [isSticky, setIsSticky] = useState(false)
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    setFocus,
    setValue,
    getValues
  } = useForm({
    shouldUnregister: true,
  });

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    getValues: getValues2
  } = useForm({
    shouldUnregister: true,
  });

  const {
    control: control3,
    handleSubmit: handleSubmit3,
    watch: watch3,
    formState: { errors: errors3 },
    getValues: getValues3
  } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    setIsSidebarOpened(false); // whenever user visits this page sidebar will collapsed LAYOV37
    setIsCommonTopbar(false);
    setIsAirlineDropdownDisabled(true);
    setIsAirportDropdownDisabled(true);
  }, [setIsCommonTopbar, setIsSidebarOpened, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled])

  /**
   * Get hotel currency symbol
   * 
   * @param {*} hotel   Hotel Object
   */
  const getHotelCurrencySymbol = (currencyCode) => {
    let hotelCurrency = '';
    if (currencyCode) {
      const currencySymbol = getCurrencySymbol({ code: currencyCode, numeric: false });
      hotelCurrency = currencySymbol?.native ? currencySymbol.native : currencyCode;
    }
    return hotelCurrency;
  };

  const onRoomsUpdate = useCallback((preTotalRooms, newTotalRooms) => {
    let totalRooms = totalSelectedRoomOccupants;
    // If preTotalRooms are less, we need to add no.of rooms
    if (preTotalRooms < newTotalRooms) {
      totalRooms += (newTotalRooms - preTotalRooms);
    } else {
      totalRooms -= (preTotalRooms - newTotalRooms);
    }
    setTotalSelectedRoomOccupants(totalRooms >= 0 ? totalRooms : 0);
  }, [totalSelectedRoomOccupants]);

  const getTotalPrice = (selectedHotels) => {
    let totalPrice = 0;
    if (Object.keys(selectedHotels).length > 0) {
      for (const hotelIndex in selectedHotels) {
        if (Object.keys(selectedHotels[hotelIndex]?.rooms).length > 0) {
          for (const hotelRoomIndex in selectedHotels[hotelIndex].rooms) {
            const hotelRoom = selectedHotels[hotelIndex].rooms[hotelRoomIndex];
            totalPrice += hotelRoom.selectedRooms * hotelRoom.price;
          }
        }
      }
    }
    return totalPrice;
  };

  /**
   * Add hotels to layover via API
   * 
   * @param {*} layoverId   Layover Id
   * @param {*} request     Request
   */
  const addHotelsToLayoverViaApi = useCallback(async (layoverId, request) => {
    try {
      const response = await addHotelsToLayover(layoverId, request);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Call API to add hotel to layover
   */
  const addHotelToLayover = useCallback((hotel, formData) => {
    if (hotel && formData && formData?.rooms) {
      startLoader();
      new Promise(async (resolve, reject) => {
        if (totalSelectedRoomOccupants && layover?.economy_passangers && totalSelectedRoomOccupants <= layover?.economy_passangers) {
          try {
            let tempHotelRooms = [];
            for (const roomId in formData.rooms) {
              tempHotelRooms.push({
                roomTypeId: roomId,
                totalRooms: formData.rooms[roomId]
              });
            }
            let request = {
              hotels: [
                {
                  hotelId: hotel.hotelId,
                  rooms: tempHotelRooms
                }
              ]
            };
            await addHotelsToLayoverViaApi(layoverId, request);
            resolve()
          } catch (e) {
            reject(toastMessages.airline.addHotelToLayover.error)
          }
        } else {
          reject(toastMessages.airline.addHotelToLayover.limitExceedError)
        }
      }).then(response => {
        toast.success(toastMessages.airline.addHotelToLayover.success, {
          autoClose: maxToastTime,
          toastId: 'update-airline-layover-add-hotel',
        })

        // Update selected hotel state
        let totalSelectedHotelRooms = 0;
        let objHotelSelectedRooms = {};
        for (const roomId in formData.rooms) {
          if (!formData.rooms[roomId]) {
            objHotelSelectedRooms[roomId] = 0;
            continue;
          }
          objHotelSelectedRooms[roomId] = Number(formData.rooms[roomId]);
          totalSelectedHotelRooms += Number(formData.rooms[roomId]);
        }
        if (totalSelectedHotelRooms > 0) {
          setSelectedHotels(oldSelectedHotels => {
            let tempSelectedHotels = _.cloneDeep(oldSelectedHotels)
            if (!tempSelectedHotels.hasOwnProperty(hotel.hotelId)) {
              tempSelectedHotels[hotel.hotelId] = {
                hotelId: hotel.hotelId,
                name: hotel.name,
                currency: hotel?.currency?.alphabetic_code,
                rooms: {}
              };
            }
            for (const room of hotel.roomTypes) {
              if (!tempSelectedHotels[hotel.hotelId].rooms.hasOwnProperty(room.roomTypeId)) {
                tempSelectedHotels[hotel.hotelId].rooms[room.roomTypeId] = {
                  occupancy: room.occupancy,
                  roomTypeId: room.roomTypeId,
                  name: room.roomTypeName,
                  price: room.price,
                  selectedRooms: 0
                };
              }
              tempSelectedHotels[hotel.hotelId].rooms[room.roomTypeId].selectedRooms = objHotelSelectedRooms?.[room.roomTypeId] ? objHotelSelectedRooms[room.roomTypeId] : 0;
            }
            return tempSelectedHotels;
          })
        } else {
          setSelectedHotels(oldSelectedHotels => {
            let tempSelectedHotels = _.cloneDeep(oldSelectedHotels)
            if (!tempSelectedHotels.hasOwnProperty(hotel.hotelId)) {
              delete tempSelectedHotels[hotel.hotelId];
            }
            return tempSelectedHotels;
          })
        }
      }).catch((e) => {
        toast.error(e ? e : toastMessages.airline.addHotelToLayover.error, {
          autoClose: maxToastTime,
          toastId: 'update-airline-layover-add-hotel-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, layover, totalSelectedRoomOccupants, layoverId, addHotelsToLayoverViaApi]);

  /**
   * Get layover details from API
   */
  const getLayoverFromApi = useCallback(async (layoverId) => {
    try {
      let request = {
        populate: {
          'airport_id': {
            fields: ['id', 'name', 'iata']
          },
          'airline_id': {
            fields: ['id', 'name']
          }
        }
      };
      const response = await getLayoverDetails(layoverId, {params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Get hotels in layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getHotelsInLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getHotelsInLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Get hoteld for layover from API
   */
  const getHotelsForLayoverFromApi = useCallback(async (layoverId, request = {}) => {
    try {
      const response = await getHotelsForLayover(layoverId, {params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async () => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const data = {...getValues()?.formFilters, ...getValues2()?.formSearch, ...getValues3()?.formSorting}
        let request = _.cloneDeep(data);
        if (request?.price_range) {
          if (request.price_range?.[0]) {
            request.minRoomRate = request.price_range[0];
          }
          if (request.price_range?.[1]) {
            request.maxRoomRate = request.price_range[1];
          }
          delete request?.price_range;
        }
        if (request?.driving_time_range) {
          if (request.driving_time_range?.[0]) {
            request.minDistanceMin = request.driving_time_range[0];
          }
          if (request.driving_time_range?.[1]) {
            request.maxDistanceMin = request.driving_time_range[1];
          }
          delete request?.driving_time_range;
        }
        const response = await getHotelsForLayoverFromApi(layoverId, request)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      if (response) {
        if (response?.hotels && response.hotels.length > 0) {
          setHotels(response.hotels)
        } else {
          setHotels([])
        }
        if (response?.currency && response.currency) {
          setAirportCurrency(response.currency)
        }
      }
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
      setTimeout(() => setHotelLoader(false), 2000)
    });
  }, [layoverId, startLoader, stopLoader, getValues, getValues2, getValues3, getHotelsForLayoverFromApi]);

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onSubmit]);

  useEffect(() => {
    const subscription3 = watch3(handleSubmit3(onSubmit));
    return () => subscription3.unsubscribe();
  }, [handleSubmit3, watch3, onSubmit]);

  useEffect(() => {
    if (layoverId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const layoverResponse = await getLayoverFromApi(layoverId)
          if (layoverResponse && layoverResponse?.data) {
            setLayover(layoverResponse.data)
            setIsLayoverAccessible(true)
            setAirlineId(layoverResponse.data.airline_id.id);
            setAirportId(layoverResponse.data.airport_id.id);
          }
          const responseHotels = await getHotelsInLayoverViaApi(layoverId);
          if (responseHotels && responseHotels.length > 0) {
            let totalSelectedRoomOcs = 0;
            for (const hotel of responseHotels) {
              let totalSelectedHotelRoomOcs = 0;
              let objHotelSelectedRooms = {};

              let tempRoomTypes = {};
              for (const room of hotel.hotel.roomTypes) {
                tempRoomTypes[room.roomTypeId] = room;
              }
              for (const room of hotel.rooms) {
                objHotelSelectedRooms[room.roomTypeId] = Number(room.totalRooms);
                let occupants = tempRoomTypes[room.roomTypeId]?.occupancy ? tempRoomTypes[room.roomTypeId].occupancy : 1;
                totalSelectedHotelRoomOcs += (Number(room.totalRooms) * Number(occupants));
              }
              if (totalSelectedHotelRoomOcs > 0) {
                setSelectedHotels(oldSelectedHotels => {
                  let tempSelectedHotels = _.cloneDeep(oldSelectedHotels)
                  if (!tempSelectedHotels.hasOwnProperty(hotel.hotel.id)) {
                    tempSelectedHotels[hotel.hotel.id] = {
                      hotelId: hotel.hotel.id,
                      name: hotel.hotel.name,
                      currency: hotel.hotel?.currency?.alphabetic_code,
                      rooms: {}
                    };
                  }
                  for (const room of hotel.hotel.roomTypes) {
                    if (!tempSelectedHotels[hotel.hotel.id].rooms.hasOwnProperty(room.roomTypeId)) {
                      tempSelectedHotels[hotel.hotel.id].rooms[room.roomTypeId] = {
                        roomTypeId: room.roomTypeId,
                        name: room.roomTypeName,
                        price: room.price,
                        occupancy: room.occupancy,
                        selectedRooms: 0
                      };
                    }
                    tempSelectedHotels[hotel.hotel.id].rooms[room.roomTypeId].selectedRooms = objHotelSelectedRooms?.[room.roomTypeId] ? objHotelSelectedRooms[room.roomTypeId] : 0;
                  }
                  return tempSelectedHotels;
                })
              } else {
                setSelectedHotels(oldSelectedHotels => {
                  let tempSelectedHotels = _.cloneDeep(oldSelectedHotels)
                  if (!tempSelectedHotels.hasOwnProperty(hotel.hotel.id)) {
                    delete tempSelectedHotels[hotel.hotel.id];
                  }
                  return tempSelectedHotels;
                })
              }
              totalSelectedRoomOcs += totalSelectedHotelRoomOcs;
              
              // if it redirect by 'Room Availability Update' alert box, then it will remove all checkout hotels
              if(checkoutDeleteAll){
                handleRemoveHotelFromCheckout(hotel.hotel.id)
              }
            }
            setTotalSelectedRoomOccupants(totalSelectedRoomOcs);
          }

          // NOTE: We are already calling the API to get the hotels in onSubmit so just call it 
          onSubmit()
          resolve()
        } catch (e) {
          setIsLayoverAccessible(false)
          reject(e)
        }
      }).then(response => {
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
  }, [layoverId, getLayoverFromApi, getHotelsInLayoverViaApi, onSubmit, startLoader, stopLoader]);

  useEffect(() => {
    if (hotels && hotels.length > 0) {
      // available hotels
      setAvailableHotels(hotels.filter((hotel) => (hotel?.totalAvailableRooms && Number(hotel.totalAvailableRooms) > 0) && hotel.is_open_for_layover === true));
      // unavailable hotels
      setUnavailableHotels(hotels.filter((hotel) => Number(hotel.totalAvailableRooms) === 0 || hotel.is_open_for_layover === false));
    } else {
      setAvailableHotels([]);
      setUnavailableHotels([]);
    }
  }, [hotels])

  /**
   * remove hotel from layover via API
   * 
   * @param {*} layoverId   Layover Id
   * @param {*} hotelId     Hotel Id
   */
  const removeHotelsFromLayoverViaApi = useCallback(async (layoverId, hotelId) => {
    try {
      const response = await removeHotelsFromLayover(layoverId, hotelId);
      return response
    } catch (e) {
      throw e
    }
  }, []);


  const handleStateChange = (status) => {
    if (status.status === Sticky.STATUS_FIXED) {
      setIsSticky(true)
    }else{
      setIsSticky(false)
    }
  };


  const handleRemoveHotelFromCheckout = useCallback((hotelId) => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        await removeHotelsFromLayoverViaApi(layoverId, hotelId);
        setCheckoutRemoveHotelId(hotelId);
        setSelectedHotels(oldSelectedHotels => {
          let tempSelectedHotels = _.cloneDeep(oldSelectedHotels)
          if (tempSelectedHotels.hasOwnProperty(hotelId)) {
            delete tempSelectedHotels[hotelId];
          }
          return tempSelectedHotels;
        });
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then(response => {
    }).catch((e) => {
      console.log('removeHotelsFromLayoverViaApi error', e);
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, removeHotelsFromLayoverViaApi, layoverId])

  // Calculate the sum of selectedRooms multiplied by occupancy to Display total no of peoples
  useEffect(() => {
    if (Object.keys(selectedHotels).length > 0) {
      const sumSelectedRooms = Object.values(selectedHotels).reduce((total, hotel) => {
        Object.values(hotel.rooms).forEach((room) => {
          total += room.selectedRooms * room.occupancy;
        });
        return total;
      }, 0);
      setTotalSelectedRoomOccupants(sumSelectedRooms);
    } else {
      setTotalSelectedRoomOccupants(0);
    }
  }, [selectedHotels]);

  return (
    <Box sx={{
      px: 2,
      pb: 2
    }}>
      {(isLayoverAccessible && layover) ? (
        <Box>
          <Grid container spacing={4} sx={{mt:0}}>
            <Grid item md={12/5} sm={4} xs={12} sx={{pt:'0 !important'}}>
              <FormProvider {...{control, handleSubmit, watch, formState: { errors }, setFocus, setValue}}>
                <form key='form-1' onSubmit={handleSubmit(onSubmit)} ref={formRef} style={{paddingTop: theme.spacing(1)}}>
                  <Filters
                    currency={airportCurrency}
                  />
                </form>
              </FormProvider>
            </Grid>
            <Grid item md={12 - (12/5)} sm={8} xs={12} sx={{pt:'0 !important'}}>
              {moment().isBetween(moment('00:00', 'HH:mm'), moment('06:00', 'HH:mm')) ? (
                <Grid container xs={10}>
                  <StyledAttentionNote sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                    <Typography sx={{...theme.typography.fontMedium600}}>Attention:</Typography> Until 6AM we show the hotel availability of the current night. This means that you can only book for the current night, and not the coming (next) night.
                  </StyledAttentionNote>
                </Grid>
              ) : (null)}
              <Grid container sx={{'& > .active': {width: `${theme.spacing(74)} !important`}}}>
                <Sticky top={30} innerZ={20} enableTransforms onStateChange={handleStateChange}>
                  <Grid item xs={12} 
                    sx={{
                      width: theme.spacing(74),
                      pt:2, pb:1, px: 2,
                      backgroundColor: isSticky ? theme.palette.sidebarBg : theme.palette.background.default,
                      borderRadius: theme.borderRadius[10],
                      color:isSticky && theme.mode === 'light' ? theme.palette.menu.main : 'inherit'
                    }}
                  >
                    <Box sx={{
                      display: 'flex',
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        pr: 2
                      }}>
                        <Typography sx={{
                          ...theme.typography.textLarge,
                          pb: 1
                        }} variant='caption'>{layover?.airport_id?.iata} {layover?.airport_id?.name}</Typography>
                        <Typography sx={theme.typography.textxSmall} variant='caption'>{layover?.airline_id?.name} {layover?.flight_code}</Typography>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left'
                      }}>
                        <StyledMainLabel sx={{mb:1, color: isSticky && theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}}>Passenger</StyledMainLabel>
                          <Input
                            value={`${totalSelectedRoomOccupants <= 0 ? 0 : totalSelectedRoomOccupants} / ${layover?.economy_passangers ? layover.economy_passangers : 0}`}
                            isError={totalSelectedRoomOccupants > layover?.economy_passangers}
                            disabled
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                People
                              </InputEndAdornment>
                            }
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                          />
                      </Box>
                    </Box>
                  </Grid>
                </Sticky>
                <Grid item xs={12} sx={{pt:2, pb:1}}>
                  <Grid container spacing={2} sx={{mt:0}}>
                    <Grid item lg={4} md={6} sm={12} sx={{pt:'0 !important'}}>
                      <form key='form-2' onSubmit={handleSubmit2(onSubmit)} ref={form2Ref}>
                        <Controller
                          name={`formSearch[search]`}
                          control={control2}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              placeholder='Search'
                              inputRef={field.ref}
                              {...field}
                              endAdornment={
                                <InputEndAdornment position='end' sx={{mr:0}}>
                                  <IconButton type='submit'>
                                    <SearchIcon sx={{color: theme.palette.primary.light}} />
                                  </IconButton>
                                </InputEndAdornment>
                              }
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                            />
                          )}
                        />
                      </form>
                    </Grid>
                    <Grid item lg={5} md={6} sm={12} sx={{pt:'0 !important'}}>
                      <FormProvider {...{control3, handleSubmit3, formState: { errors3 }}}>
                        <form key='form-3' onSubmit={handleSubmit3(onSubmit)} ref={form3Ref}>
                          <Sorting
                            options={sortingOptions}
                            defaultValue={sortingOptions[0].key}
                          />
                        </form>
                      </FormProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sx={{py:2}}>
                  <SubHeading title='Available Hotels' />
                </Grid>
                <Grid item xs={12}>
                  {availableHotels.length > 0 ? (
                    <Grid container spacing={2}>
                      {availableHotels.map((hotel, index) => (
                        <Grid item lg={4} md={6} sm={12} xs={12} key={`hotel-${index}`} sx={{ display:'flex'}}>
                          <Box sx={{width:'100%', height:'100%'}}>
                            <HotelCard
                              hotel={hotel}
                              selectedRooms={selectedHotels?.[hotel.hotelId]?.rooms ? selectedHotels[hotel.hotelId].rooms : null}
                              onRoomsUpdate={onRoomsUpdate}
                              onAddToLayover={addHotelToLayover}
                              checkoutRemoveHotelId={checkoutRemoveHotelId}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    !hotelLoader ? (
                      <Box sx={{ width: '100%', minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LargeHeading title='No hotel rooms available at the moment!' />
                      </Box>
                    ) : (
                      <Box sx={{ width: '100%', minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <LargeHeading title='Loading today´s availability' />
                      </Box>
                    )
                  )}
                </Grid>
                {unavailableHotels.length > 0 ? (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} sx={{py:2, mt:3}}>
                        <SubHeading title='Unavailable Hotels' />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {unavailableHotels.map((hotel, index) => (
                            <Grid item lg={4} md={6} sm={12} xs={12} key={`hotel-${index}`} sx={{display: 'flex'}}>
                              <Box sx={{width:'100%', height:'100%'}}>
                                <HotelCard
                                  hotel={hotel}
                                  selectedRooms={selectedHotels?.[hotel.hotelId]?.rooms ? selectedHotels[hotel.hotelId].rooms : null}
                                  onRoomsUpdate={onRoomsUpdate}
                                  onAddToLayover={addHotelToLayover}
                                  checkoutRemoveHotelId={checkoutRemoveHotelId}
                                  isUnavailableHotel={true}
                                />
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (null)}
              </Grid>
            </Grid>
          </Grid>
          {Object.keys(selectedHotels).length > 0 ? (
            <StyledSelectionContainer>
              <StyledGridContainer container sx={{display: 'flex'}}>
                {Object.keys(selectedHotels).map((hotelIndex, i) => {
                  let sHotel = selectedHotels[hotelIndex];
                  return (
                    <StyledGrid item lg={3} md={6} sm={6} xs={12} key={`shotel-${i}`} sx={{mt:2}}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <StyledSelectedHotelName>{sHotel.name}</StyledSelectedHotelName>
                        <StyledDeleteIcon aria-label='Remove' onClick={() => handleRemoveHotelFromCheckout(sHotel.hotelId)}><CloseIcon /></StyledDeleteIcon>
                      </Box>
                      <StyledSelectedHotelRooms>
                        {Object.keys(sHotel.rooms).map((roomIndex) => sHotel.rooms[roomIndex])
                        .sort((roomTypeA, roomTypeB) => roomTypeA.occupancy - roomTypeB.occupancy)
                        .map((sHotelRoom, ri) => {
                          return (
                            <StyledSelectedRoomRow key={`shotel-sroom-${ri}`}>
                              <Typography variant='body2' sx={{width:'50%'}}>{sHotelRoom.name}</Typography>
                              <Box sx={{flexGrow:1,display:'flex',alignItems:'center'}}>
                                <StyledSelectedRoomCount>{sHotelRoom.selectedRooms}</StyledSelectedRoomCount>
                                <Box sx={{mr:1}}>X</Box>
                                <StyledSelectedRoomPrice variant='subtitle1'>{getHotelCurrencySymbol(sHotel.currency)}{formatPrice(sHotelRoom.price)}</StyledSelectedRoomPrice>
                              </Box>
                            </StyledSelectedRoomRow>
                          )
                        })}
                      </StyledSelectedHotelRooms>
                    </StyledGrid>
                  )
                })}
              </StyledGridContainer>
              <StyledCheckoutBtnContainer>
                <StyledCheckoutBtn variant='contained' onClick={() => navigate(`/layover/${layoverId}/booking`)}>
                  {`${airportCurrency && airportCurrency?.alphabetic_code ? getHotelCurrencySymbol(airportCurrency.alphabetic_code) : ''}`}{formatPrice(getTotalPrice(selectedHotels))}
                  <br/>
                  Checkout
                </StyledCheckoutBtn>
              </StyledCheckoutBtnContainer>
            </StyledSelectionContainer>
          ) : (null)}
        </Box>
      ) : (
        <>
          {isLayoverAccessible === false ? (
            <Box>
              <Typography variant='subtitle2'>
                You do not have access to this layover. Please contact the administrator if you believe this is incorrect.
              </Typography>
            </Box>
          ) : (null)}
        </>
      )}
    </Box>
  );
};

export default LayoverHotelsAvailability;