import { getUserPermissions } from './getUserPermissions';
import { store } from '../redux/store';

export function checkPermissions(key) {
  const user = store.getState().user;
  const userRole = user?.user?.role?.type;
  const userAccess = getUserPermissions(userRole);
  
  if (Array.isArray(key) && userAccess) {
    const permissions = {};
    key.forEach(keyItem => {
      if (userAccess.pages[keyItem] && keyItem.startsWith('/')) {
        permissions[keyItem] = userAccess.pages[keyItem];
      }else if (userAccess.custom[keyItem]) {
        permissions[keyItem] = userAccess.custom[keyItem];
      }
    });
    return permissions;
  }
  if (userAccess.pages && userAccess.pages[key] && key.startsWith('/')){
    if (userAccess.pages && userAccess.pages[key]) {
      return userAccess.pages[key];
    }
  }else if(userAccess.custom && userAccess.custom[key]){
      return userAccess.custom[key];
  }
  return { view: true, edit: true };
}