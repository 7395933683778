import { InputAdornment, styled } from '@mui/material';

export const InputEndAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => ['color'].indexOf(prop) === -1
})(({ theme, color }) => ({
  marginRight: theme.spacing(2),
  '&, & .MuiTypography-root, & .MuiButton-root': {
    ...theme.typography.textSmall,
    ...theme.typography.fontMedium500,
    color: color ? color : theme.palette.grey[700],
  },
  '& .MuiButton-root': {
    ...theme.typography.textLarge
  }
}));