import { useEffect, memo, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Divider, styled } from '@mui/material';
import { Button } from '..';
import { isMobile, isTablet } from 'react-device-detect';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'end'
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'unset',
    borderRadius: 0,
    margin: 0,
    maxHeight: '100vh',
    height: '100vh'
  },
  '& .MuiDialogActions-root': {
    padding: 0
  }
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  ...theme.typography.fontSemiBold500,
  padding: 0
}));

const StyledDialogSubTitle = styled(Typography)(({ theme }) => ({
  // ...theme.typography.fontSemiBold500,
  padding: 0
}));

const StyledHeaderCancelButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textSmall,
  ...theme.typography.fontMedium500,
  color: theme.palette.primary.light,
  padding: 0,
  textTransform: 'capitalize'
}));

const FormDialog = (props) => {
  const { open, btnText, title, subTitle, children, onAgree, onDisagree, cancelBtnInHeader } = props;
  const [isCancelBtnInHeader, setIsCancelBtnInHeader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState('Submit');
  const [dialogTitle, setDialogTitle] = useState(null);
  const [dialogSubTitle, setDialogSubTitle] = useState(null);

  useEffect(() => {
    setIsOpen(open);
    if (btnText) { setButtonText(btnText) }
    if (title) { setDialogTitle(title) }
    if (subTitle) { setDialogSubTitle(subTitle) }
    if (cancelBtnInHeader) { setIsCancelBtnInHeader(cancelBtnInHeader) }
  }, [open, btnText, title, cancelBtnInHeader]);

  const handleClose = () => {
    setIsOpen(false);
    if (onDisagree) { onDisagree() }
  };

  const handleAgree = () => {
    setIsOpen(false);
    if (onAgree) { onAgree() }
  };

  return (
    <StyledDialog
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      open={isOpen}
      {...props}
      onClose={handleClose}
      sx={{overflowX: 'hidden'}}
    >
      {(dialogTitle || isCancelBtnInHeader) ? (
        <Box sx={{display: 'flex', alignItems: 'baseline', px:3, pt:5, pb:3}}>
          {dialogTitle && (
            <Box sx={{display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', alignItems: isMobile || isTablet ? '' : 'center', gap: 1}}>
              <StyledDialogTitle id='alert-dialog-title'>
                {dialogTitle}
              </StyledDialogTitle>
              {dialogSubTitle && (<StyledDialogSubTitle>{dialogSubTitle}</StyledDialogSubTitle>)}
            </Box>
          )}
          {isCancelBtnInHeader && (
            <Box sx={{flexGrow: 1, justifyContent: 'end', textAlign: 'right'}}>
              <StyledHeaderCancelButton
                variant='text'
                onClick={handleClose}
              >Cancel</StyledHeaderCancelButton>
            </Box>
          )}
        </Box>
      ) : ('')}
      <DialogContent dividers>
        <DialogContentText id='alert-dialog-description'>
          {children}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={handleAgree}
          variant='contained'
          style={{
            borderRadius: 0,
            width: '100%'
          }}
        >{buttonText}</Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default memo(FormDialog);