import { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftSharpIcon from '@mui/icons-material/ArrowCircleLeftSharp';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Logo from '../../assets/images/logo.png';
import { Typography } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { storage } from '../../utils';
import { InnerSwitch } from '../../components';
import { ThemeContext } from '../../context/themeContext';

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme, drawerClosedWidth) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: drawerClosedWidth,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '150px',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  backgroundColor: theme.palette.primary.dark,
  cursor: 'pointer',
}));

const DrawerFooter = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: theme.palette.sidebarBg,
}));

const SidebarDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.primary.light30,
  width: '100%',
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => ['open', 'drawerWidth', 'drawerClosedWidth'].indexOf(prop) === -1,
})(({ theme, open, drawerWidth, drawerClosedWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.sidebarBg,
    border: 'unset',
  },
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme, drawerClosedWidth),
    '& .MuiDrawer-paper': closedMixin(theme, drawerClosedWidth),
  }),
}));

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  color: theme.palette.menu.main,
  '&.active': {
    backgroundColor: theme.palette.sidebarActiveBg,
    borderRight: `2px solid ${theme.palette.primary.light}`,
    color: theme.palette.menu.main,
    '& .MuiTypography-root.sidebar-menu-text': {
      ...theme.typography.textLarge,
    },
  },
}));

const SideBar = (props) => {
  const { menus, drawerWidth, drawerClosedWidth, open, setOpen } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [sidebarMenus, setSidebarMenus] = useState(null);
  const [currentTheme, setCurrentTheme] = useState('dark');
  const { setThemeMode } = useContext(ThemeContext);

  useEffect(() => {
    setSidebarMenus(menus ? menus : null);
  }, [menus]);

  useEffect(() => {
    const currentStoredTheme = storage.getData('currentTheme');
    if (currentStoredTheme) {
      setCurrentTheme(currentStoredTheme);
    }
  }, []);

  const handleSidebarCollapse = () => {
    setOpen(!open);
    storage.storeData('sidebarOpened', !open);
  };

  const toggleTheme = () => {
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    storage.storeData('currentTheme', newTheme);
    setCurrentTheme(newTheme);
    setThemeMode(newTheme)
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant='permanent' open={open} drawerWidth={drawerWidth} drawerClosedWidth={drawerClosedWidth}>
        <DrawerHeader onClick={() => navigate('/')}>
          <img src={Logo} width={open ? 72 : 52} alt='Croowy Companion' />
        </DrawerHeader>
        {(process.env.REACT_APP_ENVIRONMENT === 'LOCAL' || process.env.REACT_APP_ENVIRONMENT === 'STAGING') ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: theme.spacing(4) }}>
              <InnerSwitch
                checked={currentTheme === 'light'}
                onClick={toggleTheme}
                formControlLabelSx={{ mb: theme.spacing(1) }}
              />
              {open && (
                <Typography sx={{ ...theme.typography.textxxSmall, color: theme.palette.menu.main, textAlign: 'center', opacity: open ? 1 : 0, whiteSpace: 'normal' }} variant='caption'>
                  {currentTheme === 'light' ? 'Light Theme' : 'Dark Theme'}
                </Typography>
              )}
            </Box>
          ) : (null)}
        <List sx={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', pt: theme.spacing(3), pb: theme.spacing(6) }}>
          {sidebarMenus ? (
            sidebarMenus.map((menu, index) => (
              menu.active && (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <StyledListItemButton
                    to={menu.route || '#'}
                    component={menu?.component === 'a' ? 'a' : NavLink}
                    exact={menu.exact || '#'}
                    open={open}
                    href={menu.route}
                    target={menu.component === 'a' ? '_blank' : ''}
                    sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, py: 2, mb: 1 }}
                  >
                    <ListItemIcon sx={{ minWidth: 0, mr: open ? 1 : 'auto', justifyContent: 'center' }}>
                      {menu?.icon ? menu.icon : <MenuOpenIcon />}
                    </ListItemIcon>
                    {open && (
                      <ListItemText
                        primary={menu.name}
                        primaryTypographyProps={{ sx: theme.typography.sidebarMenu, className: 'sidebar-menu-text' }}
                        sx={{ opacity: open ? 1 : 0, whiteSpace: 'normal' }}
                      />
                    )}
                  </StyledListItemButton>
                </ListItem>
              )
            ))
          ) : ('')}
        </List>
        <DrawerFooter>
          <SidebarDivider />
          <Box onClick={handleSidebarCollapse} sx={{ '&:hover': { cursor: 'pointer' } }}>
            <IconButton>
              <ArrowCircleLeftSharpIcon sx={{ transform: `${open ? 'rotate(0deg)' : 'rotate(180deg)'}`, transition: 'transform 250ms ease', color: theme.palette.menu.main }} />
            </IconButton>
            {open ? (
              <Typography variant='caption' sx={{ ...theme.typography.muiTypography600, color: theme.palette.menu.main }}>Collapse menu</Typography>
            ) : ('')}
          </Box>
        </DrawerFooter>
      </Drawer>
    </Box>
  );
};

export default SideBar;
