import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Create Layover
 * 
 * @param {*} request     Request
 * @returns 
 */
export const createLayover = async(request = {}) => {
  try {
    const response = await api.post(`/layovers`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Layovers
 * 
 * @param {*} request     Request
 * @returns 
 */
export const getLayovers = async(request = {}) => {
  try {
    const response = await api.get(`/layovers`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get hotel dashboard data
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getLayoversByFilter = async(hotelId, request = {}) => {
  try {
    const response = await api.get(`/hotels/${hotelId}/getDashboard?show=${request?.show}`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message && e.response.status !== 404) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Get layover details
 * 
 * @param {*} layoverId   Layover id
 * @param {*} request     Request
 * @returns 
 */
export const getLayoverDetails = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get layover preview
 * 
 * @param {*} layoverId   Layover id
 * @param {*} request     Request
 * @returns 
 */
export const getLayoverPreview = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/preview`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get layover by token
 * 
 * @param {*} token      Layover token
 * @param {*} request   Request
 * @returns 
 */
export const getLayoversByToken = async(token, request = {}) => {
  try {
    const response = await api.get(`/layovers/token/${token}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};