import React from 'react';

function formatDate(dateString) {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return '-';
  }
  const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' }; 
  return date.toLocaleDateString('en-GB', optionsDate).replace(/\//g, '.');
}

function formatTime(timeString) {
  const time = new Date(`1970-01-01T${timeString}`);
  if (isNaN(time.getTime())) {
    return '-';
  }
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true});
}

function formatTimestamp(timestamp, isDifferentReturnTimes, returnDate, returnTime, latestReturnToAirport) {
  if (isDifferentReturnTimes === 'null') {
    return 'To be announced later';
  }

  if (!timestamp) {
    if (isDifferentReturnTimes === 'YES' || isDifferentReturnTimes === 'LATER') {
      return latestReturnToAirport;
    } else {
      return (
        <>
          {formatDate(returnDate)} {formatTime(returnTime)}
        </>
      );
    }
  } else {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return '-';
    }
    const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' }; 
    const options = { hour: 'numeric', minute: 'numeric', hour12: true }; // Use hour12 for 12-hour format
    const formattedDate = `${date.toLocaleDateString('en-GB', optionsDate).replace(/\//g, '.')} ${date.toLocaleTimeString([], options)}`;
    const additionalInfo = returnDate && returnTime ? `${formatDate(returnDate)} ${formatTime(returnTime)}` : '';
    return (
      <>
        {formattedDate}
        {additionalInfo && <br />}
        {additionalInfo}
      </>
    );
  }
}

export default formatTimestamp;