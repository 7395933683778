import React, { memo } from 'react';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ToastPopup = ({ ...props }) => {
  return (
    <div>
      <ToastContainer autoClose={30000}  theme='light' {...props} />
    </div>
  );
};

export default memo(ToastPopup);
