import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLoader } from '../../../providers/loader';
import { HotelContext } from '../../../context/hotel';
import { Box, Button, Divider, Grid, Typography, styled, useTheme } from '@mui/material';
import { googleAddressReferenceKey, maxToastTime, pageTitles, toastMessages } from '../../../helper/constant';
import { getHotelDetails, updateHotelDetails,deleteImage } from '../../../services/hotel';
import { Controller, useForm } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import { Button as CustomButton, CheckBox, GoogleMap, InnerSwitch, Input, ImageUpload, Radio } from '../../../components';
import { InputEndAdornment } from '../components/endAdornment';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useGoogleMap } from '../../../providers/googleMap';
import { getFacilities } from '../../../services/other/facility';
import { getOtherServices } from '../../../services/other/otherServices';
import { toast } from 'react-toastify';
import { uploadFiles } from '../../../services/other/upload';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';

const AutoCompleteDropdown = styled(Box)(({ theme, showBorder }) => ({
  boxShadow: theme.boxShadow.shadowTwo,
  zIndex: 1,
  position: 'absolute',
  left: 0,
  right: 0,
  borderRadius: theme.borderRadius[5],
  ...theme.typography.textSmall
}));

const defaultAddressFields = {
  add1: null,
  add2: null,
  city: null,
  state: null,
  country: null,
  countryCode: null,
  postal_code: null,
  lat: null,
  lng: null
};

const HotelEditDetails = () => {
  let { hotelId } = useParams();
  hotelId = Number(hotelId);
  const formRef = useRef(null);
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { fetchLastAccessedHotelDetails, setIsCommonTopbar, setHeaderPageTitle, setIsFormDirty, setDirtyDialogOpen, onclickDirtyFormModalAgree, onclickDirtyFormModalDisAgree } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const { isGoogleMapLoaded } = useGoogleMap();
  const [isLoaded, setIsLoaded] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [otherServices, setOtherServices] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [mapCenter, setMapCenter] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [isHotelAccessible, setIsHotelAccessible] = useState(null);
  const [enterLocation, setEnterLocation] = useState('');
  const [addressInputFields, setAddressInputFields] = useState(defaultAddressFields);
  const [navigateRoute, setNavigateRoute] = useState('');
  const [uploadedImages, setUploadedImages] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue
  } = useForm({
    shouldUnregister: true,
    mode: 'all'
  });

  // checking is form having any unsaved changes or not
  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0;
  useEffect(() => {
    setIsFormDirty(hasUnsavedChanges);
  }, [hasUnsavedChanges, setIsFormDirty]);

  // If select YES on Confirmation Modal
  const onAgree = useCallback(() => {
    onclickDirtyFormModalAgree((param) => {
      setDirtyDialogOpen(false);
      setIsFormDirty(false);
      setNavigateRoute(param?.selectedSidebarMenu);
    });
  }, [onclickDirtyFormModalAgree, setDirtyDialogOpen, setIsFormDirty])

  // If select NO on Confirmation Modal
  const onDisAgree = useCallback(() => {
    onclickDirtyFormModalDisAgree((param) => {
      setDirtyDialogOpen(false);
      setIsFormDirty(false);
      navigate(param?.selectedSidebarMenu);
    });
  }, [navigate, onclickDirtyFormModalDisAgree, setDirtyDialogOpen, setIsFormDirty])

  useEffect(() => {
    onAgree();
    onDisAgree();
  }, [onAgree, onDisAgree])

  useEffect(() => {
    setIsCommonTopbar(true)
    setHeaderPageTitle(pageTitles.hotel.editDetails);
  }, [setIsCommonTopbar, setHeaderPageTitle]);

  useEffect(() => {
    if (user.user?.hotels && user.user.hotels.length > 0) {
      const accessibleHotelIds = user.user.hotels.map(i => Number(i.id));
      setIsHotelAccessible(accessibleHotelIds.includes(hotelId));
    }
  }, [user.user, hotelId])

  useEffect(() => {
    setIsLoaded(isGoogleMapLoaded() ? true : false)
  }, [isGoogleMapLoaded])

  const geocodeAddressExtract = (
    geoAddress,
    includeAllReference,
    referenceKeyArray,
    extractKey = 'long_name'
  ) => {
    if (includeAllReference) {
      const findKey = referenceKeyArray
        .map((referenceKey) =>
          geoAddress.filter((address) => address.types.includes(referenceKey))
        )
        .flat();
  
      const removeDuplicates = findKey.filter(
        (item, index) => findKey.indexOf(item) === index
      );
  
      return removeDuplicates?.length > 0
        ? removeDuplicates.map((li) => li[extractKey]).join(', ')
        : removeDuplicates[0]?.[extractKey] || '';
    } else {
      const findKey = geoAddress.find((address) =>
        referenceKeyArray.find((referenceKey) =>
          address.types.includes(referenceKey)
        )
      );
      return findKey?.[extractKey] || '';
    }
  };

  // Set address values on select of location
  const onSelectPlace = async (address) => {
    if (address) { 
      setEnterLocation(address);

      startLoader();
      await geocodeByAddress(address).then((results) => {
        const geocodeResult = results[0];

        const values = { ...addressInputFields };
        values['add1'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.address1.referenceAllKey,
          googleAddressReferenceKey.address1.key
        );
        values['add2'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.address2.referenceAllKey,
          googleAddressReferenceKey.address2.key
        );
        values['city'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.city.referenceAllKey,
          googleAddressReferenceKey.city.key
        );
        values['state'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.state.referenceAllKey,
          googleAddressReferenceKey.state.key
        );
        values['country'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.country.referenceAllKey,
          googleAddressReferenceKey.country.key
        );
        values['countryCode'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.country.referenceAllKey,
          googleAddressReferenceKey.country.key,
          'short_name'
        );
        values['postal_code'] = geocodeAddressExtract(
          geocodeResult.address_components,
          googleAddressReferenceKey.postalCode.referenceAllKey,
          googleAddressReferenceKey.postalCode.key
        );

        getLatLng(results[0]).then((res) => {
          values['lat'] = res.lat || 0;
          values['lng'] = res.lng || 0;
        });
        setAddressInputFields(values);
        setMapCenter((prev) => ({
          ...prev,
          lat: values.lat,
          lng: values.lng
        }));

        if (markerData && markerData.length > 0) {
          setMarkerData((prev) => {
            let tempMarkerData = [...prev];
            tempMarkerData[0].address = `${values.city}, ${values.country}`;
            return tempMarkerData;
          });
        }
      }).finally(() => {
        stopLoader();
      });
    }
  };

  /**
   * Get facilities from API
   */
  const getFacilitiesFromApi = useCallback(async () => {
    const request = {
      'filters[active][$eq]': '1',
      'pagination[page]': '1',
      'pagination[pageSize]': '10000'
    };
    try {
      const response = await getFacilities({params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []); 

  /**
   * Get other services from API
   */
  const getOtherServicesFromApi = useCallback(async () => {
    const request = {
      'filters[active][$eq]': '1',
      'pagination[page]': '1',
      'pagination[pageSize]': '10000'
    };
    try {
      const response = await getOtherServices({params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Get hotel details from API
   * 
   * @param {*} id  Hotel Id
   */
  const getHotelDetailsFromApi = useCallback(async (hotelId) => {
    const request = {
      'populate': '*'
    };
    try {
      const response = await getHotelDetails(hotelId, {params: request});
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Update hotel details from API
   * 
   * @param {*} id  Hotel Id
   */
  const updateHotelDetailsFromApi = useCallback(async (hotelId, request) => {
    try {
      const response = await updateHotelDetails(hotelId, request);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Upload image from API
   * 
   * @param {*} request   Request
   */
  const uploadImageViaApi = useCallback(async (request) => {
    try {
      const response = await uploadFiles(request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  const fetchAndSetHotelDetails = () => {
    if (hotelId && isHotelAccessible === true) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          let tempHotelFacilities = []
          let tempHotelOtherServices = []
          const response = await getHotelDetailsFromApi(hotelId)
          // Create array of existing hotel facilities
          if (response?.facilities && response.facilities.length) {
            for (const facility of response.facilities) {
              tempHotelFacilities.push(facility.id)
            }
          }
          // Create array of existing hotel other services
          if (response?.other_services && response.other_services.length) {
            for (const service of response.other_services) {
              tempHotelOtherServices.push(service.id)
            }
          }
          const facilitiesResponse = await getFacilitiesFromApi();
          if (facilitiesResponse && facilitiesResponse?.data && facilitiesResponse.data.length) {
            let tempFacilities = []
            for (const facility of facilitiesResponse.data) {
              tempFacilities.push({
                id: facility.id,
                name: facility.name,
                checked: tempHotelFacilities.includes(facility.id) ? 1 : 0
              })
            }
            setFacilities(tempFacilities)
          }
          const otherServicesResponse = await getOtherServicesFromApi();
          if (otherServicesResponse && otherServicesResponse?.data && otherServicesResponse.data.length) {
            let tempOtherServices = []
            for (const service of otherServicesResponse.data) {
              tempOtherServices.push({
                id: service.id,
                name: service.name,
                checked: tempHotelOtherServices.includes(service.id) ? 1 : 0
              })
            }
            setOtherServices(tempOtherServices)
          }
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        console.log('response', response);
        setHotel(response);
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
  }

  useEffect(() => {
    fetchAndSetHotelDetails();
  }, [hotelId, isHotelAccessible, getFacilitiesFromApi, getOtherServicesFromApi, getHotelDetailsFromApi, startLoader, stopLoader])

  useEffect(() => {
    if (hotel && hotel?.images) {
      setUploadedImages(hotel.images);
    }
  }, [hotel]);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (hotelId) {
      // Pass ID's of chosen facilities
      let tempFacilities = [];
      if (data.formHotelEditDetails?.facilities && Object.keys(data.formHotelEditDetails?.facilities).length > 0) {
        for (const facilityId in data.formHotelEditDetails.facilities) {
          if (data.formHotelEditDetails.facilities[facilityId]) {
            tempFacilities.push(facilityId);
          }
        }
      }
      data.formHotelEditDetails.facilities = tempFacilities;

      // Pass ID's of chosen other services
      let tempOtherServices = [];
      if (data.formHotelEditDetails?.other_services && Object.keys(data.formHotelEditDetails?.other_services).length > 0) {
        for (const otherServiceId in data.formHotelEditDetails.other_services) {
          if (data.formHotelEditDetails.other_services[otherServiceId]) {
            tempOtherServices.push(otherServiceId);
          }
        }
      }
      data.formHotelEditDetails.other_services = tempOtherServices;

      // Call API
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const img = data.formHotelEditDetails.img;
          delete data.formHotelEditDetails.img;
          const response = await updateHotelDetailsFromApi(hotelId, data.formHotelEditDetails);

          // Upload hotel images
          if (response && img) {
            // Prepare the form-data for image upload API
            const formData = new FormData();
            formData.append('ref', 'api::hotel.hotel');
            formData.append('refId', hotelId);
            formData.append('field', 'images');
            for (const singleImg of img) {
              formData.append('files', singleImg);
            }
            try {
              await uploadImageViaApi(formData);
            } catch(e) {
              reject(e)
            }
          }
          resolve(response)
        } catch(e) {
          reject(e)
        }
      }).then((response) => {
        toast.success(toastMessages.hotel.update.success, {
          autoClose: maxToastTime,
          toastId: 'update-hotel-details',
        })
        setDirtyDialogOpen(false);
        setIsFormDirty(false);
        fetchLastAccessedHotelDetails(hotelId); // updating hotel context data after SUBMIT
        navigate(navigateRoute ? `${navigateRoute}` : `/hotel/${hotelId}`);
      }).catch((e) => {
        toast.error(toastMessages.hotel.update.error, {
          autoClose: maxToastTime,
          toastId: 'update-hotel-details',
        })
      }).finally(() => {
        stopLoader();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startLoader, stopLoader, hotelId, updateHotelDetailsFromApi, uploadImageViaApi, navigateRoute]);

  // if user click on Yes of confirmation box then onsubmit will call from here and based on navigateRoute user will navigate
  useEffect(() => {
    if (navigateRoute) {
      handleSubmit(onSubmit)();
    }
  }, [navigateRoute, handleSubmit, onSubmit]);

  useEffect(() => {
    if (hotel && hotel?.lat && hotel?.lng) {
      let tempAddressFields = {...addressInputFields}
      if (hotel?.add1) {
        tempAddressFields.add1 = hotel.add1
      }
      if (hotel?.add2) {
        tempAddressFields.add2 = hotel.add2
      }
      if (hotel?.city?.city_name) {
        tempAddressFields.city = hotel.city.city_name
      }
      if (hotel?.city?.country_id?.name) {
        tempAddressFields.country = hotel.city.country_id.name
      }
      if (hotel?.city?.state_name) {
        tempAddressFields.state = hotel.city.state_name
      }
      if (hotel?.postal_code) {
        tempAddressFields.postal_code = hotel.postal_code
      }
      if (hotel?.lat) {
        tempAddressFields.lat = hotel.lat
      }
      if (hotel?.lng) {
        tempAddressFields.lng = hotel.lng
      }
      setAddressInputFields(tempAddressFields)
      setMapCenter((prev) => ({
        ...prev,
        lat: hotel.lat,
        lng: hotel.lng
      }));
      setMarkerData([{
        hotelId: hotel.id,
        name: hotel.name,
        address: `${hotel.city.city_name}${hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : ''}`,
        lat: hotel.lat,
        lng: hotel.lng
      }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel])

  useEffect(() => {
    if (addressInputFields && Object.keys(addressInputFields).length > 0) {
      for (const addField in addressInputFields) {
        setValue(`formHotelEditDetails[${addField}]`, addressInputFields[addField])
      }
      let tempAddress = ''
      if (addressInputFields?.add1) {
        tempAddress = addressInputFields.add1
      }
      if (addressInputFields?.add2) {
        tempAddress += !tempAddress ? addressInputFields.add2 : `, ${addressInputFields.add2}`
      }
      if (addressInputFields?.city) {
        tempAddress += !tempAddress ? addressInputFields.city : `, ${addressInputFields.city}`
      }
      if (addressInputFields?.country) {
        tempAddress += !tempAddress ? addressInputFields.country : `, ${addressInputFields.country}`
      }
      setEnterLocation(tempAddress ? tempAddress : '')
    }
  }, [addressInputFields, setValue])

  const handleRemoveUploadedImage = useCallback(async (index, imageId) => {
    try {
      await deleteImage(imageId);
      setUploadedImages(prevImages => {
        const updatedImages = prevImages.filter((image, i) => i !== index);
        return updatedImages;
      });
    } catch (error) {
        throw error;
    }
  }, [setUploadedImages]);
   
  return (hotel ? (
    <Box sx={{py:1, px:2}}>
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Grid container>
          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Hotel Name *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[name]`}
                    control={control}
                    defaultValue={hotel.name}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.name}
                        errorMessage={errors?.formHotelEditDetails?.name && 'Please enter valid hotel name'}
                        inputRef={field.ref}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Hotel Star Ratings *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[rating]`}
                    control={control}
                    defaultValue={hotel?.rating ? hotel.rating : 0}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.rating}
                        errorMessage={errors?.formHotelEditDetails?.rating && 'Please enter valid rating'}
                        inputRef={field.ref}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /^(([1-4]|0)(\.(0|5))?|(5|5\.0))?$/s
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='No.of Rooms *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[total_rooms]`}
                    control={control}
                    defaultValue={hotel?.total_rooms ? hotel.total_rooms : ''}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.total_rooms}
                        errorMessage={errors?.formHotelEditDetails?.total_rooms && 'Please enter valid no.of rooms'}
                        inputRef={field.ref}
                        {...field}
                        endAdornment={
                          <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                            Rooms
                          </InputEndAdornment>
                        }
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /^([1-9]\d*)$/
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Time to reach the airport by car/bus *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[airportDistance_min]`}
                    control={control}
                    defaultValue={hotel?.airportDistance?.distance_min ? hotel.airportDistance.distance_min : ''}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.airportDistance_min}
                        errorMessage={errors?.formHotelEditDetails?.airportDistance_min && 'Please enter valid time (in mins)'}
                        inputRef={field.ref}
                        {...field}
                        endAdornment={
                          <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                            mins
                          </InputEndAdornment>
                        }
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /^(0|[1-9]\d*)$/
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Hotel Accessible by Foot from Airport? *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[is_by_foot]`}
                    control={control}
                    defaultValue={hotel?.airportDistance?.is_by_foot === true ? '1' : '0'}
                    render={({ field }) => (
                      <Radio
                        options={[
                          {
                            value: 1,
                            label: 'Yes'
                          },
                          {
                            value: 0,
                            label: 'No'
                          }
                        ]}
                        isError={!!errors?.formHotelEditDetails?.is_by_foot}
                        inputRef={field.ref}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Short Description *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[description]`}
                    control={control}
                    defaultValue={hotel?.description ? hotel.description : ''}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        sx={{
                          '& .MuiInputBase-input': {
                            lineHeight: theme.lineHeight[1.37]
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.description}
                        errorMessage={errors?.formHotelEditDetails?.description && 'Please enter valid description'}
                        multiline
                        rows={10}
                        placeholder='Short Description'
                        inputRef={field.ref}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, flexBasis: 'initial !important'}}>
                  <HeadingInput title='Hotel Location *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1, flexBasis: 'initial !important', position: 'relative'}}>
                  {isLoaded ? (
                    <PlacesAutocomplete
                      value={enterLocation}
                      onChange={(address) => setEnterLocation(address ? address : '')}
                      onSelect={(address) => onSelectPlace(address ? address : '')}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <>
                          <Controller
                            name={`formHotelEditDetails[custom_location]`}
                            control={control}
                            defaultValue={enterLocation}
                            render={({ field }) => {
                              return (
                                <Input
                                  {...getInputProps({
                                    name: `formHotelEditDetails[custom_location]`,
                                    placeholder: 'Type Location...',
                                    className: 'location-search-input',
                                    isError: !!errors?.formHotelEditDetails?.add1,
                                    errorMessage: errors?.formHotelEditDetails?.add1 && 'Please enter valid location'
                                  })}
                                  formControlProps={{
                                    sx: {
                                      marginBottom: 0
                                    }
                                  }}
                                />
                              )
                            }}
                          />
                          <AutoCompleteDropdown
                            className='autocomplete-dropdown-container'
                          >
                            {/* {loading && <Box>Loading...</Box>} */}
                            {suggestions.map((suggestion,index) => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = {
                                backgroundColor: suggestion.active
                                  ? theme.palette.grey[800]
                                  : theme.palette.grey[900],
                                cursor: 'pointer',
                                padding: theme.spacing(1),
                                display: 'flex',
                                alignItems: 'center'
                              };
                              return (
                                <Box
                                key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <Box
                                    style={{
                                      marginRight: '5px',
                                      color: theme.palette.primary.light
                                    }}
                                  >
                                    <LocationOnIcon fontSize='small' />
                                  </Box>
                                  <Box>{suggestion.description}</Box>
                                </Box>
                              );
                            })}
                          </AutoCompleteDropdown>
                        </>
                      )}
                    </PlacesAutocomplete>
                  ) : (null)}
                  <Controller
                    name={`formHotelEditDetails[add1]`}
                    control={control}
                    defaultValue={addressInputFields?.add1}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                  <Controller
                    name={`formHotelEditDetails[add2]`}
                    control={control}
                    defaultValue={addressInputFields?.add2}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[city]`}
                    control={control}
                    defaultValue={addressInputFields?.city}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[state]`}
                    control={control}
                    defaultValue={addressInputFields?.state}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[country]`}
                    control={control}
                    defaultValue={addressInputFields?.country}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[postal_code]`}
                    control={control}
                    defaultValue={addressInputFields?.postal_code}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[lat]`}
                    control={control}
                    defaultValue={addressInputFields?.lat}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name={`formHotelEditDetails[lng]`}
                    control={control}
                    defaultValue={addressInputFields?.lng}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{py: 1, flexGrow: 1}}>
                  {mapCenter ? (
                    <Box sx={{width:'100%', height:'100%'}}>
                      <GoogleMap
                        markerData={markerData}
                        mapCenter={mapCenter}
                        setMapCenter={setMapCenter}
                        zoomValue={11}
                        isInfoWindow={false}
                      />
                    </Box>
                  ) : (null)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Contact Mail *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[contact_email]`}
                    control={control}
                    defaultValue={hotel?.contact_email ? hotel.contact_email : ''}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.contact_email}
                        errorMessage={errors?.formHotelEditDetails?.contact_email && 'Please enter valid email'}
                        inputRef={field.ref}
                        {...field}
                        placeholder='Contact email'
                      />
                    )}
                    rules={{
                      required: true,
                      pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column', pt:'0 !important'}}>
                <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                  <HeadingInput title='Contact Number *' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Controller
                    name={`formHotelEditDetails[contact_phone]`}
                    control={control}
                    defaultValue={hotel?.contact_phone ? hotel.contact_phone : ''}
                    render={({ field }) => (
                      <Input
                        formControlProps={{
                          sx: {
                            marginBottom: 0
                          }
                        }}
                        isError={!!errors?.formHotelEditDetails?.contact_phone}
                        errorMessage={errors?.formHotelEditDetails?.contact_phone && 'Please enter valid phone number'}
                        inputRef={field.ref}
                        {...field}
                        placeholder='Contact phone'
                      />
                    )}
                    rules={{
                      required: true
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container>
              <Grid item xs={12}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{pt:'0 !important'}}>
                <Grid item xs={12} sx={{py:1}}>
                  <HeadingInput title='Facilities' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  <Grid container sx={{display: 'flex'}}>
                    {(facilities || {}).length ? (
                      facilities.map((facility, index) => (
                        <Grid item xs={6} sx={{py:theme.space['small']}} key={index}>
                          <Controller
                            name={`formHotelEditDetails[facilities][${facility.id}]`}
                            control={control}
                            defaultValue={facility.checked}
                            render={({ field }) => (
                              <CheckBox
                                inputRef={field.ref}
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e);
                                }}
                                setFocus
                                label={
                                  <Typography variant='caption' sx={{
                                    ...theme.typography.textMedium,
                                    ml: 1
                                  }}>
                                    {facility.name}
                                  </Typography>
                                }
                                formControlLabelProps={{
                                  sx: {
                                    marginLeft: 0
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      ))
                    ) : (null)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{pt:'0 !important'}}>
                <Grid item xs={12} sx={{py:1}}>
                  <HeadingInput title='Other Services' />
                </Grid>
                <Grid item xs={12} sx={{py: 1}}>
                  {(otherServices || {}).length ? (
                    otherServices.map((otherService, index) => (
                      <Grid container sx={{display: 'flex'}} key={index}>
                        <Grid item xs={10} sx={{py:1}}>
                          <Typography variant='caption' sx={{
                            ...theme.typography.textMedium
                          }}>
                            {otherService.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} sx={{py:1, display: 'flex', justifyContent: 'end'}}>
                          <Controller
                            name={`formHotelEditDetails[other_services][${otherService.id}]`}
                            control={control}
                            defaultValue={otherService.checked}
                            render={({ field }) => (
                              <InnerSwitch
                                formControlLabelSx={{
                                  ml: theme.spacing(1)
                                }}
                                inputRef={field.ref}
                                {...field}
                                checked={field.value}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    ))
                  ) : (null)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:4}}>
            <Grid container>
              <Grid item xs={12}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:3}}>
            <Grid container>
              <Grid item xs={12} sx={{py:1}}>
                <HeadingInput title='Upload Hotel Images (image should atleast have width 750 pixels and height 600 pixels)' />
              </Grid>
              <Grid item xs={12} sx={{py: 1}}>
                <Controller
                  name={`formHotelEditDetails[img]`}
                  control={control}
                  render={({ field }) => (
                    <ImageUpload
                      alreadyuploadedimages={uploadedImages}
                      onRemoveUploadedImage={handleRemoveUploadedImage}
                      inputProps={{
                        multiple: true
                      }}
                      setValue={setValue}
                      isPreview
                      inputRef={field.ref}
                      {...field}
                      value=''
                      onChange={(e) => {
                        field.onChange(Object.values(e.target.files))
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{mb:3}}>
            <Grid container>
              <Grid item xs={12}>
                <Divider variant='fullWidth' />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={8} sx={{mt:0}}>
              <Grid item xs={6} sx={{pt:'0 !important'}}>
                <Grid item xs={12}>
                  <CustomButton
                    type='submit'
                    variant='contained'
                    width='100%'
                  >Save Changes</CustomButton>
                </Grid>
              </Grid>
              <Grid item xs={6} sx={{pt:'0 !important'}}>
                <Grid item xs={12}>
                  <Button
                    variant='outlined'
                    sx={{
                      ...theme.typography.textSmall,
                      border: 0,
                      color: theme.palette.primary.light,
                      padding: theme.spacing(2),
                      textTransform: 'capitalize',
                      '&:hover': {
                        border: 0
                      }
                    }}
                    onClick={() => {
                      setDirtyDialogOpen(false);
                      setIsFormDirty(false);
                      navigate(`/hotel/${hotel.id}`);
                    }}
                  >Cancel</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </form>
    </Box>
  ) : (null));
};

export default HotelEditDetails;