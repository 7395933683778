import React from 'react';
import { Typography, styled } from '@mui/material';

const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmall,
  ...theme.typography.fontMedium500,
  fontStyle: 'italic',
  color: theme.palette.menu.light
}));

const RequiredFieldsInfoText = (props) => {
  const { title } = props;

  return (
    <StyledTypographyHeading {...props}>{title}</StyledTypographyHeading>
  );
}

export default RequiredFieldsInfoText
