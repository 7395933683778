import { memo, useState } from 'react';
import { Box, Button, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { Button as CustomButton, Input, Timepicker } from '../../../components';
import { Controller } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { weekdays } from '../../../helper/constant';

const StyledScheduleLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge,
  color: theme.palette.dashboardWidgetHeadline
}));

const StyledScheduleButton = styled(Button, {
  shouldForwardProp: (prop) => ['color'].indexOf(prop) === -1
})(({ color, theme }) => ({
  ...theme.typography.textSmall,
  ...theme.typography.fontMedium500,
  textTransform: 'capitalize',
  color: (color === 'light') ? theme.palette.primary.light : theme.palette.roseGold[100]
}));

const StyledWeekdayName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[600],
  textTransform: 'uppercase'
}));

const StyledTimeBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '5px',
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'end',
  paddingTop: '10px',
  paddingBottom: '9px',
  paddingRight: theme.spacing(1),
  paddingLeft: theme.spacing(1)
}));

const StyledTimeBoxLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium500,
  color: theme.palette.time.contrastText,
  flexGrow: '1',
  paddingLeft: theme.spacing(1),
  paddingRight: '4px'
}));

const StyledTimeBoxIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[800],
  flexGrow: '1',
  justifyContent: 'right',
  padding: 0
}));

function AddTimeButton(props) {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;
  const theme = useTheme();

  return (
    <CustomButton
      variant='contained'
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      startIcon={<AddIcon />}
      style={{
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'unset',
        color: theme.palette.grey[800],
        padding: '12px 16px 11px'
      }}
    >
      Add time
    </CustomButton>
  );
}

// const AddTimeButton = forwardRef(function AddTimeButton(props, ref) {
//   const {
//     setOpen,
//     InputProps: { ref: containerRef } = {},
//     inputProps: { 'aria-label': ariaLabel } = {},
//     ...other
//   } = props;
//   const theme = useTheme();
//   const handleRef = useForkRef(containerRef, ref);

//   return (
//     <div ref={handleRef}>
//       <CustomButton
//         variant='contained'
//         aria-label={ariaLabel}
//         onClick={() => setOpen?.((prev) => !prev)}
//         startIcon={<AddIcon />}
//         style={{
//           backgroundColor: theme.palette.primary.main,
//           boxShadow: 'unset',
//           color: theme.palette.grey[800],
//           padding: '12px 16px 11px'
//         }}
//         {...other}
//       >
//         Add time
//       </CustomButton>
//     </div>
//   );
// });

const TransportationSchedule = (props) => {
  const { control, source, scheduleId, scheduleIndex, scheduleNumber, weekday, time, airportId, terminalId, onSelectTime, onRemoveTime, copiedSchedule, onCopySchedule, onPasteSchedule, onDeleteSchedule, permission } = props;
  const [timepickerOpen, setTimepickerOpen] = useState(false)
  const [timepickerValue, setTimepickerValue] = useState(null)
  const theme = useTheme()

  // Get weekday name by code 
  const getWeekdayName = (dayCode) => {
    return (weekdays?.[dayCode]?.name) ? weekdays[dayCode].name : dayCode;
  };

  // Get selected time on select of time in timepicker
  const onTimepickerAccept = (time) => {
    onSelectTime(source, scheduleId, airportId, terminalId, scheduleIndex, time)
  };

  // Get selected time on select of time in timepicker
  const onRemoveScheduleTime = (time) => {
    onRemoveTime(source, scheduleId, airportId, terminalId, scheduleIndex, time)
  };

  // Copy whole schedule
  const onCopyEntireSchedule = (index) => {
    onCopySchedule(source, scheduleId, airportId, terminalId, index)
  };

  // Paste whole schedule
  const onPasteEntireSchedule = (index) => {
    onPasteSchedule(source, scheduleId, airportId, terminalId, index)
  };

  // Delete whole schedule
  const onDeleteEntireSchedule = (index) => {
    onDeleteSchedule(source, scheduleId, airportId, terminalId, index)
  };

  return (
    <Grid item xs={12}>
      <Grid container sx={{justifyContent: 'center', alignItems: 'baseline', mb: 3}}>
        <Grid item xs={2}>
          {weekday.map((dayCode, index) => (
            <Box>
              <StyledWeekdayName sx={{mb:2}}>{getWeekdayName(dayCode)}</StyledWeekdayName>
              <Controller
                name={`formTransportationSchedules[${source}][${airportId}][terminals][${terminalId}][schedules][${scheduleIndex}][weekday][${index}]`}
                control={control}
                defaultValue={dayCode}
                render={({ field }) => (
                  <Input
                    type='hidden'
                    formControlProps={{sx: {m:0}}}
                    sx={{border:0}}
                    {...field}
                    disabled={!permission?.edit}
                  />
                )}
              />
            </Box>
          ))}
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} sx={{display: 'flex', alignItems: 'center'}}>
              <Box sx={{flexGrow: '1'}}>
                <StyledScheduleLabel variant='body1'>Schedule {scheduleNumber}</StyledScheduleLabel>
              </Box>
              {permission?.edit ? (
                <Box>
                  <StyledScheduleButton
                    variant='text'
                    color='light'
                    startIcon={<CopyAllIcon />}
                    sx={{mr:2}}
                    onClick={() => {
                      if (copiedSchedule) {
                        // eslint-disable-next-line eqeqeq
                        if (copiedSchedule?.source === source && copiedSchedule?.airportId === airportId && copiedSchedule?.terminalId === terminalId && copiedSchedule?.index === scheduleIndex) {
                          onCopyEntireSchedule(scheduleIndex)
                        } else {
                          onPasteEntireSchedule(scheduleIndex)
                        }
                      } else {
                        onCopyEntireSchedule(scheduleIndex)
                      }
                    }}
                  >
                    {/* eslint-disable-next-line eqeqeq */}
                    {copiedSchedule ? 
                      ((copiedSchedule?.source === source && copiedSchedule?.airportId === airportId && copiedSchedule?.terminalId === terminalId && copiedSchedule?.index === scheduleIndex) ? 'Copy Timings' : 'Paste Timings') 
                    : 'Copy Timings'}
                  </StyledScheduleButton>
                  <StyledScheduleButton
                    variant='text'
                    startIcon={<DeleteIcon />}
                    onClick={() => onDeleteEntireSchedule(scheduleIndex)}
                  >Delete Schedule</StyledScheduleButton>
                </Box>
              ):(null)}
            </Grid>
            <Grid item xs={12} sx={{py:1}}>
              {time.map((eachTime, index) => (
                <Box sx={{display: 'inline-flex', verticalAlign: 'top'}}>
                  <StyledTimeBox>
                    <StyledTimeBoxLabel variant='caption'>
                      {moment(eachTime, ['HH:mm']).format('hh:mm a')}
                    </StyledTimeBoxLabel>
                    <StyledTimeBoxIcon
                      aria-label='Remove'
                      onClick={() => onRemoveScheduleTime(eachTime)}
                      disabled={!permission?.edit}
                    >
                      <CloseIcon />
                    </StyledTimeBoxIcon>
                  </StyledTimeBox>
                  <Controller
                    name={`formTransportationSchedules[${source}][${airportId}][terminals][${terminalId}][schedules][${scheduleIndex}][time][${index}]`}
                    control={control}
                    defaultValue={eachTime}
                    render={({ field }) => (
                      <Input
                        type='hidden'
                        formControlProps={{sx: {m:0}}}
                        sx={{border:0}}
                        {...field}
                      />
                    )}
                  />
                </Box>
              ))}
              <Controller
                name={`time`}
                control={control}
                render={({ field }) => (
                  <Timepicker
                    slots={{
                      field: AddTimeButton
                    }}
                    slotProps={{
                      field: {
                        setOpen: setTimepickerOpen
                      },
                      popper: {
                        sx: {
                          '& .MuiMenuItem-root': {
                            '&.Mui-selected, &.Mui-selected:hover': {
                              color: theme.mode === 'light' ?  theme.palette.menu.main:theme.palette.primary.contrastText,
                              backgroundColor: theme.palette.primary.light
                            }
                          },
                          '& .MuiDialogActions-root': {
                            display: 'none'
                          }
                        },
                      },
                    }}
                    format={'hh:mm a'}
                    inputRef={field.ref}
                    {...field}
                    value={timepickerValue}
                    open={timepickerOpen}
                    onClose={() => {
                      setTimepickerOpen(false);
                      setTimepickerValue(null)
                    }}
                    onOpen={() => setTimepickerOpen(true)}
                    onAccept={onTimepickerAccept}
                    disabled={!permission?.edit}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(TransportationSchedule);