import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get airlines
 * 
 * @param {*} request     Request
 * @returns 
 */
export const getAirlines = async(request = {}) => {
  try {
    const response = await api.get(`/airlines`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Airline users
 * 
 * @param {*} request  
 * @returns 
 */
export const getAirlineUsers = async(request = {}) => {
  try {
    const response = await api.get('/airline-users', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get airline details
 * 
 * @param {*} airlineId   Airline id
 * @param {*} request     Request
 * @returns 
 */
export const getAirlineDetails = async(airlineId, request = {}) => {
  try {
    const response = await api.get(`/airlines/${airlineId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update airline 
 * 
 * @param {*} airlineId   airline ID 
 * @param {*} request   request
 * @returns 
 */
export const updateArline = async(airlineId, request = {}) => {
  try {
    const response = await api.put(`/airlines/${airlineId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * Update airline users
 * 
 * @param {*} airlineId   airline ID 
 * @param {*} request   request
 * @returns 
 */
export const updateArlineUsers = async(airlineId, request = {}) => {
  try {
    const response = await api.post(`/airlines/${airlineId}/users`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};

/**
 * update the QR Code Generation Setting for the airline
 * 
 * @param {*} airlineId   airlineId 
 * @param {*} airportId   airportId 
 * @param {*} request   request
 * @returns 
 */
export const updateQRSetting = async(airlineId, airportId, request = {}) => {
  try {
    const response = await api.get(`/airline-airport-qr/airlineId/${airlineId}/airportId/${airportId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Invite airline users
 * 
 * @param {*} airlineId   airlineId 
 * @param {*} request   request
 * @returns 
 */
export const inviteAirlineUsers = async(airlineId, request = {}) => {
  try {
    const response = await api.post(`/airlines/${airlineId}/invite-users`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};


/**
 * Get Airline Billing management data
 * 
 * @param {Number} airlineId
 * @param {*} request   Request
 * @returns 
 */
export const getAirlineBillingData = async(airlineId, airportId, request = {}) => {
  try {
    const response = await api.get(`/hotel-layover-billings?view=airline&airlineId=${airlineId}&airportId=${airportId}`, request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e.response.status !== 400 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * get Billing management invoice details
 * 
 * @param {Number} invoiceId
 * @param {*} request   Request
 * @returns 
 */
export const getInvoiceDetails = async(invoiceId, request = {}) => {
  try {
    const response = await api.get(`/hotel-layover-billings/${invoiceId}?populate=invoice&fields[0]=id&fields[1]=invoice_id`, request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};