import { ColorPalette as DarkColorPalette } from './dark/ColorPalette';
import { ColorPalette as LightColorPalette } from './light/ColorPalette';
import { default as BorderRadius } from './BorderRadius';
import { default as IconSize } from './IconSize';
import { default as BoxShadow } from './BoxShadow';
import { default as Spacing } from './Spacing';
import { default as Typography } from './Typography';
import { default as LineHeight } from './LineHeight';

/**
 * 
 * @param {*} mode  Palette Mode (Possible Values: light, dark)  
 */
const getTheme = (mode) => ({
  mode: mode,
  palette: {
    mode, ...(mode === 'light') ? LightColorPalette : DarkColorPalette
  },
  typography: Typography,
  space: Spacing,
  iconSize: IconSize,
  borderRadius: BorderRadius,
  boxShadow: BoxShadow,
  lineHeight: LineHeight,
  blur: { main: 'blur(4px)', secondary: 'blur(2px)' }
});

export default getTheme;