import { Checkbox, FormControlLabel, styled } from '@mui/material';
import { useEffect, memo, useState } from 'react';

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  borderRadius: '7px',
  padding: 0,
  color: theme.mode === 'light' ? theme.palette.menu.light : theme.palette.menu.main,
  '&.Mui-checked': {
    color: theme.mode === 'light' ? theme.palette.menu.light : theme.palette.primary.light,
  },
}));

const CheckBox = (props) => {
  const { checked = false, size = 'medium', onChange, formControlLabelProps, label, ...rest } = props;
  const [isChecked, setIsChecked] = useState(checked);
  const [boxSize, setBoxSize] = useState(size);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    setBoxSize(size);
  }, [size]);

  const onChecked = (e) => {
    setIsChecked(e.target.checked);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormControlLabel
      control={
        <StyledCheckbox
          checked={isChecked}
          size={boxSize}
          onChange={onChecked}
          {...rest}
        />
      }
      label={label || ''}
      {...formControlLabelProps}
    />
  );
};

export default memo(CheckBox);