import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel availability
 * 
 * @param {*} request   Request
 * @returns 
 */
export const getAvailability = async(request = {}) => {
  try {
    const response = await api.get('/hotel-availabilities', request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update hotel availability
 * 
 * @param {*} request   Request
 * @returns 
 */
export const updateAvailability = async(request = {}) => {
  try {
    const response = await api.post('/hotel-availabilities', request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Hotels for selected airline and selected airport
 * 
 * @param {*} airlineId   Airline ID
 * @param {*} airportId   Airport ID
 * @param {*} request     Request
 * @returns 
 */
export const getHotelsForAirlineAndAirport = async(airlineId, airportId, request = {}) => {
  try {
    const response = await api.get(`/fetchAvailableHotels/airlineId/${airlineId}/airportId/${airportId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};