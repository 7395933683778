import React, { useEffect, useState } from 'react';
import { Box, IconButton, InputAdornment, Typography, styled, useTheme } from '@mui/material';
import BgImgPage from '../../assets/images/bg-login.png';
import Logo from '../../assets/images/logo.png';
import { Button, Input } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { login } from '../../services/auth/login';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../redux/actions/user';
import { Link, useNavigate } from 'react-router-dom';
import { getSelfUser } from '../../services/user';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { userRoles } from '../../helper/constant';

const PageContainer = styled(Box)(({ theme }) => ({
  '& .overlap': {
    borderRadius: '10px',
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  '& .bg-image': {
    backgroundImage: `url(${BgImgPage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    userSelect: 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  '& .backdrop': {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: '#182730d9',
    backdropFilter: 'blur(5px) brightness(100%)'
  }
}));

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  '& .wrapper-fieldset': {
    borderRadius: '10px',
    borderColor: 'rgba(174, 207, 227, 0.3)',
    marginTop: '20px',
    '& legend': {
      padding: '0 20px',
      fontSize: '22px',
      fontWeight: '600',
      margin: '0 auto'
    }
  },
  '& .MuiTypography-root.login-text': {
    ...theme.typography.textMedium,
    marginBottom: theme.spacing(2)
  }
}));

const LoginPage = () => {
  const [showLogin, setShowLogin] = useState(true);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const user = useSelector((state) => state.user);
  const theme = useTheme()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus
  } = useForm();

  useEffect(() => {
    if (user.user?.id) {
      navigate('/');
    }
  }, [navigate, user.user]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  // Show/Hide password on click of button
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Call Login API on form submit
  const onSubmit = (data) => {
    doLogin({
      identifier: data.email,
      password: data.password
    });
  };

  /**
   * User Login 
   * 
   * @param {*} request   API request to login
   */
  const doLogin = async(request) => {
    try {
      const response = await login(request);
      if (response?.jwt && response?.user && response?.user?.role?.type) {
        dispatch(
          setUser({
            token: response.jwt,
            user: response?.user
          })
        );

        /**
         * To get the user role details, we need to make an extra API call to get self user details 
         */
        let selfUserRequest = {}
        switch (response.user.role.type) {
          case userRoles.hotelFrontDesk:
          case userRoles.hotelAdmin: {
            selfUserRequest = {
              'populate[role]': '*',
              'populate[hotels][fields][0]': 'name',
              'populate[hotels][fields][1]': 'is_open_for_layover',
              'populate[hotels][fields][2]': 'lat',
              'populate[hotels][fields][3]': 'lng',
              'populate[hotels][populate][city][fields][0]': 'city_name',
              'populate[hotels][populate][city][populate][country_id][fields][0]': 'name',
              'populate[last_accessed_hotel_id][fields][0]': 'id'
            };
            break;
          }
          case userRoles.airlineGroundStaff:
          case userRoles.airlineAdmin: {
            selfUserRequest = {
              'populate[role]': '*',
              'populate[airports][fields][0]': 'id',
              'populate[airports][fields][1]': 'name',
              'populate[airports][fields][2]': 'iata',
              'populate[airlines][fields][0]': 'name',
              'populate[airlines][populate][city][fields][0]': 'city_name',
              'populate[airlines][populate][city][populate][country_id][fields][0]': 'name',
              'populate[last_accessed_airline_id][fields][0]': 'id'
            };
            break;
          }
          default: {
            selfUserRequest = {
              'populate[role]': '*'
            };
            break;
          }
        }
        const userDetails = await getSelfUser({params: selfUserRequest});
        if (userDetails) {
          dispatch(
            setUser({
              token: response.jwt,
              user: userDetails
            })
          );
          navigate('/');
        } else {
          // TBD: throw error
        }
      } else {
        // TBD: throw error
      }
    } catch (e) {}
  };

  return (
    <>
      <PageContainer>
        <div className='overlap'>
          <div className='bg-image'></div>
          <div className='backdrop'></div>
          <Wrapper>
            {process.env.REACT_APP_ENVIRONMENT === 'STAGING' ? (
              <Box sx={{
                textAlign: 'center',
                bgcolor: theme.palette.error.main,
                mb: 2,
                p: 1,
                borderRadius: theme.borderRadius[8],
                color:'white'
              }}>
                <Typography variant='body2'><u>Attention:</u> You landed at the Croowy Companion Staging Environment</Typography>
                <br/>
                <Typography variant='body2'>Please only use - <Link to='https://companion.croowy.de'>https://companion.croowy.de</Link></Typography>
              </Box>
            ) : (null)}
            <img src={Logo} width={55} alt='Croowy Companion' />
            {showLogin ? (
                <Box component='fieldset' className='wrapper-fieldset' style={{
                  width: '428px',
                  padding: '25px 60px',
                  color:theme.mode === 'light' ? theme.palette.menu.main : 'inherit'
                }}>
                  <legend>Login</legend>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box>
                      <Controller
                        name='email'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='Email address'
                            label='Email address *'
                            isError={!!errors.email}
                            errorMessage={errors.email && 'Please enter valid email address'}
                            inputRef={field.ref}
                            autoFocus
                            {...field}
                            formControlProps={{
                              sx: {'& .MuiFormLabel-root':{color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}
                              }
                            }}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                        }}
                      />

                      <Controller
                        name='password'
                        control={control}
                        render={({ field }) => (
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            label='Password *'
                            isError={!!errors.password}
                            errorMessage={errors.password && 'Please enter password'}
                            inputRef={field.ref}
                            {...field}
                            formControlProps={{
                              sx: {'& .MuiFormLabel-root':{color: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}
                              }
                            }}
                            endAdornment={
                              <InputAdornment position='start'>
                                <IconButton
                                  onClick={handleClickShowPassword}
                                >
                                  {showPassword ? <VisibilityIcon sx={{color: theme.palette.icon.light}} /> : <VisibilityOffIcon sx={{color: theme.palette.icon.light}} />}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                    {/* <Typography
                      inline
                      variant='body1'
                      align='right'
                      className='login-text'
                      style={{
                        textDecoration: 'underline'
                      }}
                      onClick={() => {
                        setShowForgotPassword(true)
                        setShowLogin(false)
                      }}
                    >Forgot password?</Typography> */}
                    <Button
                      type='submit'
                      variant='contained'
                      width='100%'
                      style={{
                        marginBottom: '25px'
                      }}
                    >Sign In</Button>
                  </form>
                  <Typography
                    inline
                    variant='body1'
                    align='center'
                    className='login-text'
                  >Don't have an account yet? 
                    <Link
                      to='https://croowy.de/support/'
                      target='_blank'
                      rel='noopener noreferrer'
                      style={{ textDecoration: 'underline', color: 'white' }}
                    >
                      <Typography display='inline' className='login-text'><b> Contact Us</b></Typography>
                    </Link>
                  </Typography>
                </Box>
            ) : ('')}

            {showForgotPassword ? (
              <Box component='fieldset' className='wrapper-fieldset' style={{
                width: '428px',
                padding: '25px 60px'
              }}>
                <legend>Login</legend>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box>
                    <Controller
                      name='email'
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder='Email address'
                          label='Email address *'
                          isError={!!errors.email}
                          errorMessage={errors.email && 'Please enter valid email address'}
                          inputRef={field.ref}
                          autoFocus
                          {...field}
                        />
                      )}
                      rules={{
                        required: true,
                        pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                      }}
                    />

                    <Controller
                      name='password'
                      control={control}
                      render={({ field }) => (
                        <Input
                          type='password'
                          placeholder='Password'
                          label='Password *'
                          isError={!!errors.password}
                          errorMessage={errors.password && 'Please enter password'}
                          inputRef={field.ref}
                          {...field}
                        />
                      )}
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                  <Typography
                    inline
                    variant='body1'
                    align='right'
                    className='login-text'
                    style={{
                      textDecoration: 'underline'
                    }}
                    onClick={() => {
                      setShowForgotPassword(false)
                      setShowLogin(true)
                    }}
                  >Back to login</Typography>
                  <Button
                    type='submit'
                    variant='contained'
                    width='100%'
                    style={{
                      marginBottom: '25px'
                    }}
                  >Sign In</Button>
                </form>
                <Typography
                  inline
                  variant='body1'
                  align='center'
                  className='login-text'
                >Don't have an account yet? 
                  <Link
                    to='https://croowy.de/support/'
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{ textDecoration: 'underline', color: 'white' }}
                  >
                    <Typography display='inline' className='login-text'><b> Contact Us</b></Typography>
                  </Link>
                </Typography>
              </Box>
            ) : ('')}
          </Wrapper>
        </div>
      </PageContainer>
    </>
  );
};

export default LoginPage;