/**
 * Get website url
 * 
 * @returns string
 */

function getSiteUrl() {
  return `${window.location.protocol}//${window.location.host}`
}

export default getSiteUrl;
