import { useJsApiLoader } from '@react-google-maps/api';
import { createContext, useCallback, useContext, useMemo } from 'react';

const GoogleMapContext = createContext();

export function GoogleMapProvider({children}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script-3',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places'],
  });
  const isGoogleMapLoaded = useCallback(() => {return isLoaded}, [isLoaded]);
  const value = useMemo(
    () => ({isGoogleMapLoaded}),
    [isGoogleMapLoaded]
  );

  return (
    <GoogleMapContext.Provider value={value}>
      {children}
    </GoogleMapContext.Provider>
  );
}

export const useGoogleMap = () => useContext(GoogleMapContext);