import { memo, useEffect, useState } from 'react';
import { FormControl, Typography, Slider as MuiSlider, styled } from '@mui/material';

const FormControlContainer = styled(FormControl, {
  shouldForwardProp: (prop) => ['marginBottom'].indexOf(prop) === -1
})(({ theme, marginBottom }) => ({
  display: 'flex',
  marginBottom: marginBottom ? marginBottom : '20px',
  // We are adding padding here because we have manage the position the slider bubbles by 12px in left & 12 px in right
  paddingLeft: '12px',
  paddingRight: '12px',
  '& .MuiFormLabel-root': {
    color: theme.palette.text.secondary,
    ...theme.typography.inputLabel,
    '& + .MuiInputBase-root': {
      marginTop: '18px'
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.secondary
  },
}));

const StyledMuiSlider = styled(MuiSlider)(({ theme }) => ({
  height: '1px',
  '& .MuiSlider-rail': {
    backgroundColor: theme.palette.grey[800],
    // Set the left and width in such a way to adjust the position the slider bubbles by 12px in left & 12 px in right (i.e. edge to edge rail)
    left: '-12px',
    width: 'calc(100% + 24px)'
  },
  '& .MuiSlider-track': {
    backgroundColor: theme.palette.primary.light
  },
  '& .MuiSlider-thumb': {
    width: '25px',
    height: '25px',
    border: `1.5px solid ${theme.palette.primary.light}`
  }
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const Slider = (props) => {
  const { label, min = 0, max = 100, value, onChange, formControlProps, isError, errorMessage } = props;
  const [ariaLabel, setAriaLabel] = useState('');
  const [sliderValue, setSliderValue] = useState([min, max]);
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  useEffect(() => {
    if (isError) { setValidationErrorMessage(errorMessage ? errorMessage : '') }
  }, [isError, errorMessage]);

  useEffect(() => {
    setAriaLabel(label ? label : '')
  }, [label])

  useEffect(() => {
    setSliderValue(value)
  }, [value])

  const handleChange = (e, newValue) => {
    setSliderValue(newValue);
  };

  return (
    <FormControlContainer variant='standard' {...formControlProps}>
      <StyledMuiSlider
        getAriaLabel={() => ariaLabel}
        valueLabelDisplay='auto'
        {...props}
        value={sliderValue}
        onChange={handleChange}
        onChangeCommitted={(e, newValue) => {
          if (onChange) {
            onChange(newValue);
          }
        }}
      />
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControlContainer>
  );
};

export default memo(Slider);