import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import BgImgPage from '../../assets/images/bg-login.png';
import Logo from '../../assets/images/logo.png';
import { Button, Input, AlertDialog } from '../../components';
import { Controller, useForm } from 'react-hook-form';
import { fetchInvite, acceptInvite } from '../../services/auth/invite';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { maxToastTime, toastMessages } from '../../helper/constant';
import { toast } from 'react-toastify';
import { useLoader } from '../../providers/loader';
import { getUrlParams } from '../../utils/index';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../services/auth/logout';

const PageContainer = styled(Box)(({ theme }) => ({
  '& .overlap': {
    borderRadius: '10px',
    position: 'relative',
    width: '100vw',
    height: '100vh',
  },
  '& .bg-image': {
    backgroundImage: `url(${BgImgPage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    userSelect: 'none',
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
  },
  '& .backdrop': {
    position: 'absolute',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: theme.palette.primary.dark,
    opacity: '.85',
    backdropFilter: 'blur(5px) brightness(100%)',
  },
}));

const Wrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  width: '30%',
  '& .wrapper-fieldset': {
    borderRadius: '10px',
    borderColor: 'rgba(174, 207, 227, 0.3)',
    marginTop: '20px',
    '& legend': {
      padding: '0 20px',
      fontSize: theme.typography.dashboardHeadline.fontSize,
      fontWeight: theme.typography.fontSemiBold600.fontWeight,
      margin: '0 auto',
    },
  },
  '& .MuiTypography-root.login-text': {
    ...theme.typography.textMedium,
    marginBottom: theme.spacing(2),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
}));

const Para = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(2),
  color: theme.palette.table.header,
}));

const AcceptInvitepage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [inviteCode, setInviteCode] = useState(null);
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { value } = getUrlParams('code');
  const { startLoader, stopLoader } = useLoader();
  const user = useSelector((state) => state.user);

  const { control, handleSubmit, formState: { errors }, resetField, watch } = useForm({
    defaultValue: { email: '', first_name: '', last_name: '', password: '', confirmPassword: '' },
  });

  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('confirmPassword', '');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (user?.user !== null) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
    }
  }, [user]);

  const onDialogAgree = () => {
    logoutUser(); 
  };

  const getInvitationDetail = useCallback(async (value) => {
    try {
      const response = await fetchInvite(value);
      return response;
    } catch (e) {
      throw e;
    }
  }, []);

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await getInvitationDetail(value);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }).then((response) => {
      setInviteDetails(response);
      resetField('email', { defaultValue: response?.inviteeEmail });
      setInviteCode(value);
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [getInvitationDetail, value, resetField, startLoader, stopLoader]);

  const onSubmit = useCallback(async (data) => {
    // API call
    startLoader();
    // Create a Promise for asynchronous processing
    new Promise(async (resolve, reject) => {
      try {
        const formData = { ...data, username: data.email, code: value };
        const response = await acceptInvite(formData);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }).then((response) => {
      toast.success(toastMessages.register.success, {
        autoClose: maxToastTime,
        toastId: 'registration-success',
      });
      navigate('/user/login');
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, navigate, value]);

  const logoutUser = () => {
    const redirectUrl = `/user/accept-invite?code=${inviteCode}`;
    logout(dispatch, navigate, redirectUrl);
    setDialogOpen(false); // Close the dialog after logout
  };

  return (
    <Box>
      {/* Show AlertDialog if user is not null */}
      <AlertDialog
        title="You are already logged in"
        open={dialogOpen}
        onAgree={onDialogAgree}
        yesBtnText="Logout"
        noBtnText="Cancel"
        sx={{
          textAlign: 'center',
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{ lineHeight: '1.37rem', py: 2 }} variant='subtitle2'>
          You are already logged in to the system. Please log out by clicking on the button below and then follow the registration process.
        </Typography>
        <Button onClick={logoutUser}>Logout</Button>
      </AlertDialog>

      {user !== null && (
        <PageContainer>
          <Box className='overlap'>
            <Box className='bg-image' style={{ backgroundImage: `url(${BgImgPage})` }}></Box>
            <Box className='backdrop'></Box>
            <Wrapper>
              <Box>
                <img src={Logo} width={55} alt='Croowy Companion' />
                <Box component='fieldset' className='wrapper-fieldset'>
                  <legend>Register</legend>
                  <Para>
                    {inviteDetails?.inviter?.first_name && inviteDetails.inviter.first_name.charAt(0).toUpperCase() + inviteDetails.inviter.first_name.slice(1)}{' '}
                    {inviteDetails?.inviter?.last_name && inviteDetails.inviter.last_name.charAt(0).toUpperCase() + inviteDetails.inviter.last_name.slice(1)} invited you to join.
                  </Para>
                  <Para>{inviteDetails?.hotel?.name}</Para>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <StyledBox>
                      <Controller
                        name='first_name'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='First Name'
                            label='First Name*'
                            isError={!!errors.firstName}
                            errorMessage={errors.firstName && 'Please enter your first name'}
                            inputRef={field.ref}
                            autoFocus
                            {...field}
                          />
                        )}
                        rules={{ required: true }}
                      />
                      <Controller
                        name='last_name'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='Last Name'
                            label='Last Name*'
                            isError={!!errors.lastName}
                            errorMessage={errors.lastName && 'Please enter your last name'}
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        rules={{ required: true }}
                      />
                      <Controller
                        name='email'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='Email address'
                            label='Email address*'
                            inputRef={field.ref}
                            readOnly
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name='password'
                        control={control}
                        render={({ field }) => (
                          <Input
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            label='Password*'
                            isError={!!errors.password}
                            errorMessage={
                              errors.password
                                ? (field.value === '' ? 'Please enter a password' : 'Password must be at least 8 characters long and contain at least one letter, one number, and one special character')
                                : ''
                            }
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputAdornment position='start'>
                                <IconButton onClick={handleClickShowPassword}>
                                  {showPassword ? (
                                    <VisibilityIcon sx={{ color: theme.palette.icon.light }} />
                                  ) : (
                                    <VisibilityOffIcon sx={{ color: theme.palette.icon.light }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          minLength: 8,
                          pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
                        }}
                      />
                      <Controller
                        name='confirmPassword'
                        control={control}
                        render={({ field }) => (
                          <Input
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder='Confirm Password'
                            label='Confirm Password*'
                            isError={!!errors.confirmPassword}
                            errorMessage={errors.confirmPassword && 'Passwords do not match'}
                            inputRef={field.ref}
                            {...field}
                            endAdornment={
                              <InputAdornment position='start'>
                                <IconButton onClick={handleClickShowConfirmPassword}>
                                  {showConfirmPassword ? (
                                    <VisibilityIcon sx={{ color: theme.palette.icon.light }} />
                                  ) : (
                                    <VisibilityOffIcon sx={{ color: theme.palette.icon.light }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          validate: (value) => value === watchPassword,
                        }}
                      />
                      <Button
                        type='submit'
                        variant='contained'
                        width='100%'
                        style={{ marginBottom: '25px' }}
                        disabled={!watchPassword || !watchConfirmPassword}
                      >
                        Create Account
                      </Button>
                    </StyledBox>
                  </form>
                  <Typography variant='body1' align='center'>
                    <u>Contact Us </u>
                  </Typography>
                </Box>
              </Box>
            </Wrapper>
          </Box>
        </PageContainer>
      )}
    </Box>
  );
};

export default AcceptInvitepage;
