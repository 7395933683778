import { useContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { AlertDialog, Dropdown, InnerSwitch } from '../../components';
import { KeyboardArrowDown } from '@mui/icons-material';
import { HotelContext } from '../../context/hotel';
import { useLoader } from '../../providers/loader';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../helper/constant';

const HeaderLeftAppbar = (props) => {
  const { hotels, onHotelChange } = props;
  const theme = useTheme();
  const { hotelId, hotel, isBookingsOpen, setIsBookingsOpen, onChangeLayoverBookingSwitch } = useContext(HotelContext);
  const { startLoader, stopLoader } = useLoader();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
  }, [hotel, setIsBookingsOpen]);

  // Get the UI of dropdown item
  const getListItem = (hotel) => {
    if (hotel) {
      return (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'left'
        }}>
          <Typography sx={{
            ...theme.typography.textLarge,
            pb: 1
          }} variant='caption'>{hotel.name}</Typography>
          {hotel?.city?.city_name ? (
            <Typography sx={theme.typography.textxSmall} variant='caption'>{hotel.city.city_name}{hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : ''}</Typography>
          ) : ('')}
        </Box>
      );
    }
    return null;
  };
  /**
   * Prepare the list of hotels to show in dropdown list
   * 
   * @param {*} hotels  Array of hotels 
   * @returns 
   */
  const prepareList = (accessedHotelId, hotels) => {
    if (hotels && hotels.length) {
      return hotels.filter(function(hotel) {
        return (hotel.id !== accessedHotelId);
      }).map((hotel) => {
        return {
          key: hotel.id,
          name: getListItem(hotel),
          onClickOption: onHotelChange
        };
      });
    }
    return [];
  };

  const onChangeIsBookingsOpen = (val) => {
    setDialogOpen(true);
    setIsBookingsOpen(val);
  };

  const onDialogAgree = (isOpen, hotelId) => {
    setDialogOpen(false);
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        await onChangeLayoverBookingSwitch(isOpen, hotelId)
        resolve()
      } catch (e) {
        reject(e)
      }
    }).then((response) => {
      setIsBookingsOpen(isOpen);
      const msg = isOpen ? toastMessages.layoverBookings.open.success : toastMessages.layoverBookings.close.success;
      toast.success(msg, {
        autoClose: maxToastTime,
        toastId: 'header-layover-bookings-flag-success',
      })
    }).catch((error) => {
      setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
      // Show error message
      const msg = isOpen ? toastMessages.layoverBookings.open.error : toastMessages.layoverBookings.close.error;
      toast.error(msg, {
        autoClose: maxToastTime,
        toastId: 'header-layover-bookings-flag-error',
      })
      // Show warning messages
      const errorResponse = error?.response?.data?.error?.details?.error;
      let warningMsgs = Object.keys(errorResponse).map((errorMessage, i) => {
        return (`<p>${i+1}. ${errorResponse[errorMessage]}</p>`);
      }).join('');
      toast.warning(<div dangerouslySetInnerHTML={{ __html: warningMsgs }} />, {
        autoClose: 30000,
        toastId: 'header-layover-bookings-flag-warning',
      });
    }).finally(() => {
      stopLoader();
    });
  };

  const onDialogDisagree = () => {
    setDialogOpen(false);
    setIsBookingsOpen(hotel?.is_open_for_layover ? hotel.is_open_for_layover : false);
  };

  return ((hotelId && hotel) ? (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      py: '6px'
    }}>
      <Dropdown
        endIcon={hotels && hotels.length === 1 ? '' : <KeyboardArrowDown />}
        text={
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left'
          }}>
          {getListItem(hotel)}
          </Box>
        }
        isReadOnly={hotels && hotels.length === 1 ? true : false }
        options={prepareList(hotelId, hotels)}
        btnSx={{p: 0}}
        boxSx={{mr: theme.spacing(2)}}
        itemSx={{py: theme.spacing(2)}}
        isDivider
      />
      {hotel?.is_mandatory_info ? (
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mr: theme.spacing(2)
        }}>
          <InnerSwitch checked={isBookingsOpen} onChange={(e) => {
            onChangeIsBookingsOpen(e.target.checked);
          }} formControlLabelSx={{
            mb: theme.spacing(1)
          }} />
          <Typography sx={{
            ...theme.typography.textxxSmall,
            textAlign: 'center',
            color: theme.palette.menu.main
          }} variant='caption' >{isBookingsOpen ? 'Open for Layover' : 'Closed for Layover'}</Typography>
        </Box>
      ) : (null)}
      {/* Render Confirm Dialog */}
      <AlertDialog
        title='Are you sure?'
        open={dialogOpen}
        onAgree={() => onDialogAgree(!hotel?.is_open_for_layover, hotelId)}
        onDisagree={onDialogDisagree}
        yesBtnText={`Yes, ${hotel?.is_open_for_layover === true ? 'Close' : 'Open'}`}
        noBtnText='No, Go back'
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          Do you really want to {hotel?.is_open_for_layover === true ? 'close' : 'open'} the hotel <Typography sx={{...theme.typography.textMedium, ...theme.typography.fontMedium600}} variant='caption'>{hotel?.name}</Typography> for layovers?
        </Typography>
      </AlertDialog>
    </Box>
  ) : (''));
};

export default HeaderLeftAppbar;
