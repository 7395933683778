import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get layovers list
 * 
 * @param {*} params      Parameters to be passed in the request
 * @returns {Promise}     A promise resolving to the layovers list
 */
export const getRecentLayovers = async (params = {}) => {
  try {
    const response = await api.get('/layovers/list/recent', { params });
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message);
    }
    throw e;
  }
};


