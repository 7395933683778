/* this function is for format numbers from (700.00 | 7000.00 | 70000.00) to (700,00 | 7.000,00 | 70.000,00) */

const formatPrice = (number) => {
  if (!Number(number)){
    return number;
  }
  // return number.toLocaleString('de-DE');

  let formattedNumber = Number(number).toFixed(2);
  let [integerPart, decimalPart] = formattedNumber.split('.');
  integerPart = parseInt(integerPart).toLocaleString('de-DE');
  return `${integerPart},${decimalPart}`;
}
export default formatPrice;