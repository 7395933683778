import { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, styled, useTheme } from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import { Input } from '../../../components';
import { useLoader } from '../../../providers/loader';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { toast } from 'react-toastify';
import { getAirlines } from '../../../services/airline';
import AirlineContractRow from './airlineContractRow';
import { createAirlineContracts, getAirlineContracts } from '../../../services/hotel/hotelAirlineContracts';
import _ from 'lodash';
import { getHotelRoomTypes } from '../../../services/hotel/hotelRoomTypes';
import { HotelContext } from '../../../context/hotel';
import InfoIcon from '@mui/icons-material/Info';
import { arrayColumn } from '../../../utils';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
  boxShadow: 'unset',
  textAlign: 'center',
  '&:nth-child(1)': {
    textAlign: 'left',
  },
}));

const StyledLoadMoreButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textMedium,
  color: theme.palette.roseGold[100],
  marginTop: theme.spacing(1),
  textTransform: 'capitalize'
}));

const AirlineContracts = forwardRef((props, ref) => {
  const { hotel, permission } = props;
  const { setIsFormDirty } = useContext(HotelContext);
  const formRef = useRef(null);
  const tableRef = useRef(null);
  const theme = useTheme();
  const { startLoader, stopLoader } = useLoader();
  const [currency, setCurrency] = useState('');
  const [roomTypes, setRoomTypes] = useState(null);
  const [airlineApiTotalPages, setAirlineApiTotalPages] = useState(1);
  const [airlineApiPage, setAirlineApiPage] = useState(0);
  const [airlineApiPageSize, setAirlineApiPageSize] = useState(50);
  const [airlines, setAirlines] = useState([]);
  const [allAirlines, setAllAirlines] = useState([]);
  const [airlineContracts, setAirlineContracts] = useState([]);
  const [hotelChanged, setHotelChanged] = useState(false);
  const [previousLastRowId, setPreviousLastRowId] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setFocus,
    setValue
  } = useForm({
    shouldUnregister: true
  });

  // checking is form having any unsaved changes or not
  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0;
  useEffect(() => {
    setIsFormDirty(hasUnsavedChanges);
  }, [hasUnsavedChanges, setIsFormDirty]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    if (hotel) {
      setHotelChanged(true);
      setAirlineApiPage(0);
      setAirlines([]);
      setAllAirlines([]);
      setAirlineContracts([]);
    }
  }, [hotel])

  /**
   * Get hotel room types from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getHotelRoomTypesFromApi = useCallback(async (hotelId) => {
    const request = {
      'pagination[page]': '1',
      'pagination[pageSize]': '10000',
    };
    const response = await getHotelRoomTypes(hotelId, request);
    if (response?.data && response.data.length) {
      setRoomTypes(response.data);
    } else {
      setRoomTypes(null);
    }
  }, []);

  /**
   * Get list of all airlines from API 
   */
  const getAirlinesFromApi = useCallback(async (page, pageSize) => {
    const request = {
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
      'sort': 'name'
    };
    const response = await getAirlines({params: request});
    if (response?.data && response.data.length) {
      setAirlines(response.data);
    } else {
      setAirlines([]);
    }

    if (response?.meta?.pagination) {
      // setAirlineApiPage(response.meta.pagination.page)
      setAirlineApiPageSize(response.meta.pagination.pageSize)
      setAirlineApiTotalPages(response.meta.pagination.pageCount)
    }
  }, []);

  /**
   * Get airline contracts from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getAirlineContractsFromApi = useCallback(async (hotelId) => {
    const response = await getAirlineContracts(hotelId);
    if (response && response?.airlineContracts) {
      setAirlineContracts(response.airlineContracts);
    } else {
      setAirlineContracts([]);
    }
  }, []);

  /**
   * Update airline contracts from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateAirlineContractsViaApi = useCallback(async (hotelId, request) => {
    try {
      const response = await createAirlineContracts(hotelId, request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (hotel && hotel.id && data) {
      setIsFormDirty(false); // if user clicks on Save changes then Confirmation box will not shown

      let promises = [];

      if (data?.formAirlineContracts) {
        let tempAirlineContracts = {};
        if (airlineContracts && airlineContracts.length > 0) {
          for (const contract of airlineContracts) {
            if (contract?.airline?.id) {
              tempAirlineContracts[contract.airline.id] = _.cloneDeep(contract);
              delete tempAirlineContracts[contract.airline.id].airline;
            }
          }
        }
        for (const airlineId in data.formAirlineContracts) {
          const formContract = data.formAirlineContracts[airlineId];
          if (formContract?.roomTypes) {
            let tempFormRoomTypes = [];
            for (const roomTypeId in formContract.roomTypes) {
              tempFormRoomTypes.push({
                roomTypeId,
                ...formContract.roomTypes[roomTypeId],
              })
            }
            formContract.roomTypes = tempFormRoomTypes;
          }
          let tempFormField = {};
          if (tempAirlineContracts?.[airlineId]) {
            if (Number(tempAirlineContracts?.[airlineId]?.is_bookings_open) === Number(formContract?.is_bookings_open) &&
            Number(tempAirlineContracts?.[airlineId]?.is_prenegotiated_rates) === Number(formContract?.is_prenegotiated_rates) &&
            (Number(tempAirlineContracts?.[airlineId]?.is_bookings_open) === 0 || 
            Number(tempAirlineContracts?.[airlineId]?.is_prenegotiated_rates) === 0)) {
              continue;
            } else {
              tempFormField = {...formContract};
            }
          } else if (Number(formContract?.is_bookings_open) === 1 && Number(formContract?.is_prenegotiated_rates) === 0) {
            continue;
          } else {
            tempFormField = {...formContract};
          }
          if (Object.keys(tempFormField).length > 0) {
            let request = {};
            if (!tempFormField.is_bookings_open || !tempFormField.is_prenegotiated_rates) {
              request = {
                airlineId,
                is_bookings_open: Number(tempFormField.is_bookings_open),
                is_prenegotiated_rates: Number(tempFormField.is_prenegotiated_rates)
              };
            } else {
              request = {
                airlineId,
                ...tempFormField,
                is_bookings_open: Number(tempFormField.is_bookings_open),
                is_prenegotiated_rates: Number(tempFormField.is_prenegotiated_rates)
              };
            }
            promises.push(updateAirlineContractsViaApi(hotel.id, request))
          }
        }
      }

      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          setIsFormDirty(false)
          toast.success(toastMessages.hotel.updateHotelAirlineContracts.success, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-transportation-success',
          })
        }).catch((error) => {
          toast.success(toastMessages.hotel.updateHotelAirlineContracts.error, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-transportation-error',
          })
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [airlineContracts, updateAirlineContractsViaApi, startLoader, stopLoader,setIsFormDirty]);

  // Call function to submit the form on click of form submit button
  useImperativeHandle(ref, () => ({
    saveChanges: () => {
      handleSubmit(onSubmit)()
    }
  }), [handleSubmit, onSubmit]);

  useEffect(() => {
    if (hotelChanged && hotel && hotel.id) {
      startLoader();      
      new Promise(async (resolve, reject) => {
        try {
          await getHotelRoomTypesFromApi(hotel.id);
          await getAirlinesFromApi(airlineApiPage + 1, airlineApiPageSize);
          await getAirlineContractsFromApi(hotel.id);
          resolve();
        } catch(e) {
          reject();
        } finally {
          setHotelChanged(false);
          stopLoader();
        }
      }).catch((error) => {
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelChanged, hotel, airlineApiPage, airlineApiPageSize, getHotelRoomTypesFromApi, getAirlinesFromApi, getAirlineContractsFromApi, startLoader, stopLoader]);

  useEffect(() => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencySymbol = getCurrencySymbol({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencySymbol?.native ? currencySymbol.native : hotel.currency.alphabetic_code;
    }
    setCurrency(hotelCurrency);
  }, [hotel?.currency?.alphabetic_code]);

  // Prepare values to reset the form when API calls completed
  useEffect(() => {
    if (airlines && airlines.length > 0) {
      let tempAirlineContracts = {};
      if (airlineContracts && airlineContracts.length > 0) {
        for (const contract of airlineContracts) {
          if (contract?.airline?.id) {
            tempAirlineContracts[contract.airline.id] = _.cloneDeep(contract);
            delete tempAirlineContracts[contract.airline.id].airline;
          }
        }
      }
      let tempAllAirlines = _.cloneDeep(allAirlines);
      const airlinesIdOnlyArr = arrayColumn(tempAllAirlines, 'id');
      for (const airline of airlines) {
        const existingAirline = airlinesIdOnlyArr.find(a => a === airline.id);
        if (!existingAirline) {
          tempAllAirlines.push(airline);
        }
      }
      if (Object.keys(tempAirlineContracts).length > 0) {
        for (const airlineId in tempAirlineContracts) {
          const existingAirline = tempAllAirlines.find(a => a.id === parseInt(airlineId));
          if (existingAirline) {
            const contract = tempAirlineContracts[airlineId];
            existingAirline.contract = contract;
            // Convert checkbox & radio values into binary value
            existingAirline.contract.is_bookings_open = contract?.is_bookings_open ? 1 : 0;
            existingAirline.contract.is_prenegotiated_rates = contract?.is_prenegotiated_rates ? 1 : 0;
          }
        }
      }
      setAllAirlines(tempAllAirlines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [airlines, airlineContracts]);

  return (roomTypes && allAirlines && (
    <Box sx={{pt: 3}}>
      <FormProvider {...{control, handleSubmit, formState: { errors }, setFocus, setValue}}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Controller
            name={'hotelId'}
            control={control}
            defaultValue={hotel.id}
            render={({ field }) => (
              <Input
                type='hidden'
                formControlProps={{sx: {m:0}}}
                sx={{border:0}}
                {...field}
                disabled={!permission?.edit}
              />
            )}
          />
          <Grid container>
            <Grid item xs={12} sx={{pt: 1, pb: 3}}>
              <HeadingInput title={`On this page you can enter any pre-negotiated rates or discounts you have with airlines`} />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{px:3}} component={Paper}>
                <TableContainer sx={{ maxHeight: '94vh' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell component={Paper} sx={{
                          minWidth:'180px',
                          padding: 0
                        }}>Airlines</StyledTableHeaderCell>
                        <StyledTableHeaderCell component={Paper} sx={{minWidth:'150px'}}>
                          Bookings
                          <Tooltip title='In case you do not want to receive any bookings from this airline, you can exclude it here.' arrow>
                            <IconButton>
                              <InfoIcon sx={{color: theme.palette.icon.light}} />
                            </IconButton>
                          </Tooltip>
                        </StyledTableHeaderCell>
                        <StyledTableHeaderCell component={Paper}>Pre-negotiated?</StyledTableHeaderCell>
                        {roomTypes?.map((room, index) => (
                          <StyledTableHeaderCell component={Paper} key={`ac-rm-header-${index}`}>{room?.name}</StyledTableHeaderCell>
                        ))}
                        <StyledTableHeaderCell component={Paper}>Breakfast</StyledTableHeaderCell>
                        <StyledTableHeaderCell component={Paper}>Lunch</StyledTableHeaderCell>
                        <StyledTableHeaderCell component={Paper}>Dinner</StyledTableHeaderCell>
                        <StyledTableHeaderCell component={Paper}>Snacks</StyledTableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allAirlines && allAirlines?.map((airline, index) => {
                        const newestObj = airline.id === previousLastRowId;
                        return (
                          <AirlineContractRow
                            ref={newestObj ? tableRef : null}
                            key={index}
                            control={control}
                            errors={errors}
                            row={airline}
                            currency={currency}
                            roomTypes={roomTypes}
                            permission={permission}
                          />
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>

            {(airlineApiTotalPages && airlineApiTotalPages > 1) ? (
              <Grid item xs={12}>
                <StyledLoadMoreButton
                  variant='text'
                  onClick={() => {
                    startLoader();
                    setPreviousLastRowId(allAirlines[allAirlines.length - 2]?.id);
                    setAirlineApiPage(prev => prev + 1);
                    Promise.allSettled([
                      getAirlinesFromApi(airlineApiPage === 0 ? airlineApiPage + 2 : airlineApiPage + 1, airlineApiPageSize)
                    ]).then(() => {
                      setTimeout(() => {
                        stopLoader();
                        tableRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                      }, 100);
                    });
                  }}
                >Load More Airlines...</StyledLoadMoreButton>
              </Grid>
            ) : (null)}
          </Grid>
        </form>
      </FormProvider>
    </Box>
  ));
});

export default memo(AirlineContracts);