import { Box, IconButton, InputAdornment, Typography, styled } from '@mui/material';
import { memo, useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '../../../../../components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../../helper/constant';

const StyledRoomBox = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  marginRight: theme.spacing(2),
}))

const HotelSelectionHotelRoom = (props) => {
  const { hotelId, room, validateRoomOccupancy, onIncreaseRoom, onDecreaseRoom } = props;
  const { control, formState: { errors }, setValue } = useFormContext();
  const [selectedRooms, setSelectedRooms] = useState(0);
  const [availableRooms, setAvailableRooms] = useState(0);

  const increaseRooms = useCallback(() => {
    if (availableRooms) {
      if (selectedRooms < availableRooms) {
        if (!validateRoomOccupancy(1, room.occupancy)) {
          toast.error(toastMessages.airline.addHotelToLayover.limitExceedError, {
            autoClose: maxToastTime,
            toastId: 'update-airline-layover-add-hotel-error',
          })
        } else {
          setSelectedRooms(oldSelectedRooms => {
            return (oldSelectedRooms + 1)
          });
          onIncreaseRoom(1, room.occupancy);
        }
      }
    }
  }, [availableRooms, selectedRooms, room.occupancy, validateRoomOccupancy, onIncreaseRoom]);

  const decreaseRooms = useCallback(() => {
    if (selectedRooms > 0) {
      setSelectedRooms(oldSelectedRooms => {
        return (oldSelectedRooms - 1)
      });
      onDecreaseRoom(1, room.occupancy);
    }
  }, [selectedRooms, room.occupancy, onDecreaseRoom]);

  useEffect(() => {
    if (room?.selected_rooms) {
      setSelectedRooms(Number(room.selected_rooms));
    }
    if (room?.available_rooms) {
      setAvailableRooms(Number(room.available_rooms));
    }
  }, [room?.selected_rooms, room?.available_rooms])

  useEffect(() => {
    setValue(`formHotelSelection[hotels][${hotelId}][rooms][${room.roomTypeId}][totalRooms]`, selectedRooms)
  }, [hotelId, room.roomTypeId, selectedRooms, setValue])

  return (
    <StyledRoomBox>
      <Typography sx={{textTransform: 'capitalize'}}>{room.roomTypeName}</Typography>
      <Controller
        name={`formHotelSelection[hotels][${hotelId}][rooms][${room.roomTypeId}][totalRooms]`}
        control={control}
        defaultValue={room.selected_rooms ? room.selected_rooms : 0}
        render={({ field }) => (
          <Input
            isError={!!errors?.formHotelSelection?.hotels?.[hotelId]?.rooms?.[room.roomTypeId]?.totalRooms}
            inputRef={field.ref}
            {...field}
            startAdornment={
              <InputAdornment position='start'>
                <IconButton onClick={decreaseRooms}>
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={increaseRooms}>
                  <AddIcon />
                </IconButton>
              </InputAdornment>
            }
            formControlProps={{
              sx: {
                marginBottom: 0,
                width: '140px',
                py:1
              }
            }}
            sx={{
              '& .MuiInputBase-input': {
                paddingLeft: 0,
                paddingRight: 0,
                textAlign: 'center'
              }
            }}
          />
        )}
        rules={{
          required: true,
          pattern: /^([1-9]\d*)$/
        }}
      />
    </StyledRoomBox>
  );
};

export default memo(HotelSelectionHotelRoom);