import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import User from './user';

const rootPersistConfig = {
  key: 'root',
  storage: storage
};

const userPersistConfig = {
  key: 'user',
  storage: storage
};

/**
 * Combine all reducers
 */
const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, User)
});

export default persistReducer(rootPersistConfig, rootReducer);