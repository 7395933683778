import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel room types
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getHotelRoomTypes = async(hotelId, request = {}) => {
  try {
    if (!request?.params) {
      request.params = {};
    }
    request.params['filters[hotel_id]'] = hotelId;
    const response = await api.get('/hotel-room-types', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
  }
};