import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get airports
 * 
 * @param {*} request     Request
 * @returns 
 */
export const getAirports = async(request = {}) => {
  try {
    const response = await api.get(`/airports`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get airport details
 * 
 * @param {*} airportId   Airport id
 * @param {*} request     Request
 * @returns 
 */
export const getAirportDetails = async(airportId, request = {}) => {
  try {
    const response = await api.get(`/airports/${airportId}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get today's available rooms
 * 
 * @param {*} airportId   Airport id
 * @param {*} request     Request
 * @returns 
 */
export const getTodayAvailableRooms = async(airportId, request = {}) => {
  try {
    const response = await api.get(`/airports/${airportId}/available-rooms`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};