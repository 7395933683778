import { memo, useCallback, useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormDialog, Input, Timepicker } from '../../../../components';
import { HeadingInput } from '../../components/headingInput';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { InputEndAdornment } from '../../components/endAdornment';
import { useLoader } from '../../../../providers/loader';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../../helper/constant';
import { sendMailToAllPassengers } from '../../../../services/layover/details';
import { uploadFiles } from '../../../../services/other/upload';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';

const StyledFileInput = styled(Input)(({ theme }) => ({
  border: 0,
  background: 'none',
  paddingBottom: theme.spacing(1),
  '& input, & input:disabled': {
    cursor: 'pointer',
    opacity: '0',
    zIndex: 1
  },
  '& .file-input-label': {
    ...theme.typography.fontMedium500,
    color: theme.palette.grey[800],
    position: 'absolute',
    left: theme.spacing(4)
  }
}));

const StyledFileInputLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium
}));

const StyledAttachFileIcon = styled(AttachFileIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  marginLeft: theme.spacing(0),
  rotate: '45deg',
  transform: 'scaleX(-1)'
}));

const StyledDeleteIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  cursor: 'pointer',
  color: theme.palette.error.main
}));

const MailToAllPassenger = (props) => {
  const { open, onAgree, onDisagree, layoverId, airlineId } = props;
  const { startLoader, stopLoader } = useLoader();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    setDialogOpen(open)
    if (open) { reset() }
  }, [open, reset]);

  const handleAttachmentOnchange = (e) => {
    const selectedAttachments = Array.from(e.target.files);
    setAttachments(prevAttachments => {
      const totalAttachmentsCount = prevAttachments.length + selectedAttachments.length;
      if (totalAttachmentsCount > 5) {
        toast.warning(toastMessages.attachmentsWarning, {
          autoClose: maxToastTime,
          toastId: 'file-upload-limit-warning',
        });
      }
      const newAttachments = prevAttachments.length < 5 ? selectedAttachments.slice(0, 5 - prevAttachments.length) : [];
      return [...prevAttachments, ...newAttachments];
    });
  };

  // set current time to timestamp
  const handleCurrentTime = () => {
    setValue('formSendMailToAll[schedule]', dayjs(new Date()));
  }

  // remove attachment
  const handleRemoveAttachment = async (indexProp) => {
    const filteredArray = attachments?.filter((attachment, index) => index !== indexProp);
    setAttachments(filteredArray); 
  };

  /**
   * Upload attachments from API
   * 
   * @param {*} request   Request
  */
  const uploadEmailAttachmentsViaApi = useCallback(async (request) => {
    try {
      const response = await uploadFiles(request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Send Mail To All Passengers via API
  */
  const sendMailToAllPassengersViaApi = useCallback(async (request) => {
    try {
      const response = await sendMailToAllPassengers(layoverId, request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  const onSubmit = useCallback(async (data) => {
    if (data) {
      let formData = {...data?.formSendMailToAll};
      const request = { 
        title: formData?.title, 
        message: formData?.message, 
        schedule: formData?.schedule, 
        source: 'airline',
        sourceId: airlineId
      };

      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await sendMailToAllPassengersViaApi(request);

          // Upload email attachments
          if (response && attachments && attachments.length > 0) {
            // Prepare the form-data for attachments upload API
            const formData = new FormData();
            formData.append('ref', 'api::email-scheduler.email-scheduler');
            formData.append('refId', response?.id);
            formData.append('field', 'attachments');
            for (const singleAttachment of attachments) {
              formData.append('files', singleAttachment);
            }
            try {
              await uploadEmailAttachmentsViaApi(formData);
            } catch(e) {
              reject(e)
            }
          }
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        setDialogOpen(false);
        if (onAgree) {
          onAgree();
        }
        toast.success(toastMessages.airline.sendEmailToAllPassengers.success, {
          autoClose: maxToastTime,
          toastId: 'update-airline-send-email-to-all-passengers-success',
        });
      }).catch((e) => {
        toast.error(toastMessages.airline.sendEmailToAllPassengers.error, {
          autoClose: maxToastTime,
          toastId: 'update-airline-send-email-to-all-passengers-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, onAgree, attachments]);

  const onDialogAgree = () => {
    handleSubmit(onSubmit)()
  };

  const onDialogDisagree = () => {
    setDialogOpen(false);
    if (onDisagree) { onDisagree() }
  };

  return (
    <FormDialog
      title='Send mail to all passengers'
      open={dialogOpen}
      onAgree={onDialogAgree}
      onDisagree={onDialogDisagree}
      btnText='Send message'
      fullWidth={true}
      maxWidth={'xs'}
      cancelBtnInHeader
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid 
          container
          direction='column'
          alignItems='flex-start'
          xs={12}
          spacing={2}
        >
          <Grid item xs={12} md={6} sx={{width: '100%'}}>
            <Box>
              <HeadingInput title='Message Title' sx={{pt: 3, pb: 2}} />
              <Controller
                name={`formSendMailToAll[title]`}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Input
                    formControlProps={{sx: {marginBottom: 0}}}
                    isError={!!errors?.formSendMailToAll?.title}
                    errorMessage={errors?.formSendMailToAll?.title && 'Please enter valid title'}
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                rules={{required: true}}
              />
            </Box>
            
            <Box>
              <HeadingInput title='Your Message' sx={{pt: 3, pb: 2}} />
              <Controller
                name={`formSendMailToAll[message]`}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <Input
                    formControlProps={{sx: {marginBottom: 0}}}
                    isError={!!errors?.formSendMailToAll?.title}
                    errorMessage={errors?.formSendMailToAll?.title && 'Please enter valid message'}
                    multiline
                    rows={5}
                    inputRef={field.ref}
                    {...field}
                  />
                )}
              />
              <Controller
                name={`formSendMailToAll[attachments]`}
                control={control}
                render={({ field }) => (
                  <StyledFileInput
                    formControlProps={{
                      sx: {
                        marginBottom: 0
                      }
                    }}
                    inputProps={{
                      type: 'file',
                      multiple: true,
                    }}
                    inputRef={field.ref}
                    {...field}
                    value={''}
                    onChange={(e) => handleAttachmentOnchange(e)}
                    startAdornment={
                      <StyledAttachFileIcon />
                    }
                    endAdornment={
                      <StyledFileInputLabel className='file-input-label'>{'Attachments'}</StyledFileInputLabel>
                    }
                  />
                )}
              />
            </Box>

            {attachments && attachments?.map((file, index) => (
              <Grid item xs={12} key={index} sx={{display: 'flex', alignItems: 'center'}}>
                <Grid item xs={10}>
                  <Typography variant='body2' sx={{mb: 1.5, mr: 5}}>{index + 1}. {file?.name}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <StyledDeleteIcon aria-label='Remove' onClick={() => handleRemoveAttachment(index)}><CloseIcon /></StyledDeleteIcon>
                </Grid>
              </Grid>
            ))}

            <Box>
              <HeadingInput title='Schedule time to be sent' sx={{pt: 3, pb: 2}} />
              <Box sx={{display: 'flex', gap: 2}}>
                <Controller
                  name={`formSendMailToAll[schedule]`}
                  control={control}
                  render={({ field }) => (
                    <Timepicker
                      inputProps={{
                        formControlProps: {
                          sx: {
                            marginBottom: 0
                          }
                        }
                      }}
                      format={'hh:mm a'}
                      isError={!!errors?.formSendMailToAll?.schedule}
                      errorMessage={errors?.formSendMailToAll?.schedule && 'Please select schedule time'}
                      inputRef={field.ref}
                      {...field}
                      startAdornment={
                        <InputEndAdornment position='end' sx={{ textTransform: 'capitalize', pl: 2 }}>
                          Time
                        </InputEndAdornment>
                      }
                    />
                  )}
                  rules={{
                    required: true
                  }}
                />
                <Button
                  variant='contained'
                  onClick={handleCurrentTime}
                  sx={{
                    '&.MuiButton-contained': {
                      p: 0,
                      ...theme.typography.muiTypography600
                    }
                  }}
                >
                  Now
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </FormDialog>
  );
};

export default memo(MailToAllPassenger);
