import { memo, useEffect, useState } from 'react';
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';
import { ActionButton } from '../../../../../components';
import HotelSelectionHotelRoom from './hotelRoom';
import HotelSelectionHotelImg from './hotelImg';
import HotelSelectionHotelTransportation from './hotelTransportation';
import HotelSelectionHotelMeal from './hotelMeal';

const StyledMainLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800],
  textTransform: 'uppercase'
}))

const StyledHotelRow = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  '&:last-child': {
    borderBottom: 0
  }
}))

const HotelSelectionHotel = (props) => {
  const { hotel, validateRoomOccupancy, onIncreaseRoom, onDecreaseRoom, onRemoveHotelFromLayover } = props;
  const theme = useTheme();
  const [totalOccupants, setTotalOccupants] = useState(0);

  /**
   * Get already selected room details
   * 
   * @param {*} rooms       Rooms Array
   * @param {*} roomTypeId  Room type Id
   */
  const getSelectedRoom = (rooms, roomTypeId) => {
    let tempSelectedRoom = {selected_rooms: 0};
    if (roomTypeId && rooms && rooms.length > 0) {
      for (const room of rooms) {
        if (Number(room.roomTypeId) === Number(roomTypeId) && room?.totalRooms) {
          tempSelectedRoom.selected_rooms = Number(room.totalRooms);
          break;
        }
      }
    }
    return tempSelectedRoom;
  };

  useEffect(() => {
    if (hotel?.rooms && hotel.rooms.length > 0 && hotel?.hotel?.roomTypes && hotel.hotel.roomTypes.length > 0) {
      let tempHotelRoomTypes = {};
      for (const room of hotel.hotel.roomTypes) {
        tempHotelRoomTypes[room.roomTypeId] = room;
      }
      let tempTotalOccupants = 0;
      for (const room of hotel.rooms) {
        if (tempHotelRoomTypes?.[room.roomTypeId] && tempHotelRoomTypes?.[room.roomTypeId]?.occupancy && Number(room.totalRooms) > 0) {
          tempTotalOccupants += (Number(tempHotelRoomTypes[room.roomTypeId].occupancy) * Number(room.totalRooms));
        }
      }
      setTotalOccupants(tempTotalOccupants)
    }
  }, [hotel?.rooms, hotel?.hotel?.roomTypes]) 

  return (hotel ? (
    <StyledHotelRow item xs={12}>
      <Grid container columnSpacing={3}>
        <Grid item xs={3}>
          <HotelSelectionHotelImg
            hotelImgUrl={hotel?.hotel?.images?.[0]?.formats?.small?.url}
            hotelName={hotel.hotel.name}
            cityName={hotel.hotel?.city?.city_name}
            countryName={hotel.hotel.city?.country_id?.name}
          />
        </Grid>
        <Grid item xs={9}>
          <Box sx={{display:'flex', pb:1}}>
            <StyledMainLabel sx={{flexGrow:1}}>Rooms</StyledMainLabel>
            <ActionButton
              sx={{
                ...theme.typography.fontMedium600,
                p:0
              }}
              onClick={() => onRemoveHotelFromLayover(hotel.hotel.id)}
            >
              Remove Hotel
            </ActionButton>
          </Box>
          {hotel.hotel?.roomTypes && hotel.hotel.roomTypes.length > 0 ? (
            <Box>
              {hotel.hotel.roomTypes.map((room, roomIndex) => (
                Number(room.available_rooms) > 0 ? (
                  <HotelSelectionHotelRoom
                    key={`hs-h-r-${roomIndex}`}
                    hotelId={hotel.hotel.id}
                    room={{...room, ...getSelectedRoom(hotel?.rooms, room.roomTypeId)}}
                    validateRoomOccupancy={validateRoomOccupancy}
                    onIncreaseRoom={onIncreaseRoom}
                    onDecreaseRoom={onDecreaseRoom}
                  />
                ) : (null)
              ))}
            </Box>
          ) : (null)}
          {/* Meals Fields Description -
              - hotel_is_breakfast: means if breakfast is available at hotel?
              - is_breakfast: means if breakfast is selected previously by user while creating the layover?
              - breakfast: means if breakfast is selected previously by user while creating the layover, what's the quantity of breakfast?
           */}
          <HotelSelectionHotelMeal
            hotelId={hotel.hotel.id}
            occupants={totalOccupants}
            currency={hotel.hotel?.currency}
            hotel_is_breakfast={hotel.hotel?.is_breakfast}
            hotel_breakfast_price={hotel.hotel?.breakfast_price}
            is_breakfast={hotel?.is_breakfast}
            breakfast={hotel?.breakfast}
            weekday_breakfast_from={hotel.hotel?.weekday_breakfast_from}
            weekday_breakfast_to={hotel.hotel?.weekday_breakfast_to}
            weekend_breakfast_from={hotel.hotel?.weekend_breakfast_from}
            weekend_breakfast_to={hotel.hotel?.weekend_breakfast_to}
            hotel_is_early_bird_breakfast={hotel.hotel?.is_early_bird_breakfast}
            hotel_early_bird_breakfast_price={hotel.hotel?.early_bird_breakfast_price}
            is_early_bird_breakfast={hotel?.is_early_bird_breakfast}
            early_bird_breakfast={hotel?.early_bird_breakfast}
            weekday_early_bird_breakfast_from={hotel.hotel?.weekday_early_bird_breakfast_from}
            weekday_early_bird_breakfast_to={hotel.hotel?.weekday_early_bird_breakfast_to}
            weekend_early_bird_breakfast_from={hotel.hotel?.weekend_early_bird_breakfast_from}
            weekend_early_bird_breakfast_to={hotel.hotel?.weekend_early_bird_breakfast_to}
            hotel_is_lunch={hotel.hotel?.is_lunch}
            hotel_lunch_price={hotel.hotel?.lunch_price}
            is_lunch={hotel?.is_lunch}
            lunch={hotel?.lunch}
            croowy_lunch_price={hotel.hotel?.croowy_lunch_price}
            is_croowy_lunch={hotel?.is_croowy_lunch}
            croowy_lunch={hotel?.croowy_lunch}
            lunch_list={hotel.hotel?.lunch_list}
            weekday_lunch_from={hotel.hotel?.weekday_lunch_from}
            weekday_lunch_to={hotel.hotel?.weekday_lunch_to}
            weekday_lunch_max_people={hotel.hotel?.weekday_lunch_max_people}
            weekend_lunch_from={hotel.hotel?.weekend_lunch_from}
            weekend_lunch_to={hotel.hotel?.weekend_lunch_to}
            weekend_lunch_max_people={hotel.hotel?.weekend_lunch_max_people}
            hotel_is_dinner={hotel.hotel?.is_dinner}
            hotel_dinner_price={hotel.hotel?.dinner_price}
            is_dinner={hotel?.is_dinner}
            dinner={hotel?.dinner}
            croowy_dinner_price={hotel.hotel?.croowy_dinner_price}
            is_croowy_dinner={hotel?.is_croowy_dinner}
            croowy_dinner={hotel?.croowy_dinner}
            dinner_list={hotel.hotel?.dinner_list}
            weekday_dinner_from={hotel.hotel?.weekday_dinner_from}
            weekday_dinner_to={hotel.hotel?.weekday_dinner_to}
            weekday_dinner_max_people={hotel.hotel?.weekday_dinner_max_people}
            weekend_dinner_from={hotel.hotel?.weekend_dinner_from}
            weekend_dinner_to={hotel.hotel?.weekend_dinner_to}
            weekend_dinner_max_people={hotel.hotel?.weekend_dinner_max_people}
            hotel_is_snacks={hotel.hotel?.is_snacks}
            hotel_snacks_price={hotel.hotel?.snacks_price}
            is_snacks={hotel?.is_snacks}
            snacks={hotel?.snacks}
            snacks_list={hotel.hotel?.snacks_list}
          />
          <HotelSelectionHotelTransportation
            hotelId={hotel.hotel.id}
            hotel_transportation={hotel?.hotel_transportation}
            transportation_schedules={hotel.hotel?.transportation_schedules}
            is_shuttle_service={(!hotel.hotel.hasOwnProperty('is_shuttle_service') || !hotel.hotel.is_shuttle_service) ? false : true}
            is_by_foot={hotel.hotel?.is_by_foot}
          />
        </Grid>
      </Grid>
    </StyledHotelRow>
  ): (null));
};

export default memo(HotelSelectionHotel);