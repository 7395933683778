import { memo, useEffect, useState } from 'react';
import { getUniqueId } from '../../utils';
import { FormControl, MenuItem, TextField, Typography, styled } from '@mui/material';

const FormControlContainer = styled(FormControl, {
  shouldForwardProp: (prop) => ['marginBottom'].indexOf(prop) === -1
})(({ theme, marginBottom }) => ({
  display: 'flex',
  marginBottom: marginBottom ? marginBottom : '20px',
  '& .MuiFormLabel-root': {
    color: theme.palette.text.secondary,
    ...theme.typography.inputLabel,
    '& + .MuiInputBase-root': {
      marginTop: '18px'
    }
  },
  '& .MuiFormLabel-root.Mui-focused': {
    color: theme.palette.text.secondary
  },
}));

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => ['marginRightIcon', 'paddingSelect', 'textAlignSelect', 'isError'].indexOf(prop) === -1
})(({
  marginRightIcon,
  paddingSelect,
  textAlignSelect,
  theme,
  isError
}) => ({
  backgroundColor: theme.mode === 'light'? theme.palette.grey['A200'] : theme.palette.grey['A900'],
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${isError ? theme.palette.error.main : theme.palette.grey['A900']}`,
  'label + &': {
    marginTop: theme.spacing(3)
  },
  '& .MuiInput-root, & .MuiInputBase-input': {
    ...theme.typography.inputText,
    '&:hover:not(.Mui-disabled, .Mui-error)::before, &:hover:not(.Mui-disabled, .Mui-error)::after, &:before, &:after': {
      border: 'unset'
    }, 
    '& .MuiSelect-select': {
      textAlign: textAlignSelect ? textAlignSelect : 'left',
      padding: paddingSelect ? paddingSelect : '10px 32px 10px 15px !important',
      color: theme.palette.menu.dark,
    },
    '& .MuiSelect-icon': {
      marginRight: marginRightIcon ? marginRightIcon : theme.spacing(1),
      color: theme.palette.menu.dark,
    }
  },
  '& .MuiInput-root:focus, & .MuiInputBase-input:focus': {
    backgroundColor: 'transparent'
  },
  '& .MuiInput-underline.Mui-disabled:before': {
    borderBottom: 0
  }
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingTop: '8px',
  paddingBottom: '8px'
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium500,
  color: theme.palette.grey[600]
}));

const Select = (props) => {
  const { options, selectProps, menuItemProps, formControlProps, placeholder, isError, errorMessage } = props;
  const uniqueId = getUniqueId();
  const [validationErrorMessage, setValidationErrorMessage] = useState('');
  const [inputPlaceholder, setInputPlaceholder] = useState('');

  useEffect(() => {
    if (isError) {
      setValidationErrorMessage(errorMessage ? errorMessage : '');
    }
  }, [isError, errorMessage]);

  useEffect(() => {
    setInputPlaceholder(placeholder ? placeholder : '');
  }, [placeholder]);

  return (options ? (
    <FormControlContainer variant='standard' {...formControlProps}>
      <StyledTextField
        id={`select-${uniqueId}`}
        select
        InputLabelProps={{
          shrink: true
        }}
        variant='standard'
        autoComplete='off'
        SelectProps={{
          displayEmpty: true,
          native: false,
          ...selectProps
        }}
        isError={isError}
        {...props}
      >
        {inputPlaceholder ? (
          <StyledMenuItem disabled value=''>
            <StyledPlaceholder>{inputPlaceholder}</StyledPlaceholder>
          </StyledMenuItem>
        ) : (null)}
        {options?.map((option, index) => (
          <StyledMenuItem
            key={`${option?.name}_${index}`}
            value={option?.key}
            {...menuItemProps}
          >
            {option?.name}
          </StyledMenuItem>
        ))}
      </StyledTextField>
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControlContainer>
  ) : (null));
};

export default memo(Select);