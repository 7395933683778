import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Upload file(s)
 * 
 * @param {*} request   Request
 * @returns 
 */
export const uploadFiles = async(request) => {
  try {
    const response = await api.post('/upload', request, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};