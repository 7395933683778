import { memo, useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Divider, Grid, IconButton, Rating, Typography, styled, useTheme } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { symbol as getCurrencySymbol } from 'better-currency-codes';
import { prepareImageUrlByPath } from '../../../../utils';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccessibleIcon from '@mui/icons-material/Accessible';
import PetsIcon from '@mui/icons-material/Pets';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import HotelDefaultImg from '../../../../assets/images/hotel_default.png';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../../../components';
import HotelCardCollapse from './hotelCardCollapse';
import formatPrice from '../../../../utils/formatPrice';
import { toast } from 'react-toastify';
import { maxToastTime } from '../../../../helper/constant';
import moment from 'moment';

const weekdayMapping = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday'
};

const StyledCard = styled(Card)(({ theme, isUnavailableHotel }) => ({
  backgroundImage: 'unset',
  border: `1px solid ${theme.palette.dashboardWidgetHeadline}33`,
  borderRadius: theme.borderRadius[5],
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  opacity: isUnavailableHotel ? 0.4 : 1
}))

const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
  flexGrow: 1,
  '&, & .MuiCardHeader-content': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'initial',
    '& .MuiCardHeader-title': {
      flexGrow: 1
    }
  }
}))

const StyledTabItemLabel = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textxSmall,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const StyledTabItemValue = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium600,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const StyledCardMedia = styled(CardMedia, {
  shouldForwardProp: (prop) => ['img'].indexOf(prop) === -1
})(({ theme, img }) => ({
  backgroundImage: `url(${img})`,
  backgroundPosition: 'center',
  height: '189px',
  borderRadius: theme.borderRadius[3],
  marginLeft: theme.spacing(2),
  marginRight: theme.spacing(2),
  position: 'relative',
  textAlign: 'center',
  marginTop: theme.spacing(2)
}))

const StyledHotelName = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  ...theme.typography.textxSmall,
  lineHeight: theme.lineHeight[1.37]
}))

const StyledHotelLocation = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  ...theme.typography.textMedium,
  lineHeight: theme.lineHeight[1.2]
}))

const StyledSupportLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.bg[300],
  display: 'inline-flex',
  backgroundColor: `${theme.palette.background.default}88`,
  borderRadius: theme.borderRadius[5],
  padding: theme.spacing(1),
  borderWidth: '1px',
  borderStyle: 'solid',
  transform: 'translate(0, -50%)'
}))

const StyledCardMediaBottom = styled(Box)(({ theme }) => ({
  width: '100%',
  position: 'absolute',
  bottom: 0,
  padding: theme.spacing(1),
  display: 'flex',
  '& .MuiSvgIcon-root': {
    width: '20px'
  }
}))

const StyledFacilityIcoBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.black}88`,
  borderRadius: theme.borderRadius[3],
  width: '30px',
  height: '30px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}))

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textMedium,
  ...theme.typography.fontMedium600,
  color: theme.palette.alert.main,
  textTransform: 'capitalize',
  width: '100%',
  padding: theme.spacing(2)
}));

const StyledRoomAvailabilityBox = styled(Box, {
  shouldForwardProp: (prop) => ['isError'].indexOf(prop) === -1
})(({ theme, isError }) => ({
  border: `1px solid ${isError ? theme.palette.error.main : theme.palette.primary.light20}`,
  borderRadius: theme.borderRadius[5],
  padding: theme.spacing(1.5)
}));

const StyledRoomName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800],
  textTransform: 'capitalize'
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  '& .MuiCollapse-wrapper, & .MuiCollapse-wrapper .MuiCollapse-wrapperInner': {
    height: '100%'
  },
  '& .MuiCollapse-wrapper .MuiCollapse-wrapperInner': {
    overflow: 'auto'
  }
}));

const StyledTabItemValueText = styled(Typography, {
  shouldForwardProp: (prop) => ['error'].indexOf(prop) === -1
})(({ theme, error }) => ({
  ...theme.typography.textxxSmall,
  color: error ? theme.palette.roseGold[100] : theme.palette.text.primary
}));

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip arrow classes={{ popper: className }} {...props} enterTouchDelay={0}/>
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.grey[800],
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.grey[800],
  },
}));

const HotelCard = (props) => {
  const { hotel, selectedRooms, onRoomsUpdate, onAddToLayover, checkoutRemoveHotelId, isUnavailableHotel, isDisabledAddToLayoverButton, isRoomsCountInputHidden } = props;
  const theme = useTheme();
  const formRef = useRef(null);
  const [totalHotelRoomOccupants, setTotalHotelRoomOccupants] = useState(0);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const today = moment().format('ddd').toUpperCase();
  const todaySchedulesForATOH = hotel?.transportationSchedules?.ATOH?.filter(terminal => terminal?.weekday?.includes(today));
  const todaySchedulesForHTOA = hotel?.transportationSchedules?.HTOA?.filter(terminal => terminal?.weekday?.includes(today));
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    reset
  } = useForm({
    shouldUnregister: true,
  });

  /**
   * Get first hotel image from the array of images
   * 
   * @param {*} hotel   Hotel Object
   */
  const getHotelImage = (hotel) => {
    let url = HotelDefaultImg;
    if (hotel?.images?.[0]?.formats?.small?.url) {
      url = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(hotel.images[0].formats.small.url) : hotel.images[0].formats.small.url
    }
    return url;
  };

  /**
   * Get hotel currency symbol
   * 
   * @param {*} hotel   Hotel Object
   */
  const getHotelCurrencySymbol = (hotel) => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencySymbol = getCurrencySymbol({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencySymbol?.native ? currencySymbol.native : hotel.currency.alphabetic_code;
    }
    return hotelCurrency;
  };

  // /**
  //  * Get lowest rate of the room types
  //  * 
  //  * @param {*} hotel   Hotel Object
  //  */
  // const getLowestRate = (hotel) => {
  //   let lowestRate = 0;
  //   if (hotel?.roomTypes && hotel?.roomTypes.length) {
  //     const priceArr = arrayColumn(hotel.roomTypes, 'price')
  //     lowestRate = Math.min(...priceArr);
  //   }
  //   return lowestRate;
  // };

  /**
   * Update the no.of pax state and call function to update it in parent
   * 
   * @param {*} hotel   Hotel Object
   */
  const handlePaxChange = useCallback(() => {
    const values = getValues();
    if (values && values?.formAddHotelToLayover?.rooms && values?.formAddHotelToLayover?.roomOccupancies) {
      let newTotalRoomOccupancies = 0;
      for (const roomId in values.formAddHotelToLayover.rooms) {
        let noOfRooms = values.formAddHotelToLayover.rooms[roomId];
        // Check the room occupancy also
        if (/^[0-9]+$/.test(noOfRooms) && values.formAddHotelToLayover.roomOccupancies?.[roomId] && /^[0-9]+$/.test(values.formAddHotelToLayover.roomOccupancies[roomId])) {
          // Multiply the room with occupancy to get total room occupancies
          newTotalRoomOccupancies += (Number(noOfRooms) * Number(values.formAddHotelToLayover.roomOccupancies[roomId]));
        }
      }
      if (onRoomsUpdate) {
        onRoomsUpdate(totalHotelRoomOccupants, newTotalRoomOccupancies);
      }
      setTotalHotelRoomOccupants(newTotalRoomOccupancies);
    }
  }, [getValues, onRoomsUpdate, totalHotelRoomOccupants]);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (data && hotel && data?.formAddHotelToLayover && data.formAddHotelToLayover?.rooms && onAddToLayover) {
      let tempTotalSelectedRooms = 0
      for (const selectedRooms of data.formAddHotelToLayover.rooms) {
        if (selectedRooms && Number(selectedRooms > 0)) {
          tempTotalSelectedRooms += Number(selectedRooms)
        }
      }
      if (tempTotalSelectedRooms > 0) {
        onAddToLayover(hotel, data.formAddHotelToLayover)
      } else {
        toast.error('Please select no. of rooms', {
          autoClose: maxToastTime,
          toastId: 'update-airline-layover-add-hotel-no-room-error',
        })
      }
    }
  }, [hotel, onAddToLayover]);
  // Set previously selected room values
  useEffect(() => {
    if(checkoutRemoveHotelId === hotel.hotelId) {
      reset()
      setTotalHotelRoomOccupants(0)
    }
    if (selectedRooms && Object.keys(selectedRooms).length > 0) {
      let tempTotalHotelRoomOccupants = 0;
      for (const roomId in selectedRooms) {
        if (selectedRooms[roomId]?.selectedRooms) {
          setValue(`formAddHotelToLayover[rooms][${roomId}]`, selectedRooms[roomId].selectedRooms)
          tempTotalHotelRoomOccupants += (Number(selectedRooms[roomId].selectedRooms) * Number(selectedRooms[roomId].occupancy));
        }
      }
      setTotalHotelRoomOccupants(tempTotalHotelRoomOccupants);
    } else {
      if (hotel) {
        for (let i = 0; i < hotel.roomTypes.length; i++) {
          const room = hotel.roomTypes[i];
          setValue(`formAddHotelToLayover[rooms][${room.roomTypeId}]`, '');
        }
      }
    }
  }, [selectedRooms, setValue, hotel, checkoutRemoveHotelId, reset])

  return (
    <form key={`form-hotel-${hotel.hotelId}`} onSubmit={handleSubmit(onSubmit)} ref={formRef} style={{height:'100%'}}>
      <StyledCard isUnavailableHotel={isUnavailableHotel}>
        <Box sx={{position: 'relative', display: 'flex', flexDirection: 'column', flexGrow: 1}}>
          <StyledCardHeader
            title={hotel.name}
            titleTypographyProps={{
              sx: {
                ...theme.typography.textLarge,
                lineHeight: theme.lineHeight[1.37]
              }
            }}
            subheader={
              <Box sx={{
                display: 'flex'
              }}>
                <StyledHotelName>
                  {hotel.city.city_name}{hotel.city?.country_id?.name ? `, ${hotel.city.country_id.name}` : (null)}
                </StyledHotelName>
                {hotel.rating && hotel.rating >= 1 ? (
                  <Box sx={{ display: 'flex' }}>
                    <Rating readOnly size='small' defaultValue={1} max={1} sx={{ mr: '2px' }} />
                    <StyledHotelLocation>
                      {hotel.rating && hotel.rating <= 5 ? `${hotel.rating % 1 === 0 ? hotel.rating : hotel.rating.toFixed(1)}.0` : (null)}
                    </StyledHotelLocation>
                  </Box>
                ) : (null)}
              </Box>
            }
          />
          <StyledCardMedia img={getHotelImage(hotel)}>
            {hotel?.onsite_layover_support ? (
              <StyledSupportLine>
                <SupportAgentIcon sx={{mr:1}} />
                <Typography sx={{
                  ...theme.typography.btnText,
                  lineHeight: theme.lineHeight[1.5]
                }}>
                  Onsite Layover Support
                </Typography>
              </StyledSupportLine>
            ) : (null)}
            <StyledCardMediaBottom>
              <Box sx={{flexGrow: 1, display: 'flex', textAlign: 'left'}}>
                {hotel?.no_show?.is_room_for_disabled ? (
                  <StyledFacilityIcoBox>
                    <AccessibleIcon />
                  </StyledFacilityIcoBox>
                ) : (null)}
                {hotel?.no_show?.is_pets ? (
                  <StyledFacilityIcoBox>
                    <PetsIcon />
                  </StyledFacilityIcoBox>
                ) : (null)}
                {(hotel?.meals?.is_breakfast || hotel?.meals?.is_lunch || hotel?.meals?.is_dinner) ? (
                  <StyledFacilityIcoBox sx={{cursor: 'pointer'}}>
                    <StyledTooltip title={
                      <Grid container sx={{px:1, pb:3}}>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price)}>
                                Early Bird Breakfast
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price)}>
                                {hotel?.meals?.early_bird_breakfast_price && hotel?.meals?.is_early_bird_breakfast ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel.meals.early_bird_breakfast_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                              <StyledTabItemValueText>
                                {hotel?.meals?.early_bird_breakfast_from && hotel?.meals?.early_bird_breakfast_to && hotel?.meals?.is_early_bird_breakfast && hotel?.meals?.early_bird_breakfast_price ? `${moment(hotel?.meals?.early_bird_breakfast_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.early_bird_breakfast_to, 'HH:mm').format('HH:mm')}` : null}
                              </StyledTabItemValueText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!hotel?.meals?.is_breakfast}>
                                Breakfast
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!hotel?.meals?.is_breakfast}>
                                {hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price === 0 
                                  ? 'Available' 
                                  : hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price > 0 ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel?.meals?.breakfast_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                              <StyledTabItemValueText>
                                {hotel?.meals?.breakfast_from && hotel?.meals?.breakfast_to && hotel?.meals?.is_breakfast && hotel?.meals?.breakfast_price ? `${moment(hotel?.meals?.breakfast_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.breakfast_to, 'HH:mm').format('HH:mm')}` : null}
                              </StyledTabItemValueText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.is_lunch && hotel?.meals?.lunch_price)}>
                                Lunch
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.is_lunch && hotel?.meals?.lunch_price)}>
                                {hotel?.meals?.lunch_price && hotel?.meals?.is_lunch ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel.meals.lunch_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                              <StyledTabItemValueText>
                                {hotel?.meals?.lunch_from && hotel?.meals?.lunch_to && hotel?.meals?.is_lunch && hotel?.meals?.lunch_price  ? `${moment(hotel?.meals?.lunch_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.lunch_to, 'HH:mm').format('HH:mm')}` : null}
                              </StyledTabItemValueText>
                              <StyledTabItemValueText>
                                {hotel?.meals?.lunch_max_people && hotel?.meals?.is_lunch && hotel?.meals?.lunch_price ? `(max. ${hotel?.meals?.lunch_max_people} ppl.)`  : null}
                              </StyledTabItemValueText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.is_dinner && hotel?.meals?.dinner_price)}>
                                Dinner
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.is_dinner && hotel?.meals?.dinner_price)}>
                                {hotel?.meals?.dinner_price && hotel?.meals?.is_dinner ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel.meals.dinner_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                              <StyledTabItemValueText>
                                {hotel?.meals?.dinner_from && hotel?.meals?.dinner_to && hotel?.meals?.is_dinner && hotel?.meals?.dinner_price ? `${moment(hotel.meals.dinner_from, 'HH:mm').format('HH:mm')} - ${moment(hotel.meals.dinner_to, 'HH:mm').format('HH:mm')}`  : null}
                              </StyledTabItemValueText>
                              <StyledTabItemValueText>
                                {hotel?.meals?.is_dinner && hotel?.meals?.dinner_price && hotel?.meals?.dinner_max_people ? `(max. ${hotel?.meals?.dinner_max_people} ppl.)`  : null}
                              </StyledTabItemValueText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price)}>
                                Late Dinner
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price)}>
                                {hotel?.meals?.late_dinner_price && hotel?.meals?.is_late_dinner ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel.meals.late_dinner_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                              <StyledTabItemValueText>
                                {hotel?.meals?.late_dinner_from && hotel?.meals?.late_dinner_to && hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price ? `${moment(hotel?.meals?.late_dinner_from, 'HH:mm').format('HH:mm')} - ${moment(hotel?.meals?.late_dinner_to, 'HH:mm').format('HH:mm')}` : null}
                              </StyledTabItemValueText>
                              <StyledTabItemValueText>
                                {hotel?.meals?.is_late_dinner && hotel?.meals?.late_dinner_price && hotel?.meals?.late_dinner_min_people && hotel?.meals?.late_dinner_max_people ? `(min. ${hotel?.meals?.late_dinner_min_people} & max ${hotel?.meals?.late_dinner_max_people} ppl.)`  : null}
                              </StyledTabItemValueText>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.is_snacks && hotel?.meals?.snacks_price)}>
                                Snacks
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.is_snacks && hotel?.meals?.snacks_price)}>
                                {hotel?.meals?.snacks_price && hotel?.meals?.is_snacks ? `${getHotelCurrencySymbol(hotel)}${formatPrice(hotel.meals.snacks_price)}` : 'Not Available'}
                              </StyledTabItemValue>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container sx={{alignItems: 'center'}}>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemLabel error={!(hotel?.meals?.available_foods?.length > 0)}>
                                Special meals
                              </StyledTabItemLabel>
                            </Grid>
                            <Grid item xs={6} sx={{py:1}}>
                              <StyledTabItemValue error={!(hotel?.meals?.available_foods?.length > 0)}>
                                {hotel?.meals?.available_foods?.length > 0 ? hotel?.meals?.available_foods?.join(', ') : 'Not Available'}
                              </StyledTabItemValue>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }>
                      <LocalDiningIcon />
                    </StyledTooltip>
                  </StyledFacilityIcoBox>
                ) : (null)}
                {(hotel?.is_shuttle_service && (todaySchedulesForHTOA?.length > 0 || todaySchedulesForATOH?.length > 0)) ? (
                  <StyledFacilityIcoBox sx={{cursor: 'pointer'}}>
                    <StyledTooltip title={
                      <Grid container spacing={1.5}>
                        {todaySchedulesForATOH?.length > 0 ? (
                          <Grid container item spacing={0.5} sx={{mb: 1}}>
                            <Grid item xs={12} sx={{mt: 1}}>
                              <Typography
                                align='center'
                                sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500 }}
                              >
                                Terminal - Hotel
                              </Typography>
                            </Grid>
                            {todaySchedulesForATOH?.map((terminal, i) => (
                              <Fragment key={`atoh-${i}`}>
                                <Grid item xs={12}>
                                  <Typography sx={{ color: theme.palette.primary.light }}>
                                    {terminal.airport_terminal_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    component='span'
                                    sx={{ ...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1 }}
                                  >
                                    Day:
                                  </Typography>
                                  {weekdayMapping[today]}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    component='span'
                                    sx={{ ...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1 }}
                                  >
                                    Time:
                                  </Typography>
                                  {terminal.time.map(time => moment(time, 'HH:mm').format('hh:mm a')).join(', ')}
                                </Grid>
                              </Fragment>
                            ))}
                          </Grid>
                        ):(
                          <Grid item>
                            <Typography
                              align='center'
                              sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1  }}
                            >
                              There is no shuttle service for Terminal to Hotel today.
                            </Typography>
                          </Grid>
                        )}
                        {todaySchedulesForHTOA?.length > 0 ? (
                          <Grid container item spacing={0.5} sx={{mb: 1}}>
                            <Grid item xs={12} sx={{mt: 1}}>
                              <Typography
                                align='center'
                                sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500 }}
                              >
                                Hotel - Terminal
                              </Typography>
                            </Grid>
                            {todaySchedulesForHTOA?.map((terminal, i) => (
                              <Fragment key={`htoa-${i}`}>
                                <Grid item xs={12}>
                                  <Typography sx={{ color: theme.palette.primary.light }}>
                                    {terminal.airport_terminal_name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    component='span'
                                    sx={{ ...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1 }}
                                  >
                                    Day:
                                  </Typography>
                                  {weekdayMapping[today]}
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    component='span'
                                    sx={{ ...theme.typography.body2, ...theme.typography.fontSemiBold500, mr: 1 }}
                                  >
                                    Time:
                                  </Typography>
                                  {terminal.time.map(time => moment(time, 'HH:mm').format('hh:mm a')).join(', ')}
                                </Grid>
                              </Fragment>
                            ))}
                          </Grid>
                        ):(
                          <Grid item>
                            <Typography
                              align='center'
                              sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1 }}
                            >
                              There is no shuttle service for Hotel to Terminal today.
                            </Typography>
                          </Grid>
                        )}
                        {hotel?.shuttle_departure_point ? 
                          <Grid item>
                            <Typography sx={{ ...theme.typography.subtitle2, ...theme.typography.fontSemiBold500, mb: 1, ml: 1 }}>
                              Shuttle Departure Point:
                            </Typography>
                            <Typography sx={{ ...theme.typography.body2, mb: 1, ml: 1, whiteSpace: 'pre-wrap' }}>
                              {hotel?.shuttle_departure_point}
                            </Typography>
                          </Grid>
                        : ''}
                      </Grid>
                    }>
                      <DirectionsBusIcon />
                    </StyledTooltip>
                  </StyledFacilityIcoBox>
                ) : (null)}
              </Box>
              {hotel?.distance_min ? <Box>
                <StyledFacilityIcoBox sx={{width: 'auto', p:1, mr:0}}>
                  <FlightTakeoffIcon />
                  <Typography sx={{
                    ...theme.typography.btnText,
                    ml: theme.spacing(.5),
                    whiteSpace: 'nowrap'
                  }}>
                    {hotel.distance_min} min
                  </Typography>
                </StyledFacilityIcoBox>
              </Box> : (null)}
            </StyledCardMediaBottom>
          </StyledCardMedia>
          <CardContent sx={{py:1}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Box sx={{mr:1, flexGrow:1, display: 'flex', alignItems: 'center'}}>
                {hotel?.roomTypes ? (
                  <Box sx={{mr:2}}>
                    {hotel.roomTypes.map((room, index) => (
                      <Typography key={`h-r-ar-${index}`} sx={{
                        ...theme.typography.textMedium,
                        ...theme.typography.fontMedium600,
                        mb: 1
                      }}>
                        {room?.available_rooms}x {room.roomTypeName}s at {getHotelCurrencySymbol(hotel)}{formatPrice(room.price)}
                      </Typography>
                    ))}
                  </Box>
                ) : (null)}
                {/* <Box sx={{flexGrow:1}}>
                  <Typography sx={{
                    ...theme.typography.textMedium,
                    ...theme.typography.fontMedium600
                  }}>
                    From {getHotelCurrencySymbol(hotel)}{formatPrice(getLowestRate(hotel))}/room
                  </Typography>
                </Box> */}
              </Box>
              <Box>
                <IconButton onClick={() => setIsSliderOpen(!isSliderOpen)} sx={{
                  zIndex: 1,
                  transform: `${isSliderOpen ? 'rotate(0deg)' : 'rotate(-180deg)'}`,
                  transition: 'transform 250ms ease-in'
                }}>
                  <ExpandCircleDownIcon fontSize='medium' sx={{color: theme.palette.grey[600]}} />
                </IconButton>
              </Box>
            </Box>
          </CardContent>
          <StyledCollapse in={isSliderOpen}>
            <HotelCardCollapse 
              hotel={hotel} 
              getCurrencySymbol={getHotelCurrencySymbol} 
              isOpen={isSliderOpen} 
              weekdayMapping={weekdayMapping}
              today={today}
              todaySchedulesForHTOA={todaySchedulesForHTOA}
              todaySchedulesForATOH={todaySchedulesForATOH}
            />
          </StyledCollapse>
        </Box>
        <Divider variant='fullWidth' />
        {isUnavailableHotel ? 
          (<Box></Box>)
        : (hotel?.totalAvailableRooms && Number(hotel.totalAvailableRooms) > 0) ? (
          <Box>
            {isRoomsCountInputHidden 
              ? (<Box></Box>)
              : (
                <CardContent>
                  {hotel?.roomTypes ? (
                    <Grid container columnSpacing={2}>
                      {hotel.roomTypes.map((room, index) => (
                        Number(room.available_rooms) > 0 ? (
                          <Grid item xs={6} key={`hra-${hotel.hotelId}-${room.roomTypeId}`}>
                            <StyledRoomAvailabilityBox isError={!!errors?.formAddHotelToLayover?.rooms?.[room.roomTypeId]}>
                              <StyledRoomName>{room.roomTypeName}</StyledRoomName>
                              <Box>
                                <Controller
                                  name={`formAddHotelToLayover[roomOccupancies][${room.roomTypeId}]`}
                                  control={control}
                                  defaultValue={room.occupancy}
                                  render={({ field }) => (
                                    <Input
                                      type='hidden'
                                      formControlProps={{sx: {m:0}}}
                                      sx={{border:0}}
                                      {...field}
                                    />
                                  )}
                                />
                                <Controller
                                  name={`formAddHotelToLayover[rooms][${room.roomTypeId}]`}
                                  control={control}
                                  defaultValue={''}
                                  render={({ field }) => (
                                    <Input
                                      type='number'
                                      isError={!!errors?.formAddHotelToLayover?.rooms?.[room.roomTypeId]}
                                      inputRef={field.ref}
                                      {...field}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (!isNaN(value) && Number(value) >= 0) {
                                          field.onChange(value);
                                          handlePaxChange();
                                        }
                                      }}
                                      onWheel={(e) => e.target.blur()}
                                      formControlProps={{
                                        sx: {
                                          marginBottom: 0
                                        }
                                      }}
                                      sx={{
                                        backgroundColor: 'transparent',
                                        border: 0,
                                        '& .MuiInputBase-input': {
                                          paddingTop: theme.spacing(1),
                                          paddingBottom: 0,
                                          paddingRight: theme.spacing(.25),
                                          paddingLeft: theme.spacing(.25)
                                        }
                                      }}
                                    />
                                  )}
                                  rules={{
                                    validate: value => {
                                      if (value === '' || (Number(value) >= 0 && Number(value) <= room.available_rooms)) {
                                        return true;
                                      }
                                    },
                                    min: 0,
                                    max: room.available_rooms
                                  }}
                                />
                              </Box>
                            </StyledRoomAvailabilityBox>
                          </Grid>
                        ) : (null)
                      ))}
                    </Grid>
                  ) : (null)}
                </CardContent>
              )
            }
            <Divider variant='fullWidth' />
            <CardActions sx={{p:0}}>
              <StyledButton type='submit' disabled={isDisabledAddToLayoverButton}>
                Add to Layover
              </StyledButton>
            </CardActions>
          </Box>
        ) : (
          <Box>
            {/* <CardActions sx={{p:0, justifyContent: 'center'}}>
              <StyledButton sx={{
                color: theme.palette.roseGold[100],
                letterSpacing: 0
              }}>
                Request hotel for availability
              </StyledButton>
            </CardActions> */}
          </Box>
        )}
      </StyledCard>
    </form>
  );
};

export default memo(HotelCard);