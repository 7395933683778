import { Avatar, Box, Button, Typography, styled, useTheme } from '@mui/material';
import { Dropdown } from '../../components';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import {useEffect, useState } from 'react';
import { logout } from '../../services/auth/logout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { checkPermissions } from '../../utils/checkPermissions';

const TopBar = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '3px',
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => ['active'].indexOf(prop) === -1
})(({ theme, active, passive }) => ({
  ...theme.typography.textMedium,
  borderRadius: theme.spacing(1),
  borderColor:theme.mode === 'dark' ? (passive ? theme.palette.primary.light : theme.palette.time.light) : (passive ? theme.palette.menu.main : theme.palette.time.light),
  backgroundColor: theme.mode === 'dark' ? (active ? theme.palette.primary.light : 'transparent') : (active ? theme.palette.time.light : 'transparent'),
  color:theme.mode === 'dark' ? (active ? theme.palette.primary.dark : theme.palette.primary.light): (active ? theme.palette.primary.light : theme.palette.menu.main ),
  textTransform: 'capitalize',
  minHeight: '42px',
  marginRight: theme.spacing(2),
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: theme.mode === 'dark' ? (active ? theme.palette.primary.light : 'transparent') : (active ? theme.palette.time.light : 'transparent'),
    opacity: '.85',
    borderColor:passive ? theme.palette.time.light: 'transparent'
  }
}));

const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.dashboardHeadline,
  color: theme.palette.menu.main
}));

const Header = (props) => {
  const { pageTitle, isCommonTopbar, headerLeftAppbar, headerRightAppbar, headerTopbarSx } = props; 
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [pathname, setPathname] = useState('');
  const [userIconOptions, setUserIconOptions] = useState(null);
  const accountSettingButton = checkPermissions('accountSettingButton');
  const { hotelId } = useParams();

  const commonTopbarSx = {
    borderBottom: `1px solid ${theme.palette.primary.dark}`,
    borderRadius: 2
  };


  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!headerRightAppbar) {
      setUserIconOptions([
        {
          name: 'Sign out',
          onClickOption: () => {
            logout(dispatch, navigate);
          }
        }
      ]);
    }
  }, [dispatch, navigate, headerRightAppbar]);

  return (
    <Box component='main' sx={{ flexGrow: 1, pt: 4, pr: 1, pb: 1, pl: 1 }}>
      <TopBar sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
        py: 1,
        ...(isCommonTopbar ? commonTopbarSx : {}),
        ...headerTopbarSx
      }}>
        {headerLeftAppbar ? (
          headerLeftAppbar
        ) : (
          <Box>
            <StyledHeaderTitle variant='h6'>{pageTitle}</StyledHeaderTitle>
          </Box>
        )}

        {headerRightAppbar ? (
          headerRightAppbar
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems:'center',
            flexGrow: 1,
            py: '6px'
          }}>
            {/* <StyledButton
              variant='outlined'
              startIcon={<NotificationImportantIcon />}
              sx={{
                minWidth: '0',
                '& .MuiButton-startIcon': {
                  margin: 0 
                }
              }}
            /> */}
            {accountSettingButton?.view ? (
              <StyledButton
                startIcon={<SettingsIcon />}
                variant={(pathname === '/account/settings') ? 'contained' : 'outlined'}
                active={!!(pathname === '/account/settings')}
                passive={!!(pathname === `/hotel/${hotelId}` || pathname ===`/hotel/${hotelId}/edit`)}
                onClick={() => navigate('/account/settings')}>
                Account Settings
              </StyledButton>
            ) : (null)} 
            <Dropdown
              text={
                <Box sx={{minWidth:'100px', maxWidth:'150px', display:'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <Avatar sx={{borderRadius: theme.spacing(1)}} variant='rounded' src={'/broken-image.jpg'}/>
                  <Typography variant='body1' sx={{textTransform:'capitalize', mt: theme.spacing(1.5)}}>
                    {`${user?.user?.first_name ? user?.user?.first_name : ''} ${user?.user?.last_name ? user?.user?.last_name : ''}`}
                  </Typography>
                </Box>
              }
              btnSx={{
                borderRadius: theme.spacing(1),
                minWidth: '40px',
                p: 0
              }}
              menuProps={{
                sx: {
                  mt: 1
                }
              }}
              options={userIconOptions}
            />
          </Box>
        )}
      </TopBar>
    </Box>
  );
};

export default Header;