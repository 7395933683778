import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../helper/constant';

/**
 * 
 * @param {*} status    HTTP status code
 * @param     message   Custom Message to show (If not provided default error message will be showed)
 * @returns 
 */
export const handleApiError = (status, message = '') => {
  status = parseInt(status);

  switch(status) {
    case 403: {
      return toast.error(message || toastMessages.HTTPErrorStatus[403], {
        autoClose: maxToastTime,
        toastId: 'api-error-403',
      });
    }
    case 500: {
      return toast.error(message || toastMessages.HTTPErrorStatus[500], {
        autoClose: maxToastTime,
        toastId: 'api-error-500',
      });
    }
    case 400: {
      return toast.error(message || toastMessages.HTTPErrorStatus[400], {
        autoClose: maxToastTime,
        toastId: 'api-error-400',
      });
    }
    case 401: {
      return toast.error(toastMessages.HTTPErrorStatus[401], {
        autoClose: maxToastTime,
        toastId: 'api-error-401',
      });
    }
    default: {
      return toast.error(message || toastMessages.HTTPErrorStatus.default, {
        autoClose: maxToastTime,
        toastId: 'api-error-default',
      });
    }
  }
};