import { lsKeyIdentifier } from '../helper/constant';

/**
 * Store, retrive data to/from localStorage
 */
const storage = {
  getData(key, isJson = false) {
    key = `${lsKeyIdentifier}__${key}`
    let value = localStorage.getItem(key);
    if (isJson && value !== null) {
      value = JSON.parse(value);
    }
    if (value === 'null') value = null;
    if (value === 'undefined') value = undefined;
    if (value === 'true') value = true;
    if (value === 'false') value = false;
    return value;
  },

  storeData(key, value, isJson = false) {
    key = `${lsKeyIdentifier}__${key}`
    if (isJson && value !== null) {
      value = JSON.stringify(value);
    }
    try {
      // Try and save data
      localStorage.setItem(key, value);
    } catch (e) {
      // Display error if occurred while saving data
      console.log('Could not store the data into localStorage', e);
    }
  },

  removeData(key) {
    key = `${lsKeyIdentifier}__${key}`
    // Remove key from localStorage
    localStorage.removeItem(key);
  },

  removeAllData() {
    // Remove all keys from localStorage
    localStorage.clear();
  }
};

export default storage;