import { useState, useCallback, useEffect } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  useTheme,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import { Link } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Input } from '../../../components';
import { LargeHeading } from '../components/heading';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { useLoader } from '../../../providers/loader';
import { uploadFiles } from '../../../services/other/upload';
import { deleteImage } from '../../../services/hotel';
import { updateInvoiceStatus } from '../../../services/hotel/hotelBillingManagement';
import moment from 'moment';
import { prepareImageUrlByPath } from '../../../utils';
import downloadPDF from '../../../utils/downloadPDF';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontSemiBold500,
  textTransform: 'uppercase',
  color: theme.palette.table.header,
  borderBottom: 'unset',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottomColor: theme.palette.table.Boxider,
}));

const StyledFileInput = styled(Input)(({ theme }) => ({
  border: 0,
  background: 'none',
  paddingBottom: theme.spacing(1),
  '& input, & input:disabled': {
    cursor: 'pointer',
    opacity: '0',
    zIndex: 1
  },
  '& .file-input-label': {
    ...theme.typography.fontMedium500,
    color: theme.palette.grey[800],
    position: 'absolute',
    left: theme.spacing(4)
  }
}));

const StyledFileInputLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium
}));

const StyledAttachFileIcon = styled(AttachFileIcon)(({ theme }) => ({
  color: theme.palette.primary.light,
  marginLeft: theme.spacing(0),
  rotate: '45deg',
  transform: 'scaleX(-1)'
}));

const StyledDeleteIcon = styled(IconButton)(({ theme }) => ({
  padding: 0,
  cursor: 'pointer',
  color: theme.palette.error.main
}));

const BillingTable = (props) => {
  const theme = useTheme();
  const { invoices, setInvoiceTableData } = props;
  const [invoicesData, setInvoicesData] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const { startLoader, stopLoader } = useLoader();
  
  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  /**
   * Upload invoice from API
   * 
   * @param {*} request   Request
  */
  const uploadInvoiceViaApi = useCallback(async (file) => {
    try {
      const response = await uploadFiles(file);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * upload additional files
   */
  const updateInvoiceStatusFromApi = useCallback(async (id, request) => {
    try {
      const response = await updateInvoiceStatus(id, request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

   /**
   *  delete upload additional files
   */
   const deleteInvoiceFromApi = useCallback(async (id) => {
    try {
      const response = await deleteImage(id);
      return response;
    } catch (e) {
      throw e
    }
  }, []);
  
  // upload invoice
  const handleUploadInvoiceClick = async (id, file) => {
    if (file) {
      if (file.type !== 'application/pdf') {
        toast.error('Please upload a PDF file only', {
          autoClose: maxToastTime,
          toastId: 'upload-invoice-error',
        });
        return;
      }

      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const formData = new FormData();
          formData.append('ref', 'api::hotel-layover-billing.hotel-layover-billing');
          formData.append('refId', id);
          formData.append('field', 'invoice');
          formData.append('files', file);
          const response = await uploadInvoiceViaApi(formData);
          if (response) {
            try {
              await updateInvoiceStatusFromApi(id, { current_invoice_stage: 'SUBMITTED' });
              setInvoicesData(prevInvoiceData => {
                return prevInvoiceData.map(invoiceData => {
                  if (invoiceData.id === id) {
                    return { ...invoiceData, invoiceStage: 'SUBMITTED', invoice: response[0] };
                  }
                  return invoiceData;
                })
              })
            } catch (err) {
              reject(err)
            }
          }
          resolve(response)
        } catch (err) {
          reject(err)
        }
      }).then(response => {
        toast.success(toastMessages.hotel.BillingManagementUploadInvoice.success, {
          autoClose: maxToastTime,
          toastId: 'upload-invoice-success',
        });
      }).catch((e) => {
        toast.error(toastMessages.hotel.BillingManagementUploadInvoice.error, {
          autoClose: maxToastTime,
          toastId: 'upload-invoice-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  };

  const handleDownloadAdditionalDoc = async (url, fileName) => {
    const tempUrl = await (process.env.REACT_APP_ENVIRONMENT === 'LOCAL') ? prepareImageUrlByPath(url) : url
    await downloadPDF(tempUrl, fileName)
  }

  // add attachment 
  const handleAttachmentOnchange = (e, id) => {
    const filteredData = invoicesData?.filter(invoice => invoice?.id === id)
    const additionalDocumentsLength = filteredData?.[0]?.additionalDocuments?.length || 0
    const selectedAttachments = Array.from(e.target.files);
    const totalAttachmentsCount = additionalDocumentsLength + selectedAttachments.length;
    if (totalAttachmentsCount > 3) {
      toast.warning(toastMessages.hotel.BillingManagementAdditionalFiles.warning, {
        autoClose: maxToastTime,
        toastId: 'file-upload-limit-warning',
      });
    }else{
      const newAttachments = additionalDocumentsLength < 3 ? selectedAttachments.slice(0, 3 - additionalDocumentsLength) : [];
      const tempAttachments= [...(filteredData?.[0]?.additionalDocuments || []), ...newAttachments]
      handleAdditionalDocuments(id, tempAttachments)
    }
  };

  // remove attachment
  const handleRemoveAttachment = async (indexProp, id) => {
    startLoader();
    new Promise(async (resolve, reject) => {
      const response = await deleteInvoiceFromApi(id);
      resolve(response)
    }).then((res) =>{
      toast.success(toastMessages.hotel.BillingManagementAdditionalFiles.deleteSuccess, {
        autoClose: maxToastTime,
        toastId: 'addition-files-upload-success'
      })
      setInvoicesData(prevInvoicesData=>{
        return prevInvoicesData.map(invoice => {
          if (invoice.id === indexProp) {
            invoice.additionalDocuments = invoice.additionalDocuments.filter(doc => doc.id !== id);
          }
          return invoice;
        });
      })
      setInvoiceTableData(invoicesData)
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  };

  // update additional attachments for the invoice in API
  const handleAdditionalDocuments = async (id, files) => {
    if (files) {
      startLoader();
      new Promise(async (resolve, reject) => {
        const formData = new FormData();
        formData.append('ref', 'api::hotel-layover-billing.hotel-layover-billing');
        formData.append('refId', id);
        formData.append('field', 'additional_documents');
        for (const singleAttachment of files) {
          formData.append('files', singleAttachment);
        }
        const response = await uploadInvoiceViaApi(formData);
        resolve(response)
      }).then(response => {
        setInvoicesData(prevInvoiceData => {
          return prevInvoiceData.map(invoice => {
            if(invoice.id === id){
              invoice?.additionalDocuments?.push(...response)
            }
            return invoice;
          })
        })
        toast.success(toastMessages.hotel.BillingManagementAdditionalFiles.success, {
          autoClose: maxToastTime,
          toastId: 'addition-files-upload-success',
        });
        setInvoiceTableData(invoicesData)
      }).catch((e) => {
        toast.error(toastMessages.hotel.BillingManagementAdditionalFiles.error, {
          autoClose: maxToastTime,
          toastId: 'addition-files-upload-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }

  useEffect(() => {
    setInvoicesData(invoices.map(invoice => ({
      ...invoice,
      additionalDocuments: invoice.additionalDocuments || [],
    })));
  }, [invoices])
  
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell sx={{ width: '15%', pl: 3 }}>Layover Id</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Layover Date</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Invoice Uploaded On</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Airline Name</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ width: '15%', textAlign:'center' }}>Current Invoice Stage</StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ textAlign:'center' }}>Additional Files</StyledTableHeaderCell>
            </TableRow>
          </TableHead>

          {invoicesData?.length > 0 ? (
            <TableBody>
              {invoicesData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell sx={{width: '10%', pl: 3}}>
                      <Link 
                        to={`/layovers/${row?.layoverId}/details`} 
                        style={{ color: 'inherit', textDecoration: 'none' }}
                      >
                        {row?.flightCode}
                      </Link>
                    </StyledTableCell>
                    <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                      {moment(row?.invoiceDate).format('YYYY-MM-DD')}
                    </StyledTableCell>
                    <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                      {row?.invoice?.createdAt ? moment(row?.invoice?.createdAt).format('YYYY-MM-DD') : '-'}
                    </StyledTableCell>
                    <StyledTableCell sx={{width: '15%', textAlign: 'center'}}>
                      <Typography>
                      {row?.airlineName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell key={row?.invoiceId} sx={{width: '15%', textAlign: 'center'}}>
                      {row?.invoiceStage === 'SUBMITTED' ? (
                        <Link to={`/billing/${row?.id}`} style={{textAlign: 'center', textDecoration: 'none', color: theme.palette.success.main}}>
                          Invoice Submitted
                        </Link>
                      ) : (
                        <Box>
                          <input
                            type='file'
                            accept='.pdf, .xlsx, .xls, image/jpeg, image/png, image/gif'
                            id={`upload-invoice-${row?.invoiceId}`}
                            style={{display: 'none'}}
                            onChange={(e) => handleUploadInvoiceClick(row?.id, e.target.files[0])}
                          />
                          <label htmlFor={`upload-invoice-${row?.invoiceId}`} style={{textAlign: 'center'}}>
                            <Typography
                              sx={{
                                color: theme.palette.roseGold[100],
                                cursor: 'pointer',
                                textDecoration: 'underline',
                              }}
                            >
                              Upload Invoice
                            </Typography>
                          </label>
                        </Box>
                      )}
                    </StyledTableCell>
                    <StyledTableCell sx={{ textAlign:'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Box>
                        {row?.additionalDocuments ? row.additionalDocuments?.map((file, index) => (
                          <Grid container key={index} sx={{display: 'flex', alignItems: 'center'}}>
                            <Grid item xs={10}>
                              <Typography 
                                variant='body2' 
                                sx={{
                                  pl: 1, width: theme.spacing(17), 
                                  whiteSpace: 'nowrap', 
                                  textOverflow: 'ellipsis', 
                                  overflow: 'hidden',
                                  cursor: 'pointer',  
                                  textAlign: 'left',
                                  color: theme.palette.primary.light
                                }}
                                onClick={()=>handleDownloadAdditionalDoc(file.url, file?.name)}
                              >
                                {index + 1}. {file?.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <StyledDeleteIcon aria-label='Remove' onClick={() => handleRemoveAttachment(row?.id, file?.id)}><CloseIcon /></StyledDeleteIcon>
                            </Grid>
                          </Grid>
                        )) : null}
                        {row?.additionalDocuments?.length < 3 ? (
                          <StyledFileInput
                            formControlProps={{
                              sx: {
                                marginBottom: 0,
                                width: theme.spacing(20)
                              }
                            }}
                            inputProps={{
                              type: 'file',
                              multiple: true,
                            }}
                            value={''}
                            onChange={(e) => handleAttachmentOnchange(e, row?.id)}
                            startAdornment={
                              <StyledAttachFileIcon />
                            }
                            endAdornment={
                              <StyledFileInputLabel className='file-input-label'>{'Add Attachments'}</StyledFileInputLabel>
                            }
                          />
                        ):(null)}
                      </Box>
                    </StyledTableCell>
                  </TableRow>
                ))}
            </TableBody>
          ):(null)}
        </Table>
        {!invoicesData.length > 0 ? (
          <Box sx={{ width:'100%', py: 28, display: 'flex', justifyContent: 'center' }}>
            <LargeHeading title='Your hotel does not yet have layover bookings.' style={{ textAlign: 'center' }} />
          </Box>
        ):(null)}
      </TableContainer>

      <Box sx={{ mt: 3.5, display: 'flex', justifyContent: 'center' }}>
        <Pagination
          count={Math.ceil(invoicesData?.length / rowsPerPage)}
          page={page + 1}
          variant='outlined'
          shape='rounded'
          onChange={handlePageChange}
          hidePrevButton
          hideNextButton
          sx={{
            '& .MuiPaginationItem-root': {
              backgroundColor: theme.palette.grey[800],
              color: theme.palette.background.default,
              border: 'none',
            },
            '& .MuiPaginationItem-root.Mui-selected': {
              backgroundColor: theme.palette.primary.light,
              color: theme.palette.background.default,
              border: 'none',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default BillingTable;
