/**
 * Get number separated with comma (thousand separator)
 * 
 * @param {*} num   Number
 * @returns String
 */
function numberWithCommas(num) {
  return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export default numberWithCommas;
