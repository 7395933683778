import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLoader } from '../../providers/loader';
import { verifyLayoverBookingToken } from '../../services/layover/bookings';
import { toast } from 'react-toastify';
import { maxToastTime } from '../../helper/constant';
import NotFound from '../passenger/notFound'
import Cookies from 'js-cookie';

const VerifyLayoverToken = () => {
  let { token } = useParams();
  const [showerrorPage, setShowerrorPage] = useState(false)
  const { startLoader, stopLoader } = useLoader();
  const [searchParams] = useSearchParams();
  const clearCache = searchParams.get('clear_cache');

  /**
   * Verify layover booking token from API
   */
  const verifyLayoverBookingTokenFromApi = useCallback(async (token) => {
    try {
      const response = await verifyLayoverBookingToken(token);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    if (token) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await verifyLayoverBookingTokenFromApi(token)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        if (response?.redirectUrl) {
          if(clearCache) {
            Cookies.remove('passengerInfo')
            Cookies.remove('passengerRoomsDetails')
            Cookies.remove('welcomeDetails')
          }
          window.location.href = response.redirectUrl;
        }
      }).catch((e) => {
        if (e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
          setShowerrorPage(true)
        } else {
          toast.error(e?.response?.data?.error?.message, {
            autoClose: maxToastTime,
            toastId: 'verify-token-flag',
          });
        }
      }).finally(() => {
        stopLoader();
      });
    }
  }, [token, startLoader, stopLoader, verifyLayoverBookingTokenFromApi]);

  if(showerrorPage){
    return <NotFound/>
  }

  return (
    <></>
  );
};

export default VerifyLayoverToken;