import { memo, useEffect, useState } from 'react';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import HelpIcon from '@mui/icons-material/Help';

const TooltipClick = (props) => {
  const { children, open, title, tooltipIco } = props;
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <Tooltip
        arrow
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
      >
        {children ? children : (
          <IconButton
            onClick={() => setIsOpen(true)}
            aria-label='information'
            sx={{
              p: 0,
              ml: 1,
              verticalAlign: 'top'
            }}
          >
            {tooltipIco ? (
              tooltipIco
            ) : (
              <HelpIcon sx={{...theme.typography.textMedium, color: theme.palette.grey[700]}} />
            )}
          </IconButton>
        )}
      </Tooltip>
    </ClickAwayListener>
  );
};

export default memo(TooltipClick);