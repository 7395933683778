import { memo, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Select } from '../../../components';
import { useTheme } from '@mui/material';

const SelectMealsAndShuttle = (props) => {
  const {entity, hotelId, date, defaultValue, onChange, options, disabled = false} = props;
  const { control, setValue } = useFormContext();
  const theme = useTheme();

  const onSelectChange = (name, value) => {
    let formFieldData = {};
    const keys = name.split(/\[(.*?)\]/gi).filter(e => e !== '')
    keys.reduce(function(a, b, i) {
      return (i !== keys.length - 1) ? a[b] || (a[b] = {}) : a[b] = value;
    }, formFieldData)
    // Call the onChange function
    if (onChange && formFieldData?.formAvailability?.[date] && Object.keys(formFieldData?.formAvailability?.[date]).length > 0) {
      onChange(hotelId, {
        date: date,
        ...formFieldData.formAvailability[date]
      })
    }
  };

  useEffect(() => {
    setValue(`formAvailability[${date}][${entity}]`, defaultValue)
  }, [setValue, defaultValue, date, entity])

  return (
    <Controller
      name={`formAvailability[${date}][${entity}]`}
      control={control}
      defaultValue={true}
      render={({ field }) => (
        <Select
          sx={{
            backgroundColor: 'unset',
            border: 0,
            marginBottom: 0
          }}
          InputProps={{
            sx: {
              width: '48px',
              '& .MuiSelect-select.MuiInputBase-input': {
                minHeight: 'auto',
                ...theme.typography.textSmall
              }
            }
          }}
          formControlProps={{
            marginBottom: '0 !important'
          }}
          marginRightIcon='0 !important'
          paddingSelect='5px 0 5px 3px !important'
          textAlignSelect='left'
          options={options}
          inputRef={field.ref}
          {...field}
          disabled={disabled}
          onChange={(e) => {
            onSelectChange(e.target.name, e.target.value)
            field?.onChange(e)
          }}
        />
      )}
      rules={{
        required: true
      }}
    />
  );
};

export default memo(SelectMealsAndShuttle);