import { toast } from 'react-toastify';
import { toastMessages } from '../../helper/constant';
import { setUser } from '../../redux/actions/user';

/**
 * User logout
 */
export const logout = (dispatch, navigate, redirectTo) => {
  try {
    return new Promise(() => {
      dispatch(
        setUser({
          token: null,
          user: null
        })
      );
      navigate(redirectTo ? redirectTo : '/user/login');
      toast.success(toastMessages.logout, {
        toastId: 'toast-logout'
      });
    });
  } catch (e) {
    // Handle the error
    console.log('Logout Error', e);
  }
};