import React from 'react';
import { Box, Typography, useTheme, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import lufthansaLogo from '../../../assets/dummy/lufthansaLogo.jpg';

const InvoiceDetailsBox = (props) => {
  const theme = useTheme();

  const GreySmallTitle = ({ style, label }) => {
    return (
      <Typography
        sx={{
          ...style,
          ...theme.typography.textxxSmall,
          ...theme.typography.fontMedium600,
          color: theme.palette.grey[700],
        }}
      >
        {label}
      </Typography>
    );
  };

  const WhiteSmallTitle = ({ style, label }) => {
    return (
      <Typography
        sx={{
          ...style,
          ...theme.typography.textSmall,
          color: theme.palette.grey[400],
        }}
      >
        {label}
      </Typography>
    );
  };

  return (
    <Grid container xs={11} sx={{ mt: 1, overflow: 'hidden' }}>
      <Grid
        xs={12}
        sx={{
          backgroundColor: theme.palette.primary.main,
          p: 3,
          ml: 1,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.light,
            mb: 2,
          }}
        >
          LA7834
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <img
            src={lufthansaLogo}
            alt='Logo'
            style={{
              height: 30,
              width: 30,
              borderRadius: '50%',
            }}
          />

          <Typography sx={{ ...theme.typography.textMedium }}>
            Lufthansa LH6847
          </Typography>

          <Typography
            sx={{
              color: theme.palette.grey[700],
            }}
          >
            YYC to CDG
          </Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography
          sx={{
            ...theme.typography.textMedium,
            ...theme.typography.fontMedium500,
          }}
        >
          Cameron Williamson
        </Typography>

        <Grid
          xs={12}
          container
          sx={{
            mt: 2.5,
            mb: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid xs={6}>
            <GreySmallTitle
              style={{
                mb: 1,
              }}
              label={'CHECK-IN'}
            />

            <WhiteSmallTitle label={'03.09.23 at 05.00 pm'} />
          </Grid>

          <Grid xs={6}>
            <GreySmallTitle
              style={{
                mb: 1,
              }}
              label={'CHECK-OUT'}
            />

            <WhiteSmallTitle label={'04.09.23 at 06.00 pm'} />
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            xs={6}
            container
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid xs={6}>
              <GreySmallTitle
                style={{
                  mb: 1,
                }}
                label={'ROOM NO.'}
              />

              <WhiteSmallTitle label={'312'} />
            </Grid>

            <Grid xs={6}>
              <GreySmallTitle
                style={{
                  mb: 1,
                }}
                label={'ROOM TYPE'}
              />

              <WhiteSmallTitle label={'Single Bed'} />
            </Grid>
          </Grid>

          <Grid xs={6}>
            <GreySmallTitle
              style={{
                mb: 1,
              }}
              label={'MEALS'}
            />

            <WhiteSmallTitle label={'Breakfast, Lunch & Dinner'} />
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} sx={{ padding: 3, ml: 1 }}>
        <Typography
          sx={{
            ...theme.typography.textLarge,
            color: theme.palette.dashboardWidgetHeadline,
            mt: 1,
          }}
        >
          TOTAL SUMMARY
        </Typography>

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Grid xs={8}>
            <GreySmallTitle label={'ROOM RATE (INCL. BREAKFAST)'} />
          </Grid>

          <Grid xs={4}>
            <WhiteSmallTitle label={'$20/night'} />
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Grid xs={8}>
            <GreySmallTitle label={'NO SHOW/SHOW CHARGES'} />
          </Grid>

          <Grid xs={4}>
            <WhiteSmallTitle label={'-'} />
          </Grid>
        </Grid>

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Grid xs={8}>
            <GreySmallTitle label={'LUNCH & DINNER CHARGES'} />
          </Grid>

          <Grid xs={4}>
            <WhiteSmallTitle label={'$15'} />
          </Grid>
        </Grid>

        <Divider sx={{ my: 3 }} />

        <Grid
          xs={12}
          container
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            mt: 2,
          }}
        >
          <Grid xs={8}>
            <Typography
              sx={{
                ...theme.typography.textxxSmall,
              }}
            >
              TOTAL COST
            </Typography>
          </Grid>

          <Grid xs={4}>
            <Typography
              sx={{
                ...theme.typography.textLarge,
                color: theme.palette.primary.light,
              }}
            >
              $35
            </Typography>

            <GreySmallTitle
              style={{
                mt: 0.5,
              }}
              label={'Incl. of tax'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InvoiceDetailsBox;
