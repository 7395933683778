import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get Hotels for Layover
 * NOTE: DIFFERENCE BETWEEN "getHotelsForLayover" AND "getHotelsInLayover" IS -
 * 1. IN "getHotelsForLayover" WE WILL FETCH ALL THE HOTELS NEARBY TO THE AIRPORT FOR SELECTION
 * 2. IN "getHotelsInLayover" WE WILL FETCH THE HOTELS ALREADY ADDED TO TEH LAYOVER
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} request     Request
 * @returns 
 */
export const getHotelsForLayover = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/hotels`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Add Hotels to Layover
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} request     Request
 * @returns 
 */
export const addHotelsToLayover = async(layoverId, request = {}) => {
  try {
    const response = await api.post(`/layovers/${layoverId}/hotels`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Remove Hotels from Layover
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} hotelId     Hotel Id
 * @returns 
 */
export const removeHotelsFromLayover = async(layoverId, hotelId) => {
  try {
    const response = await api.delete(`/layovers/${layoverId}/hotels/${hotelId}`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Get Hotels in Layover
 * NOTE: DIFFERENCE BETWEEN "getHotelsForLayover" AND "getHotelsInLayover" IS -
 * 1. IN "getHotelsForLayover" WE WILL FETCH ALL THE HOTELS NEARBY TO THE AIRPORT FOR SELECTION
 * 2. IN "getHotelsInLayover" WE WILL FETCH THE HOTELS ALREADY ADDED TO TEH LAYOVER
 * 
 * @param {*} layoverId   Layover ID
 * @param {*} request     Request
 * @returns 
 */
export const getHotelsInLayover = async(layoverId, request = {}) => {
  try {
    const response = await api.get(`/layovers/${layoverId}/hotels-selected`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};