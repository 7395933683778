import { Card, CardContent, Divider, Typography, styled } from '@mui/material';

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => ['isOpenForLayover'].indexOf(prop) === -1
})(({ theme, isOpenForLayover, noDataFound, onClick }) => ({
  backgroundColor: noDataFound ? theme.palette.red[300] : theme.palette.primary.light20,
  color: theme.palette.text.dark,
  opacity: isOpenForLayover ? '1' : '.5',
  '&:hover': {
    cursor: onClick ? 'pointer' : ''
  },
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center'
}));

const StyledTypographyH3 = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontMedium500,
  marginRight: theme.spacing(1)
}));

const WidgetCard = (props) => {
  const { heading, label, headingTwo, labelTwo, isOpenForLayover, noDataFound, onClick } = props;

  return (
    <StyledCard isOpenForLayover={isOpenForLayover} noDataFound={noDataFound} onClick={onClick}>
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <StyledTypographyH3 variant='h3'>{heading}</StyledTypographyH3>
        <Typography variant='body1'>{label}</Typography>
      </CardContent>

      {labelTwo && (<Divider orientation='vertical' flexItem sx={{my:3}} />)}

      {labelTwo &&
       (<CardContent sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <StyledTypographyH3 variant='h3'>{headingTwo}</StyledTypographyH3>
          <Typography variant='body1'>{labelTwo}</Typography>
        </CardContent>)
      }
    </StyledCard>
  );
};

export default WidgetCard;
