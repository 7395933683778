import { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HotelContext } from '../../../../context/hotel';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { maxToastTime, pageTitles, toastMessages, userRoles } from '../../../../helper/constant';
import { LargeHeading, SubHeading } from '../../components/heading';
import { useDispatch, useSelector } from 'react-redux';
import HotelCard from './hotelCard';
import FrontDeskUserCard from './frontDeskUserCard';
import { Input } from '../../../../components';
import { arrayColumn, getSiteUrl } from '../../../../utils';
import { getHotelUsers, getSelfUser, inviteHotelUsers, updateHotelUsers } from '../../../../services/user';
import Select from '../../../../components/select';
import { Controller, useForm } from 'react-hook-form';
import { InputEndAdornment } from '../../components/endAdornment';
import { useLoader } from '../../../../providers/loader';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { checkPermissions } from '../../../../utils/checkPermissions';

const Settings = () => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  // const dispatch = useDispatch();
  const { startLoader, stopLoader } = useLoader();
  const { hotelId, setIsCommonTopbar, fetchLastAccessedHotelDetails, setHeaderPageTitle } = useContext(HotelContext);
  const [hotels, setHotels] = useState(null);
  const [frontDeskUsers, setFrontDeskUsers] = useState(null);
  const [frontDeskUsersResponse, setFrontDeskUsersResponse] = useState(null);
  const userPermissionAccountSettingManageUsersBox = checkPermissions('accountSettingManageUsersBox');
  const userPermissionAccountSettingHotelFrontDeskBox = checkPermissions('accountSettingHotelFrontDeskBox');
  const userPermissionAccountSettingManageHotelAdminBox = checkPermissions('accountSettingManageHotelAdminBox');
  const [hotelSelectOptions, setHotelSelectOptions] = useState([]);

  // fetching and displaying hotels which are connected with LoggedIn User
  const getLoggedInUserDetails = async () => {
    startLoader();
    let selfUserRequest = {
      'populate[hotels][fields][0]': 'name',
      'populate[hotels][fields][1]': 'is_open_for_layover',
      'populate[hotels][fields][2]': 'lat',
      'populate[hotels][fields][3]': 'lng',
      'populate[hotels][populate][city][fields][0]': 'city_name',
      'populate[hotels][populate][city][populate][country_id][fields][0]': 'name',
      'populate[last_accessed_hotel_id][fields][0]': 'id'
    };

    const userDetails = await getSelfUser({params: selfUserRequest});
    setHotels(userDetails.hotels);
    stopLoader();
  }

  useEffect(() => {
    getLoggedInUserDetails();
  }, [])

  // useEffect(() => {
  //   if (user.user?.hotels) {
  //     setHotels(user.user.hotels);
  //   }
  // }, [user.user]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setFocus,
    setValue
  } = useForm();

  useEffect(() => {
    setIsCommonTopbar(true)
    setHeaderPageTitle(pageTitles.hotel.accountSettings);
  }, [setIsCommonTopbar, setHeaderPageTitle]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
  /**
   * Get hotel front desk users
   * 
   * @param {*} hotelIds      Hotel Ids
   */
  const getHotelFrontDeskUsers = useCallback(async (hotelIds) => {
    if (hotelIds) {
      let request = {
        hotels: hotelIds.join(','),
        roleTypes: userRoles.hotelFrontDesk
      };
      const response = await getHotelUsers({params: request});
      if (response && response?.hotels && Object.keys(response.hotels).length) {
        setFrontDeskUsersResponse(response.hotels);
      }
    }
  }, []);

  /**
   * Invite hotel front desk users
   * 
   * @param {*} hotelId      Hotel Id
   * @param {*} request      Request
   */
  const inviteHotelFrontDeskUsers = useCallback(async (hotelId, request) => {
    if (hotelId && request) {
      try {
        const response = await inviteHotelUsers(hotelId, request);
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  /**
   * Remove hotel front desk users
   * 
   * @param {*} hotelId      Hotel Id
   * @param {*} request      Request
   */
  const removeHotelFrontDeskUsers = useCallback(async (hotelId, request) => {
    if (hotelId && request) {
      try {
        const response = await updateHotelUsers(hotelId, request);
        setFrontDeskUsers(oldFrontDeskUsers => {
          let tempFrontDeskUsers = _.cloneDeep(oldFrontDeskUsers)
          for (const userId of request.DeleteRequest.userIds) {
            if (tempFrontDeskUsers[userId]?.hotels && tempFrontDeskUsers[userId].hotels.length > 0) {
              for (const hotelKey in tempFrontDeskUsers[userId].hotels) {
                const hotel = tempFrontDeskUsers[userId].hotels[hotelKey]
                if (Number(hotel.id) === Number(hotelId)) {
                  (tempFrontDeskUsers[userId].hotels).splice(hotelKey, 1);
                }
              }
              if (tempFrontDeskUsers[userId]?.hotels.length <= 0) {
                delete tempFrontDeskUsers[userId];
              }
            }
          }
          return tempFrontDeskUsers;
        });
        return response;
      } catch (e) {
        throw e
      }
    }
  }, []);

  const removeUser = useCallback(async (hotelIds, userId) => {
    if (hotelIds && hotelIds.length && userId) {
      let promises = [];

      for (const hotelId of hotelIds) {
        const request = {
          DeleteRequest: {
            userIds: [ userId ]
          }
        };
        promises.push(removeHotelFrontDeskUsers(hotelId, request))
      }

      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          toast.success(toastMessages.hotel.removeHotelFrontDeskUsers.success, {
            autoClose: maxToastTime,
            toastId: 'remove-hotel-front-desk-user-success',
          })
        }).catch((error) => {
          toast.success(toastMessages.hotel.removeHotelFrontDeskUsers.error, {
            autoClose: maxToastTime,
            toastId: 'remove-hotel-front-desk-user-error',
          })
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [removeHotelFrontDeskUsers, startLoader, stopLoader]);

  // Call invite hotel front desk API on form submit
  const onSubmit = useCallback((data) => {
    // Call API to send an invite email to the hotel staff
    if (data && data?.hotelId && user.user?.id) {
      // Call API
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const request = {
            ...data,
            inviterId: user.user.id,
            role: userRoles.hotelFrontDesk,
            callbackUrl: `${getSiteUrl()}/user/accept-invite`
          }
          const response = await inviteHotelFrontDeskUsers(data.hotelId, request);
          resolve(response)
        } catch(e) {
          reject(e)
        }
      }).then((response) => {
        toast.success(toastMessages.hotel.inviteHotelFrontDeskUsers.success, {
          autoClose: maxToastTime,
          toastId: 'invite-hotel-front-desk-user-success',
        })
      }).catch((error) => {
        toast.error(toastMessages.hotel.inviteHotelFrontDeskUsers.error, {
          autoClose: maxToastTime,
          toastId: 'invite-hotel-front-desk-user-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, user.user, inviteHotelFrontDeskUsers]);

  useEffect(() => {
    if (hotels && hotels.length) {
      const ddOptions = hotels.map((hotel) => {
        return {
          key: hotel.id,
          name: hotel.name
        };
      });
      setHotelSelectOptions(ddOptions);

      // Get hotel front desk users
      const hotelIds = arrayColumn(hotels, 'id');
      getHotelFrontDeskUsers(hotelIds);
    }
  }, [hotels, getHotelFrontDeskUsers]);

  useEffect(() => {
    if (hotels && hotels.length && frontDeskUsersResponse && Object.keys(frontDeskUsersResponse).length) {
      let tempHotels = {};
      for (const hotel of hotels) {
        tempHotels[hotel.id] = {
          id: hotel.id,
          name: hotel.name
        };
      }
      let hotelFrontDeskUsers = {};
      for (const hotelId in frontDeskUsersResponse) {
        if (tempHotels?.[hotelId] && frontDeskUsersResponse[hotelId]?.users && frontDeskUsersResponse[hotelId].users.length) {
          for (const hotelUser of frontDeskUsersResponse[hotelId].users) {
            if (hotelUser?.confirmed && !hotelUser?.blocked && hotelUser.id !== user.user?.id) {
              if (!hotelFrontDeskUsers?.[hotelUser.id]) {
                hotelFrontDeskUsers[hotelUser.id] = {...hotelUser, hotels: []};
              }
              hotelFrontDeskUsers[hotelUser.id].hotels.push(tempHotels[hotelId]);
            }
          }
        }
      }
      setFrontDeskUsers(hotelFrontDeskUsers)
    }
  }, [user.user, hotels, frontDeskUsersResponse]);

  // prefill first value if there is only one value in options
  useEffect(() => {
    if (hotelSelectOptions.length > 0) {
      setValue('hotelId', hotelSelectOptions[0].key);
    }
  }, [hotelSelectOptions, setValue])

  return (
    <Box sx={{
      px: 2,
      py: 1
    }}>
      <Box sx={{mb: 4}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LargeHeading title='Manage my hotels' textColor={theme.palette.dashboardWidgetHeadline} />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {(hotels && hotels.length) ? (
                hotels.map((hotel, index) => (
                  <Grid item xs={4} key={`hotels-${index}`}>
                    <HotelCard user={user} hotel={hotel} lastAccessedHotelId={hotelId} onLastAccessedHotelChange={fetchLastAccessedHotelDetails} />
                  </Grid>
                ))
              ) : (
                <Grid item xs={12}>
                  <Typography variant='subtitle2'>
                    You do not have access to any hotel. Please contact the administrator if you believe this is incorrect.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{mb: 4}}>
        <Grid container spacing={2}>
          { userPermissionAccountSettingManageUsersBox?.view ? (
            <Grid item xs={12}>
              <LargeHeading title='Manage users' textColor={theme.palette.dashboardWidgetHeadline} />
              <Typography variant='subtitle2' sx={{ lineHeight: theme.lineHeight[1.5], mt: 2, mb: 4 }}>Add or remove front desk users from your assigned hotels.</Typography>
            </Grid>
          ) : (null)}  
          { userPermissionAccountSettingHotelFrontDeskBox?.view ? (
            <>
              <Grid item xs={12}>
                <SubHeading title='Hotel Front Desk' />
              </Grid>
              <Grid item xs={12}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Controller
                        name='hotelId'
                        control={control}
                        render={({ field }) => (
                          <Select
                            placeholder='Select hotel'
                            options={hotelSelectOptions}
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors.hotelId}
                            errorMessage={errors.hotelId && 'Please select a hotel'}
                            inputRef={field.ref}
                            selectProps={{
                              onChange: field.onChange,
                              autoFocus: true,
                              value: field.value ? field.value : ''
                            }}
                            {...field}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        name='emailAddress'
                        control={control}
                        render={({ field }) => (
                          <Input
                            placeholder='Enter email address'
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors.email}
                            errorMessage={errors.email && 'Please enter valid email address'}
                            inputRef={field.ref}
                            autoFocus
                            {...field}
                            endAdornment={
                              <InputEndAdornment position='end' color={theme.palette.menu.light}>
                                <Button
                                  type='submit'
                                  sx={theme.palette.menu.light}
                                >
                                  Send Invite
                                </Button>
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
                        }}
                      />
                    </Grid>
                  </Grid>
                </form>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {(frontDeskUsers && Object.keys(frontDeskUsers).length) ? (
                    Object.keys(frontDeskUsers).map((userId, i) => {
                      let frontDeskUser = frontDeskUsers[userId]
                      return (
                        <Grid item xs={4} key={`fdu-${i}`}>
                          <FrontDeskUserCard
                            user={frontDeskUser}
                            removeUser={removeUser}
                          />
                        </Grid>
                      )
                    })
                  ) : (null)}
                </Grid>
              </Grid>
            </>
          ) : (null)}
        </Grid>
      </Box>
      { userPermissionAccountSettingManageHotelAdminBox?.view ? (
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SubHeading title='Hotel Admin' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='subtitle2' sx={{lineHeight: theme.lineHeight[1.5]}}>
                Do you need another user with admin rights or other user types? Please contact us at <Link to='mailto:support@croowy.de' target='_blank' style={{color: theme.palette.primary.light}}>support@croowy.de</Link> and we will get back to you within 1 working day.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ) : (null)}
    </Box>
  );
};

export default Settings;