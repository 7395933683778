import { api } from '..';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel Billing management data
 * 
 * @param {Number} hotelId
 * @param {*} request   Request
 * @returns 
 */
export const getHotelBillingData = async(hotelId, request = {}) => {
  try {
    const response = await api.get(`/hotel-layover-billings?view=hotel&hotelId=${hotelId}`, request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e.response.status !== 400 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update hotel Billing management invoice status
 * 
 * @param {Number} invoiceId  
 * @param {*} request   Request
 * @returns 
 */
export const updateInvoiceStatus = async(invoiceId, request = {}) => {
  try {
    const response = await api.put(`/hotel-layover-billings/${invoiceId}`, request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * get hotel Billing management invoice details
 * 
 * @param {Number} invoiceId   
 * @param {*} request   Request
 * @returns 
 */
export const getInvoiceDetails = async(invoiceId, request = {}) => {
  try {
    const response = await api.get(`/hotel-layover-billings/${invoiceId}?populate=invoice&fields[0]=id&fields[1]=invoice_id`, request);
    if (parseInt(response?.status) !== 200 && parseInt(response?.status) !== 404) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};