import axios from 'axios';
import { toast } from 'react-toastify';
import { toastMessages } from '../helper/constant';
import { setUser } from '../redux/actions/user';

// Code to import redux store
let store;
export const injectStore = _store => {
  store = _store
};

/**
 * Create the instance of the axios
 */
export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL
});

api.interceptors.request.use(
  (config) => {
    const user = store.getState().user;
    let token = process.env.REACT_APP_BACKEND_API_TOKEN;

    if (user?.token) {
      token = user.token;
    }

    /**
     * Set headers along with authentication headers
     */
    config.headers = {
      Authorization: `Bearer ${token}`,
      // 'Content-Type': 'application/json'
    };

    return config;
  },
  function (error) {
    // Handle request errors
    toast.error(toastMessages.networkError, { toastId: 'network-error' });
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async(response) => {
    return response;
  },
  async function (e) {
    const { dispatch } = store;
    // Handle response errors
    if (/Network\s*Error/i.test(e.message)) {
      toast.error(toastMessages.networkError, { toastId: 'network-error' });
      return null;
    }
    if (e?.response?.status && parseInt(e.response.status) === 401) {
      dispatch(
        setUser({
          token: null,
          user: null
        })
      );
    }
    return Promise.reject(e);
  }
);