import { userActionTypes } from '../types';

/**
 * Set the user details
 * 
 * @param {*} token   User token
 * @param {*} user    User details
 * @returns 
 */
export const setUser = ({token, user}) => ({
  type: userActionTypes.SET_USER,
  token: token,
  payload: user
});