import { Button, styled } from '@mui/material';
import { memo } from 'react';

const ButtonField = styled(Button, {
  shouldForwardProp: (prop) => 
    ['buttonSize', 'buttoncolor'].indexOf(prop) === -1
})(({
  theme,
  disabled = false,
  pressed = false,
  height,
  width,
  buttonSize,
  buttoncolor
}) => ({
  '&.MuiButton-contained': {
    ...theme.typography.btnText,
    backgroundColor: theme.palette.menu.light,
    color: theme.palette.text.dark,
    height: `${height}`,
    width: `${width}`,
    textTransform: 'capitalize',
    borderRadius: '5px',
    padding: theme.spacing(2),
    opacity: disabled ? .25 : 1
  }
}));

export default memo(ButtonField);