/**
 * Serialize object into string
 * e.g. Convert {foo: 'hi there', bar: '100%'} into foo=hi%20there&bar=100%25
 * 
 * @param {*} obj     Object to serialize
 * @param {*} prefix  Prefix if any
 * @returns String
 */

function serialize(obj, prefix) {
  let str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      var k = prefix ? prefix + '[' + p + ']' : p,
        v = obj[p];
      str.push((v !== null && typeof v === 'object') ?
        serialize(v, k) :
        encodeURIComponent(k) + '=' + encodeURIComponent(v));
    }
  }
  return str.join('&');
}

export default serialize;
