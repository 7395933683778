import { Grid, useTheme, Typography, styled } from '@mui/material'
import { AlertDialog } from '../../components'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const Para = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.4),
  marginTop: theme.spacing(1.3),
  alignItems: 'flex-start',
  textAlign: 'left',
  lineHeight: theme.lineHeight[1.37]
}))

export default function TransportationAlertBox(props) {
  const {hotel, onDisagree, open, today} = props;
  const { t } = useTranslation()
  const theme = useTheme();
  const weekdayMapping = {
    MON: t('MON'),
    TUE: t('TUE'),
    WED: t('WED'),
    THU: t('THU'),
    FRI: t('FRI'),
    SAT: t('SAT'),
    SUN: t('SUN')
  };

  return (
    <AlertDialog
      title={t('fullSchedule')}
      dialogTitleProps={{
        sx: {
          ...theme.typography.textxxLarge,
          pt: theme.spacing(5),
          color: theme.palette.success.main,
          pb: theme.spacing(2)
        }
      }}
      open={open}
      onDisagree={onDisagree}
      noBtnText={t('close')}
      sx={{
        textAlign: 'center'
      }}
      fullWidth={true}
      maxWidth={'xs'}
      stretchBtn
    >
      <Grid
        container item
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        spacing={1.5}
      >
        {hotel?.hotelTransportationSchedules?.ATOH?.filter(schedule =>schedule.weekday.includes(today))?.length > 0 ? 
          (
            <Grid 
              container item spacing={0.5} 
              direction='column'
              justifyContent='center'
              alignItems='flex-start'>
              <Grid item>
                <Para variant='body1' sx={{color: theme.palette.primary.light}}>{t('ATH')}</Para>
              </Grid>
              <Grid item>
                <Para variant='body2'>{t('day')}: {weekdayMapping[today]}</Para>
              </Grid>
              {hotel?.hotelTransportationSchedules?.ATOH
                ?.filter(schedule =>schedule.weekday.includes(today))
                ?.map((schedule, index) => (
                  <Grid item key={`ATOH-${index}`}>
                    <Para variant='body2'>
                      {schedule.airportTerminalName}{': '}  
                      {schedule.time.map(time=>moment(time, 'HH:mm').format('hh:mm A')).join(', ')}
                    </Para>
                  </Grid>
              ))}
            </Grid>
          ):(
            <Grid item>
              <Para variant='subtitle2' sx={{...theme.typography.fontMedium600}}>{t('ATHNotAvailable')}</Para>
            </Grid>
          )
        }
        {hotel?.hotelTransportationSchedules?.HTOA?.filter(schedule =>schedule.weekday.includes(today))?.length > 0 ? 
          (
            <Grid 
              container item spacing={0.5} 
              direction='column'
              justifyContent='center'
              alignItems='flex-start'>
              <Grid item>
                <Para variant='body1' sx={{color: theme.palette.primary.light}}>{t('HTA')}</Para>
              </Grid>
              <Grid item>
                <Para variant='body2'>{t('day')}: {weekdayMapping[today]}</Para>
              </Grid>
              {hotel?.hotelTransportationSchedules?.HTOA
                ?.filter(schedule =>schedule.weekday.includes(today))
                ?.map((schedule, index) => (
                  <Grid item key={`HTOA-${index}`}>
                    <Para variant='body2'>
                      {schedule.airportTerminalName}{': '}  
                      {schedule.time.map(time=>moment(time, 'HH:mm').format('hh:mm A')).join(', ')}
                    </Para>
                  </Grid>
              ))}
            </Grid>
          ):(
            <Grid item>
              <Para variant='subtitle2' sx={{...theme.typography.fontMedium600}}>{t('HTANotAvailable')}</Para>
            </Grid>
          )
        }
      </Grid>
    </AlertDialog>
  )
}
