import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonLayout from '../common';
import { getMenus, storage } from '../../utils';
import HeaderLeftAppbar from './headerLeftAppbar';
import { HotelContext } from '../../context/hotel';
import { getHotelDetails, updateHotelDetails } from '../../services/hotel';
import { setUser } from '../../redux/actions/user';
import { updateSelfUser } from '../../services/user';
import CustomSidebar from './CustomSidebar';
import { TermsConditionContext } from '../../context/termsCondition';
import TermsConditionDialogue from '../common/termsConditionDialogue';

// passing function from parent to child (YES and NO)
let handleDirtyModalAgree;
const onclickDirtyFormModalAgree = (callback) => {
  handleDirtyModalAgree = callback;
};

let handleDirtyModalDisagree;
const onclickDirtyFormModalDisAgree = (callback) => {
  handleDirtyModalDisagree = callback;
};

const HotelLayout = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [sidebarMenus, setSidebarMenus] = useState(null);
  const [isCommonTopbar, setIsCommonTopbar] = useState(true);
  const [headerPageTitle, setHeaderPageTitle] = useState('');
  const [hotelId, setHotelId] = useState(null);
  const [hotel, setHotel] = useState(null);
  const [hotels, setHotels] = useState([]);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [dirtyDialogOpen, setDirtyDialogOpen] = useState(false);
  const [rateChargesNextTabValue, setRateChargesNextTabValue] = useState('');
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [isSidebarOpened, setIsSidebarOpened] = useState(true);
  const [isBookingsOpen, setIsBookingsOpen] = useState(false);

  useEffect(() => {
    setIsSidebarOpened(storage.getData('sidebarOpened'));
  }, [])
  
  useEffect(() => {
    if (!isCommonTopbar) {
      setHeaderPageTitle('')
    }
  }, [setHeaderPageTitle, isCommonTopbar])

  useEffect(() => {
    if (!user.user?.id) {
      navigate('/user/login');
    }
    if(user.user?.role?.type) {
      const menus = getMenus(user.user.role.type);
      setSidebarMenus(menus);
    }
    setTermsDialogOpen(!user.user?.terms_accepted)

    /**
     * Set last accessed hotel id from API
     * NOTE: If last accessed hotel id does not exists in API, then set first hotel in the list as last accessed hotel id
     */
    if (user.user?.hotels && user.user.hotels.length > 0) {
      setHotels(user.user.hotels);
      const accessibleHotelIds = user.user.hotels.map(i => i.id);
      if (user.user.last_accessed_hotel_id?.id && accessibleHotelIds.includes(user.user.last_accessed_hotel_id.id)) {
        setHotelId(user.user.last_accessed_hotel_id.id);
      } else {
        setHotelId(user.user.hotels[0].id);
      }
    } else {
      setHotels([]);
      setHotelId(null);
    }
  }, [navigate, user.user])

  /**
   * Get hotel details from API
   * 
   * @param {*} id  Hotel Id
   */
  const fetchLastAccessedHotelDetails = async (id) => {
    const request = {
      'populate[currency][fields][0]': 'alphabetic_code',
      'populate[city][fields][0]': 'city_name',
      'populate[city][populate][country_id][fields][0]': 'name',
      'populate[is_mandatory_info]': 'true'
    };
    const hotelDetails = await getHotelDetails(id, {params: request});
    if (hotelDetails) {
      setHotel(hotelDetails);
      setIsBookingsOpen(hotelDetails?.is_open_for_layover ? hotelDetails.is_open_for_layover : false);
    }
  };

  useEffect(() => {
    if (hotelId) {
      fetchLastAccessedHotelDetails(hotelId);
    }
  }, [hotelId])

  // Update last accessed hotel id
  const onHotelChange = (hotelId) => {
    updateLastAccessedHotelId(hotelId);
  };

  // Update is open for layover flag on hotel level
  const onChangeLayoverBookingSwitch = async (checked, hotelId) => {
    await updateLayoverBookingFlag(checked, hotelId);
  };

  /**
   * Update last accessed hotel id via udpate self user API
   * 
   * @param {*} hotelId   Hotel id
   */
  const updateLastAccessedHotelId = async (hotelId) => {
    await updateSelfUser({last_accessed_hotel_id: hotelId});
    dispatch(
      setUser({
        ...user,
        user: {
          ...user.user,
          last_accessed_hotel_id: {
            ...user.user?.last_accessed_hotel_id,
            id: hotelId
          }
        }
      })
    );
  };

  /**
   * Update terms_accepted status via udpate self user API
   * 
   * @param {*} 
   */
  const confirmStatus = async () => {
    try {
      await updateSelfUser({terms_accepted: true});
      dispatch(
        setUser({
          ...user,
          user: {
            ...user.user,
            terms_accepted: true
          }
        })
      );
    } catch (e) {}
  };

  /**
   * Update open for layover hotel level flag via udpate hotel API
   * 
   * @param {*} isBookingsOpen  Is bookings open for layover
   * @param {*} hotelId         Hotel id
   */
  const updateLayoverBookingFlag = async (isBookingsOpen, hotelId) => {
    try {
      await updateHotelDetails(hotelId, {
        is_open_for_layover: isBookingsOpen
      });
      // Update last accessed hotel details
      if (hotelId === hotel.id) {
        await fetchLastAccessedHotelDetails(hotelId);
      }
      let userHotels = null;
      if (user.user?.hotels) {
        userHotels = user.user.hotels.map((hotel, i) => (
          hotel.id === hotelId ? {
            ...hotel,
            is_open_for_layover: isBookingsOpen
          } : hotel
        ));
      }
      dispatch(
        setUser({
          ...user,
          user: {
            ...user.user,
            hotels: userHotels
          }
        })
      );
    } catch(e) {
      throw(e)
    }
  };

  return (
    <HotelContext.Provider value={{ hotelId, hotel, fetchLastAccessedHotelDetails, setIsCommonTopbar, setHeaderPageTitle, isFormDirty, setIsFormDirty, 
        dirtyDialogOpen, setDirtyDialogOpen, onclickDirtyFormModalAgree, onclickDirtyFormModalDisAgree, handleDirtyModalAgree, handleDirtyModalDisagree,
        rateChargesNextTabValue, setRateChargesNextTabValue, isSidebarOpened, setIsSidebarOpened, isBookingsOpen, setIsBookingsOpen, onChangeLayoverBookingSwitch }}>
      <TermsConditionContext.Provider value={{ termsDialogOpen, setTermsDialogOpen}}>
        <CommonLayout
          menus={sidebarMenus}
          isCommonTopbar={isCommonTopbar}
          isSidebarOpened={isSidebarOpened}
          pageTitle={headerPageTitle}
          headerLeftAppbar={isCommonTopbar ? '' : (
            <HeaderLeftAppbar hotels={hotels} onHotelChange={onHotelChange} />
          )}
          customSidebar={<CustomSidebar menus={sidebarMenus} />}
          termsConditionDialogue={<TermsConditionDialogue confirmStatus={confirmStatus}/>}
        >
          {hotelId ? children : 'You do not have access to any hotel. Please contact the administrator if you believe this is incorrect.'}
        </CommonLayout>
      </TermsConditionContext.Provider>
    </HotelContext.Provider>
  );
};

export default HotelLayout;
