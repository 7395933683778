export const ColorPalette = {
  background: {
    paper: '#DDF2FF',
    default: '#DAE4E9'
  },
  common: {
    black: '#eeeeee',
    white: '#ffffff'
  },
  menu: {
    main: '#cfdce4', 
    light: '#093B56',
    dark: '#093B56',
    
  },
  alert:{
    main: '#093B56', 
    light: '#DDF2FF',
    dark: '#093B56',
    contrastText: '#bcc2c6'
  },
  time: {
    main: '#eeeeee',
    light: '#DDF2FF',
    dark: '#0288d1',
    contrastText: '#eeeeee' 
  },
  filter: {
    main: '#093B56',
  },
  primary: {
    main: '#093B56',
    light: '#022E46',
    light20: '#022E46',
    dark: '#022E46',
    contrastText: 'rgba(28, 27, 27, .65)',
    light30: 'rgba(136, 196, 232, .3)'
  },
  secondary: {
    main: '#ce93d8',
    light: '#022E46',
    dark: '#ab47bc',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  error: {
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
    contrastText: '#fff'
  },
  warning: {
    main: '#ffa726',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  info: {
    main: '#29b6f6',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  success: {
    main: '#66bb6a',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#093B56',
    600: '#093B56',
    650: '#eeeeee',
    700: '#616161',
    800: '#474b4e',
    850: '#787878',
    900: '#292D32',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#bdbdbd',
    A700: '#616161',
    A900: '#eeeeee'
  },
  red: {
    100: '#e18674',
    300: '#fd9686',
    500: '#e03434',
    800: '#a21005'
  },
  roseGold: {
    100: '#d32f2f',
    200: '#ef6c00'
  },
  contrastThreshold: '3',
  tonalOffset: '0.2',
  text: {
    primary: '#022E46',
    light: '#022E46',
    secondary: '#022E46',
    dark: '#ffffff',
    disabled: '#92A09B',
    icon: 'rgba(255, 255, 255, 0.5)'
  },
  divider: '#393939',
  backdrop: 'rgba(0, 0, 0, .8)',
  dashboardWidgetHeadline: '#022E46',
  sidebarBg: '#022E46',
  sidebarActiveBg: '#1d516d',
  checkoutBoxBorder: '#7e7e7e',
  table: {
    header: '#022E46',
    divider: 'rgba(107, 132, 148, 0.2)'
  },
  icon: {
    light: '#353C40'
  },
  rating: {
    light: '#093B56'
  },
  bg: {
    100: '#e0e0e0',
    200: '#ffffff',
    300: '#1d516d',
    400: '#DDF2FF' 
  },
  orange: {
    300: '#ef6c00',
    500: '#fb5607',
  }
};