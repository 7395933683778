import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import { thunk } from 'redux-thunk';
import { persistStore } from 'redux-persist';

const middleware = [thunk];

/**
 * Create Store
 */
const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware)
  )
);

const persistor = persistStore(store);

export { persistor, store };
