import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './languages/en.json';
import fr from './languages/fr.json';
import es from './languages/es.json';
import pt from './languages/pt.json';
import it from './languages/it.json';
import ch from './languages/ch.json';
import de from './languages/de.json';
import ru from './languages/ru.json';
import jp from './languages/jp.json';


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'fr', 'es', 'pt', 'it', 'ch', 'de', 'ru', 'jp'],
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    resources: {
        en: {
          translation: en
        },
        fr: {
          translation: fr
        },
        es: {
          translation: es
        },
        pt: {
          translation: pt
        },
        it: {
          translation: it
        },
        ch: {
          translation: ch
        },
        de: {
          translation: de
        },
        ru: {
          translation: ru
        },
        jp: {
          translation: jp
        }
      },
    react: {
      useSuspense: false
    },
  });

export default i18n;