import { userActionTypes } from '../types';

// Initial state of the user reducer
const INITIAL_STATE = {
  token: null,
  user: null,
};

/**
 * Set the user reducer
 * 
 * @param {*} state   Current state
 * @param {*} action  Action
 * @returns 
 */
const User = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case userActionTypes.SET_USER: {
      return {
        ...state,
        token: action.token,
        user: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default User;
