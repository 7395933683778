/** 
   *  Function to download CSV file
   * 
   *  @param {string} data CSV string to download
   *  @param {string} fileName set CSV file name
   *  @param {string} fileType set CSV file type
   */ 

const downloadCSVFile = ({ data, fileName, fileType }) => {
  // Create a Blob object with the data and the specified file type
  const blob = new Blob([data], { type: fileType })

  // Create a link element and set its download attribute and click it
  const objectUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName || 'passengerData';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(objectUrl);
}

export default downloadCSVFile;