import { memo } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Dialog, styled } from '@mui/material';
import Input from '../input';
import moment from 'moment';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.background.default
  },
  '& .MuiDialogActions-root .MuiButton-root': {
    ...theme.typography.textLarge,
    color: theme.palette.primary.light,
    textTransform: 'capitalize',
    '&:last-child': {
      color: theme.palette.roseGold[100]
    }
  },
  '& .MuiPickersToolbar-root': {
    '& .MuiTypography-h4': {
      color: theme.palette.alert.dark
    }
  }
}));

const Datepicker = (props) => {
  const { onAccept, inputProps } = props;

  const onAcceptHandler = (val) => {
    if (onAccept) {
      let formattedValue = val;
      if (val?.$d) {
        formattedValue = moment(val.$d).format('yyyy-mm-dd');
      }
      onAccept(formattedValue);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        // defaultValue={dayjs('2022-04-17T15:30')}
        localeText={{
          cancelButtonLabel: 'Cancel',
          okButtonLabel: 'Select',
          toolbarTitle: 'Select Date'
        }}
        reduceAnimations
        format={`yyyy-mm-dd`}
        {...props}
        slots={{
          dialog: StyledDialog,
          textField: Input,
          ...props?.slots
        }}
        slotProps={{
          textField: {
            placeholder: 'yyyy-mm-dd',
            ...inputProps,
            ...props
          },
          ...props?.slotProps
        }}
        onAccept={onAcceptHandler}
      />
    </LocalizationProvider>
  );
};

export default memo(Datepicker);