import { Box, InputAdornment, Typography, useTheme } from '@mui/material';
import { Select } from '../../../../components';
import { Controller, useFormContext } from 'react-hook-form';
import SwapVertIcon from '@mui/icons-material/SwapVert';

const Sorting = (props) => {
  const { options, defaultValue } = props;
  const { control3 } = useFormContext();
  const theme = useTheme();

  return (
    <Box>
      <Controller
        name={`formSorting[sortBy]`}
        control={control3}
        defaultValue={defaultValue ? defaultValue : null}
        render={({ field }) => (
          <Select
            InputProps={{
              startAdornment: (
                <InputAdornment position='end'>
                  <SwapVertIcon sx={{color: theme.palette.grey[500]}} />
                  <Typography
                    sx={{
                      ...theme.typography.textxSmall,
                      color: theme.palette.text.primary
                    }}
                  >Sort by:</Typography>
                </InputAdornment>
              )
            }}
            formControlProps={{
              marginBottom: '0 !important'
            }}
            sx={{
              '& .MuiInputBase-root .MuiSelect-select': {
                lineHeight: '1.37rem'
              }
            }}
            textAlignSelect='left'
            options={options}
            inputRef={field.ref}
            {...field}
            onChange={(e) => {
              field?.onChange(e)
            }}
          />
        )}
      />
    </Box>
  );
};

export default Sorting;