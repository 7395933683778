import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Document, Page, Text, View, StyleSheet, PDFViewer, BlobProvider } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { languages } from '../../../../../helper/constant';
import { HotelContext } from '../../../../../context/hotel';
import { getLayoverDetailsForHotel } from '../../../../../services/layover/details';
import { useLoader } from '../../../../../providers/loader';
import { Box, Divider, styled, useTheme, Grid, Typography } from '@mui/material';
import moment from 'moment';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Document as DocumentView, Page as PageView } from 'react-pdf';
import { isMobile, isTablet } from 'react-device-detect';
import downloadPDF from '../../../../../utils/downloadPDF';
import { ActionButton } from '../../../../../components';

const CustomBox = styled(Box)(({ theme }) =>({
  fontWeight: theme.typography.fontMedium500.fontWeight,
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  marginRight: theme.spacing(1.2),
}));

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
  },
  section: {
    margin: 10,
    padding: 40,
    flexGrow: 1,
  },
  fontSizeForText: { fontSize: 9 },
  header: {
    textAlign: 'center',
    fontWeight: 'black',
    fontSize: 17,
    marginBottom: 8
  },
  screenLeft: { float: 'left' },
  screenRight: {
    position: 'absolute',
    right: 40
  },
  right_6: { top: 117},
  centerTextBet: {
    position: 'absolute',
    left: '51%',
    top: 66
  },
  centerTextAnd: {
    top: 76,
    left: '52.5%',
  },
  paragraph: {
    lineHeight: 1.3,
    wordSpacing: 1
  },
  heading: {
    fontSize: 12,
    fontWeight: 'heavy',
    margin: 8,
    marginLeft: 0
  },
  table: {
    display: 'table',
    borderStyle: 'solid',
    marginBottom: 15,
    borderWidth: 1,
  },
  tableRow: { flexDirection: 'row' },
  tableHeader: {
    fontWeight: 'bold',
    fontSize: 10,
    borderWidth: 1,
    padding: 3,
    flex: 1,
    margin: -0.5,
    textAlign: 'center'
  },
  tableCell: {
    margin: -0.5,
    padding: 5,
    flex: 1,
    borderWidth: 1,
    fontSize: 10,
    textAlign: 'center'
  },
  hr: {
    borderBottomColor: 'black',
    borderBottomWidth: 1,
    marginVertical: 10,
  },
});

const CreateLayoverDetailsPDF = () => {
  const { hotel } = useContext(HotelContext);
  const theme = useTheme();
  const { layoverId } = useParams();
  const { startLoader, stopLoader } = useLoader();
  const [layoverDetails, setLayoverDetails] = useState([]);
  const [transportations, setTransportations] = useState([]);
  const [passengers, setPassengers] = useState([]);
  const [numPages, setNumPages] = useState(null);

  /**
   * Get layover details
   * 
   * @param {*} layoverId   Layover id
   * @param {*} hotelId     hotel id
   * @param {*} request     Request
   * @returns 
  */
  const getLayoverFromApi = useCallback(async (layoverId, hotelId) => {
    try {
      const response = await getLayoverDetailsForHotel(layoverId, hotelId);
      return response;
    } catch (e) {
      throw e;
    }
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // download invoice
  const handleDownloadInvoice = async (pdfUrl) => {
    await downloadPDF(pdfUrl);
  };

  useEffect(() => {
    if (hotel && layoverId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getLayoverDetailsForHotel(layoverId, hotel.id);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      })
      .then((response) => {
        setLayoverDetails(response);
        setPassengers(response.bookingInfo.passengerList);
        setTransportations([
          {
            route: 'airportToHotel',
            date: response.bookingInfo?.transportationInfo?.airportToHotel?.date,
            pickup: response.bookingInfo?.transportationInfo?.airportToHotel?.pickup,
            drop: response.bookingInfo?.transportationInfo?.airportToHotel?.drop,
            terminal: response.bookingInfo?.transportationInfo?.airportToHotel?.terminal
          },
          {
            route: 'hotelToAirport',
            date: response.bookingInfo?.transportationInfo?.hotelToAirport?.date,
            pickup: response.bookingInfo?.transportationInfo?.hotelToAirport?.pickup,
            drop: response.bookingInfo?.transportationInfo?.hotelToAirport?.drop,
            terminal: response.bookingInfo?.transportationInfo?.hotelToAirport?.terminal
          }
        ]);
      }).catch((e) => {
        // Handle error if necessary
      }).finally(() => {
        stopLoader();
      });
    }
  }, [hotel, layoverId, getLayoverFromApi, startLoader, stopLoader]);

  return (
    <>
    <Box sx={{my: 2}}>
      {!(isMobile || isTablet) ? (
        <PDFViewer fileName='layoverDetails.pdf' style={{ width: '100%', height: '800px' }}>
          <CreateLayoverDetailsDocument hotel={hotel} transportations={transportations} layoverDetails={layoverDetails} passengers={passengers}/>
        </PDFViewer>
      ):(
        <BlobProvider 
          document={<CreateLayoverDetailsDocument hotel={hotel} transportations={transportations} layoverDetails={layoverDetails} passengers={passengers}/>} 
          fileName='layoverDetails.pdf'
        >
          {({ url, loading }) =>
            loading ? 'loading...' : (
              <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', px: 1}}>
                <Grid sx={{ overflow: 'hidden' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      mb: 1
                    }}
                  >
                    <Typography sx={{
                      ...theme.typography.textxLarge, 
                      color: theme.palette.text.light, 
                      textTransform: 'capitalize'
                    }}>
                      Layover Details PDF 
                    </Typography>
                    <ActionButton
                      startIcon={<FileDownloadIcon sx={{ color: theme.palette.roseGold[100] }} />}
                      onClick={()=>handleDownloadInvoice(url)}
                      sx={{
                        ...theme.typography.textLarge
                      }}
                    >
                      Download PDF
                    </ActionButton>
                  </Box>
                </Grid>
                <DocumentView file={url} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.from(new Array(numPages), (el, index) => (
                    <PageView
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      scale={0.75}
                      devicePixelRatio={1}
                    />
                  ))}
                </DocumentView>
              </Box>
            )
          }
        </BlobProvider>
      )}
    </Box>
    </>
  );
};

export default CreateLayoverDetailsPDF;

const CreateLayoverDetailsDocument = ({ transportations, layoverDetails, passengers})=>{

  // display Langauges in Full form using Language code
  const getLanguageByCode = (langCode) => {
    if (langCode) {
      const langCodeLowerCase = langCode?.toLowerCase();
      return (languages?.[langCodeLowerCase]?.name) ? languages[langCodeLowerCase].name : langCode;
    }
  }

  return(
    <Document title={`Layover Details - ${layoverDetails?.layoverCode}`}>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.header}>Layover Details</Text>
          <Text style={{ fontSize: 9, textAlign: 'center', marginBottom: 20 }}>{`Layover Code - ${layoverDetails?.layoverCode}`}</Text>

          <View style={styles.heading}>
            <Text>1. Airline Booking Info</Text>
          </View>
          <View style={styles.fontSizeForText}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Airline Name</Text>
                <span style={styles.tableHeader}>
                  <Text>Airline</Text>
                  <Text>Representative</Text>
                </span>
                <span style={styles.tableHeader}>
                  <Text>Booking Date</Text>
                  <Text>& Time</Text>
                </span>
                <Text style={styles.tableHeader}>Estimated arrival in the hotel</Text>
                <Text style={styles.tableHeader}>Latest arrival at the airport</Text>
                <Text style={styles.tableHeader}>Paid By</Text>
              </View>

              <View style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {`${layoverDetails?.bookingInfo?.airlineName} ${layoverDetails?.bookingInfo?.flightCode} ${layoverDetails?.bookingInfo?.toAirportCodeIata}`}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText, { textTransform: 'capitalize' }]}>
                  {`${layoverDetails?.bookingInfo?.airlineRepresentativeFirstName} ${layoverDetails?.bookingInfo?.airlineRepresentativeLastName} ${layoverDetails?.bookingInfo?.contactNumber ? layoverDetails?.bookingInfo?.contactNumber : ''}`}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {moment(layoverDetails?.bookingInfo?.bookingDate).format('DD.MM.YYYY')}, {moment(layoverDetails?.bookingInfo?.bookingTime, 'HH:mm:ss.SSS').format('hh:mm A')}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.estimatedArrivalAtHotel}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.latestReturnToAirport}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.paidBy}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.heading}>
            <Text>2. Transportation Info</Text>
          </View>
          <View style={styles.fontSizeForText}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Route</Text>
                <Text style={styles.tableHeader}>Pickup</Text>
                <Text style={styles.tableHeader}>Drop</Text>
              </View>

              {transportations && transportations.map((transportation, i) => 
                (<View style={styles.tableRow} key={i}>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>
                    {`${transportation?.route === 'airportToHotel' ? 'From Airport to Hotel' : 'From Hotel to Airport'} on ${layoverDetails?.bookingInfo?.transportationInfo?.airportToHotel?.date}`}
                  </Text>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>
                    {`${transportation?.pickup}${transportation?.route === 'airportToHotel' ? `, ${transportation?.terminal}` : `, Hotel`}`}
                  </Text>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>
                    {`${transportation?.drop}${transportation?.route === 'airportToHotel' ? `, Hotel` : `, ${transportation?.terminal}`}`}
                  </Text>
                </View>)
              )}
            </View>
          </View>

          <View style={styles.heading}>
            <Text>3. Passenger Details</Text>
          </View>
          <View style={styles.fontSizeForText}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Total Passengers</Text>
                <Text style={styles.tableHeader}>Registered Passengers</Text>
                <Text style={styles.tableHeader}>Rooms Booked</Text>
              </View>

              <View style={styles.tableRow}>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.passengerDetails?.totalPassengers}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.passengerDetails?.registeredPassengers}
                </Text>
                <Text style={[styles.tableCell, styles.fontSizeForText]}>
                  {layoverDetails?.bookingInfo?.passengerDetails?.bookedRooms}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.heading}>
            <Text>4. Passenger Listing</Text>
          </View>
          <View style={styles.fontSizeForText}>
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <Text style={styles.tableHeader}>Passengers Info</Text>
                <Text style={styles.tableHeader}>Room Type</Text>
                <Text style={styles.tableHeader}>Preferred Language</Text>
                <Text style={styles.tableHeader}>Check-In & Check-Out Info</Text>
                <Text style={styles.tableHeader}>Booking Code</Text>
              </View>

              {(passengers && passengers.length > 0) ? passengers.map((passenger, i) => 
                (<View style={styles.tableRow} key={i}>
                  <span style={[styles.tableCell]}>
                    <Text style={{fontWeight: 'bold', fontSize: 10, textAlign:'left', marginBottom: 3 }}>
                      {passenger?.leadPassengerFirstName && passenger?.leadPassengerLastName ? `${passenger?.leadPassengerFirstName} ${passenger?.leadPassengerLastName}.` : ''}
                    </Text>
                    <br />
                    {passenger?.coPassengers?.map((coPassenger, inde) => (
                      <>
                        <Text style={[styles.fontSizeForText, {marginLeft: 5, textAlign:'left'}]} key={inde}>
                          {coPassenger?.first_name && coPassenger?.last_name ? `${inde+1}. ${coPassenger?.first_name} ${coPassenger?.last_name}` : ''}
                        </Text>
                        <br />
                      </>
                    ))}
                  </span>
                  <span style={[styles.tableCell]}>
                    {passenger?.roomsinfo?.length > 0 
                      ? (passenger?.roomsinfo?.map((room, index) => (
                          <>
                            <Text style={[styles.fontSizeForText]} key={index}>
                              {`${room.bookedRoom} x ${room.name}`}{index < passenger?.roomsinfo?.length - 1 && ', '}
                            </Text>
                            <br />
                          </>
                        )))
                      : (<Text style={[styles.fontSizeForText]}>-</Text>)}
                  </span>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>
                    {passenger?.preferredLanguageCode ? getLanguageByCode(passenger?.preferredLanguageCode) : '-'}
                  </Text>
                  <span style={[styles.tableCell, styles.fontSizeForText]}>
                    {passenger?.checkInTime && passenger?.checkOutTime && (
                      <>
                        <Text style={[styles.fontSizeForText]}>{passenger?.checkInTime}</Text>
                        <br />
                        <Text style={[styles.fontSizeForText]}>to</Text>
                        <br />
                        <Text style={[styles.fontSizeForText]}>{passenger?.checkOutTime}</Text>
                      </>
                    )}
                  </span>
                  <Text style={[styles.tableCell, styles.fontSizeForText]}>
                    {passenger?.confirmationCode ? passenger?.confirmationCode : '-'}
                  </Text>
                </View>)
              ) : (
                <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.fontSizeForText, {paddingTop: 30, paddingBottom: 30}]}>
                    No passengers found!
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}