import { memo, useEffect, useState } from 'react';
import { FormControl, ToggleButtonGroup as ToggleBtnGroup, ToggleButton, Typography, styled } from '@mui/material';

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const ToggleButtonGroup = (props) => {
  const { options, formControlProps, toggleButtonProps, isError, errorMessage } = props;
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  useEffect(() => {
    if (isError) { setValidationErrorMessage(errorMessage ? errorMessage : '') }
  }, [isError, errorMessage]);

  return (options ? (
    <FormControl variant='standard' {...formControlProps}>
      <ToggleBtnGroup
        {...props}
      >
        {options?.map((option, index) => (
          <ToggleButton
            key={`${option?.name}_${index}`}
            value={option?.key}
            aria-label={option?.name}
            {...toggleButtonProps}
          >
            {option?.name}
          </ToggleButton>
        ))}
      </ToggleBtnGroup>
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControl>
  ) : (''));
};

export default memo(ToggleButtonGroup);