const Typography = {
  h1: {
    fontSize: '64px',
    lineHeight: '70px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  h2: {
    fontSize: '48px',
    lineHeight: '54px',
    fontWeight: '500',
    fontFamily: 'SemplicitaPro',
  },
  h3: {
    fontSize: '38px',
    lineHeight: '42px',
    fontWeight: '500',
    fontFamily: 'SemplicitaPro',
  },
  h4: {
    fontSize: '30px',
    lineHeight: '36px',
    fontWeight: '600',
    fontFamily: 'SemplicitaPro',
  },
  h5: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  h6: {
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  subtitle1: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  subtitle2: {
    fontSize: '16px',
    lineHeight: '18px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  body1: {
    fontSize: '15px',
    lineHeight: '16.35px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  body2: {
    fontSize: '14px',
    lineHeight: '15.5px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  textSmall: {
    fontSize: '14px',
    lineHeight: '16.35px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  textxSmall: {
    fontSize: '13px',
    lineHeight: '14.17px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  textxxSmall: {
    fontSize: '12px',
    lineHeight: '14.17px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  textMedium: {
    fontSize: '15px',
    lineHeight: '16.35px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro',
  },
  textLarge: {
    fontSize: '16px',
    lineHeight: '16.35px',
    fontWeight: '600',
    fontFamily: 'SemplicitaProMedium',
  },
  textxLarge: {
    fontSize: '17px',
    lineHeight: '18.53px',
    fontWeight: '600',
    fontFamily: 'SemplicitaProMedium',
  },
  textxxLarge: {
    fontSize: '18px',
    lineHeight: '18.53px',
    fontWeight: '600',
    fontFamily: 'SemplicitaProMedium',
  },
  muiTypography400: {
    fontWeight: '400',
    fontFamily: 'SemplicitaPro'
  },
  muiTypography500: {
    fontWeight: '500',
    fontFamily: 'SemplicitaPro'
  },
  muiTypography600: {
    fontWeight: '600',
    fontFamily: 'SemplicitaPro'
  },
  dashboardHeadline: {
    fontSize: '22px',
    lineHeight: '24px',
    fontWeight: '500',
    fontFamily: 'SemplicitaProSemiBold',
  },
  dashboardSmall: {
    fontFamily: 'SemplicitaPro',
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '18px',
  },
  dashboardMedium: {
    fontFamily: 'SemplicitaPro',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
  },
  inputLabel: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '600',
    fontFamily: 'SemplicitaPro',
  },
  inputText: {
    fontSize: '15px',
    lineHeight: '16.35px',
    fontWeight: '600',
    fontFamily: 'SemplicitaPro',
  },
  btnText: {
    fontSize: '15px',
    lineHeight: '16.35px',
    fontWeight: '500',
    fontFamily: 'SemplicitaPro',
  },
  inlineErrorMesage: {
    fontSize: '15px',
    lineHeight: '16.35px',
    fontWeight: '600',
    fontFamily: 'SemplicitaPro',
  },
  sidebarMenu: {
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: '400',
    fontFamily: 'SemplicitaPro'
  },
  fontMedium500: {
    fontWeight: '500',
    fontFamily: 'SemplicitaProMedium'
  },
  fontMedium600: {
    fontWeight: '600',
    fontFamily: 'SemplicitaProMedium'
  },
  fontSemiBold500: {
    fontWeight: '500',
    fontFamily: 'SemplicitaProSemiBold'
  },
  fontSemiBold600: {
    fontWeight: '600',
    fontFamily: 'SemplicitaProSemiBold'
  },
  fontProBold500: {
    fontWeight: '500',
    fontFamily: 'SemplicitaProBold'
  },
  fontProBold600: {
    fontWeight: '600',
    fontFamily: 'SemplicitaProBold'
  }
};

export default Typography;