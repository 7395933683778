import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Grid } from '@mui/material'
import { Button, Input, CheckBox } from '../../components'
import { useNavigate, useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import ScanIcon from '../../assets/icons/theme/dark/scan.svg'
import { useTranslation } from 'react-i18next';
import { getSuffix } from '../../utils'

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
}))

const LinkedTo = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  marginBottom: theme.spacing(2.5),
  display: 'flex',
}))

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.text.dark,
  border: `1px solid ${theme.palette.divider}`,
}))

const StyledLightButton = styled(Button, {
  shouldForwardProp: (prop) => ['disabled'].indexOf(prop) === -1,
})(({ theme, disabled }) => ({
  border: `1px solid ${theme.palette.primary.light}`,
  color: theme.mode === 'light' ? theme.palette.primary.light : theme.palette.common.white,
  textTransform: 'none',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1.3),
  width: '100%',
  ...(disabled && {
    pointerEvents: 'none',
    border: `2px solid ${theme.mode === 'light' ? theme.palette.grey[700] : theme.palette.primary.dark}`,
    color:theme.palette.grey[700],
  }),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  color: 'white',
  textTransform: 'none',
  marginBottom: theme.spacing(1.3),
}))

const Para = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1.3),
  alignItems: 'flex-start',
  textAlign: 'left',
}))

function RoomSelection() {
  const [rooms, setRooms] = useState([
    {
      id: 1,
      passengers: [{ firstName: '', lastName: '' }],
      roomfilled: false,
    },
  ])
  const [addPassengerDisabled, setAddPassengerDisabled] = useState(true)
  const [addRoomDisabled, setAddRoomDisabled] = useState(true)
  const [coPassengerData, setCoPassengerData] = useState({})
  const [neededRoomChecked, setNeededRoomChecked] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation()
  let { iata, code } = useParams()
  const navigate = useNavigate()

  const handleAddPassenger = () => {
    const currentRoom = rooms[rooms.length - 1]
    if (currentRoom.passengers.length < 2 && coPassengerData?.noOfCoPassenger !== 0) {
      // If the current room has less than 2 passengers, add another passenger
      const newPassenger = { firstName: '', lastName: '' }
      const updatedRoom = {
        ...currentRoom,
        passengers: [...currentRoom.passengers, newPassenger],
      }
      const updatedRooms = [...rooms.slice(0, -1), updatedRoom]
      setRooms(updatedRooms)
      const totalPassengersfiiled = updatedRooms.reduce((total, room) => total + room.passengers.length, 0);
      if (coPassengerData?.noOfCoPassenger < totalPassengersfiiled){
        setAddPassengerDisabled(true);
      }
      setAddRoomDisabled(true);
    }
  }

  const handleRoomClick = () => {
    const clonedRooms = [...rooms]
    const currentRoom = clonedRooms[clonedRooms.length - 1]

    if (currentRoom.passengers.length < coPassengerData?.noOfCoPassenger + 1 && coPassengerData?.noOfRooms > 1) {
      currentRoom.roomfilled = true
      const nextRoomId = currentRoom.id + 1
      const newRoom = {
        id: nextRoomId,
        passengers: [{ firstName: '', lastName: '' }],
        roomfilled: false,
      }
      setRooms([...clonedRooms, newRoom])
      setAddRoomDisabled(true)
      setAddPassengerDisabled(true)
    }
  }
  
  const handlePassengerFirstNameChange = (e, roomId, passengerIndex) => {
    const newRooms = [...rooms]
    newRooms[roomId - 1].passengers[passengerIndex].firstName = e.target.value
    setRooms(newRooms)
  }

  const handlePassengerLastNameChange = (e, roomId, passengerIndex) => {
    const newRooms = [...rooms]
    newRooms[roomId - 1].passengers[passengerIndex].lastName = e.target.value
    setRooms(newRooms)
  }

  const handleRemovePassenger = (roomId, passengerIndex) => {
    const newRooms = [...rooms]
    newRooms[roomId - 1].passengers.splice(passengerIndex, 1)
    setRooms(newRooms)
    if (addPassengerDisabled){ setAddPassengerDisabled(false) }
  }

  const isContinueButtonActive = rooms?.every((room) =>
    room?.passengers?.every(
      (passenger) => passenger?.firstName?.trim() !== '' && passenger?.lastName?.trim() !== ''
    )
  )

  const totalPassengerCount = rooms?.reduce(
    (total, room) => total + room.passengers.length,
    0
  )

  const handleContinueButton = () => {
    setErrorMessage(false)
    let totalPassengersAdded = rooms?.reduce((total, item) => total + item.passengers.length, 0)
    if(totalPassengersAdded !== (coPassengerData?.noOfCoPassenger + 1)) {
      setErrorMessage(true)
      return;
    }

    // The condition checks if the current room has less than 2 passengers
    // Only if the condition is met, a new room is created
    const currentRoom = rooms[rooms?.length - 1]
    if (currentRoom.passengers.length < 2) {
      currentRoom.roomfilled = false
      const nextRoomId = currentRoom.id + 1
      const newRoom = {
        id: nextRoomId,
        passengers: [{ firstName: '', lastName: '' }],
        roomfilled: false,
      }
      setRooms([...rooms, newRoom])
      setAddRoomDisabled(false)
    } else{
      currentRoom.roomfilled = true
    }
    Cookies.set('passengerRoomsDetails', JSON.stringify(rooms), { expires: 2 });
    navigate(`/passenger/${iata}/${code}/hotels`)
  }

  function onRoomChange(roomId) {
    const updatedRooms = rooms.map(room => {
      if (room.id === roomId) {
        return { ...room, roomfilled: false }
      }
      return room;
    }).filter(room => room.id <= roomId)
    setRooms(updatedRooms)
  }

  useEffect(() => {
    const totalPassengersfiiled = rooms.reduce((total, room) => total + room.passengers.length, 0);
    if (isContinueButtonActive && coPassengerData.noOfRooms >= 1 && coPassengerData.noOfRooms >= rooms.length && !(coPassengerData?.noOfCoPassenger < totalPassengersfiiled)) {
      setAddRoomDisabled(false);
      // bug fixed if double rooms are already booked then User should not be able to book double room 
      const checkIfDoubleRoomBooked = (rooms.filter((room) => room.passengers.length === 2).length) !== ((coPassengerData?.noOfCoPassenger + 1) - (coPassengerData?.noOfRooms));
      if ((coPassengerData?.noOfCoPassenger + 1 !== coPassengerData.noOfRooms) && (checkIfDoubleRoomBooked)) {
        setAddPassengerDisabled(false);
      }
    }
    const currentRoom = rooms[rooms.length - 1]
    if (currentRoom.passengers.length === 2) {
      setAddPassengerDisabled(true)
    }
    if (coPassengerData.noOfRooms <=  rooms.length) {
      setAddRoomDisabled(true)
    }
  }, [isContinueButtonActive, rooms, coPassengerData])

  useEffect(() => {
    const passengerInfoDetails = JSON.parse(Cookies.get('passengerInfo') || '{}');
    const { noOfCoPassenger, noOfRooms, lead_passenger_first_name, lead_passenger_last_name } = passengerInfoDetails;
    const savedPassengerRoomsDetails = Cookies.get('passengerRoomsDetails');
    if(!savedPassengerRoomsDetails){
      setRooms([
        {
          id: 1,
          passengers: [{ firstName: lead_passenger_first_name, lastName: lead_passenger_last_name }],
          roomfilled: false,
        },
      ])
    }
    setCoPassengerData({
      noOfCoPassenger: noOfCoPassenger ?? 0,
      noOfRooms: noOfRooms ?? 1
    });
    if (noOfCoPassenger === 0) {setAddPassengerDisabled(true)}
    if (savedPassengerRoomsDetails) {
      const parsedRooms = JSON.parse(savedPassengerRoomsDetails);
      const totalPassengersfiiled = parsedRooms.reduce((total, room) => total + room.passengers.length, 0);
      if(noOfRooms < parsedRooms.length){
        const tempRooms = parsedRooms.slice(0, noOfRooms)
        tempRooms[tempRooms.length - 1].roomfilled = false
        setRooms(tempRooms);
      }else if(totalPassengersfiiled > noOfCoPassenger + 1){
        setRooms([
          {
            id: 1,
            passengers: [{ firstName: lead_passenger_first_name, lastName: lead_passenger_last_name }],
            roomfilled: false,
          },
        ])
      }else{
        setRooms(parsedRooms);
      }
    }
    if(noOfRooms ?? 1 > noOfCoPassenger){
      setNeededRoomChecked(true)
    }
  }, []);
  
  return (
    <Container>
      <Grid sx={{ml: -2}}>
      <LinkedTo onClick={() => navigate(`/passenger/${iata}/${code}/passenger-information`)}>
          <ChevronLeftIcon sx={{color:theme.mode === 'light' ? theme.palette.primary.main : 'inherit',}}/>
          <Typography
            sx={{
              ...theme.typography.dashboardHeadline,
              fontSize: theme.typography.h6,
              textTransform: 'none',
              color:theme.mode === 'light' ? theme.palette.primary.main : 'inherit',
            }}
            variant='caption'
          >
            {t('passengerInfo')}
          </Typography>
        </LinkedTo>
      </Grid>

      {rooms.map((room, nextRoomId) =>
        room.roomfilled ? (
          <Grid container key={nextRoomId}>
            <Box
              sx={{
                pb: theme.spacing(1),
                cursor: 'pointer', 
                display: 'flex', 
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
              }}
              onClick={()=>{onRoomChange(room.id)}}
            >
              <Typography
                sx={{
                  ...theme.typography.textxLarge,
                  color: theme.palette.primary.light,
                  whiteSpace: 'nowrap'
                }}
              >
                {t('room') + ' ' + (nextRoomId + 1)}
              </Typography>
              <Typography sx={{ ...theme.typography.textSmall, ...theme.typography.fontMedium600, lineHeight: theme.lineHeight[1.25], color: theme.palette.grey[700], textTransform: 'uppercase'}}>
                {t('passenger')}
              </Typography>
              <Typography sx={{ fontSize: theme.typography.body2}}>
              {room.passengers.map((passenger) => `${passenger.firstName} ${passenger.lastName}`).join(', ')}
              </Typography>
            </Box>
            <Divider width={'100%'} sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.light, mb: theme.spacing(2) }}/>
          </Grid>
        ) : (
          <Box key={nextRoomId} 
            sx={{
              display: 'flex',
              flexDirection: 'column'
              }}>
            <Para
              sx={{
                ...theme.typography.textxLarge,
                color: theme.palette.primary.light,
              }}
            >
              {t('room') + ' ' + (nextRoomId + 1)}
            </Para>
            <Divider sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.light, mb: theme.spacing(2) }}/>
            {room.passengers.map((passenger, index) => (
              <Box key={index} sx={{ ml: theme.spacing(1.5) }}>
                <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  sx={{mt: index > 0 ? theme.spacing(2) : theme.spacing(1), mb: theme.spacing(2)}}
                >
                  <Grid item xs={8}>
                    <Typography
                      sx={{
                        ...theme.typography.textSmall,
                        ...theme.typography.fontMedium600,
                        textTransform: 'uppercase',
                      }}
                      variant='caption'
                    >
                      {t('passenger')} {rooms.length === 1 && index === 0 ? `1 (${t('yourself')})` : rooms?.slice(0, -1).reduce((total, room)=>(total + room?.passengers?.length),0) + index + 1}
                    </Typography>
                  </Grid>
                  <Grid container item xs={4} justifyContent='flex-end' direction='row'>
                    <Grid item>
                      {index > 0 ? (
                        <Button
                          sx={{
                            ...theme.typography.textSmall,
                            textTransform: 'capitalize',
                            color: theme.palette.roseGold[100],
                          }}
                          onClick={() => handleRemovePassenger(room.id, index)}
                        >
                          {t('remove')}
                        </Button>
                      ):(null)}
                    </Grid>
                  </Grid>
                </Grid>
                {/* <StyledButton variant='outlined' startIcon={<img src={ScanIcon} alt='scan icon'/>}>
                  <Typography
                    sx={{
                      ...theme.typography.textxSmall,
                      p: theme.spacing(1)
                    }}>
                    {t('scanningPass')}
                  </Typography>
                </StyledButton> */} 
                <Para>{rooms?.length ===1 && index === 0 ? t('yourFirstName') : t('firstName')}</Para>
                <StyledInput
                  sx={{
                    '& .MuiInputBase-input': {
                      ...theme.typography.textSmall,
                    },
                    border: `1px solid ${theme.palette.grey[800]}`,
                  }}
                  variant='outlined'
                  value={passenger.firstName}
                  onChange={(e) =>
                    handlePassengerFirstNameChange(e, room.id, index)
                  }
                  fullWidth
                />
                <Para style={{ marginTop: theme.spacing(1.3) }}>
                  {rooms?.length ===1 && index === 0 ? t('yourLastName') : t('lastName')}
                </Para>
                <StyledInput
                  sx={{
                    '& .MuiInputBase-input': {
                      ...theme.typography.textSmall,
                    },
                    border: `1px solid ${theme.palette.grey[800]}`,
                  }}
                  variant='outlined'
                  value={passenger.lastName}
                  onChange={(e) =>
                    handlePassengerLastNameChange(e, room.id, index)
                  }
                  fullWidth
                />
              </Box>
            ))}
          </Box>
        )
      )}
      <Divider sx={{ borderBottomWidth: 2, borderColor: theme.palette.primary.light, my: theme.spacing(2)}}/>
      <Typography sx={{textAlign: 'center', mb: theme.spacing(2), opacity: isContinueButtonActive ? 1 : 0.4}}>
        {coPassengerData?.noOfCoPassenger + 1 !== rooms?.reduce((total, room)=>(total + room?.passengers?.length),0) ? 
        t('addPassNo', {count: (rooms?.reduce((total, room)=>(total + room?.passengers?.length),0) + 1)}) : null
        }
      </Typography>
      <Grid
          container
          spacing={3}
          direction='row'
          justifyContent='space-between'
          alignItems='center'>
        <Grid item xs={6} >  
          <StyledLightButton disabled={addPassengerDisabled} onClick={handleAddPassenger}>
            {t('room')} {rooms.length === 1 ? 1 : rooms.length}
          </StyledLightButton>
        </Grid>
        <Grid item xs={6}>
          {coPassengerData?.noOfRooms > rooms.length ? (
            <StyledLightButton disabled={addRoomDisabled} onClick={handleRoomClick}>
              {rooms.length > 1 ? `${t('room')} ${rooms.length + 1}` : t('diffRoom')}
            </StyledLightButton>
          ) : (null)}
        </Grid>
      </Grid>
      {!(coPassengerData?.noOfRooms > coPassengerData?.noOfCoPassenger) ? (
        <CheckBox
          sx={{ ml: 1, mt: 1 }}
          checked={neededRoomChecked}
          onChange={(e) => {setNeededRoomChecked(e.target.checked)}}
          label={
            <Typography sx={{ml: 2, mt: 1}}>
              {t('needRoomsAccept', {noOfRooms: coPassengerData.noOfRooms, noOfCoPassenger: (coPassengerData.noOfCoPassenger + 1)})}
            </Typography>
          }
        />) : (null)}
        {errorMessage ? (
          <Typography sx={{ fontSize: theme.typography.body2, mt: 1, textAlign: 'start', color: theme.palette.red[100], lineHeight: theme.lineHeight[1] }}>
            {t('addPassError', {noOfRooms: getSuffix(coPassengerData.noOfRooms), noOfCoPassenger: getSuffix(coPassengerData.noOfCoPassenger + 1)})}
          </Typography>
        ):(null)}
      {isContinueButtonActive && totalPassengerCount <= 6 ? (
        <Button
          variant='contained'
          width='100%'
          disabled={!neededRoomChecked}
          sx={{
            mt: 2,
            '&.MuiButton-contained': {
              ...theme.typography.muiTypography400
            }
          }}
          onClick={handleContinueButton}
        >
          {t('continue')}
        </Button>
      ): (null)}
    </Container>
  )
}

export default RoomSelection