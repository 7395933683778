import { Typography, styled } from '@mui/material';

const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge,
  ...theme.typography.fontMedium500,
  color: theme.palette.alert.dark,
  display: 'flex',
  alignItems: 'center'
}));

export const HeadingInput = (props) => {
  const { title, startIcon, endIcon } = props;

  return (
    <StyledTypographyHeading {...props} >
      {startIcon ? startIcon : ''}
      {title}
      {endIcon ? endIcon : ''}
    </StyledTypographyHeading>
  );
};