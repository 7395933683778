import { memo, useCallback, useEffect, useState } from 'react';
import { CheckBox, FormDialog } from '../../../components';
import { Grid, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { weekdays } from '../../../helper/constant';

const TransportationAddSchedule = (props) => {
  const { source, airportId, terminalId, open, onAgree, onDisagree } = props;
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    control,
    handleSubmit,
    reset
  } = useForm();

  useEffect(() => {
    setDialogOpen(open)
    if (open) { reset() }
  }, [open, reset]);

  // Set weekdays on form submit
  const onSubmit = useCallback(async (data) => {
    if (data) {
      if (data.formWeekdays) {
        let tempWeekdays = [];
        for (const dayCode in weekdays) {
          if (data.formWeekdays?.[dayCode]) {
            tempWeekdays.push(dayCode)
          }
        }
        if (onAgree) {
          onAgree(source, airportId, terminalId, tempWeekdays)
        }
      }
    }
  }, [onAgree, source, airportId, terminalId]);

  const onDialogAgree = () => {
    setDialogOpen(false);
    handleSubmit(onSubmit)()
  };

  const onDialogDisagree = () => {
    setDialogOpen(false);
    if (onDisagree) { onDisagree() }
  };

  return (
    <FormDialog
      title='Add Schedule'
      open={dialogOpen}
      onAgree={onDialogAgree}
      onDisagree={onDialogDisagree}
      btnText={`Create Schedule`}
      fullWidth={true}
      maxWidth={'xs'}
      cancelBtnInHeader
    >
      <Typography
        variant='body1'
        sx={{
          ...theme.typography.textSmall,
          my: 3,
          color:theme.palette.menu.dark
        }}
      >
        Select days you want to include into the schedule
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          {Object.keys(weekdays).map((dayCode, i) => {
            const dayData = weekdays[dayCode];
            return (
              <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                <Controller
                  name={`formWeekdays[${dayCode}]`}
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <CheckBox
                      inputRef={field.ref}
                      {...field}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                      }}
                      setFocus
                      label={
                        <Typography variant='caption' sx={{
                          ...theme.typography.textLarge,
                          ml: 1,
                          color:theme.palette.menu.dark
                        }}>
                          {dayData.name}
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            );
          })}
        </Grid>
      </form>
    </FormDialog>
  );
};

export default memo(TransportationAddSchedule);