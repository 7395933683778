import { api } from '..';
import { serialize } from '../../utils';
import { handleApiError } from '../handleApiError';

/**
 * Get hotel room fixed rates
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const getHotelRoomFixedRates = async(hotelId, request = {}) => {
  try {
    if (!request?.params) {
      request.params = {};
    }
    request.params['filters[hotel]'] = hotelId;
    const response = await api.get('/hotel-room-fixed-rates', request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e.response.status !== 404 && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Create hotel room fixed rates
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const createHotelRoomFixedRates = async(hotelId, roomId, request = {}, qs = {}) => {
  try {
    if (!request) {
      request = {};
    }
    request.hotel = hotelId;
    request.hotel_room_type = roomId;
    const response = await api.post(`/hotel-room-fixed-rates?${serialize(qs)}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};

/**
 * Update hotel room fixed rates
 * 
 * @param {*} hotelId   Hotel id
 * @param {*} request   Request
 * @returns 
 */
export const updateHotelRoomFixedRates = async(id, request = {}, qs = {}) => {
  try {
    const response = await api.put(`/hotel-room-fixed-rates/${id}?${serialize(qs)}`, request);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      const message = e?.response?.data?.error?.message ? e.response.data.error.message : e.message;
      handleApiError(e.response.status, message)
    }
    throw e
  }
};