import { useState, useEffect } from 'react';
import { Box, Stack, Divider, Button, IconButton, Typography, styled, useTheme } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Logo from '../../../assets/images/logo.png';
import outlinedMenu from '../../../assets/icons/theme/dark/category-empty.svg'
import filledMenu from '../../../assets/icons/theme/dark/category.svg'
import NotificationsIcon from '@mui/icons-material/Notifications';
import EastIcon from '@mui/icons-material/East';
import { logout } from '../../../services/auth/logout';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMenus, getPathFromURL } from '../../../utils';

const TopBar = styled(Box)(({ theme }) => ({
  borderRadius: '3px',
}));

const StyledMenuDivider = styled(Box)(({ theme }) => ({
  background: theme.mode === 'light'? `${theme.palette.menu.main}22` : `${theme.palette.primary.light}22`, 
  height: theme.spacing(0.7), 
  width: '100%',
  marginBottom: `${theme.spacing(1)} !important`
}));

const StyledMenu = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle1, 
  textTransform: 'capitalize', 
  color: theme.mode === 'light'? theme.palette.menu.main : theme.palette.text.primary,
  justifyContent: 'space-between',
  paddingRight: theme.spacing(3),
}));

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => ['active'].indexOf(prop) === -1
})(({ theme, active }) => ({
  ...theme.typography.textMedium,
  borderRadius: theme.spacing(1),
  borderColor: theme.palette.primary.light,
  backgroundColor: active ? theme.palette.primary.light : 'transparent',
  color: active ? theme.palette.primary.dark : theme.palette.primary.light,
  textTransform: 'capitalize',
  minHeight: '42px',
  marginRight: theme.spacing(2),
  '&:hover': {
    backgroundColor: active ? theme.palette.primary.light : 'transparent',
    opacity: '.85'
  }
}));

const StyledHeaderTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.dashboardHeadline
}));

const Header = (props) => {
  const { pageTitle, isCommonTopbar, headerLeftAppbar, headerTopbarSx } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const path = getPathFromURL(window.location.href)
  const [showMenu, setShowMenu] = useState(false)
  const [mobileMenus, setMobileMenus] = useState(null)

  const commonTopbarSx = {
    backgroundColor: 'transparent',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.primary.dark}`
  };

  const onCroowyLogoClick = () => {
    setShowMenu(false);
    navigate('/');
  };
  
  // Function to handle navigation to a specific route when a menu item is clicked
  // Also toggles the visibility of the menu
  const onNavigateMenu = (route)=>{
    navigate(route)
    setShowMenu(!showMenu);
  }

  // Function to handle the user's logout action
  const handleLogOut = () => {
    logout(dispatch, navigate);
  }

  // Fetching the menu items based on the user's role type and filtering only the mobile menu items
  useEffect(() => {
    if(user.user?.role?.type) {
      const menus = getMenus(user.user.role.type);
      const filteredMenu = menus.filter(menu => menu.isMobile)
      setMobileMenus(filteredMenu);
    }
  }, [user.user])

  return (
    <Box component='main'>
      <Box sx={{pt: 2, px: 3, pb: 2, display:'flex', justifyContent:'space-between', alignItems:'center', bgcolor: theme.palette.sidebarBg}}>
        <img src={Logo} width={55} alt='Croowy Companion' onClick={onCroowyLogoClick} />

        <Box sx={{display:'flex', alignItems:'center', gap:1}}>
          {/* <StyledButton
            variant='outlined'
            startIcon={<NotificationImportantIcon />}
            sx={{
              minWidth: '0',
              '& .MuiButton-startIcon': {
                margin: 0 
              }
            }}
          /> */}

          <IconButton
            onClick={()=>setShowMenu(!showMenu)}
            aria-label='information'
            sx={{
              p: 0
            }}
          >
            {showMenu ? (
              <img src={filledMenu} alt='menu' width={31} height={31}/>
            ):(
              <img src={outlinedMenu} alt='menu' />
            )}
          </IconButton>
        </Box>
      </Box>

      {/* rendering mobile menu */}
      {showMenu ? (
        <Stack spacing={2} sx={{bgcolor: theme.palette.sidebarBg,  position: 'absolute', width: '100%', zIndex: 2}}>
          <StyledMenuDivider/>
          {mobileMenus?.map(menu =>(
            <StyledMenu 
              sx={{fontWeight: path === menu.route ? 600 : 400, }}
              onClick={() => onNavigateMenu(menu.route)}
              endIcon={path === menu.route ? <EastIcon/> : ''}
            >{menu.name}</StyledMenu>
          ))}
          <StyledMenuDivider sx={{mt: `${theme.spacing(2)} !important`,}}/>
          <StyledMenu onClick={() => {navigate('/account/settings'); setShowMenu(!showMenu);}}>Account Settings</StyledMenu>
          <StyledMenu onClick={handleLogOut}>Log Out</StyledMenu>
          <Box sx={{height: theme.spacing(1), 
            width: '100%', 
            display: 'flex', 
            alignItems: 'baseline', 
            justifyContent: 'center',
            mt: 2}}
          >
            <Divider width='10%' sx={{borderColor: theme.palette.grey[200]}}/>
          </Box>
        </Stack>
      ) : (null)}
      <Box sx={{ flexGrow: 1, pt: 1, pr: 1, pl: 1, backgroundColor: theme.palette.primary.dark }}>
        <TopBar sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          px: 2,
          py: 1,
          ...(isCommonTopbar ? commonTopbarSx : {}),
          ...headerTopbarSx
        }}>
          {headerLeftAppbar ? (
            headerLeftAppbar
          ) : (
            <Box>
              <StyledHeaderTitle variant='h6'>{pageTitle}</StyledHeaderTitle>
            </Box>
          )}
        </TopBar>
      </Box>
    </Box>
  );
};

export default Header;