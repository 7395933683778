/**
 * Get website URL and URL parameter by key
 * 
 * @returns object { siteUrl: string, code: string }
 */
function getUrlParams(key) {
  const url = new URL(window.location.href);
  const value = url.searchParams.get(key);
  const siteUrl = `${window.location.protocol}//${window.location.host}`;
  return { siteUrl, value };
}
export default getUrlParams;