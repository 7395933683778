const LineHeight = {
  1: '1rem',
  1.2: '1.2rem',
  1.25: '1.25rem',
  1.37: '1.37rem',
  1.5: '1.5rem',
  1.75: '1.75rem',
  2: '2rem',
};

export default LineHeight;