import { useState, useCallback, useEffect, Fragment } from 'react'
import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  styled,
  ListItem,
  ListItemText,
  List,
  useTheme,
} from '@mui/material'
import { GoogleMap, Button, Input, AlertDialog } from '../../components'
import moment from 'moment';
import Cookies from 'js-cookie';
import PetsIcon from '@mui/icons-material/Pets';
import AccessibleIcon from '@mui/icons-material/Accessible';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import Logo from '../../assets/images/logo.png';
import hotelDefaultImg from '../../assets/images/hotel_default.png';
import tranportationDefaultImg from '../../assets/images/tranportation_default.png';
import { createMapLink, prepareImageUrlByPath } from '../../utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useLoader } from '../../providers/loader';
import { getPassengerDetails, getUpdatePassengerEmail, cancelPassengerBooking } from '../../services/layover/bookings';
import TransportationAlertBox from './transportationAlertBox'
import { useTranslation } from 'react-i18next';
import NotFound from './notFound';
import { toast } from 'react-toastify';
import { maxToastTime } from '../../helper/constant';
import { luggageOptions } from '../airline/layover/hotelBooking/bookingDetails';

const Para = styled(Typography)(({ theme }) => ({
  alignItems: 'flex-start',
  textAlign: 'left',
  lineHeight: theme.lineHeight[1.37]
}))

const StyledLink = styled(Box)(({ theme }) => ({
  ...theme.typography.textSmall,
  color: theme.palette.primary.light,
  textDecoration: 'underline',
  cursor: 'pointer'
}))

const StyledHeader = styled(Typography)(({ theme }) => ({
  alignItems: 'flex-start',
  textAlign: 'left',
  color: theme.palette.primary.light,
  mt: 1,
}))

const DarkPara = styled(Typography)(({ theme }) => ({
  ...theme.typography.textSmall,
  textTransform: 'uppercase',
  color: theme.palette.grey[600],
  marginBottom: theme.spacing(0.7),
  alignItems: 'flex-start',
  textAlign: 'left',
}))

const HotelCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: `${theme.palette.common.black}88`,
  border: 0,
  boxShadow: 'none',
}))

const StyledFacilityIcoBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.common.black}88`,
  borderRadius: theme.borderRadius[3],
  padding: theme.spacing(1),
  height: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(1)
}))

function Booking({bookingId, languageCode}) {
  const [bookingData, setBookingData] = useState(null)
  const [passengerMail, setPassengerMail] = useState('')
  const { startLoader, stopLoader } = useLoader();
  const [dialogChangeEmail, setDialogChangeEmail] = useState(false)
  const [dialogCancel, setDialogCancel] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [showerrorPage, setShowerrorPage] = useState(false)
  const [bookingCode, setBookingCode] = useState(null)
  const [selectedHotelDialog, setSelectedHotelDialog] = useState(null);
  const [mailEdit, setMailEdit] = useState(true)
  const [selectedLuggageType, setSelectedLuggageType] = useState('');
  const theme = useTheme()
  const [searchParams] = useSearchParams();
  const directCancel = searchParams.get('a');
  const navigate = useNavigate()
  let { iata, code } = useParams()
  const { t, i18n } = useTranslation()
  const today = moment().format('ddd').toUpperCase()
  const returnDate = bookingData?.passangerAirportReturnDate ? moment(bookingData?.passangerAirportReturnDate).format('DD.MM.YY') : null
  const departDate = bookingData?.passangerAirportDepartureDate ? moment(bookingData?.passangerAirportDepartureDate).format('DD.MM.YY') : null

  const getSelectedMeals = (hotel) => {
    const mealOptions = {
      isEarlyBreakfast: t('earlyBirdBreakfast'),
      isBreakfast: t('breakfast'),
      isLunch: t('lunch'),
      isDinner: t('dinner'),
      isSnacks: t('snacks'),
    };
  
    return Object.entries(mealOptions)
      .filter(([key]) => hotel[key])
      .map(([, value]) => value)
      .join(', ');
  };

  const handleMailChange = () =>{
    if(bookingData?.status === 'CONFIRMED'){
      setMailEdit(false)
    }else{
      return
    }
  }
  const handleDialogOpen = (hotel) => {
    setSelectedHotelDialog(hotel);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedHotelDialog(null);
  };

  const onCopyConfirmationCode = (text) => {
    navigator.clipboard.writeText(text);
  };

  /**
   * Get first hotel image from the array of images
   * 
   * @param {*} hotel   Hotel Object
   */
  const getHotelImage = (hotel) => {
    let url = hotelDefaultImg;
    if (hotel?.images?.[0]?.formats?.small?.url) {
      url = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(hotel.images[0].formats.small.url) : hotel.images[0].formats.small.url
    }
    return url;
  };

  const fetchPassengerBookingDetails = () => {
    startLoader();
    let savedPassengerbookedRoom;
    const passengerbookedRoom = Cookies.get('passengerbookedRoom')
    if (bookingId) {
      setBookingCode(bookingId)
      savedPassengerbookedRoom = bookingId
    } else if( passengerbookedRoom) {
      const {booking_id} = JSON.parse(passengerbookedRoom)
      savedPassengerbookedRoom = booking_id
      setBookingCode(booking_id)
    }
    new Promise(async (resolve, reject) => {
      try {
        const response = await getPassengerDetailsViaApi(savedPassengerbookedRoom)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      setBookingData(response);
      setPassengerMail(response?.lead_passenger_email);
      setSelectedLuggageType((response?.luggageType && luggageOptions && luggageOptions.length > 0) ? luggageOptions?.find((option) => option?.key === response?.luggageType)?.name : '');
      if(directCancel === 'cancel' && response?.status === 'CONFIRMED') {
        setDialogCancel(true)
      }
    }).catch((e) => {
      if (e?.response?.data?.error?.message?.toLowerCase()?.includes('this booking does not exists') || e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
        setShowerrorPage(true)
      } else {
        toast.error(e?.response?.data?.error?.message, {
          autoClose: maxToastTime,
          toastId: 'passenger-booking-flag',
        });
      }
    }).finally(() => {
      stopLoader();
    });
  }

  function onNavigateHotelLocation(hotelName, lat, lng) {
    const link = createMapLink(hotelName, lat, lng)
    window.location.href=link
  }

  const getPassengerDetailsViaApi = useCallback(async (bookingCode) => {
    try {
      const response = await getPassengerDetails(bookingCode);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const getUpdatePassengerEmailViaApi = useCallback(async (bookingCode, passengerMail) => {
    try {
      const response = await getUpdatePassengerEmail(bookingCode, passengerMail);
      return response
    } catch (e) {
      throw e
    }
  }, []);
  
  const cancelPassengerBookingViaApi = useCallback(async (bookingCode) => {
    try {
      const response = await cancelPassengerBooking(bookingCode);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const onCancelBooking = useCallback(async () => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await cancelPassengerBookingViaApi(bookingCode)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      navigate(`/passenger/${iata}/${code}/booking/${bookingCode}`)
      setDialogCancel(false)
      fetchPassengerBookingDetails()
      Cookies.remove('passengerbookedRoom')
      toast.success(response?.message, {
        autoClose: maxToastTime,
        toastId: 'passenger-booking-cancel-success-flag',
      })
    }).catch((e) => {
      if (e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
        setShowerrorPage(true)
      } else {
        toast.error(e?.response?.data?.error?.message, {
          autoClose: maxToastTime,
          toastId: 'passenger-booking-cancel-flag',
        });
      }
    }).finally(() => {
      stopLoader();
    });
  },[startLoader, stopLoader, iata, code, navigate, bookingCode, cancelPassengerBookingViaApi])

  const onMailSubmit = useCallback(async () => {
    startLoader();
    const requestMailObject = { email: passengerMail }
    new Promise(async (resolve, reject) => {
      try {
        const response = await getUpdatePassengerEmailViaApi(bookingCode, requestMailObject)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      setMailEdit(true)
      setDialogChangeEmail(true)
    }).catch((e) => {
      if (e?.response?.data?.error?.message?.toLowerCase()?.includes('layover expired')) {
        setShowerrorPage(true)
      } else {
        toast.error(e?.response?.data?.error?.message, {
          autoClose: maxToastTime,
          toastId: 'passenger-booking-submit-flag',
        });
      }
    }).finally(() => {
      stopLoader();
    });
  },[startLoader, stopLoader, passengerMail, bookingCode, getUpdatePassengerEmailViaApi])


  useEffect(() => {
    if(languageCode){
      i18n.changeLanguage(languageCode)
    }
    fetchPassengerBookingDetails()
  }, [startLoader, stopLoader, getPassengerDetailsViaApi]);

  if(showerrorPage){
    return <NotFound/>
  }

  return (
    <Box>
      <Box sx={{ 
        width: '100%', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.sidebarBg,
        p: 1 }}
      >
        <img src={Logo} width={52} alt='Croowy Companion' />
      </Box>
      <Box sx={{display: 'flex', justifyContent:'space-around', alignItems: 'center', mb: -2}}>
        <Input
          type='text'
          formControlProps={{
            sx: {
              width: '70%'
            }
          }}
          value={passengerMail}
          disabled={mailEdit}
          onChange={(e)=>{setPassengerMail(e.target.value)}}
          sx={{ backgroundColor: theme.palette.text.dark, border: 'none'}}
        />
        {
          mailEdit ? 
            <DriveFileRenameOutlineIcon onClick={handleMailChange} sx={{cursor: 'pointer', mb: 2}} />
          : 
            <SaveOutlinedIcon onClick={onMailSubmit} sx={{cursor: 'pointer', mb: 2}}/>
        }
      </Box>
      <Divider />
      <Box sx={{px: 3}}>
        <Box mt={5} sx={{gap: 1, display: 'flex', flexDirection: 'column'}}>
          <Para variant='subtitle1'>{t('hi')} {bookingData?.lead_passenger_first_name},</Para>
          {bookingData?.status === 'CONFIRMED' ? (
            <Para variant='subtitle1' sx={{...theme.typography.fontMedium600, mb: 2}}>
              {t('bookingConfirm')}
            </Para>
          ):(
            <Para variant='subtitle1' sx={{...theme.typography.fontMedium600, mb: 2, color: theme.palette.roseGold[100]}}>
              {t('bookingCancel')}
            </Para>
          )}
          <Box sx={{pb: 2, color: theme.palette.menu.main}}>
            <List sx={{ background: theme.palette.primary.main, borderRadius: 1}}>
              <ListItem>
                <ListItemText primary={t('bookingDate')} sx={{ color:theme.mode === 'light' ? theme.palette.background.default : theme.palette.grey[600] }} />
                <ListItemText primary={moment(bookingData?.createdAt).format('DD.MM.YY')} sx={{textAlign: 'center'}}/>
              </ListItem>
              <Divider variant='middle' sx={{borderColor: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}}/>
              <ListItem>
                <ListItemText primary={bookingData?.total_rooms === 1 ? t('noRoom') : t('noRooms') } sx={{ color: theme.mode === 'light' ? theme.palette.background.default :theme.palette.grey[600] }} />
                <ListItemText primary={bookingData?.total_rooms} sx={{textAlign: 'center', mr: 1.5}}/>
              </ListItem>
              <Divider variant='middle' sx={{borderColor: theme.mode === 'light' ? theme.palette.menu.main : 'inherit'}}/>
              <ListItem>
                <ListItemText primary={bookingData?.total_guests === 1 ? t('noGuest') : t('noGuests') } sx={{ color: theme.mode === 'light' ? theme.palette.background.default :theme.palette.grey[600] }} />
                <ListItemText primary={bookingData?.total_guests} sx={{textAlign: 'center', mr: 1.3}}/>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Typography sx={{
              ...theme.typography.subtitle2,
              color: theme.palette.grey[600],
              marginBottom: theme.spacing(0.7),
              textAlign: 'center'
            }}>
              {t('confirmCode')}
            </Typography>
            <Box
              mt={1}
              sx={{
                border: `2px dashed ${theme.palette.primary.light}`,
                p: 2, mb: 2,
                borderRadius: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              >
              <Typography variant='body1' 
                sx={{ 
                  ...theme.typography.textxLarge, 
                  letterSpacing: theme.spacing(0.8),
                  color: theme.palette.primary.light, 
                  }} onClick={()=>{onCopyConfirmationCode(bookingData?.confirmation_code)}}>
                {bookingData?.confirmation_code}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1}}>
            <StyledHeader variant='subtitle1'>{t('hotel')}</StyledHeader>
            <Box sx={{ width: '10%', ml: 1 }}>
              <Divider sx={{ borderBottomWidth: 2 }}/>
            </Box>
          </Box>
          {bookingData?.hotels?.map(hotel =>(
            <Fragment key={`b-h-${hotel?.id}`}>
              <Para variant='subtitle2' sx={{mb: 1.3, mt: 2}}>{hotel?.name}</Para>
              <HotelCard>
                  <Grid 
                    container 
                    sx={{
                      backgroundImage: `url(${getHotelImage(hotel)})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      height: 200,
                      borderRadius: 1,
                      p: 1, mb: 2,
                      position: 'relative',
                    }}
                  >
                    <Box 
                      sx={{
                        position: 'absolute',
                        bottom: theme.spacing(1),
                        left: theme.spacing(1),
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {hotel?.hotelTransportation === 'BY_FOOT' ? (
                        <StyledFacilityIcoBox>
                          <DirectionsWalkIcon />
                        </StyledFacilityIcoBox>
                      ):(null)}
                      {hotel?.isRoomForDisabled ? (
                        <StyledFacilityIcoBox>
                          <AccessibleIcon />
                        </StyledFacilityIcoBox>
                      ):(null)}
                      {hotel?.isPets ? (
                        <StyledFacilityIcoBox>
                          <PetsIcon />
                        </StyledFacilityIcoBox>
                      ):(null)}
                      {hotel?.isSnacks || hotel?.isDinner || hotel?.isLunch || hotel?.isBreakfast || hotel?.isEarlyBirdBreakfast ? (
                        <StyledFacilityIcoBox>
                          <LocalDiningIcon />
                        </StyledFacilityIcoBox>
                      ):(null)}
                    </Box>
                    <Box 
                      sx={{
                        position: 'absolute',
                        bottom: theme.spacing(1),
                        right: theme.spacing(1),
                      }}
                    >
                      {bookingData?.differentReturnTimes === 'NO' ? (
                        <StyledFacilityIcoBox>
                          <FlightTakeoffIcon />
                          <Typography variant='body2'>{moment(bookingData?.passangerAirportReturnTime, 'HH:mm:ss.SSS').format('h:mm A')}</Typography>
                        </StyledFacilityIcoBox>
                      ):(null)}
                    </Box>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DarkPara variant='body2'>{t('checkIn')}</DarkPara>
                      <Para variant='body2'>After {moment(bookingData?.passangerAirportDepartureTime,  'HH:mm:ss.SSS').format('h:mm A')}, {departDate}</Para>
                    </Grid>
                    { 
                      (bookingData?.passangerAirportReturnTime || bookingData?.passangerAirportReturnDate) && bookingData?.differentReturnTimes === 'NO' ? (
                        <Grid item xs={6}>
                          <DarkPara variant='body2'>{t('checkOut')}</DarkPara>
                          <Para variant='body2'>{moment(bookingData?.passangerAirportReturnTime,  'HH:mm:ss.SSS').format('h:mm A')}, {returnDate}</Para>
                        </Grid>
                      ):(null)
                    }
                    <Grid item xs={6}>
                      <DarkPara variant='body2'>{t('meals')}</DarkPara>
                      <Para variant='body2'>{getSelectedMeals(hotel)}.</Para>
                    </Grid>
                  </Grid>
              </HotelCard>
              <Box sx={{ 
                width:'100%', 
                height:theme.spacing(15), 
                position: 'relative', 
                cursor: 'pointer',
                }}
                onClick={()=>{onNavigateHotelLocation(hotel?.name, hotel?.lat, hotel?.lng)}}
              >
                <GoogleMap
                  markerData={[{ 
                    hotelId: hotel?.hotelId,
                    name: hotel?.name,
                    address: hotel?.address,
                    lat: hotel?.lat, 
                    lng: hotel?.lng
                  }]}
                  mapCenter={{
                    lat: hotel?.lat, 
                    lng: hotel?.lng
                  }}
                  currentHighlighted={[{ 
                    hotelId: hotel?.hotelId,
                    name: hotel?.name,
                    address: hotel?.address,
                    lat: hotel?.lat, 
                    lng: hotel?.lng
                  }]}
                  zoomValue={12}
                  isInfoWindow={false}
                />
                <Box 
                sx={{
                  position: 'absolute', 
                  backgroundColor: theme.palette.bg[400], 
                  opacity: 0.7, bottom: 0, 
                  height: theme.spacing(3), 
                  width: '100%', 
                  zIndex: 1, 
                }}>
                  <Grid container sx={{justifyContent: 'space-between', px: 1}}>
                    <Para variant='body1' sx={{...theme.typography.fontMedium500}}>{t('location')}</Para>
                    <Box sx={{display: 'flex',  mt: 0.2}}>
                      <FmdGoodOutlinedIcon sx={{height: theme.spacing(2.5)}}/>
                      <Para variant='body2' sx={{...theme.typography.fontMedium500}}>
                        {t('viewMap')}
                      </Para>
                    </Box>
                  </Grid>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
                  <StyledHeader variant='subtitle1'>{t('transportation')}</StyledHeader>
                  <Box sx={{ width: '10%', ml: 1 }}>
                    <Divider sx={{ borderBottomWidth: 2, pt: 0.5 }}/>
                  </Box>
                </Box>
                {/* Your transportation details */}
                { hotel?.hotelTransportation === 'BY_FOOT' ? (
                <Grid container >
                  <Grid item xs={12}>
                    <Para>
                      {t('transportionByFoot')}
                    </Para>
                    <Button
                      variant='contained'
                      width={'100%'}
                      sx={{
                        mt: 2,
                        '&.MuiButton-contained': {
                          ...theme.typography.muiTypography400
                        }
                      }}
                      onClick={()=>{onNavigateHotelLocation(hotel?.name, hotel?.lat, hotel?.lng)}}
                    >
                      {t('transportionByFootBtn')}
                    </Button>
                  </Grid>
                </Grid>
                ):(null)}
                { hotel?.hotelTransportation === 'SELF_ORGANIZED' ? (
                  <Grid container>
                    <Grid item xs={12}>
                      <Para>{t('transportionSelfOrganied', {airlineName: bookingData?.airlineName})}</Para>
                    </Grid>
                  </Grid>
                ):(null)}
                { hotel?.hotelTransportation === 'SHUTTLE' ? 
                  (hotel?.hotelTransportationSchedules?.ATOH?.filter(schedule =>schedule.weekday.includes(today))?.length > 0 || hotel?.hotelTransportationSchedules?.ATOH?.filter(schedule =>schedule.weekday.includes(today))?.length > 0) ? (
                    <Grid
                      container
                      direction='column'
                      justifyContent='center'
                      alignItems='flex-start'
                      spacing={3}
                    >
                      <Grid item width='100%'>
                        <Box 
                          sx={{
                            backgroundImage: `url(${tranportationDefaultImg})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            height: 150,
                            borderRadius: 1,
                            p: 1, mb: -1
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Para variant='body2'>
                          {t('transportionShuttleMess')}  
                        </Para>
                      </Grid>
                      <Grid 
                        container item
                        direction='column'
                        justifyContent='center'
                        alignItems='flex-start'
                        spacing={1.5}
                      > 
                        <Grid item>
                          <DarkPara sx={{...theme.typography.textxxSmall, mb: 0}}>{t('airportPickupTime')}</DarkPara>
                        </Grid>
                        {hotel?.hotelTransportationSchedules?.ATOH?.filter(schedule =>schedule.weekday.includes(today))?.length > 0 ? 
                          (
                            <Grid 
                              container item spacing={0.5} 
                              direction='column'
                              justifyContent='center'
                              alignItems='flex-start'>
                              <Grid item>
                                <Para variant='body1' sx={{color: theme.palette.primary.light}}>{t('ATH')}</Para>
                              </Grid>
                              {hotel?.hotelTransportationSchedules?.ATOH
                                ?.filter(schedule =>schedule.weekday.includes(today))
                                ?.map((schedule, index) => (
                                  <Grid item key={`ATOH-${index}`} sx={{width: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <Para variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                      {schedule.airportTerminalName}{': '}  
                                      {schedule.time.map(time=>moment(time, 'HH:mm').format('hh:mm A')).join(', ')}
                                    </Para>
                                  </Grid>
                              ))}
                            </Grid>
                          ):(
                            <Grid item>
                              <Para variant='subtitle2' sx={{...theme.typography.fontMedium600}}>{t('ATHNotAvailable')}</Para>
                            </Grid>
                          )
                        }
                        {hotel?.hotelTransportationSchedules?.HTOA?.filter(schedule =>schedule.weekday.includes(today))?.length > 0 ? 
                          (
                            <Grid container item spacing={0.5}>
                              <Grid item>
                                <Para variant='body1' sx={{color: theme.palette.primary.light}}>{t('HTA')}</Para>
                              </Grid>
                              {hotel?.hotelTransportationSchedules?.HTOA
                                ?.filter(schedule =>schedule.weekday.includes(today))
                                ?.map((schedule, index) => (
                                  <Grid item key={`HTOA-${index}`} sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                    <Para variant='body2' sx={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                      {schedule.airportTerminalName}{': '}  
                                      {schedule.time.map(time=>moment(time, 'HH:mm').format('hh:mm A')).join(', ')}
                                    </Para>
                                  </Grid>
                              ))}
                            </Grid>
                          ):(
                            <Grid item>
                              <Para variant='subtitle2' sx={{...theme.typography.fontMedium600}}>{t('HTANotAvailable')}</Para>
                            </Grid>
                          )
                        }
                        <Grid item>
                          <StyledLink onClick={() => handleDialogOpen(hotel)} sx={{mb: 1}}>{t('viewShuttle')}</StyledLink>
                        </Grid>
                        <Grid
                          container
                          direction='row'
                          justifyContent='flex-start'
                          alignItems='center'
                          spacing={1}
                          sx={{ml: 0.4}}
                        >
                          <Grid item xs={5.8}>
                            <Button
                              variant='contained'
                              sx={{
                                mt: 2,
                                '&.MuiButton-contained': {
                                  ...theme.typography.muiTypography400,
                                  p: 1
                                }
                              }}
                              onClick={()=>{onNavigateHotelLocation(hotel?.name, hotel?.lat, hotel?.lng)}}
                            >
                              {t('terPickup1')}
                            </Button>
                          </Grid>
                          <Grid item xs={5.8}>
                            <Button
                              variant='contained'
                              sx={{
                                mt: 2,
                                '&.MuiButton-contained': {
                                  ...theme.typography.muiTypography400,
                                  p: 1
                                }
                              }}
                              onClick={()=>{onNavigateHotelLocation(hotel?.name, hotel?.lat, hotel?.lng)}}
                            >
                              {t('terPickup2')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid 
                        container item
                        direction='column'
                        justifyContent='center'
                        alignItems='flex-start'
                        spacing={1.5}
                      >
                        <Grid item>
                          <DarkPara sx={{...theme.typography.textxxSmall, mb: 0}}>{t('airportReturnTime')}</DarkPara>
                        </Grid>
                        {bookingData?.differentReturnTimes === 'NO' ? (
                          <>
                            <Grid item>
                              <Para variant='body1'>{t('returnTimeAvailableFirstMess', {airlineName: bookingData?.airlineName, passangerAirportReturnTime: moment(bookingData?.passangerAirportReturnTime, 'HH:mm:ss.SSS').format('h:mm A'), returnDate})} </Para>
                            </Grid>
                            <Grid item>
                              <Para variant='body1'>{t('returnTimeAvailableSecondMess')}</Para>
                            </Grid>
                          </>
                        ):(
                          <>
                            <Grid item>
                              <Para variant='body1'>{t('returnTimeNotAvailableSecondMess')}</Para>
                            </Grid>
                            <Grid item>
                              <Para variant='body1'>{t('returnTimeNotAvailableSecondMess')}</Para>
                            </Grid>
                          </>
                        )}
                        
                        <Grid item>
                          <StyledLink onClick={() => handleDialogOpen(hotel)}>{t('viewShuttle')}</StyledLink>
                        </Grid>
                      </Grid>
                    </Grid>
                  ):(
                    <Grid container>
                      <Grid item xs={12}>
                        <Para>{t('transportionSelfOrganied', {airlineName: bookingData?.airlineName})}</Para>
                      </Grid>
                    </Grid>
                  )
                 :(null)}
                { hotel?.hotelTransportation === 'COST_EFFICIENT' || hotel?.hotelTransportation === 'INDIVIDUAL'  ? (
                  <Grid
                    container
                    direction='column'
                    justifyContent='center'
                    alignItems='flex-start'
                    spacing={3}
                  >
                    <Grid 
                      container item
                      direction='column'
                      justifyContent='center'
                      alignItems='flex-start'
                      spacing={1.5}
                    >
                      <Grid item>
                        <Para variant='body1'>{t('hotelIndiviual.first')}</Para>
                      </Grid>
                      <Grid item>
                        <Para variant='body1'>{t('hotelIndiviual.second')}</Para>
                      </Grid>
                    </Grid>
                    <Grid 
                      container item
                      direction='column'
                      justifyContent='center'
                      alignItems='flex-start'
                      spacing={1.5}
                    >
                      <Grid item sx={{mb: -1}}>
                        <DarkPara sx={{...theme.typography.textxxSmall}}>{t('airportReturnTime')}</DarkPara>
                      </Grid>
                      {bookingData?.differentReturnTimes === 'NO' ? (
                        <Grid item>
                          <Para variant='body1'>
                            {t('hotelIndiviualAvailable', {airlineName: bookingData?.airlineName, passangerAirportReturnTime: moment(bookingData?.passangerAirportReturnTime, 'HH:mm:ss.SSS').format('h:mm A'), returnDate})}
                          </Para>
                        </Grid>
                      ):(
                        <>
                          <Grid item>
                            <Para variant='body1'>{t('returnTimeNotAvailableFirstMess')}</Para>
                          </Grid>
                          <Grid item>
                            <Para variant='body1'>{t('hotelIndiviualNotAvailableSecondMess')}</Para>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {/* commenting the code for - Layov 232  */}
                    {/* {selectedLuggageType ? (
                      <Grid item>
                        <Para variant='body1' sx={{...theme.typography.fontMedium500}}>{t('luggage')}: {selectedLuggageType}</Para>
                      </Grid>
                    ) : (<></>)} */}
                  </Grid>
                ):(null)}
              </Box>
              <TransportationAlertBox 
                open={dialogOpen} 
                hotel={selectedHotelDialog} 
                onDisagree={handleDialogClose}
                today={today} 
              />
            </Fragment>
          ))}
          {bookingData?.status === 'CONFIRMED' ? (
            <>
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 4 }}>
                  <StyledHeader variant='subtitle1'>{t('cancellation')}</StyledHeader>
                  <Box sx={{ width: '10%', ml: 1 }}>
                    <Divider sx={{ borderBottomWidth: 2, pt: 0.5 }}/>
                  </Box>
                </Box>
                <Para variant='body2' sx={{mt: 2, ml: 2, color: theme.palette.grey[600], }}>
                {t('cancellationTitle')}
                </Para>
              </Box>
              <Button
                variant='outlined'
                color='error'
                sx={{
                '&.MuiButton-contained': {
                  ...theme.typography.muiTypography400
                  },
                m: 1, mx: 2
                }}
                onClick={() => setDialogCancel(true)}
                >
                {t('cancelBooking')}
              </Button>
            </>
          ):(
            <Box sx={{height: 5}} />
          )}
        </Box>
      </Box>

      {/* alert box for changing email ID */}
      <AlertDialog
        title={t('alertDialog.bookingMailIDChangeTitle')}
        open={dialogChangeEmail}
        onDisagree={()=>{setDialogChangeEmail(false)}}
        noBtnText={t('close')}
        dialogTitleProps={{
          sx: {color: theme.palette.success.main}
        }}
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          {t('alertDialog.hotelSelectionSuccess.message')}
        </Typography>
      </AlertDialog>

      {/* alert box for cancel booking */}
      <AlertDialog
        title={t('alertDialog.cancelBooking.title')}
        open={dialogCancel}
        onAgree={onCancelBooking}
        onDisagree={() => setDialogCancel(false)}
        yesBtnText={t('alertDialog.cancelBooking.reject')}
        noBtnText={t('alertDialog.cancelBooking.accept')}
        sx={{
          textAlign: 'center'
        }}
        fullWidth={true}
        maxWidth={'xs'}
        stretchBtn
      >
        <Typography sx={{lineHeight: '1.37rem', py: 2}} variant='subtitle2'>
          {t('alertDialog.cancelBooking.message')}
        </Typography>
      </AlertDialog>
    </Box>
  )
}

export default Booking
