import { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import required modules
import { Pagination, Navigation, Keyboard } from 'swiper/modules';
import { Box, useTheme } from '@mui/material';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const SwiperCarousel = (props) => {
  const { images, imgIndex, swiperSlideProps } = props;
  const theme = useTheme();

  return (
    <Box>
      {images && images.length > 0 ? (
        <Swiper
          lazy
          loop
          navigation
          initialSlide={imgIndex}
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
            dynamicBullets: true
          }}
          modules={[Keyboard, Pagination, Navigation]}
          className='swiper-carousel'
          keyboard={{
            enabled: true,
          }}
          style={{
            '--swiper-navigation-color': theme.palette.primary.light,
            '--swiper-pagination-color': theme.palette.grey[500],
            width: '100%',
            height: '100%',
            borderRadius: theme.borderRadius[5],
            backgroundColor: theme.palette.primary.main
          }}
          {...props}
        >
          {images.map((img, index) => (
            <SwiperSlide key={`swps-swpsl-${index}`} {...swiperSlideProps} style={{minHeight: '250px', ...swiperSlideProps?.style}}>
              <img
                style={{
                  width: 'auto',
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  '-ms-transform': 'translate(-50%, -50%)',
                  '-webkit-transform': 'translate(-50%, -50%)',
                  '-moz-transform': 'translate(-50%, -50%)',
                  transform: 'translate(-50%, -50%)',
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                }}
                alt=''
                src={img.url}
                data-src={img.url}
                className='swiper-lazy'
                loading='lazy'
              />
              <Box className='swiper-lazy-preloader swiper-lazy-preloader-white'></Box>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (null)}
    </Box>
  );
};

export default memo(SwiperCarousel);