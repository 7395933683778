import { api } from '..';
import { handleApiError } from '../handleApiError';
import { toast } from 'react-toastify'
import { toastMessages } from '../../helper/constant'


/**
 * Fetch invite data by code
 *
 * @param {string} code The invitation code
 * @returns {Promise<object>} The invite data
 */
export const fetchInvite = async (value) => {
  try {
    const response = await api.get(`/invite/${value}`);
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status);
    } else {
      return response?.data;
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      let message = e?.response?.data?.error?.message
        ? e.response.data.error.message
        : e.message;
      handleApiError(e.response.status, message);
    }
    throw e;
  }
};

/**
 * User registration
 *
 * @param {*} request
 * @returns
 */
export const acceptInvite = async (request) => {
  try {
    const response = await api.post('/auth/local/register', request)
    if (parseInt(response?.status) !== 200) {
      throw new Error(response?.status)
    } else {
      return response?.data
    }
  } catch (e) {
    // Handle the error
    if (e?.response?.status && e?.message) {
      let message = e?.response?.data?.error?.message
        ? e.response.data.error.message
        : e.message
      handleApiError(e.response.status, message)
    }
    throw e
  }
}