import { Typography, styled } from '@mui/material';

const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.dashboardHeadline,
  color: theme.palette.text.light,
  textTransform: 'capitalize'
}));

export const Heading = (props) => {
  const { title } = props;

  return (
    <StyledTypographyHeading variant='subtitle1' {...props} >
      {title}
    </StyledTypographyHeading>
  );
};

const StyledTypographySubHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge,
  color: theme.palette.dashboardWidgetHeadline
}));

export const SubHeading = (props) => {
  const { title } = props;

  return (
    <StyledTypographySubHeading variant='body1' {...props}>
      {title}
    </StyledTypographySubHeading>
  );
};

const StyledTypographyLargeHeading = styled(Typography)(({ theme, textColor }) => ({
  ...theme.typography.textxxLarge,
  color: textColor 
}));

export const LargeHeading = (props) => {
  const { title, textColor } = props;

  return (
    <StyledTypographyLargeHeading variant='subtitle1' textColor={textColor} {...props} >
      {title}
    </StyledTypographyLargeHeading>
  );
};
