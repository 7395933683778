import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { AirlineContext } from '../../../context/airline';
import { Box, Chip, Divider, Grid, IconButton, Typography, styled, useTheme } from '@mui/material';
import { SubHeading } from '../components/heading';
import { HeadingInput } from '../components/headingInput';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input } from '../../../components';
import { numberWithCommas } from '../../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createLayover, getLayovers } from '../../../services/layover';
import { useLoader } from '../../../providers/loader';
import moment from 'moment';
// import { getFlights } from '../../../services/flights';
import { toast } from 'react-toastify';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import { getTodayAvailableRooms } from '../../../services/airport';
import { getHotelsInLayover } from '../../../services/layover/hotels';
import formatTimestamp from '../../../utils/formatTimestamp';

const StyledCreateLayoverContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg[100],
  borderRadius: theme.borderRadius[3],
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4)
}));

const StyledLayoverItemLabel = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[800]
}));

const Dashboard = () => {
  const { setIsCommonTopbar, airlineId, setIsAirlineDropdownDisabled, airportId, setIsAirportDropdownDisabled } = useContext(AirlineContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  // const [flightsOptions, setFlightsOptions] = useState([]);
  const [todayAvailableRooms, setTodayAvailableRooms] = useState(0);
  const [layovers, setLayovers] = useState([]);
  const [swiperLayovers, setSwiperLayovers] = useState(null);
  const [swiperActivePageNo, setSwiperActivePageNo] = useState(1);
  const [swiperTotalPages, setSwiperTotalPages] = useState(4);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    shouldUnregister: true,
  });

  useEffect(() => {
    setIsCommonTopbar(false);
    setIsAirlineDropdownDisabled(false);
    setIsAirportDropdownDisabled(false);
  }, [setIsCommonTopbar, setIsAirlineDropdownDisabled, setIsAirportDropdownDisabled])

  // Prepare meal statement
  const prepareMealStatement = (isEarlyBirdBreakfastIncluded, isBreakfastIncluded, isLunchIncluded, isDinnerIncluded, isSnacksIncluded) => {
    let str = '';
    if (isEarlyBirdBreakfastIncluded || isBreakfastIncluded || isLunchIncluded || isDinnerIncluded || isSnacksIncluded) {
      str += `with`;
      if (isEarlyBirdBreakfastIncluded) {
        str += ` Early bird breakfast,`;
      }
      if (isBreakfastIncluded) {
        str += ` Breakfast,`;
      }
      if (isLunchIncluded) {
        str += ` Lunch,`;
      }
      if (isDinnerIncluded) {
        str += ` Dinner,`;
      }
      if (isSnacksIncluded) {
        str += ` Snacks`;
      }
      str = str.replace(/,\s*$/, '');
      str = str.replace(/,(?=[^,]+$)/, ' &');
    }
    return str;
  };

  /**
   * Get recently created layovers from API
   */
  const getLayoversFromApi = useCallback(async () => {
    const request = {
      'airlineId': airlineId,
      'airportId': airportId,
      'filters[createdAt][$gte]': moment().subtract(2, 'days').toISOString(),
      'pagination[page]': '1',
      'pagination[pageSize]': '10000'
    };
    try {
      const response = await getLayovers({params: request});
      return response;
    } catch (e) {
      throw e
    }
  }, [airlineId, airportId]);

  /**
   * Get flights from API
   */
  // const getFlightsFromApi = useCallback(async (airportId, airlineId) => {
  //   const request = {
  //     airlineId: airlineId
  //   };
  //   try {
  //     const response = await getFlights(airportId, {params: request});
  //     return response;
  //   } catch (e) {
  //     throw e
  //   }
  // }, []);

  /**
   * Get no. of available rooms today from API
   */
  const getTodayAvailableRoomsFromApi = useCallback(async (airportId) => {
    try {
      const response = await getTodayAvailableRooms(airportId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Create layover via API
   */
  const createLayoverViaApi = useCallback(async (request) => {
    try {
      const response = await createLayover(request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (data?.formCreateLayover) {
      // NOTE: For now we are considering only economy class passengers, but API need all class passangers, so just pass the remaining as zero
      let request = {
        ...data.formCreateLayover,
        airlineId,
        airportId,
        firstClassPassengers: 0,
        businessClassPassengers: 0
      };
      // Call API to store details
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await createLayoverViaApi(request)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        toast.success(toastMessages.airline.createLayover.success, {
          autoClose: maxToastTime,
          toastId: 'update-airline-create-layover-success',
        })
        if (response?.layoverId) {
          navigate(`/layover/${response.layoverId}/hotels`);
        }
      }).catch((e) => {
        toast.error(toastMessages.airline.createLayover.error, {
          autoClose: maxToastTime,
          toastId: 'update-airline-create-layover-error',
        })
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader, navigate, createLayoverViaApi, airlineId, airportId]);

  useEffect(() => {
    startLoader();
    new Promise(async (resolve, reject) => {
      try {
        const response = await getLayoversFromApi();
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      if (response && response.length > 0) {
        setSwiperTotalPages(Math.ceil(response.length / 2))
        const rows = response.reduce(function (rows, key, index) { 
          return (index % 2 === 0 ? rows.push([key]) 
            : rows[rows.length-1].push(key)) && rows;
        }, []);
        setLayovers(rows);
      } else {
        setLayovers([]);
      }
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [startLoader, stopLoader, getLayoversFromApi])

  /**
   * NOTE: DON'T REMOVE THE CODE BELOW, AS IT IS TEMPORARILY HIDDEN DUE TO THE FLIGHTS FUNCTIONALITY CONTRADICTION - https://croowy.atlassian.net/browse/LAYOV-36?focusedCommentId=12123 
   */
  // useEffect(() => {
  //   if (selectedAirportId && airlineId) {
  //     startLoader();
  //     new Promise(async (resolve, reject) => {
  //       try {
  //         const response = await getFlightsFromApi(selectedAirportId, airlineId);
  //         resolve(response)
  //       } catch (e) {
  //         reject(e)
  //       }
  //     }).then(response => {
  //       if (response?.scheduled_arrivals && response.scheduled_arrivals.length > 0) {
  //         let tempFlights = [];
  //         for (const flight of response.scheduled_arrivals) {
  //           tempFlights.push({
  //             key: flight.ident_iata,
  //             name: flight.ident_iata
  //           })
  //         }
  //         setFlightsOptions(tempFlights)
  //       }
  //     }).catch((e) => {
  //     }).finally(() => {
  //       stopLoader();
  //     });
  //   }
  // }, [startLoader, stopLoader, getFlightsFromApi, selectedAirportId, airlineId])

  useEffect(() => {
    if (airportId) {
      // startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getTodayAvailableRoomsFromApi(airportId);
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        if (response && response?.availableRooms && Number(response.availableRooms) > 0) {
          setTodayAvailableRooms(Number(response.availableRooms));
        } else {
          setTodayAvailableRooms(0);
        }
      }).catch((e) => {
      }).finally(() => {
        // stopLoader();
      });
    }
  }, [airportId, getTodayAvailableRoomsFromApi])

  /**
   * Get hotels in layover via API
   * 
   * @param {*} layoverId  Layover Id
   */
  const getHotelsInLayoverViaApi = useCallback(async (layoverId) => {
    try {
      const response = await getHotelsInLayover(layoverId);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const handleStatusClick = async (layover) => {
    const response = await getHotelsInLayoverViaApi(layover?.layoverId);
    if (layover?.status === 'draft') {
      if (response?.length === 0) {
        navigate(`/layover/${layover?.layoverId}/hotels`);
      } else {
        navigate(`/layover/${layover?.layoverId}/booking`);
      }
    } else {
      navigate(`/layovers/${layover?.layoverId}/details`);
    }
  };

  const handleAirlineNameClick = async (layover) => {
    const response = await getHotelsInLayoverViaApi(layover?.layoverId);
    if (layover?.status === 'draft') {
      if (response?.length === 0) {
        navigate(`/layover/${layover?.layoverId}/hotels`);
      } else {
        navigate(`/layover/${layover?.layoverId}/booking`);
      }
    } else {
      navigate(`/layovers/${layover?.layoverId}/details`);
    }
  };

  return (
    <Box sx={{
      px: 2,
      py: 1
    }}>
      {/* <Heading title='Welcome!' /> */}
      <Grid container spacing={4} sx={{pt:2}}>
        <Grid item md={6} sm={12} xs={12}>
          <StyledCreateLayoverContainer>
            <Grid item xs={12} sx={{pb: 1, display: 'flex'}}>
              <SubHeading title='Create New Layover' sx={{pb:3, color: theme.palette.primary.light}} />
              <RequiredFieldsInfoText title='* fields are mandatory' sx={{flexGrow: 1, textAlign: 'right'}} />
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
              <Grid container>
                <Grid item xs={12} sx={{mb:3}}>
                  <Grid container>
                    <Grid item xs={12} sx={{py:1}}>
                      <HeadingInput title='Flight Number *' />
                    </Grid>
                    <Grid item xs={12} sx={{py:1}}>
                      <Controller
                        name={`formCreateLayover[flightCode]`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            sx={{
                              backgroundColor: theme.palette.bg[200],
                              borderRadius: theme.borderRadius[5]
                            }}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            isError={!!errors?.formCreateLayover?.flightCode}
                            errorMessage={errors?.formCreateLayover?.flightCode && 'Please enter valid flight number'}
                            inputRef={field.ref}
                            {...field}
                            onChange={(e) => {
                              let event = _.cloneDeep(e);
                              event.target.value = event.target.value.toUpperCase();
                              field.onChange(event);
                            }}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^[a-z0-9]+$/i
                        }}
                      />
                      {/* <Controller
                        name={`formCreateLayover[flightId]`}
                        control={control}
                        render={({ field }) => (
                          <Select
                            InputProps={{
                              sx: {
                                backgroundColor: theme.palette.bg[200],
                                borderRadius: theme.borderRadius[5],
                                '& .MuiSelect-select.MuiInputBase-input': {
                                  minHeight: 'auto'
                                }
                              }
                            }}
                            formControlProps={{
                              marginBottom: '0 !important'
                            }}
                            textAlignSelect='left'
                            options={flightsOptions}
                            isError={!!errors?.formCreateLayover?.flightId}
                            errorMessage={errors?.formCreateLayover?.flightId && 'Please select the flight'}
                            inputRef={field.ref}
                            {...field}
                            onChange={(e) => {
                              field?.onChange(e)
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      /> */}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{mb:3}}>
                  <Grid container>
                    <Grid item xs={12} sx={{py:1}}>
                      <HeadingInput title='No.of Stranded passengers *' />
                    </Grid>
                    <Grid item xs={12} sx={{py:1}}>
                      <Controller
                        name={`formCreateLayover[economyClassPassengers]`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            sx={{
                              backgroundColor: theme.palette.bg[200],
                              borderRadius: theme.borderRadius[5]
                            }}
                            isError={!!errors?.formCreateLayover?.economyClassPassengers}
                            errorMessage={errors?.formCreateLayover?.economyClassPassengers && 'Please enter valid no. of passangers'}
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        rules={{
                          required: true,
                          pattern: /^([1-9]\d*)$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* <Grid item xs={12} sx={{mb:4}}>
                  <Box sx={{display: 'flex'}}>
                    <Box>
                      <Controller
                        name={`formCreateLayover[isNotifyAllHotels]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <CheckBox
                            formControlLabelProps={{
                              sx: {
                                m: 0
                              }
                            }}
                            inputRef={field.ref}
                            {...field}
                            checked={field.value}
                            onChange={(e) => {
                              field.onChange(e);
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box sx={{flexGrow: 1, ml: 1}}>
                      <HeadingInput
                        title='Notify All hotels in the market to update their availabilities immediately'
                        sx={{
                          lineHeight: theme.lineHeight[1.37]
                        }}
                      />
                    </Box>
                  </Box>
                </Grid> */}

                <Grid item xs={12}>
                  <Button
                    type='submit'
                    variant='contained'
                    width='100%'
                    sx={{
                      '&.MuiButton-contained': {
                        ...theme.typography.muiTypography600
                      }
                    }}
                  >Create Layover</Button>
                </Grid>

              </Grid>
            </form>
          </StyledCreateLayoverContainer>
        </Grid>
        <Grid item md={6} sm={12} xs={12}>
          <Grid item xs={12} sx={{mb:2}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <Typography component='span' variant='h4'>{numberWithCommas(todayAvailableRooms)}</Typography>
              <Typography component='span' variant='body1' sx={{ml:1}}>Today's Available Rooms</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{mb:3}}>
            <Divider variant='fullWidth' />
          </Grid>
          <Grid item xs={12} sx={{mb:3}}>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
              <SubHeading title='Recently created layovers' />
              {(swiperLayovers && layovers.length > 0) ? (
                <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'right'}}>
                  <IconButton
                    onClick={() => {
                      if (swiperActivePageNo > 1) {
                        setSwiperActivePageNo(swiperActivePageNo - 1)
                      }
                      swiperLayovers.slidePrev()
                    }}
                    sx={{p:0}}
                  >
                    <ChevronLeftIcon sx={{color: theme.palette.grey[500]}} />
                  </IconButton>
                  <Typography component='span' sx={{
                    ...theme.typography.textSmall,
                    ...theme.typography.fontMedium500,
                    color: theme.palette.dashboardWidgetHeadline,
                    textAlign: 'center'
                  }}>
                    {swiperActivePageNo} / {swiperTotalPages}
                  </Typography>
                  <IconButton
                    onClick={() => {
                      if (swiperActivePageNo < swiperTotalPages) {
                        setSwiperActivePageNo(swiperActivePageNo + 1)
                      }
                      swiperLayovers.slideNext()
                    }}
                    sx={{p:0}}
                  >
                    <ChevronRightIcon sx={{color: theme.palette.grey[500]}} />
                  </IconButton>
                </Box>
              ) : (null)}
            </Box>
            {layovers.length > 0 ? (
              <Swiper
                className='mySwiper'
                initialSlide={0}
                slidesPerView={1}
                onInit={(e) => {
                  setSwiperLayovers(e)
                }}
                style={{
                  paddingTop: theme.spacing(2),
                  width: '100%'
                }}
              >
                {layovers.map((twoLayovers, index) => (
                  <SwiperSlide key={`lay-swiper-${index}`}>
                    <Box>
                      {twoLayovers.map((layover, indexLayover) => (
                        <Box key={`lay-swiper-${index}-${indexLayover}`} sx={{mb:4}}>
                          <Box sx={{mb:2, display: 'flex', alignItems: 'center'}}>
                            <Box sx={{flexGrow:1}}>
                              <Typography variant='body1' component='span' sx={{cursor: 'pointer'}} onClick={() => handleAirlineNameClick(layover)}>
                                {layover.airlineName}
                              </Typography>
                              <Typography variant='body1' component='span' sx={{ml: 2, color: theme.palette.table.header}}>
                                {layover.toAirportCode}{layover?.flightCode ? `, ${layover.flightCode}` : ''}
                              </Typography>
                            </Box>
                            {layover?.status ? (
                              <Box>
                                <Chip
                                  label={`${layover.status === 'draft' ? 'Draft' : layover.status === 'closed' ? 'Closed' : 'In Progress'}`}
                                  color={layover.status === 'draft' ? 'error' : layover.status === 'closed' ? 'warning' : 'success'}
                                  onClick={() => handleStatusClick(layover)}
                                  sx={{height:'auto', py:.5}}
                                />
                              </Box>
                            ) : (null)}
                          </Box>
                          <Grid container>
                            <Grid item xs={12} sx={{py:1}}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <StyledLayoverItemLabel>Booking Date & Time</StyledLayoverItemLabel>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    {formatTimestamp(layover.bookingDate)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{py:1}}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <StyledLayoverItemLabel>Estimated Arrival at Hotel</StyledLayoverItemLabel>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    {formatTimestamp(layover.estimatedArrival)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{py:1}}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <StyledLayoverItemLabel>Rooms Booked</StyledLayoverItemLabel>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    {layover.roomsBooked} Rooms {prepareMealStatement(layover.isEarlyBirdBreakfast, layover.isBreakfastIncluded, layover.isLunchIncluded, layover.isDinnerIncluded, layover.isSnacks)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{py:1}}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <StyledLayoverItemLabel>Latest Return to Airport</StyledLayoverItemLabel>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    {layover.isDifferentReturnTimes ? `Different return times` : `Same time`}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} sx={{py:1}}>
                              <Grid container>
                                <Grid item xs={6}>
                                  <StyledLayoverItemLabel>Paid By</StyledLayoverItemLabel>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography variant='body1'>
                                    {'Airline'}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      ))}
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (<Box sx={{display:'flex', justifyContent:'center', pt: 25}}><HeadingInput title='No Layover Created For Selected Airline' /></Box>)}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;