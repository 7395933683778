import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Button, Grid, Typography, styled, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import Input from '../../../components/input';
import { InputEndAdornment } from '../components/endAdornment';
import { name as getCurrencyName } from 'better-currency-codes';
import { useLoader } from '../../../providers/loader';
import { createHotelTimings, getHotelTimings, updateHotelTimings } from '../../../services/hotel/hotelTimings';
import { maxToastTime, toastMessages, weekdays } from '../../../helper/constant';
import { toast } from 'react-toastify';
import { CheckBox, Radio, Timepicker } from '../../../components';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { createHotelTimingsDaywiseCheckinouts, getHotelTimingsDaywiseCheckinouts, updateHotelTimingsDaywiseCheckinouts } from '../../../services/hotel/hotelTimingsDaywiseCheckinouts';
import moment from 'moment';
import dayjs from 'dayjs';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import { HotelContext } from '../../../context/hotel';

const hotelTimingFields = [
  'checkin_time',
  'checkout_time',
  'day_stay_from',
  'day_stay_to',
  'early_checkin_from',
  'early_checkin_to',
  'late_checkout_from',
  'late_checkout_to',
  'weekday_checkin_from',
  'weekday_checkin_to',
  'weekend_checkin_from',
  'weekend_checkin_to'
];

const hotelTimingDaywiseFields = [
  'checkin_from',
  'checkin_to',
  'checkout_from',
  'checkout_to'
];

const StyledGridWeekdayInner = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey['A900'],
  borderRadius: '5px',
  justifyContent: 'center',
  alignItems: 'center'
}));

const StyledWeekdayName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  ...theme.typography.fontMedium600,
  color: theme.palette.grey[600],
  textTransform: 'uppercase'
}));

const StyledCopyValueButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textSmall,
  color: theme.palette.menu.light,
  border: `1px dashed ${theme.palette.menu.light}`,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  textTransform: 'none'
}));

const HotelTimings = forwardRef((props, ref) => {
  const { hotel, permission } = props;
  const { setIsFormDirty } = useContext(HotelContext);
  const theme = useTheme();
  const formRef = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  const [currency, setCurrency] = useState('');
  const [hotelTimings, setHotelTimings] = useState({});
  const [hotelTimingsDaywise, setHotelTimingsDaywise] = useState({});
  const [resetFormData, setResetFormData] = useState({});
  const [isPerDayTimings, setIsPerDayTimings] = useState(0);
  const [isDayStayAllowed, setIsDayStayAllowed] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setFocus,
    getValues,
    reset,
    resetField
  } = useForm();

  // checking is form having any unsaved changes or not
  useEffect(() => {
    if (Object.keys(dirtyFields).length > 0) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [dirtyFields, setIsFormDirty]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const onPerDayCheckboxChange = (checked) => {
    setIsPerDayTimings(checked);
  };

  const onIsDayStayAllowedChange = (value) => {
    setIsDayStayAllowed(parseInt(value));
  };

  const copyDaywiseCheckinTimings = () => {
    const checkin_from = getValues('formTimingsDaywise[MON][checkin_from]');
    const checkin_to = getValues('formTimingsDaywise[MON][checkin_to]');
    if (checkin_from || checkin_to) {
      if (checkin_from) {
        for (const dayCode in weekdays) {
          if (dayCode === 'MON') {
            continue;
          }
          resetField(`formTimingsDaywise[${dayCode}][checkin_from]`, {defaultValue: checkin_from})
        }
      }
      if (checkin_to) {
        for (const dayCode in weekdays) {
          if (dayCode === 'MON') {
            continue;
          }
          resetField(`formTimingsDaywise[${dayCode}][checkin_to]`, {defaultValue: checkin_to})
        }
      }
    }
  };

  const copyDaywiseCheckoutTimings = () => {
    const checkout_from = getValues('formTimingsDaywise[MON][checkout_from]');
    const checkout_to = getValues('formTimingsDaywise[MON][checkout_to]');
    if (checkout_from || checkout_to) {
      if (checkout_from) {
        for (const dayCode in weekdays) {
          if (dayCode === 'MON') {
            continue;
          }
          resetField(`formTimingsDaywise[${dayCode}][checkout_from]`, {defaultValue: checkout_from})
        }
      }
      if (checkout_to) {
        for (const dayCode in weekdays) {
          if (dayCode === 'MON') {
            continue;
          }
          resetField(`formTimingsDaywise[${dayCode}][checkout_to]`, {defaultValue: checkout_to})
        }
      }
    }
  };

  /**
   * Get hotel timings from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getHotelTimingsFromApi = useCallback(async (hotelId) => {
    const response = await getHotelTimings(hotelId);
    if (response?.data && response.data.length) {
      setHotelTimings(response.data[0]);
      setIsDayStayAllowed(Number(response?.is_day_stay_allowed));
    } else {
      setHotelTimings({});
      setIsDayStayAllowed(0);
    }
  }, []);

  /**
   * Get hotel timings daywise from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getHotelTimingsDaywiseFromApi = useCallback(async (hotelId) => {
    const response = await getHotelTimingsDaywiseCheckinouts(hotelId);
    if (response?.data && response.data.length) {
      setHotelTimingsDaywise(response.data);
    } else {
      setHotelTimingsDaywise({});
      setIsPerDayTimings(0);
    }
  }, []);

  /**
   * Update hotel timings from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateHotelTimingsViaApi = useCallback(async (hotelId, request) => {
    let response = null;
    // If id exists, call update API
    try {
      if (request?.id) {
        response = await updateHotelTimings(request.id, request);
      } else {
        response = await createHotelTimings(hotelId, request);
      }
      if (response?.data) {
        setHotelTimings({...hotelTimings, ...response.data});
      }
    } catch (e) {
      throw e
    }
  }, [hotelTimings]);

  /**
   * Update hotel timings daywise from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateHotelTimingsDaywiseViaApi = useCallback(async (hotelId, dayCode, request) => {
    let response = null;
    // If id exists, call update API
    try {
      if (request?.id) {
        response = await updateHotelTimingsDaywiseCheckinouts(request.id, request);
      } else {
        response = await createHotelTimingsDaywiseCheckinouts(hotelId, dayCode, request);
      }
      if (response?.data) {
        setHotelTimingsDaywise(hotelTimingsDaywise => {
          const tempHotelTimingsDaywise = [];
          if (hotelTimingsDaywise.length) {
            for (const rfRate of hotelTimingsDaywise) {
              if (rfRate?.id && rfRate.id === response.data.id) {
                tempHotelTimingsDaywise.push({...rfRate, ...response.data});
              } else {
                tempHotelTimingsDaywise.push(rfRate);
              }
            }
          } else {
            tempHotelTimingsDaywise.push(response.data);
          }
          return tempHotelTimingsDaywise;
        });
      }
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    /**
     * NOTE: We need to convert each and every time object into 24 hour time format before passing to API request
     * e.g. Timepicker returns the time object as follows -
     * 
     */
    if (hotel && hotel.id && data) {
      setIsFormDirty(false); // if user clicks on Save changes then Confirmation box will not shown
      
      let promises = [];
      // Update hotel timings
      if (data.formTimings) {
        for (const field of hotelTimingFields) {
          if (data.formTimings?.[field] && data.formTimings?.[field]?.$d) {
            data.formTimings[field] = moment(data.formTimings[field].$d).format('HH:mm:ss.SSS')
          }
        }

        // accept 0 as discount rate in % is done
        let newobj = {
            ...data.formTimings,
            day_stay_discount_percent: data?.formTimings?.day_stay_discount_percent || data?.formTimings?.day_stay_discount_percent === 0
              ? parseInt(data?.formTimings?.day_stay_discount_percent)
              : 0,
        };

        promises.push(updateHotelTimingsViaApi(hotel.id, newobj));
      }

      // Update hotel timings daywise
      if (data.formTimingsDaywise) {
        for (const dayCode in data.formTimingsDaywise) {
          let request = data.formTimingsDaywise[dayCode];
          for (const field of hotelTimingDaywiseFields) {
            if (request?.[field] && request?.[field]?.$d) {
              request[field] = moment(request[field].$d).format('HH:mm:ss.SSS')
            }
          }
          promises.push(updateHotelTimingsDaywiseViaApi(hotel.id, dayCode, request));
        }
      }

      if (promises.length) {
        startLoader();
        await Promise.all(promises).then(() => {
          toast.success(toastMessages.hotel.updateHotelTimings.success, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-timings',
          })
        }).catch((value) => {
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [updateHotelTimingsViaApi, updateHotelTimingsDaywiseViaApi, startLoader, stopLoader]);

  // Call function to submit the form on click of form submit button
  useImperativeHandle(ref, () => ({
    saveChanges: () => {
      handleSubmit(onSubmit)()
    }
  }), [handleSubmit, onSubmit]);

  useEffect(() => {
    if (hotel && hotel.id) {
      startLoader();
      Promise.allSettled([
        getHotelTimingsFromApi(hotel.id),
        getHotelTimingsDaywiseFromApi(hotel.id)
      ]).then(() => {
        stopLoader();
      });
    }
  }, [hotel, getHotelTimingsFromApi, getHotelTimingsDaywiseFromApi, startLoader, stopLoader]);

  // Prepare values to reset the form when API calls completed
  useEffect(() => {
    let tempResetFormData = {};
    if (hotelTimings && Object.keys(hotelTimings).length > 0) {
      tempResetFormData['formTimings'] = hotelTimings;
      // Convert checkbox & radio values into binary value
      tempResetFormData['formTimings']['is_per_day_timing'] = hotelTimings?.is_per_day_timing ? 1 : 0;
      setIsPerDayTimings(hotelTimings?.is_per_day_timing ? 1 : 0);

      // Convert time values in timepicker supported values
      for (const field of hotelTimingFields) {
        if (tempResetFormData.formTimings?.[field]) {
          tempResetFormData.formTimings[field] = dayjs(tempResetFormData.formTimings[field], 'HH:mm:ss')
        }
      }
    }
    if (hotelTimingsDaywise && hotelTimingsDaywise.length > 0) {
      let htdFormData = {};
      for (const htd of hotelTimingsDaywise) {
        htdFormData[htd.day] = htd;
        // Convert time values in timepicker supported values
        for (const field of hotelTimingDaywiseFields) {
          if (htdFormData[htd.day]?.[field]) {
            htdFormData[htd.day][field] = dayjs(htdFormData[htd.day][field], 'HH:mm:ss')
          }
        }
      }
      tempResetFormData['formTimingsDaywise'] = htdFormData;
    }
    setResetFormData(tempResetFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelTimings, hotelTimingsDaywise]);

  // Provide values to reset the form when API calls completed
  useEffect(() => {
    if (Object.keys(resetFormData).length > 0) {
      reset({});
      reset(resetFormData);
    } else {
      reset({});
      reset({});
    }
  }, [reset, resetFormData]);

  useEffect(() => {
    let hotelCurrency = '';
    if (hotel?.currency?.alphabetic_code) {
      const currencyName = getCurrencyName({ code: hotel.currency.alphabetic_code, numeric: false });
      hotelCurrency = currencyName?.unit?.plural ? currencyName.unit.plural : hotel.currency.alphabetic_code;
    }
    setCurrency(hotelCurrency);
  }, [hotel?.currency?.alphabetic_code]);

  // getting value of default checkin time and prefilling into daywise fields
  const prefillSelectedCheckinTimings = useCallback(() => {
    const checkin_from = getValues('formTimings[checkin_time]');

    if (checkin_from) {
      for (const dayCode in weekdays) {
        resetField(`formTimingsDaywise[${dayCode}][checkin_from]`, {
          defaultValue: checkin_from,
        });
      }
    }
  }, [getValues, resetField]);

  useEffect(() => {
    if (isPerDayTimings && Number(isPerDayTimings) === 1) {
      prefillSelectedCheckinTimings();
    }
  }, [isPerDayTimings, prefillSelectedCheckinTimings]);

  return (
    <Box sx={{pt: 3}}>
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Controller
          name={'hotelId'}
          control={control}
          defaultValue={hotel.id}
          render={({ field }) => (
            <Input
              type='hidden'
              formControlProps={{sx: {m:0}}}
              sx={{border:0}}
              {...field}
            />
          )}
        />
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Default check in time *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formTimings[checkin_time]`}
                        control={control}
                        render={({ field }) => (
                          <Timepicker
                            format={'hh:mm a'}
                            inputProps={{
                              formControlProps: {
                                sx: {
                                  marginBottom: 0
                                }
                              }
                            }}
                            isError={!!errors?.formTimings?.checkin_time}
                            errorMessage={errors?.formTimings?.checkin_time && 'Please select the time'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Default check out time *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formTimings[checkout_time]`}
                        control={control}
                        render={({ field }) => (
                          <Timepicker
                            format={'hh:mm a'}
                            inputProps={{
                              formControlProps: {
                                sx: {
                                  marginBottom: 0
                                }
                              }
                            }}
                            isError={!!errors?.formTimings?.checkout_time}
                            errorMessage={errors?.formTimings?.checkout_time && 'Please select the time'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={12} sx={{my: 2}}>
                <Grid container>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    <Controller
                      name={`formTimings[is_per_day_timing]`}
                      control={control}
                      defaultValue={isPerDayTimings}
                      render={({ field }) => (
                        <CheckBox
                          inputRef={field.ref}
                          {...field}
                          disabled={!permission?.edit}
                          checked={isPerDayTimings}
                          onChange={(e) => {
                            onPerDayCheckboxChange(e.target.checked);
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    <Typography variant='caption' sx={{
                      ...theme.typography.textMedium,
                      ml: 1
                    }}>
                      Specify timings per day
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {isPerDayTimings && Number(isPerDayTimings) === 1 ?  
                  <Grid container sx={{justifyContent: 'center', alignItems: 'center'}}>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={10}>
                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                              <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                                <HeadingInput title='Check in time' />
                              </Grid>
                            </Grid>
                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                              <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                                <HeadingInput title='Check out time' />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      {Object.keys(weekdays).map((dayCode, i) => {
                        const dayData = weekdays[dayCode];
                        return (
                          <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
                            <Grid item xs={2}>
                              <StyledWeekdayName>{dayData.name}</StyledWeekdayName>
                            </Grid>
                            <Grid item xs={10}>
                              <Grid container spacing={10}>
                                <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                  <StyledGridWeekdayInner container>
                                    <Grid item xs={2} sx={{px: 2}}>
                                      {/* From */}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Controller
                                        name={`formTimingsDaywise[${dayCode}][checkin_from]`}
                                        control={control}
                                        render={({ field }) => (
                                          <Timepicker
                                            inputProps={{
                                              formControlProps: {
                                                sx: {
                                                  marginBottom: 0,
                                                  width: '150px'
                                                }
                                              }
                                            }}
                                            format={'hh:mm a'}
                                            inputRef={field.ref}
                                            {...field}
                                            disabled={!permission?.edit}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {/* <Grid item xs={2} sx={{px: 2}}>
                                      To
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Controller
                                        name={`formTimingsDaywise[${dayCode}][checkin_to]`}
                                        control={control}
                                        render={({ field }) => (
                                          <Timepicker
                                            inputProps={{
                                              formControlProps: {
                                                sx: {
                                                  marginBottom: 0
                                                }
                                              }
                                            }}
                                            format={'hh:mm a'}
                                            inputRef={field.ref}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </Grid> */}
                                  </StyledGridWeekdayInner>
                                </Grid>

                                <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                  <StyledGridWeekdayInner container sx={{justifyContent: 'center', alignItems: 'center'}}>
                                    <Grid item xs={2} sx={{px: 2}}>
                                      {/* From */}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Controller
                                        name={`formTimingsDaywise[${dayCode}][checkout_from]`}
                                        control={control}
                                        render={({ field }) => (
                                          <Timepicker
                                            inputProps={{
                                              formControlProps: {
                                                sx: {
                                                  marginBottom: 0,
                                                  width: '150px'
                                                }
                                              }
                                            }}
                                            format={'hh:mm a'}
                                            inputRef={field.ref}
                                            {...field}
                                            disabled={!permission?.edit}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    {/* <Grid item xs={2} sx={{px: 2}}>
                                      To
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Controller
                                        name={`formTimingsDaywise[${dayCode}][checkout_to]`}
                                        control={control}
                                        render={({ field }) => (
                                          <Timepicker
                                            inputProps={{
                                              formControlProps: {
                                                sx: {
                                                  marginBottom: 0
                                                }
                                              }
                                            }}
                                            format={'hh:mm a'}
                                            inputRef={field.ref}
                                            {...field}
                                          />
                                        )}
                                      />
                                    </Grid> */}
                                  </StyledGridWeekdayInner>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container sx={{justifyContent: 'center', alignItems: 'center'}}>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={10}>
                          <Grid container spacing={10}>
                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                              <StyledCopyValueButton
                                variant='outlined'
                                startIcon={<CopyAllIcon />}
                                onClick={copyDaywiseCheckinTimings}
                                disabled={!permission?.edit}
                              >
                                Copy values from Monday to all other days
                              </StyledCopyValueButton>
                            </Grid>
                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                              <StyledCopyValueButton
                                variant='outlined'
                                startIcon={<CopyAllIcon />}
                                onClick={copyDaywiseCheckoutTimings}
                                disabled={!permission?.edit}
                              >
                                Copy values from Monday to all other days
                              </StyledCopyValueButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                : null}
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={12} sx={{pt: 1, pb: 2}}>
                    <HeadingInput title={`Are there times where there is limited check-in capacity? E.g. from 10:00 pm - 04:00 am only 10 rooms can be checked in *`} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekdays</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                      From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[weekday_checkin_from]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[weekday_checkin_to]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formTimings[weekday_checkin_no_rooms]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formTimings?.weekday_checkin_no_rooms}
                            errorMessage={errors?.formTimings?.weekday_checkin_no_rooms && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of Rooms
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /^([1-9]\d*)$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Weekends</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[weekend_checkin_from]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[weekend_checkin_to]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formTimings[weekend_checkin_no_rooms]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formTimings?.weekend_checkin_no_rooms}
                            errorMessage={errors?.formTimings?.weekend_checkin_no_rooms && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end'>
                                No. of Rooms
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /^([1-9]\d*)$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Is flexibility in Check In and Check Out possible?' />
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{pt: 1, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Extra Costs for flexible Check in and Check out' />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Early Check-ins</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[early_checkin_from]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[early_checkin_to]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formTimings[early_checkin_extra_cost]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formTimings?.early_checkin_extra_cost}
                            errorMessage={errors?.formTimings?.early_checkin_extra_cost && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /^([1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}>
                <StyledWeekdayName>Late Check-outs</StyledWeekdayName>
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <StyledGridWeekdayInner container>
                      <Grid item xs={2} sx={{px: 2}}>
                        From
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[late_checkout_from]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={2} sx={{px: 2}}>
                        To
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          name={`formTimings[late_checkout_to]`}
                          control={control}
                          render={({ field }) => (
                            <Timepicker
                              inputProps={{
                                formControlProps: {
                                  sx: {
                                    marginBottom: 0
                                  }
                                }
                              }}
                              format={'hh:mm a'}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                    </StyledGridWeekdayInner>
                  </Grid>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12}>
                      <Controller
                        name={`formTimings[late_checkout_extra_cost]`}
                        control={control}
                        defaultValue={0}
                        render={({ field }) => (
                          <Input
                            formControlProps={{
                              sx: {
                                marginBottom: 0
                              }
                            }}
                            isError={!!errors?.formTimings?.late_checkout_extra_cost}
                            errorMessage={errors?.formTimings?.late_checkout_extra_cost && 'Please enter valid rate'}
                            inputRef={field.ref}
                            {...field}
                            disabled={!permission?.edit}
                            endAdornment={
                              <InputEndAdornment position='end' sx={{textTransform:'capitalize'}}>
                                In {currency}
                              </InputEndAdornment>
                            }
                          />
                        )}
                        rules={{
                          pattern: /^([1-9]\d*)(\.\d+)?$/
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container spacing={10}>
                  
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='If day stays are allowed, specify the timings' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <StyledGridWeekdayInner container>
                        <Grid item xs={2} sx={{px: 2}}>
                          From
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name={`formTimings[day_stay_from]`}
                            control={control}
                            render={({ field }) => (
                              <Timepicker
                                inputProps={{
                                  formControlProps: {
                                    sx: {
                                      marginBottom: 0
                                    }
                                  }
                                }}
                                format={'hh:mm a'}
                                inputRef={field.ref}
                                {...field}
                                disabled={!permission?.edit}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2} sx={{px: 2}}>
                          To
                        </Grid>
                        <Grid item xs={4}>
                          <Controller
                            name={`formTimings[day_stay_to]`}
                            control={control}
                            render={({ field }) => (
                              <Timepicker
                                inputProps={{
                                  formControlProps: {
                                    sx: {
                                      marginBottom: 0
                                    }
                                  }
                                }}
                                format={'hh:mm a'}
                                inputRef={field.ref}
                                {...field}
                                disabled={!permission?.edit}
                              />
                            )}
                          />
                        </Grid>
                      </StyledGridWeekdayInner>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Grid>
      </form>
    </Box>
  );
});

export default HotelTimings;