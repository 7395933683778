import { Box, Typography, styled, useTheme } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import Logo from '../../assets/images/logo.png';
import {useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Select, CheckBox } from '../../components';
import Cookies from 'js-cookie';
import { useLoader } from '../../providers/loader';
import { getLayoversByToken } from '../../services/layover';
import { checkLayoverBookingByToken } from '../../services/layover/bookings';
import { useTranslation } from 'react-i18next';
import NotFound from './notFound';

const Wrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.sidebarBg,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(3.75),
  padding: 0,
}))

// const StyledInput = styled(Input)(({ theme }) => ({
//   backgroundColor: theme.palette.text.dark,
//   border: `1px solid ${theme.palette.divider}`,
// }))

const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: theme.typography.body2,
  color: theme.palette.primary.light,
}))

const languages = [
  { name: 'English', key: 'en' },
  { name: 'French', key: 'fr' },
  { name: 'Spanish', key: 'es' },
  { name: 'Portuguese', key: 'pt' },
  { name: 'Italian', key: 'it' },
  { name: 'Chinese', key: 'ch' },
  { name: 'German', key: 'de' },
  { name: 'Russian', key: 'ru' },
  { name: 'Japanese', key: 'jp' }
]

function Welcome() {
  let { iata, token } = useParams();
  const theme = useTheme()
  const navigate = useNavigate()
  const { i18n, t } = useTranslation()
  const { startLoader, stopLoader } = useLoader()
  const [flights, setFlights] = useState([])
  const [showErrorPage, setShowErrorPage] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      language: languages[0].key
    }
  })

  const handleChangeLanguage = (key) => {
    i18n.changeLanguage(key);
  };
  
  /**
   * Get layovers by token from API
   */
  const getLayoversByTokenFromApi = useCallback(async (token, iata) => {
    try {
      const response = await getLayoversByToken(token, {params: {iata}});
      return response;
    } catch (e) {
      throw e
    }
  }, []);
  
  /**
   * Check layover exists by token, airport iata & flight code from API
   */
  const checkLayoverBookingByTokenFromApi = useCallback(async (token, iata, flightCode) => {
    try {
      const response = await checkLayoverBookingByToken(token, {params: {iata, flight_code: flightCode}});
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    if (token && iata) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getLayoversByTokenFromApi(token, iata)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        if (!response?.flights || response?.flights.length <= 0) {
          setShowErrorPage(true);
        } else {
          var flightsOptions = response.flights.map(function(item) {
            return {key: item, name: item};
          }).reverse();
          setFlights(flightsOptions)
        }
      }).catch((e) => {
        if (e.response.status === 404) {
          setShowErrorPage(true);
        }
      }).finally(() => {
        stopLoader();
      });
    }
  }, [token, iata, startLoader, stopLoader, getLayoversByTokenFromApi, setValue]);

  useEffect(() => {
    const welcomeDetails = JSON.parse(Cookies.get('welcomeDetails') || '{}');
    const { language: languageKey, flight_code: flightCode } = welcomeDetails;
    setValue('language', languageKey || languages[0]?.key);
    i18n.changeLanguage(languageKey || languages[0]?.key);
    if (flightCode) {
      setValue('flight_code', flightCode);
    } else {
      setValue('flight_code', flights[0]?.key);
    }
  }, [setValue, languages, flights]);

  const onSubmit = useCallback(async (data) => {
    Cookies.set('welcomeDetails', JSON.stringify({...data}), { expires: 2 });
    if (token && iata && data?.flight_code) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await checkLayoverBookingByTokenFromApi(token, iata, data.flight_code)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        // id: Layover Id, code: Layover Code
        if (!response?.code || !response?.id || !response?.airline_name) {
          setShowErrorPage(true);
        } else {
          Cookies.set('welcomeDetails', JSON.stringify({...data, ...response, layoverToken: token}), { expires: 2 });
          navigate(`/passenger/${iata}/${response.code}/passenger-information`)
        }
      }).catch((e) => {
        if (e.response.status === 404) {
          setShowErrorPage(true);
        }
      }).finally(() => {
        stopLoader();
      });
    }
  }, [token, iata, startLoader, stopLoader, checkLayoverBookingByTokenFromApi])

  if(showErrorPage){
    return <NotFound />
  }

  return (
    <Box>
      <Wrapper sx={{ width: '100%', p: theme.spacing(1) }}>
        <img src={Logo} width={52} alt='Croowy Companion' />
      </Wrapper>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: theme.spacing(2.5),
        px: theme.spacing(4)
      }}>
        <Typography
          sx={{
            ...theme.typography.dashboardHeadline,
            textAlign: 'center',
            p: theme.spacing(2.5),
          }}
          variant='caption'
        >
          {t('welcome')}
        </Typography>
        <Box
          sx={{
            width: '100%',
            mx: theme.spacing(2.5),
            padding: 0,
          }}
        >
          <Typography
            sx={{
              ...theme.typography.textMedium,
              textAlign: 'left',
              fontWeight: 'normal',
              marginBottom: theme.spacing(2),
              padding: 0,
            }}
            variant='caption'
          >
            {t('preferredLang')}
          </Typography>
          <Controller
            name='language'
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                options={languages}
                onChange={(e) => {
                  handleChangeLanguage(e.target.value)
                  field.onChange(e.target.value)
                }}
                value={field.value}
                sx={{
                  backgroundColor: theme.palette.text.dark,
                  border: `1px solid ${theme.palette.divider}`,
                }}
                placeholder={t('selectLang')}
                formControlProps={{
                  sx: {
                    mt: 2
                  },
                }}
                inputRef={field.ref}
                isError={!!errors.language}
                errorMessage={
                  errors.language && t('langRequired')
                }
              />
            )}
            rules={{ required: t('langRequired') }}
          />
          <Typography
            sx={{
              ...theme.typography.textMedium,
              textAlign: 'left',
              fontWeight: 'normal',
              m: 0, p: 0,
            }}
            variant='caption'
          >
            {t('flightNo')}
          </Typography>
          <Controller
            name='flight_code'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <Select
                {...field}
                options={flights}
                value={field.value}
                sx={{
                  backgroundColor: theme.palette.text.dark,
                  border: `1px solid ${theme.palette.divider}`,
                }}
                placeholder={t('selectFlight')}
                formControlProps={{
                  sx: {
                    mt: 2
                  },
                }}
                inputRef={field.ref}
                isError={!!errors.flight_code}
                errorMessage={
                  errors.flight_code && t('flightRequired')
                }
              />
            )}
            rules={{ required: t('flightRequired') }}
          />
        </Box>
        <Box sx={{ pb: 2}}>
          <Controller
            name='acceptTerms'
            control={control}
            defaultValue={false}
            rules={{ required: t('iAgreeTermsErrorMess') }}
            render={({ field, fieldState: { error } }) => (
              <>
                <CheckBox
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                  sx={{ ml: 1 }}
                  label={
                    <Typography sx={{ fontSize: theme.typography.body2, pl: 1, pt: 0 }}>
                      {t('iAgreeTerms.firstPart')} <StyledLink to='https://croowy.de/terms'>{t('iAgreeTerms.secondPart')}</StyledLink>
                    </Typography>
                  }
                />
                {error ? (
                  <Typography color='error' sx={{ fontSize: theme.typography.body2, ml: 3}}>
                    {error.message}
                  </Typography>
                ):(null)}
              </>
            )}
          />
        </Box>
        <Button
          variant='contained'
          width='100%'
          onClick={handleSubmit(onSubmit)}
        >
          {t('continue')}
        </Button>
      </Box>
    </Box>
  )
}

export default Welcome;