import { useEffect, useState, useCallback } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import {
  FormControl,
  Typography,
  useTheme,
  Grid,
  Box,
} from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Input, Select, AlertDialog, CheckBox, Radio } from '../../components'
import { Controller, useForm } from 'react-hook-form'
import Cookies from 'js-cookie';
import { confirmPassengerEmail } from '../../services/layover/bookings'
import { useLoader } from '../../providers/loader';
import { useTranslation } from 'react-i18next';

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  fontSize: theme.typography.body2,
  fontWeight: '100',
  padding: theme.spacing(2.5)
}))

const LinkedTo = styled(Button)(({ theme }) => ({
  textDecoration: 'none',
  color: 'white',
  marginBottom: theme.spacing(2),
  display: 'flex',
}))

const FormField = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2.5),
}))

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.text.dark,
  border: `1px solid ${theme.palette.divider}`,
}))

const Para = styled(Typography)(({ theme }) => ({
  ...theme.typography.textMedium,
  marginBottom: theme.spacing(0.6),
}))

const Span = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxxSmall,
  marginBottom: theme.spacing(0.6),
  color: theme.palette.grey[700],
}))

function PassengerInfo() {
  const theme = useTheme()
  const navigate = useNavigate()
  const { t } = useTranslation()
  let { iata, code } = useParams()
  const { startLoader, stopLoader } = useLoader();
  const [dialogOpen, setDialogOpen] = useState(false)
  const [coPassenger, setCoPassenger] = useState(0);
  const [airlineName, setAirlineName] = useState('');
  const [totalPassengerCount, setTotalPassengerCount] = useState(0);
  const [totalAvailableRoomsCount, setTotalAvailableRoomsCount] = useState(0);
  const [coPassengerOptions, setCoPassengerOptions] = useState([]);
  const [roomOptions, setRoomOptions] = useState([]);
  const [layoverToken, setLayoverToken] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm();

  const confirmPassengerEmailViaApi = useCallback(async (layoverId, passengerMail) => {
    try {
      const response = await confirmPassengerEmail(layoverId, passengerMail);
      return response
    } catch (e) {
      throw e
    }
  }, []);

  const onSubmit = useCallback(async (data) => {
    startLoader();
    const savedWelcomeDetails = JSON.parse(Cookies.get('welcomeDetails'));
    new Promise(async (resolve, reject) => {
      try {
        const response = await confirmPassengerEmailViaApi(savedWelcomeDetails?.id, data?.lead_passenger_email)
        resolve(response)
      } catch (e) {
        reject(e)
      }
    }).then(response => {
      if(response?.isBookingExists){
        setDialogOpen(true)
      }else{
        const noOfCoPassenger = coPassenger ? data.noOfCoPassenger : 0;
        if(!coPassenger){
          Cookies.remove('passengerRoomsDetails')
        }
        Cookies.set('passengerInfo', JSON.stringify({...data, noOfCoPassenger}), { expires: 2 });
        navigate(`/passenger/${iata}/${code}/hotel-rooms`);
      }
    }).catch((e) => {
    }).finally(() => {
      stopLoader();
    });
  }, [code, iata, coPassenger, confirmPassengerEmailViaApi, navigate, startLoader, stopLoader])

  useEffect(() => {
    const savedWelcomeDetails = JSON.parse(Cookies.get('welcomeDetails'));
    setAirlineName(savedWelcomeDetails?.airline_name);

    let totalPassengerRemaining = 0;
    for (const roomType in savedWelcomeDetails.roomCountWithType) {
      const { occupancy, total } = savedWelcomeDetails.roomCountWithType[roomType];
      totalPassengerRemaining += occupancy * total;
    }
    setTotalPassengerCount(totalPassengerRemaining ? Number(totalPassengerRemaining) : 0);

    let totalAvailableRooms = 0;
    if (savedWelcomeDetails?.totalAvailableRooms === totalPassengerRemaining) {
      totalAvailableRooms = Number(totalPassengerRemaining);
    } else {
      totalAvailableRooms = Number(savedWelcomeDetails?.totalAvailableRooms);
    }
    setTotalAvailableRoomsCount(totalAvailableRooms ? Number(totalAvailableRooms) : 0);
    
    const passengerInfoDetails = JSON.parse(Cookies.get('passengerInfo') || '{}');
    const { lead_passenger_first_name, lead_passenger_last_name, lead_passenger_email, coPassenger, noOfCoPassenger, noOfRooms, is_passenger_disabled, is_family_room, is_pets } = passengerInfoDetails;
    setValue('lead_passenger_first_name', lead_passenger_first_name || '');
    setValue('lead_passenger_last_name', lead_passenger_last_name || '');
    setValue('lead_passenger_email', lead_passenger_email || '');
    setValue('coPassenger', coPassenger || 0);
    setValue('noOfCoPassenger', noOfCoPassenger || 1);
    setValue('noOfRooms', noOfRooms || 1);
    setValue('is_passenger_disabled', is_passenger_disabled || 0);
    setValue('is_family_room', is_family_room || false);
    setValue('is_pets', is_pets || false);
    setCoPassenger(coPassenger || 0);
  }, [setValue]);

  useEffect(() => {
    const maxCoPassenger = 5;
    if (totalPassengerCount !== 0) {
      const rooms = Array.from({ length: Math.min(totalPassengerCount - 1, maxCoPassenger) }, (_, index) => ({
          name: index + 1,
          key: index + 1,
        })
      );
      setCoPassengerOptions(rooms);
    }
  }, [totalPassengerCount]);

  useEffect(() => {
    const maxRoomsCount = 6;
    if (totalAvailableRoomsCount !== 0) {
      const savedWelcomeDetails = JSON.parse(Cookies.get('welcomeDetails'));
      if (savedWelcomeDetails) {
        setLayoverToken(savedWelcomeDetails.layoverToken);
        for (const roomType in savedWelcomeDetails.roomCountWithType) {
          const { occupancy, total } = savedWelcomeDetails.roomCountWithType[roomType];
          if (occupancy > 1 && totalAvailableRoomsCount === 2 && occupancy === totalAvailableRoomsCount && total === 0) {
            setRoomOptions([{ name: 2, key: 2 }]);
            return;
          } else {
            const rooms = Array.from({ length: Math.min(totalAvailableRoomsCount, maxRoomsCount) }, (_, index) => ({
              name: index + 1,
              key: index + 1,
            }));
            setRoomOptions(rooms);
          }
        }
      }
    }
  }, [totalAvailableRoomsCount]);

  return (
    <PageContainer>
      <Grid sx={{ ml: -2}} container direction='row' alignItems='center'>
        <LinkedTo onClick={() => navigate(`/passenger/${iata}/${layoverToken}`)}>
          <ChevronLeftIcon sx={{color:theme.mode === 'light' ? theme.palette.primary.main : 'inherit',}}/>
          <Typography
            sx={{
              ...theme.typography.dashboardHeadline,
              fontSize: theme.typography.h6.fontSize,
              color:theme.mode === 'light' ? theme.palette.primary.main : 'inherit',
              textTransform: 'none'
            }}
            variant='caption'
          >
            {t('passengerInfo')}
          </Typography>
        </LinkedTo>
      </Grid>
      <Para sx={{ textWrap: 'wrap',  mb: 2 }}>
        {t('passengerInfoTitle', {airlineName})}
      </Para>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormField>
            <Para>{t('yourFirstName')}</Para>
            <Controller
              name='lead_passenger_first_name'
              control={control}
              rules={{ required: t('yourFirstName') + ' ' + t('isRequired') }}
              render={({ field }) => (
                <StyledInput
                  type='text'
                  placeholder={t('yourFirstName')}
                  {...field}
                  isError={!!errors.lead_passenger_first_name}
                  errorMessage={
                    errors.lead_passenger_first_name && (t('yourFirstName') + ' ' + t('isRequired'))
                  }
                />
              )}
            />
          </FormField>
          <FormField>
            <Para>{t('yourLastName')}</Para>
            <Controller
              name='lead_passenger_last_name'
              control={control}
              rules={{ required: t('yourLastName') + ' ' + t('isRequired') }}
              render={({ field }) => (
                <StyledInput
                  type='text'
                  placeholder={t('yourLastName') }
                  {...field}
                  isError={!!errors.lead_passenger_last_name}
                  errorMessage={
                    errors.lead_passenger_last_name && (t('yourLastName') + ' ' + t('isRequired'))
                  }
                />
              )}
            />
          </FormField>
          <FormField>
            <Para>{t('emailAdd')}</Para>
            <Controller
              name='lead_passenger_email'
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
                  message: t('emailError')
                }
              }}
              render={({ field }) => (
                <StyledInput
                  type='email'
                  placeholder={t('emailAdd')}
                  {...field}
                  isError={!!errors.lead_passenger_email}
                  errorMessage={
                    errors.lead_passenger_email && t('emailError')
                  }
                />
              )}
            />
          </FormField>
          <FormField>
            {(coPassengerOptions.length > 0) ? (
              <FormControl component='fieldset'>
                <Para>{t('wishCoPassenger')}</Para>
                <Controller
                  name='coPassenger'
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <Radio
                      options={[
                        { value: 1, label: t('yes') },
                        { value: 0, label: t('no') }
                      ]}
                      inputRef={field.ref}
                      {...field}
                      value={coPassenger}
                      onChange={(e) => {
                        setCoPassenger(Number(e.target.value))
                        field.onChange(e)
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : (<></>)}
          </FormField>
          {Number(coPassenger) ? (
            <>
              <FormField>
                <FormControl fullWidth>
                  <Para>{t('needCoPassenger')}</Para>
                  <Controller
                    name='noOfCoPassenger'
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <Select
                        // options={[{ name: 1, key: 1 }, { name: 2, key: 2 }, { name: 3, key: 3 }, { name: 4, key: 4 }, { name: 5, key: 5 }]}
                        options={coPassengerOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        inputRef={field.ref}
                        sx={{
                          backgroundColor: theme.palette.text.dark,
                          border: `1px solid ${theme.palette.divider}`,
                        }}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </FormField>
              <FormField>
                <FormControl fullWidth>
                  <Para>{t('needRooms')}</Para>
                  <Span>{t('needRoomsNote')}</Span>
                  {roomOptions && roomOptions.length < 6 
                    ? (<Span sx={{fontStyle:'italic', color:theme.palette.roseGold[100]}}>{t('remainingRoomsNote', {remainingRoomsCount:roomOptions.length})}</Span>) 
                    : (null)
                  }
                  <Controller
                    name='noOfRooms'
                    control={control}
                    defaultValue={1}
                    render={({ field }) => (
                      <Select
                        // options={[{ name: 1, key: 1 }, { name: 2, key: 2 }, { name: 3, key: 3 }, { name: 4, key: 4 }, { name: 5, key: 5 }, { name: 6, key: 6 }]}
                        options={roomOptions}
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        inputRef={field.ref}
                        sx={{
                          backgroundColor: theme.palette.text.dark,
                          border: `1px solid ${theme.palette.divider}`,
                        }}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </FormField>
          </>):(null)}
          <FormField>
            <FormControl component='fieldset'>
              <Para>
                {t('challengedDisable')}
              </Para>
              <Controller
                name='is_passenger_disabled'
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <Radio
                    options={[
                      { value: 1, label: t('yes') },
                      { value: 0, label: t('no') }
                    ]}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </FormField>
          {/* <FormField>
            <FormControl component='fieldset'>
            <Controller
              name='is_family_room'
              control={control}
              defaultValue={false}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  checked={field.value}
                  onChange={(e) => {field.onChange(e)}}
                  sx={{ marginLeft: theme.spacing(1)}}
                  {...field}
                  label={
                    <Typography sx={{ml: 2}}>
                      {t('reqForFamily')}
                    </Typography>
                  }
                />
              )}
            />
            </FormControl>
          </FormField> */}
          <FormField>
            <FormControl component='fieldset'>
              <Controller
                name='is_pets'
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <CheckBox
                    checked={field.value}
                    onChange={(e) => {field.onChange(e.target.checked)}}
                    sx={{ marginLeft: theme.spacing(1)}}
                    label={
                      <Typography sx={{ml: 2}}>
                        {t('reqForPet')}
                      </Typography>
                    }
                    {...field}
                  />
                )}
              />
            </FormControl>
          </FormField>
          <AlertDialog
            title={t('alertDialog.Roombooked.title')}
            dialogTitleProps={{
              sx: {
                ...theme.typography.textxxLarge,
                pt: theme.spacing(3),
                color: theme.palette.roseGold[100],
                pb: theme.spacing(2)
              }
            }}
            open={dialogOpen}
            onDisagree={()=>{setDialogOpen(false)}}
            noBtnText={t('close')}
            sx={{
              textAlign: 'center'
            }}
            fullWidth={true}
            maxWidth={'xs'}
            stretchBtn
          >
            <Box>
              <Typography sx={{pb:1, lineHeight: theme.lineHeight[1.37]}} variant='body1'>
                {t('alertDialog.Roombooked.message')}
              </Typography>
            </Box>
          </AlertDialog>
          <FormField>
            <Button
              variant='contained'
              width='100%'
              type='submit'
              sx={{
                '&.MuiButton-contained': {
                  ...theme.typography.muiTypography400
                }
              }}
            >
              {t('continue')}
            </Button>
          </FormField>
        </form>
      </Box>
    </PageContainer>
  );
}

export default PassengerInfo;