import { Box, styled } from '@mui/material';
import { useContext, useEffect } from 'react';
import { HotelContext } from '../../context/hotel';
import { pageTitles, userRoles } from '../../helper/constant';
import { AirlineContext } from '../../context/airline';
import comingSoonImg from '../../assets/images/coming_soon.png';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '85vh',
  backgroundColor: theme.palette.primary.surface,
}));

const StyledImg = styled('img')(({ theme }) => ({
  height: 'auto',
  maxWidth: '100%',
  objectFit: 'contain',
}));

const Error404 = (props) => {
  const { role } = props;
  const hotelContext = useContext(HotelContext);
  const airlineContext = useContext(AirlineContext);

  useEffect(() => {
    switch(role) {
      case userRoles.hotelAdmin: {
        hotelContext?.setIsCommonTopbar(false)
        break;
      }
      case userRoles.airlineAdmin: {
        airlineContext?.setIsCommonTopbar(false)
        break;
      }
    }
  }, [role, hotelContext, airlineContext]);

  return (
    <StyledBox>
      <StyledImg
        src={comingSoonImg}
        alt='coming soon'
      />
    </StyledBox>
  );
};

export default Error404;