import { userRoles } from './constant';
import LayoverMgmtDark from '../assets/icons/theme/dark/buliding.svg';
import TransportationMgmtDark from '../assets/icons/theme/dark/car.svg';
import DashboardDark from '../assets/icons/theme/dark/category.svg';
import BillingDark from '../assets/icons/theme/dark/receipt-2.svg';
import ReportsDark from '../assets/icons/theme/dark/status-up.svg';
import AvailabilityMgmtDark from '../assets/icons/theme/dark/task-square.svg';
import RatesConditionsDark from '../assets/icons/theme/dark/clipboard-text.svg';
import HotelAvailabilityMgmtDark from '../assets/icons/theme/dark/hotel-line.svg';

// User accessible menus
export const menus = {
  [userRoles.airlineAdmin]: [
    {
      name: 'Dashboard',
      route: '/',
      active: true,
      icon: <img src={DashboardDark} alt='Dashboard' />,
      isMobile: true
    },
    {
      name: 'Hotel Availability',
      route: '/hotel/availability',
      active: true,
      icon: <img src={HotelAvailabilityMgmtDark} alt='Hotel Availability' />,
      isMobile: true
    },
    {
      name: 'Layover Management',
      route: '/layovers',
      active: true,
      icon: <img src={LayoverMgmtDark} alt='Layover Management' />,
      isMobile: true
    },
    // {
    //   name: 'Transportation Management',
    //   route: '/rates-conditions',
    //   active: true,
    //   icon: <img src={TransportationMgmtDark} alt='Transportation Management' />,
    //   isMobile: false
    // },
    {
      name: 'Billing Management',
      route: '/billing',
      active: true,
      icon: <img src={BillingDark} alt='Billing Management' />,
      isMobile: true
    },
    // {
    //   name: 'Reports/Analytics',
    //   route: '/reports',
    //   active: true,
    //   icon: <img src={ReportsDark} alt='Reports/Analytics' />,
    //   isMobile: false
    // },
  ],
  [userRoles.airlineGroundStaff]: [
    {
      name: 'Dashboard',
      route: '/',
      active: true,
      icon: <img src={DashboardDark} alt='Dashboard' />,
      isMobile: true
    },
    {
      name: 'Hotel Availability',
      route: '/hotel/availability',
      active: true,
      icon: <img src={HotelAvailabilityMgmtDark} alt='Hotel Availability' />,
      isMobile: true
    },
    {
      name: 'Layover Management',
      route: '/layovers',
      active: true,
      icon: <img src={LayoverMgmtDark} alt='Layover Management' />,
      isMobile: true
    },
    // {
    //   name: 'Transportation Management',
    //   route: '/rates-conditions',
    //   active: true,
    //   icon: <img src={TransportationMgmtDark} alt='Transportation Management' />
    // },
    {
      name: 'Billing Management',
      route: '/billing',
      active: true,
      icon: <img src={BillingDark} alt='Billing Management' />,
      isMobile: true
    },
    // {
    //   name: 'Reports/Analytics',
    //   route: '/reports',
    //   active: true,
    //   icon: <img src={ReportsDark} alt='Reports/Analytics' />
    // },
  ],
  [userRoles.hotelAdmin]: [
    {
      name: 'Dashboard',
      route: '/',
      active: true,
      icon: <img src={DashboardDark} alt='Dashboard' />,
      isMobile: true
    },
    {
      name: 'Layover Management',
      route: '/layovers',
      active: true,
      icon: <img src={LayoverMgmtDark} alt='Layover Management' />,
      isMobile: true
    },
    {
      name: 'Availability Management',
      route: '/availability',
      active: true,
      icon: <img src={AvailabilityMgmtDark} alt='Availability Management' />,
      isMobile: true
    },
    {
      name: 'Rates and Conditions',
      route: '/rates-conditions',
      active: true,
      icon: <img src={RatesConditionsDark} alt='Rates and Conditions' />,
      isMobile: true
    },
    {
      name: 'Billing Management',
      route: '/billing',
      active: true,
      icon: <img src={BillingDark} alt='Billing Management' />,
      isMobile: true
    },
    {
      name: 'Reports/Analytics',
      route: '/reports',
      active: true,
      icon: <img src={ReportsDark} alt='Reports/Analytics' />,
      isMobile: true
    },
  ],
  [userRoles.hotelFrontDesk]: [
    {
      name: 'Dashboard',
      route: '/',
      active: true,
      icon: <img src={DashboardDark} alt='Dashboard' />,
      isMobile: true
    },
    {
      name: 'Layover Management',
      route: '/layovers',
      active: true,
      icon: <img src={LayoverMgmtDark} alt='Layover Management' />,
      isMobile: true
    },
    {
      name: 'Availability Management',
      route: '/availability',
      active: true,
      icon: <img src={AvailabilityMgmtDark} alt='Availability Management' />,
      isMobile: true
    },
    {
      name: 'Rates and Conditions',
      route: '/rates-conditions',
      active: true,
      icon: <img src={RatesConditionsDark} alt='Rates and Conditions' />,
      isMobile: true
    },
    {
      name: 'Billing Management',
      route: '/billing',
      active: true,
      icon: <img src={BillingDark} alt='Billing Management' />,
      isMobile: true
    }
  ]
};