import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { HeadingInput } from '../components/headingInput';
import { Button as CustomButton, Input, Radio } from '../../../components';
import { useLoader } from '../../../providers/loader';
import { maxToastTime, toastMessages } from '../../../helper/constant';
import { toast } from 'react-toastify';
import { prepareImageUrlByPath } from '../../../utils';
import AddIcon from '@mui/icons-material/Add';
import TransportationAddSchedule from './transportationAddSchedule';
import TransportationSchedule from './transportationSchedule';
import { createHotelTransportations, getHotelTransportations } from '../../../services/hotel/hotelTransportations';
import { getAirports } from '../../../services/airport';
import _ from 'lodash';
import { uploadFiles } from '../../../services/other/upload';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import { HotelContext } from '../../../context/hotel';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

// const StyledAttachFileIcon = styled(AttachFileIcon)(({ theme }) => ({
//   color: theme.palette.primary.light,
//   marginLeft: theme.spacing(2),
//   rotate: '45deg',
//   transform: 'scaleX(-1)'
// }));

// const StyledFileInput = styled(Input)(({ theme }) => ({
//   borderTopLeftRadius: 0,
//   borderTopRightRadius: 0,
//   paddingTop: theme.spacing(1),
//   paddingBottom: theme.spacing(1),
//   '& input, & input:disabled': {
//     cursor: 'pointer',
//     opacity: '0',
//     zIndex: 1
//   },
//   '& .file-input-label': {
//     ...theme.typography.fontMedium500,
//     color: theme.palette.grey[800],
//     position: 'absolute',
//     left: theme.spacing(6)
//   }
// }));

// const StyledFileInputLabel = styled(Typography)(({ theme }) => ({
//   ...theme.typography.textMedium
// }));

const StyledAirportName = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxLarge,
  color: theme.palette.primary.light
}));

const Transportation = forwardRef((props, ref) => {
  const { hotel, permission } = props;
  const { setIsFormDirty } = useContext(HotelContext);
  const formRef = useRef(null);
  const { startLoader, stopLoader } = useLoader();
  const [airports, setAirports] = useState({});
  const [transportation, setTransportation] = useState({});
  const [airportSchedules, setAirportSchedules] = useState({});
  const [resetFormData, setResetFormData] = useState({});
  const [filename, setFilename] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [selectedSource, setSelectedSource] = useState('');
  const [dialogAirportId, setDialogAirportId] = useState(null);
  const [dialogTerminalId, setDialogTerminalId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isShuttleService, setIsShuttleService] = useState(null);
  const [copiedSchedule, setCopiedSchedule] = useState(null);
  // eslint-disable-next-line no-unused-vars
  let i = 0;

  const {
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
    setFocus,
    reset,
    setValue
  } = useForm({
    shouldUnregister: true
  });

  // checking is form having any unsaved changes or not
  const hasUnsavedChanges = Object.keys(dirtyFields).length > 0;
  useEffect(() => {
    setIsFormDirty(hasUnsavedChanges);
  }, [hasUnsavedChanges, setIsFormDirty]);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
  
    if (firstError) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);

  const onShuttleServiceCheckboxChange = (value) => {
    setIsShuttleService(parseInt(value));
  };

  // Generate UI on click on create schedule button in add schedule dialog
  const onCreateSchedule = (source, airportId, terminalId, arrDayCodes) => {
    setDialogOpen(false);
    if (arrDayCodes && arrDayCodes.length) {
      setAirportSchedules(oldAirportSchedules => {
        let tempAirportSchedules = _.cloneDeep(oldAirportSchedules);
        tempAirportSchedules[airportId].terminals[terminalId].schedules.push({
          id: uuidv4(),
          source: source,
          airport_terminal: terminalId,
          weekday: arrDayCodes,
          time: []
        });
        return tempAirportSchedules;
      });
    }
  };

  // Add timing in transportation schedule
  const onSelectScheduleTime = (source, scheduleId, airportId, terminalId, index, time) => {
    let formattedValue = time;
    if (time?.$d) {
      formattedValue = moment(time.$d).format('HH:mm');
    }
    if (scheduleId && formattedValue) {
      setAirportSchedules(oldAirportSchedules => {
        let tempAirportSchedules = _.cloneDeep(oldAirportSchedules);
        let tempArray = tempAirportSchedules[airportId].terminals[terminalId].schedules;
        if (tempAirportSchedules[airportId].terminals[terminalId].schedules?.[index].time.indexOf(formattedValue) < 0) {
          tempArray?.find((val) => val?.id === scheduleId)?.time.push(formattedValue);
        }
        return tempAirportSchedules;
      });
    }
  };

  // Remove timing from transportation schedule
  const onRemoveScheduleTime = (source, scheduleId, airportId, terminalId, index, time) => {
    setAirportSchedules(oldAirportSchedules => {
      let tempAirportSchedules = _.cloneDeep(oldAirportSchedules);
      let timeIndex =  tempAirportSchedules[airportId].terminals[terminalId].schedules.find((newval) => newval.id === scheduleId).time.indexOf(time);
      if (timeIndex > -1) {
        tempAirportSchedules[airportId].terminals[terminalId].schedules.find((newval) => newval.id === scheduleId).time.splice(timeIndex, 1);
      } 
      return tempAirportSchedules;
    });
  };

  // Copy entire schedule
  const onCopyEntireSchedule = (source, scheduleId, airportId, terminalId, index) => {
    setCopiedSchedule({scheduleId, source, airportId, terminalId, index});
  };

  // Paste entire schedule
  const onPasteEntireSchedule = (source, scheduleId, airportId, terminalId, index) => {
    if (copiedSchedule) {
      setAirportSchedules(oldAirportSchedules => {
        let tempAirportSchedules = _.cloneDeep(oldAirportSchedules);
        if (tempAirportSchedules) {
          const schedule = tempAirportSchedules[airportId]?.terminals[terminalId]?.schedules?.find((val) => val?.id === scheduleId);
          if (schedule) {
            schedule.time = airportSchedules[copiedSchedule.airportId].terminals[copiedSchedule.terminalId].schedules?.find((val) => val?.id === copiedSchedule.scheduleId).time;
          }
          return tempAirportSchedules;
        }
      });
    }
    setCopiedSchedule(null);
  };

  // Delete entire schedule
  const onDeleteEntireSchedule = (source, scheduleId, airportId, terminalId, index) => {
    setAirportSchedules(oldAirportSchedules => {
      let tempAirportSchedules = _.cloneDeep(oldAirportSchedules);
      tempAirportSchedules[airportId].terminals[terminalId].schedules = tempAirportSchedules[airportId].terminals[terminalId].schedules?.filter((newval) => newval.id !== scheduleId);
      return tempAirportSchedules;
    });
    // Delete copied schedule if all params matches
    setCopiedSchedule(oldCopiedSchedule => {
      let tempCopiedSchedule = _.cloneDeep(oldCopiedSchedule);
      if (tempCopiedSchedule && tempCopiedSchedule.airportId === airportId && tempCopiedSchedule.terminalId === terminalId && tempCopiedSchedule.index === index) {
        return null;
      }
      return tempCopiedSchedule;
    });
  };

  /**
   * Get airports from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getAirportsFromApi = useCallback(async (hotelId) => {
    setAirports({});
    setAirportSchedules({});

    const response = await getAirports({params: {hotelId}});
    if (response) {
      setAirports(response);
    } else {
      setAirports({});
      setAirportSchedules({});
    }
  }, []);

  /**
   * Get transportation from API
   * 
   * @param {*} hotelId   Hotel id 
   */
  const getTransportationFromApi = useCallback(async (hotelId) => {
    setTransportation({});
    setIsShuttleService(0);

    const response = await getHotelTransportations(hotelId);
    if (response) {
      setTransportation(response);
      setIsShuttleService(1);
    } else {
      setTransportation({});
      setIsShuttleService(0);
    }
  }, []);

  /**
   * Update transportation from API
   * 
   * @param {*} hotelId   Hotel id
   * @param {*} request   Request
   */
  const updateTransportationViaApi = useCallback(async (hotelId, request) => {
    try {
      const response = await createHotelTransportations(hotelId, request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  /**
   * Upload image from API
   * 
   * @param {*} request   Request
   */
  const uploadImageViaApi = useCallback(async (request) => {
    try {
      const response = await uploadFiles(request);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  // Call API's on form submit
  const onSubmit = useCallback(async (data) => {
    if (hotel && hotel.id && data) {
      setIsFormDirty(false); // if user clicks on Save changes then Confirmation box will not shown

      // Merge the transportation schedules into transportation
      let transportationSchedules = [];
      if (data.formTransportationSchedules) {
        for (const sourceId of Object.keys(data.formTransportationSchedules)) {
          const source = data.formTransportationSchedules[sourceId];
          for (const airportId of Object.keys(source)) {
            const airport = source[airportId];
            for (const terminalId of Object.keys(airport.terminals)) {
              const terminal = airport.terminals[terminalId];
              for (const schedule of terminal.schedules) {
                if ((sourceId === 'ATOH' || sourceId === 'HTOA') && schedule && schedule.weekday && schedule.time) {
                  transportationSchedules.push({
                    airport_terminal: terminalId,
                    source: sourceId,
                    ...schedule
                  })
                }
              }
            }
          }
        }
      }
      let formTransportation = _.cloneDeep(data.formTransportation);
      formTransportation.schedules = transportationSchedules;
      formTransportation.is_wheelchair = formTransportation.is_wheelchair ? true : false;

      // Update transportation
      if (formTransportation) {
        const img = formTransportation.img;
        delete formTransportation.img;

        // Call API
        startLoader();
        new Promise(async (resolve, reject) => {
          try {
            const response = await updateTransportationViaApi(hotel.id, formTransportation);
            if (response?.id && img) {
              // Prepare the form-data for image upload API
              const formData = new FormData();
              formData.append('ref', 'api::hotel-transportation.hotel-transportation');
              formData.append('refId', response.id);
              formData.append('field', 'img');
              formData.append('files', img);
              try {
                const imgResponse = await uploadImageViaApi(formData);
                if (imgResponse && imgResponse?.[0]?.formats?.small?.url) {
                  setFileUrl(imgResponse[0].formats.small.url);
                }
                setValue('formTransportation[img]', null);
                setFilename(null);
              } catch(e) {
                reject(e)
              }
            }
            resolve(response);
          } catch(e) {
            reject(e)
          }
        }).then((response) => {
          toast.success(toastMessages.hotel.updateHotelTransportation.success, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-transportation',
          })
        }).catch((e) => {
          toast.success(toastMessages.hotel.updateHotelTransportation.error, {
            autoClose: maxToastTime,
            toastId: 'update-hotel-transportation',
          })
        }).finally(() => {
          stopLoader();
        });
      }
    }
  }, [setValue, updateTransportationViaApi, uploadImageViaApi, startLoader, stopLoader, setIsFormDirty, hotel]);

  // Call function to submit the form on click of form submit button
  useImperativeHandle(ref, () => ({
    saveChanges: () => {
      handleSubmit(onSubmit)()
    }
  }), [handleSubmit, onSubmit]);

  useEffect(() => {
    if (hotel && hotel.id) {
      startLoader();
      Promise.allSettled([
        getAirportsFromApi(hotel.id),
        getTransportationFromApi(hotel.id)
      ]).then(() => {
        stopLoader();
      });
    }
  }, [hotel, getAirportsFromApi, getTransportationFromApi, startLoader, stopLoader]);

  // Prepare values to reset the form when API calls completed
  useEffect(() => {
    let tempResetFormData = {};
    let tempTpSchedules = {};
    if (transportation && Object.keys(transportation).length > 0) {
      let tpSchedules = [];
      if (transportation?.schedules && Object.keys(transportation?.schedules).length > 0) {
        tpSchedules = [...(transportation?.schedules?.ATOH || []), ...(transportation?.schedules?.HTOA || [])];
        for (const schedule of tpSchedules) {
          if (!tempTpSchedules?.[schedule.airport_terminal.id]) {
            tempTpSchedules[schedule.airport_terminal.id] = [];
          }
          let tempSchedule = _.cloneDeep(schedule);
          delete tempSchedule.createdAt;
          delete tempSchedule.publishedAt;
          delete tempSchedule.updatedAt;
          tempSchedule.airport_terminal = schedule.airport_terminal.id;
          tempTpSchedules[schedule.airport_terminal.id].push(tempSchedule);
        }
      }
      tempResetFormData['formTransportation'] = _.cloneDeep(transportation);
      // Convert checkbox & radio values into binary value
      setIsShuttleService(transportation?.is_shuttle_service_available ? 1 : 0);
      tempResetFormData['formTransportation']['is_shuttle_service_available'] = transportation?.is_shuttle_service_available ? 1 : 0;
      delete tempResetFormData['formTransportation']?.createdAt;
      delete tempResetFormData['formTransportation']?.publishedAt;
      delete tempResetFormData['formTransportation']?.updatedAt;
      delete tempResetFormData['formTransportation']?.img;
      // Convert checkbox & radio values into binary value
      tempResetFormData['formTransportation']['is_wheelchair'] = transportation?.is_wheelchair ? 1 : 0;
      // Set file url
      if (transportation?.img) {
        setFileUrl(transportation?.img?.formats?.small?.url ? transportation.img.formats.small.url : null);
      }
    }

    if (airports && airports.length) {
      let tempAirports = {};
      for (const airport of airports) {
        if (airport?.terminals && airport.terminals.length) {
          let tempAirportTerminals = {};
          for (const terminal of airport.terminals) {
            tempAirportTerminals[terminal.id] = {...terminal};
            tempAirportTerminals[terminal.id].schedules = [];
            if (tempTpSchedules[terminal.id]) {
              tempAirportTerminals[terminal.id].schedules = tempTpSchedules[terminal.id];
            }
          }
          tempAirports[airport.id] = {};
          tempAirports[airport.id] = {...airport, terminals: tempAirportTerminals};
        }
      }
      setAirportSchedules(tempAirports);
      tempResetFormData['formTransportationSchedules'] = tempAirports;
    } else {
      setAirportSchedules({});
    }
    setResetFormData(tempResetFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotel, transportation, airports]);

  // Provide values to reset the form when API calls completed
  useEffect(() => {
    if (Object.keys(resetFormData).length > 0) {
      reset({});
      reset(resetFormData);
    } else {
      reset({});
      reset({});
    }
  }, [reset, resetFormData]);

  return (
    <Box sx={{pt: 3}}>
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <Controller
          name={'hotelId'}
          control={control}
          defaultValue={hotel.id}
          render={({ field }) => (
            <Input
              type='hidden'
              formControlProps={{sx: {m:0}}}
              sx={{border:0}}
              {...field}
            />
          )}
        />
        <Grid container>
          <Grid item xs={12}>
            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
              <Grid item xs={2}></Grid>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                    <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                      <HeadingInput title='Shuttle service available? *' />
                    </Grid>
                    <Grid item xs={12} sx={{py: 1}}>
                      <Controller
                        name={`formTransportation[is_shuttle_service_available]`}
                        control={control}
                        defaultValue={isShuttleService}
                        render={({ field }) => (
                          <Radio
                            options={[
                              {
                                value: 1,
                                label: 'Yes',
                                disabled: !permission?.edit
                              },
                              {
                                value: 0,
                                label: 'No',
                                disabled: !permission?.edit
                              }
                            ]}
                            isError={!!errors?.formTransportation?.is_shuttle_service_available}
                            errorMessage={errors?.formTransportation?.is_shuttle_service_available && 'Please choose the option'}
                            inputRef={field.ref}
                            {...field}
                            onChange={(e) => {
                              onShuttleServiceCheckboxChange(e.target.value);
                              field.onChange(e);
                            }}
                          />
                        )}
                        rules={{
                          required: true
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {Number(isShuttleService) === 1 ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                        <HeadingInput title='Shuttle Description' />
                      </Grid>
                      <Grid item xs={12} sx={{py: 1}}>
                        <Controller
                          name={`formTransportation[shuttle_description]`}
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              sx={{
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                '& .MuiInputBase-input': {
                                  lineHeight: '1.25rem'
                                }
                              }}
                              multiline
                              rows={4}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                        {fileUrl ? (
                          <img loading='lazy' src={process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(fileUrl) : fileUrl} alt='shuttle' />
                        ) : (null)}
                        {/* Hidden temporarily */}
                        {/* <Controller
                          name={`formTransportation[img]`}
                          control={control}
                          render={({ field }) => (
                            <StyledFileInput
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              inputProps={{
                                type: 'file',
                                accept: 'image/png, image/jpeg, image/jpg'
                              }}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                              value={field.value?.fileName}
                              onChange={(e) => {
                                if (e?.target?.value) {
                                  setFilename(getFilenameFromPath(e.target.value))
                                } else {
                                  setFilename(null)
                                }
                                field.onChange(e.target.files[0])
                              }}
                              startAdornment={
                                <StyledAttachFileIcon />
                              }
                              endAdornment={
                                <StyledFileInputLabel className='file-input-label'>{filename ? filename : (transportation?.img?.formats?.small?.url ? `Change Image` : `Attach Image`)}</StyledFileInputLabel>
                              }
                            />
                          )}
                        /> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                        <HeadingInput title='Shuttle Departure Point' />
                      </Grid>
                      <Grid item xs={12} sx={{py: 1}}>
                        <Controller
                          name={`formTransportation[shuttle_departure_point]`}
                          control={control}
                          defaultValue={''}
                          render={({ field }) => (
                            <Input
                              formControlProps={{
                                sx: {
                                  marginBottom: 0
                                }
                              }}
                              sx={{
                                borderBottomLeftRadius: 0,
                                borderBottomRightRadius: 0,
                                '& .MuiInputBase-input': {
                                  lineHeight: '1.25rem'
                                }
                              }}
                              multiline
                              rows={6}
                              inputRef={field.ref}
                              {...field}
                              disabled={!permission?.edit}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={10}>
                        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                          <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                            <HeadingInput title='Maximum Shuttle Capacity *' />
                          </Grid>
                          <Grid item xs={12} sx={{py: 1}}>
                            <Controller
                              name={`formTransportation[max_people]`}
                              control={control}
                              defaultValue={0}
                              render={({ field }) => (
                                <Input
                                  formControlProps={{
                                    sx: {
                                      marginBottom: 0
                                    }
                                  }}
                                  isError={!!errors?.formTransportation?.max_people}
                                  errorMessage={errors?.formTransportation?.max_people && 'Please enter valid number'}
                                  inputRef={field.ref}
                                  {...field}
                                  disabled={!permission?.edit}
                                />
                              )}
                              rules={{
                                required: true,
                                pattern: /^(0|[1-9]\d*)$/
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                          <Grid item xs={12} sx={{py: 1, display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                            <HeadingInput title='Is it wheelchair accessible?' />
                          </Grid>
                          <Grid item xs={12} sx={{py: 1}}>
                            <Controller
                              name={`formTransportation[is_wheelchair]`}
                              control={control}
                              defaultValue={0}
                              render={({ field }) => (
                                <Radio
                                  options={[
                                    {
                                      value: 1,
                                      label: 'Yes',
                                      disabled: !permission?.edit
                                    },
                                    {
                                      value: 0,
                                      label: 'No',
                                      disabled: !permission?.edit
                                    }
                                  ]}
                                  inputRef={field.ref}
                                  {...field}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {(Object.keys(airportSchedules).length > 0) ? (
                  Object.keys(airportSchedules).map((airportId, aInd) => {
                    const airport = airportSchedules[airportId];
                    return (
                      <Grid item xs={12} key={`as-a-${airportId}`}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
                              <Grid item xs={12}>
                                <Divider variant='fullWidth' />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={10}>
                                <Grid container>
                                  <Grid item xs={12} sx={{pt: 1, pb: 2}}>
                                    <StyledAirportName>Airport: {airport.name}</StyledAirportName>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          {(airport?.terminals && Object.keys(airport.terminals).length > 0) ? (
                            Object.keys(airport.terminals).map((terminalId, tInd, {length}) => {
                              i = 0;
                              const terminal = airport.terminals[terminalId];
                              return (
                                <Grid item xs={12} key={`as-at-${terminalId}`}> 
                                  {/* ATOH */}
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Grid container>
                                            <Grid item xs={12} sx={{pt: 1, pb: 2}}>
                                              <HeadingInput title={`Shuttle timings from ${terminal.Name} to Hotel`} />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
            
                                    {(terminal?.schedules && terminal?.schedules.length > 0) ? (
                                      terminal.schedules.filter((val) => (val.source === 'ATOH')
                                        ).map((schedule, index) => {
                                        i++;
                                        return (
                                          <TransportationSchedule
                                            key={`ts-${index}`}
                                            source={'ATOH'}
                                            scheduleId={schedule?.id}
                                            scheduleIndex={index}
                                            scheduleNumber={index + 1}
                                            control={control}
                                            weekday={schedule?.weekday}
                                            time={schedule?.time}
                                            airportId={airport?.id}
                                            terminalId={terminal?.id}
                                            copiedSchedule={copiedSchedule}
                                            onSelectTime={onSelectScheduleTime}
                                            onRemoveTime={onRemoveScheduleTime}
                                            onCopySchedule={onCopyEntireSchedule}
                                            onPasteSchedule={onPasteEntireSchedule}
                                            onDeleteSchedule={onDeleteEntireSchedule}
                                            permission={permission}
                                          />
                                        );
                                      })
                                    ) : ('')}

                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Grid container columnSpacing={10}>
                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                              <CustomButton
                                                variant='contained'
                                                width='100%'
                                                disabled={!permission?.edit}
                                                onClick={() => {
                                                  setSelectedSource('ATOH');
                                                  setDialogAirportId(airport.id);
                                                  setDialogTerminalId(terminal.id);
                                                  setDialogOpen(true)
                                                }}
                                              ><AddIcon />Add Schedule</CustomButton>
                                            </Grid>
                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Divider variant='fullWidth' />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  {/* HTOA */}
                                  <Grid container>
                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 2}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Grid container>
                                            <Grid item xs={12} sx={{pt: 1, pb: 2}}>
                                              <HeadingInput title={`Shuttle timings from Hotel to ${terminal.Name}`} />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
            
                                    {(terminal?.schedules && terminal?.schedules.length > 0) ? (
                                      terminal.schedules.filter((val) => (val.source === 'HTOA')
                                        ).map((schedule, index) => {
                                        i++;
                                        return (
                                          <TransportationSchedule
                                            key={`ts-${index}`}
                                            source={'HTOA'}
                                            scheduleId={schedule?.id}
                                            scheduleIndex={index}
                                            scheduleNumber={index + 1}
                                            control={control}
                                            weekday={schedule?.weekday}
                                            time={schedule?.time}
                                            airportId={airport?.id}
                                            terminalId={terminal?.id}
                                            copiedSchedule={copiedSchedule}
                                            onSelectTime={onSelectScheduleTime}
                                            onRemoveTime={onRemoveScheduleTime}
                                            onCopySchedule={onCopyEntireSchedule}
                                            onPasteSchedule={onPasteEntireSchedule}
                                            onDeleteSchedule={onDeleteEntireSchedule}
                                            permission={permission}
                                          />
                                        );
                                      })
                                    ) : ('')}

                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 5}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Grid container columnSpacing={10}>
                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                              <CustomButton
                                                variant='contained'
                                                width='100%'
                                                disabled={!permission?.edit}
                                                onClick={() => {
                                                  setSelectedSource('HTOA');
                                                  setDialogAirportId(airport.id);
                                                  setDialogTerminalId(terminal.id);
                                                  setDialogOpen(true)
                                                }}
                                              ><AddIcon />Add Schedule</CustomButton>
                                            </Grid>
                                            <Grid item xs={6} sx={{display: 'flex', flexDirection: 'column'}}>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Grid container sx={{justifyContent: 'center', alignItems: 'center', mb: 4}}>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={10}>
                                          <Divider variant='fullWidth' />
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                </Grid>
                              )
                            })
                          ) : (null)}
                        </Grid>
                      </Grid>
                    )
                  })
                ) : (null)}
              </Grid>
            </Grid>
          ) : (null)}

        </Grid>
      </form>

      {/* Render add schedule dialog box */}
      <TransportationAddSchedule
        source={selectedSource}
        airportId={dialogAirportId}
        terminalId={dialogTerminalId}
        open={dialogOpen}
        onDisagree={() => setDialogOpen(false)}
        onAgree={onCreateSchedule}
      />
    </Box>
  );
});

export default Transportation;