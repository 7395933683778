import * as XLSX from 'xlsx';

/**
 * Function to download XLSX file
 *
 * @param {Array} data XLSX data to download (as a 2D array)
 * @param {string} fileName Set XLSX file name
*/
const downloadJsonAsXlsx = async ({ data, fileName }) => {
  try {
        const worksheet = XLSX.utils.aoa_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const xlsxBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const arrayBuffer = new ArrayBuffer(xlsxBinary.length);
        const view = new Uint8Array(arrayBuffer);
        for (let i = 0; i < xlsxBinary.length; i++) {
            view[i] = xlsxBinary.charCodeAt(i) & 0xFF;
        }
        const blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName || 'passengerData.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
  } catch (error) {
    console.error('Error while downloading data in Xlsx format', error);
  }
};

export default downloadJsonAsXlsx;


// /** 
//  *  Function to download CSV file
//  * 
//  *  @param {string} data CSV string to download
//  *  @param {string} fileName set CSV file name
//  *  @param {string} fileType set CSV file type
//  */ 

// const downloadCSVFile = ({ data, fileName, fileType }) => {
//   const blob = new Blob([data], { type: fileType });
//   const objectUrl = window.URL.createObjectURL(blob);
//   const link = document.createElement('a')
//   link.href = window.URL.createObjectURL(blob)
//   link.download = fileName || 'passengerData';
//   document.body.appendChild(link);
//   link.click();
//   document.body.removeChild(link);
//   window.URL.revokeObjectURL(objectUrl);
// }