import { memo, useEffect, useState } from 'react';
import { FormControlLabel, Switch, styled } from '@mui/material';

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 0,
  width: '38px',
  height: '18px',
  '& .MuiSwitch-switchBase': {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& + .MuiSwitch-track': {
      backgroundColor: theme.mode === 'light' ? theme.palette.grey[400] : theme.palette.grey[800],
      opacity: 1
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: .2,
    },
    '&.Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.common.black,
        opacity: 1
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.time.dark,
        opacity: 1
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: .2,
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&::before': {
      left: 12,
    },
    '&::after': {
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.grey[600],
    opacity: 1,
    boxShadow: 'none',
    width: 14,
    height: 14,
    margin: 2,
  }
}));

const InnerSwitch = (props) => {
  const { checked, onChange, formControlLabelSx } = props;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const onChecked = (event) => {
    setIsChecked(event.target.checked);
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <FormControlLabel
      control={
        <Android12Switch
          checked={isChecked}
          onChange={onChecked}
          disableRipple
          {...props}
        />
      }
      sx={{
        m: 0,
        ...formControlLabelSx
      }}
    />    
  );
};

export default memo(InnerSwitch);