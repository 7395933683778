import React from 'react'
import notfound from '../../assets/icons/theme/dark/notfound.svg'
import { Box, Typography, styled, useTheme } from '@mui/material'
// import { Button } from '../../components'
// import CallIcon from '@mui/icons-material/Call'
import { useTranslation, Trans } from 'react-i18next'

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  height: '100vh',
  padding: theme.spacing(4),
}))

const Image = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  height: 'auto',
  marginBottom: theme.spacing(2.5),
}))

function NotFound({allRoomBooked}) {
  const theme = useTheme()
  const { t } = useTranslation()
  return (
    <Container>
      <Image src={notfound} alt='img' />
      <Typography
        sx={{
          ...theme.typography.dashboardHeadline,
          textAlign: 'center',
        }}
        variant='caption'
      >
        {allRoomBooked ? t('noRoomLeft') : t('noLayover')}  
      </Typography>
      {allRoomBooked ? (null):(
        <Typography
          sx={{
            ...theme.typography.textMedium,
            margin: theme.spacing(2.5),
            textAlign: 'center',
          }}
          variant='body1'
        >
          <Trans i18nKey='noLayoverCreate'>
            Sorry! No Layover is created for the given <br /> flight number.
          </Trans>
        </Typography>
      )}
      <Typography
        sx={{
          ...theme.typography.textMedium,
          textAlign: 'center',
        }}
        variant='body1'
      >
        <Trans i18nKey='contactAirline'>
          Please contact Airline ground staff <br /> for further assistance.
        </Trans>
      </Typography>
      {/* <Button
        variant='outlined'
        color='primary'
        style={{ textTransform: 'none', color: theme.palette.primary.light }}
        startIcon={<CallIcon />}
        sx={{
          border: `1px solid ${theme.palette.primary.light}`,
          width: '100%',
          p: 1,
          marginTop: theme.spacing(3),
        }}
      >
        6532 659 856
      </Button> */}
    </Container>
  )
}

export default NotFound
