import React from 'react';
import { Button, styled } from '@mui/material';

const StyledButton = styled(Button)(({ theme }) => ({
  ...theme.typography.textMedium,
  textTransform: 'capitalize',
  color: theme.palette.roseGold[100],
}));

const ActionButton = (props) => {
  const { children } = props;

  return (
    <StyledButton
      variant='text'
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default ActionButton;
