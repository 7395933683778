import { userRoles } from './constant';
export const access = {
  [userRoles.hotelFrontDesk]: {
    pages: {
      '/rates-conditions': {
        view: true,
        edit: false
      },
      '/billing': {
          view: true,
          edit: false
      },
      '/account/settings': {
          view: true,
          edit: false
      },
    },
    custom: {
      'editHotelInfoButton': {
          view: false,
          edit: false
      },
      'rateAndConditionSaveChangesButton': {
          view: false,
          edit: false
      },
      'billingMangementDeleteInvoiceButton': {
          view: false,
          edit: false
      },
      'accountSettingManageUsersBox': {
          view: false,
          edit: false
      },
      'accountSettingHotelFrontDeskBox': {
          view: false,
          edit: false
      },
      'accountSettingManageHotelAdminBox': {
          view: false,
          edit: false
      },
    }
  },
  [userRoles.airlineAdmin]: {
    custom: {
      'accountSettingButton': {
        view: true,
        edit: false
      },
    }
  }
};