import { useState, useCallback, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, useTheme } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Heading } from '../../hotel/components/heading';
import downloadPDF from '../../../utils/downloadPDF';
import PdfViewer from '../../../components/pdfViewer';
import InvoiceDetailsBox from './invoiceDetailsBox';
import { ActionButton } from '../../../components';
import { useLoader } from '../../../providers/loader';
import { prepareImageUrlByPath } from '../../../utils';
import { getInvoiceDetails } from '../../../services/airline';

const InvoiceDetails = () => {
  const theme = useTheme();
  const { invoiceId } = useParams()
  const { startLoader, stopLoader } = useLoader();
  const [pdfUrl, setPdfUrl] = useState('')
  const [billingDetails, setBillingDetails] = useState(null)

  // download invoice
  const handleDownloadInvoice = async (pdfUrl) => {
    await downloadPDF(pdfUrl);
  };

  /**
   * get hotel invoice data from API
   */
  const getInvoiceDetailsFromApi = useCallback(async (hotelId) => {
    try {
      const response = await getInvoiceDetails(hotelId);
      return response;
    } catch (e) {
      throw e
    }
  }, []);

  useEffect(() => {
    if (invoiceId) {
      startLoader();
      new Promise(async (resolve, reject) => {
        try {
          const response = await getInvoiceDetailsFromApi(invoiceId)
          resolve(response)
        } catch (e) {
          reject(e)
        }
      }).then(response => {
        setBillingDetails(response?.data)
        if(response?.data?.invoice?.url){
          const tempUrl = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? prepareImageUrlByPath(response?.data?.invoice?.url) : response?.data?.invoice?.url
          setPdfUrl(tempUrl)
        }
      }).catch((e) => {
      }).finally(() => {
        stopLoader();
      });
    }
  }, [startLoader, stopLoader]);
  
  return (
    <Box sx={{ px: 3, my: 2 }}>
      <Grid container>
        <Grid md={6} sx={{ overflow: 'hidden' }}>
          {/* <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
              p: 1,
            }}
          >
            <Heading title='Invoice Details' />
          </Box>

          <Box sx={{ mt: 2 }}>
            <InvoiceDetailsBox />
          </Box> */}
        </Grid>

        <Grid md={6} sx={{ overflow: 'hidden' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
              p: 1,
            }}
          >
            <Heading title='PDF Preview' />
            <ActionButton
              startIcon={<FileDownloadIcon sx={{ color: theme.palette.roseGold[100] }} />}
              onClick={()=>handleDownloadInvoice(pdfUrl)}
              sx={{
                ...theme.typography.textLarge
              }}
            >Download PDF</ActionButton>
          </Box>

          <PdfViewer pdfUrl={pdfUrl} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceDetails;
