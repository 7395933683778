import { memo, useEffect, useState } from 'react';
import { FormControl, Autocomplete as MuiAutocomplete, TextField, Typography, styled } from '@mui/material';
import { getUniqueId } from '../../utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FormControlContainer = styled(FormControl, {
  shouldForwardProp: (prop) => ['marginBottom'].indexOf(prop) === -1
})(({ theme, marginBottom }) => ({
  display: 'flex',
  marginBottom: marginBottom ? marginBottom : '20px'
}));

const StyledMuiAutocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  backgroundColor: theme.palette.bg[200],
  borderRadius: theme.borderRadius[5],
  '& .MuiFormControl-root .MuiInputBase-root': {
    paddingTop: '10px',
    paddingBottom: '10px',
    '& .MuiInputBase-input': {
      padding: 0
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0
    }
  }
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  ...theme.typography.inlineErrorMesage,
  textAlign: 'left',
  color: theme.palette.error.main
}));

const Autocomplete = (props) => {
  const { options, inputProps, formControlProps, isError, errorMessage } = props;
  const uniqueId = getUniqueId();
  const [validationErrorMessage, setValidationErrorMessage] = useState('');

  useEffect(() => {
    if (isError) { setValidationErrorMessage(errorMessage ? errorMessage : '') }
  }, [isError, errorMessage]);

  return (options ? (
    <FormControlContainer variant='standard' {...formControlProps}>
      <StyledMuiAutocomplete
        id={`autocomplete-${uniqueId}`}
        disablePortal
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            {...inputProps}
          />
        )}
        popupIcon={<KeyboardArrowDownIcon />}
        {...props}
      />
      {(isError && validationErrorMessage) && 
        <ErrorMessage variant='subtitle2'>
          {validationErrorMessage}
        </ErrorMessage>
      }
    </FormControlContainer>
  ) : (null));
};

export default memo(Autocomplete);