import React from 'react'
import Booking from './booking'
import { useParams, useSearchParams } from 'react-router-dom'

const BookingPage = () => {
  let { bookingId } = useParams();
  const [searchParams] = useSearchParams();
  const languageCode = searchParams.get('language_code');

  return (
    <Booking bookingId={bookingId} languageCode={languageCode}/>
  )
}

export default BookingPage;