/**
 * Get unique id
 * 
 * @returns number
 */

function getUniqueId() {
  return (new Date().valueOf());
}

export default getUniqueId;
