/**
 * Create google map link
 * 
 * @param {*} name  Name of the location
 * @param {*} lat   Latitude of the location
 * @param {*} lng   Longitude of the location
 * @returns String
 */

function createMapLink(name, lat, lng) {
  return `https://maps.google.com/?q=${name}&ll=${lat},${lng}`;
}

export default createMapLink;
