/**
 * Get array column (Similar to php array_column)
 * 
 * @param {*} array   Multidimensional array
 * @param {*} column  Column key to search
 * @returns String
 */

function arrayColumn(array, column) {
  return array.map(i => i[column]);
}

export default arrayColumn;