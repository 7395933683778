import { memo, useCallback, useEffect, useState } from 'react';
import { CheckBox, FormDialog, Input, Timepicker, ToggleButtonGroup } from '../../../components';
import { Grid, Typography, useTheme } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import _ from 'lodash';
import RequiredFieldsInfoText from '../../../components/requiredFieldsInfoText';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isMobile } from 'react-device-detect';

const notificationFrequencies = [
  {
    key: '1',
    name: '1 time',
  },
  {
    key: '2',
    name: '2 times',
  },
  {
    key: '3',
    name: '3 times',
  },
  {
    key: '4',
    name: '4 times',
  },
  {
    key: '5',
    name: '5 times',
  }
];

const SetReminder = ({ hotelId, open, onAgree, onDisagree, onClose, reminder }) => {
  const theme = useTheme();
  dayjs.extend(utc)
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isEmailNotification, setIsEmailNotification] = useState(false);
  const [isPushNotification, setIsPushNotification] = useState(false);
  const [isPhoneCall, setIsPhoneCall] = useState(false);
  const [notificationFrequency, setNotificationFrequency] = useState(null);
  const [emailId, setEmailId] = useState('');

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setDialogOpen(open);
    if (open) {
      setIsPushNotification(false);
      setIsPhoneCall(false);
    }
  }, [open, reset, reminder, hotelId]);

  useEffect(() => {
    if (!dialogOpen && onClose) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogOpen]);

  // prefill already saved reminder data
  const prefillReminderData = (reminderprop) => {
    if (reminderprop) {
      setIsEmailNotification(reminderprop?.enable_email);
      setEmailId(reminderprop?.emails);
      setNotificationFrequency(reminderprop?.timing && reminderprop?.timing.length.toString());

      if (reminderprop?.timing && reminderprop?.timing.length > 0) {
        for (let i = 0; i < reminderprop?.timing.length; i++) {
          let convertedTime = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? dayjs(reminderprop?.timing[i], 'HH:mm:ss') : dayjs.utc(reminderprop?.timing[i], 'HH:mm:ss').local();
          setValue(`formReminder[timing][${i}]`, convertedTime);
        }
      }
    } else {
      reset();
      setNotificationFrequency(null);
      setIsEmailNotification(false);
      setEmailId('');
    }
  };

  useEffect(() => {
    prefillReminderData(reminder);
  }, [reminder, hotelId])

  // Set weekdays on form submit
  const onSubmit = useCallback(async (data) => {
    if (data) {
      if (data.formReminder) {
        setDialogOpen(false);
        let request = _.cloneDeep(data.formReminder);
        if (request?.timing && request.timing.length > 0) {
          let notifTimings = [];
          for (const indTime of request.timing) {
            if (indTime && indTime?.$d) {
              let convertedTime = process.env.REACT_APP_ENVIRONMENT === 'LOCAL' ? moment(indTime.$d).format('HH:mm:ss.SSS') : moment.utc(indTime.$d).format('HH:mm:ss.SSS');
              notifTimings.push(convertedTime)
            }
            request.timing = notifTimings;
          }
        }
        if (onAgree) {
          onAgree(hotelId, request)
        }
      }
    }
  }, [onAgree, hotelId]);

  const onDialogAgree = () => {
    handleSubmit(onSubmit)()
  };

  const onDialogDisagree = () => {
    setDialogOpen(false);
    if (onDisagree) { onDisagree() }
  };

  return (
    <FormDialog
      title='Set Daily Reminder'
      open={dialogOpen}
      onAgree={onDialogAgree}
      onDisagree={onDialogDisagree}
      btnText={`Save & Confirm`}
      fullWidth={true}
      maxWidth={'sm'}
      cancelBtnInHeader
    >
      <Grid item xs={12} sx={{py: 1, display: 'flex', justifyContent: 'end'}}>
        <RequiredFieldsInfoText title='* fields are mandatory' />
      </Grid>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>

          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 1}}>
            <Controller
              name={`formReminder[enable_email]`}
              control={control}
              defaultValue={isEmailNotification}
              render={({ field }) => (
                <CheckBox
                  inputRef={field.ref}
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    setIsEmailNotification(e.target.checked)
                    field.onChange(e);
                  }}
                  autoFocus
                  label={
                    <Typography variant='caption' sx={{
                      ...theme.typography.textLarge,
                      ml: 1,
                      color: theme.palette.menu.light
                    }}>
                      Email Notification *
                    </Typography>
                  }
                  formControlLabelProps={{
                    sx: {
                      marginLeft: 0
                    }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3, pl: 4}}>
            <Controller
              name={`formReminder[emails]`}
              control={control}
              defaultValue={emailId}
              render={({ field }) => (
                <Input
                  formControlProps={{
                    sx: {
                      marginBottom: 0,
                      width: '82%'
                    }
                  }}
                  placeholder='Email address'
                  isError={!!errors?.formReminder?.emails}
                  errorMessage={errors?.formReminder?.emails && 'Please enter valid email address'}
                  inputRef={field.ref}
                  {...field}
                />
              )}
              rules={{
                required: isEmailNotification ? true : false,
                pattern: /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
              }}
            />
          </Grid>

          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3, pl: 4}}>
            <Typography variant='caption' sx={{
              ...theme.typography.textLarge,
              color: theme.palette.menu.light
            }}>
              Notification Frequency (no. of times per day) *
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3, pl: 4}}>
            <Controller
              name={`formReminder[frequency_per_day]`}
              control={control}
              defaultValue={notificationFrequency}
              render={({ field }) => (
                <ToggleButtonGroup
                  options={notificationFrequencies}
                  exclusive
                  aria-label='Notification Frequency'
                  isError={!!errors?.formReminder?.frequency_per_day}
                  errorMessage={errors?.formReminder?.frequency_per_day && 'Please select notification frequency'}
                  inputRef={field.ref}
                  {...field}
                  onChange={(e, newVal) => {
                    setNotificationFrequency(newVal)
                    field.onChange(e)
                  }}
                  sx={isMobile ? {flexWrap: 'wrap', gap: 1} : {}}
                  toggleButtonProps={{
                    sx: {
                      ...theme.typography.textLarge,
                      border: `2px solid ${theme.palette.menu.light} !important`,
                      borderRadius: '5px !important',
                      marginRight: theme.spacing(1),
                      textTransform: 'lowercase',
                      '&, &:hover, &.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: theme.mode === 'light' ? theme.palette.menu.main : 'inherit',
                      },
                      '&:last-child': {
                        marginRight: 0
                      },
                      '&.Mui-selected': {
                        border: `2px solid ${theme.palette.menu.light} !important`,
                        color: theme.palette.menu.light
                      }
                    }
                  }}
                />
              )}
              rules={{
                required: (isEmailNotification || isPushNotification || isPhoneCall) ? true : false
              }}
            />
          </Grid>

          {notificationFrequency ? (
            <>
              <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3, pl: 4}}>
                <Typography variant='caption' sx={{
                  ...theme.typography.textLarge,
                  color: theme.palette.menu.light
                }}>
                  Specify notification timing *
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{display: 'flex', alignItems: 'center', mb: 3, pl: 4}}>
                {Array.from(
                  { length: Number(notificationFrequency) },
                  (_, i) => (
                    <Controller
                      name={`formReminder[timing][${i}]`}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Timepicker
                            inputProps={{
                              formControlProps: {
                                sx: {
                                  marginRight: 1,
                                  marginBottom: 0,
                                  maxWidth: '100px',
                                },
                              },
                            }}
                            format={'hh:mm a'}
                            isError={!!errors?.formReminder?.timing?.[i + 1]}
                            inputRef={field.ref}
                            {...field}
                          />
                        );
                      }}
                      rules={{ required: true }}
                    />
                  )
                )}
              </Grid>
            </>
          ): (null)}

        </Grid>
      </form>
    </FormDialog>
  );
};

export default memo(SetReminder);