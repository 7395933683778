import { Typography, styled } from '@mui/material';

const StyledTypographyHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.dashboardHeadline
}));

export const Heading = (props) => {
  const { title } = props;

  return (
    <StyledTypographyHeading variant='subtitle1' {...props} >
      {title}
    </StyledTypographyHeading>
  );
};

const StyledTypographySubHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.textLarge,
  color: theme.palette.dashboardWidgetHeadline
}));

export const SubHeading = (props) => {
  const { title } = props;

  return (
    <StyledTypographySubHeading variant='body1' {...props}>
      {title}
    </StyledTypographySubHeading>
  );
};

const MobileStyledTypographySubHeading = styled(Typography)(({ theme }) => ({
  ...theme.typography.textxLarge,
  color: theme.palette.primary.light
}));

export const SubHeadingMobile = (props) => {
  const { title } = props;

  return (
    <MobileStyledTypographySubHeading variant='body1' {...props}>
      {title}
    </MobileStyledTypographySubHeading>
  );
};
