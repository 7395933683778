import { memo, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '../../../components';
import _ from 'lodash';

const RoomRates = (props) => {
  const {date, hotelId, roomTypeId, defaultValue, onChange, disabled = false, timeout} = props;
  const { control, formState: { errors }, setValue } = useFormContext();
  const [inputProps, setInputProps] = useState({name: null, value: null});

  useEffect(() => {
    /**
     * Add debouncing to stop calling API immediately
     */
    if (inputProps?.name) {
      const storeData = setTimeout(() => {
        const isError = _.get(errors, inputProps.name);
        if (!isError) {
          let formFieldData = {};
          const keys = (inputProps.name).split(/\[(.*?)\]/gi).filter(e => e !== '')
          keys.reduce(function(a, b, i) {
            return (i !== keys.length - 1) ? a[b] || (a[b] = {}) : a[b] = inputProps?.value ? Number(inputProps.value) : 0;
          }, formFieldData)
          // Call the onChange function
          if (onChange && formFieldData?.formAvailability?.[date] && formFieldData?.formAvailability?.[date]?.rooms && formFieldData?.formAvailability?.[date]?.rooms?.[roomTypeId] && Object.keys(formFieldData?.formAvailability?.[date]?.rooms?.[roomTypeId]).length > 0) {
            onChange(hotelId, {
              date: date,
              roomTypes: [
                {
                  roomTypeId: roomTypeId,
                  ...formFieldData.formAvailability[date].rooms[roomTypeId]
                }
              ]
            })
          }
        }
      }, (timeout ? timeout : 2000))

      return () => clearTimeout(storeData)
    }
  }, [inputProps, errors, timeout, date, hotelId, onChange, roomTypeId])

  useEffect(() => {
    setValue(`formAvailability[${date}][rooms][${roomTypeId}][price]`, defaultValue)
  }, [setValue, defaultValue, date, roomTypeId])

  return (
    <Controller
      name={`formAvailability[${date}][rooms][${roomTypeId}][price]`}
      control={control}
      defaultValue={0}
      render={({ field }) => (
        <Input
          formControlProps={{
            sx: {
              marginBottom: 0
            }
          }}
          sx={{
            borderRadius: 0,
            '& .MuiInputBase-input': {
              p: 1,
              textAlign: 'center'
            }
          }}
          isError={!!errors?.formAvailability?.[date]?.rooms?.[roomTypeId]?.price}
          inputRef={field.ref}
          {...field}
          value={field.value.toString().replace(/\./g, ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
          onChange={(e) => {
            const value = e.target.value.replace(/\./g, '').replace(/,/g, '.')
            setInputProps({name: e.target.name, value: Number(value)})
            field?.onChange(value)
          }}
          disabled={disabled}
        />
      )}
      rules={{
        required: true,
        pattern: /^(0|[1-9]\d*)(\.\d+)?$/
      }}
    />
  );
};

export default memo(RoomRates);