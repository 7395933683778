import { memo, useEffect, useState } from 'react';
import { Box, InputAdornment, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Select } from '../../../components';

const AirlineContractPreNegotiated = (props) => {
  const { selectDefault, selectOptions, id, entity, roomTypeId, contract, currency, sxProp, permission } = props;
  const { control, formState: { errors }, setValue } = useFormContext();
  const theme = useTheme();
  const [selectedType, setSelectedType] = useState(selectDefault);

  const onSelectChange = (val) => {
    setSelectedType(val)
  }

  useEffect(() => {
    if (contract) {
      if (entity === 'roomTypes') {
        let contractRoomTypes = {};
        if (contract?.roomTypes && contract?.roomTypes.length) {
          for (const room of contract.roomTypes) {
            contractRoomTypes[room?.hotel_room_type?.id] = room;
          } 
        }
        if (contractRoomTypes?.[roomTypeId]) {
          if (contractRoomTypes?.[roomTypeId]?.discount_type) {
            setValue(`formAirlineContracts[${id}][${entity}][${roomTypeId}][discount_type]`, contractRoomTypes[roomTypeId].discount_type);
            setSelectedType(contractRoomTypes[roomTypeId].discount_type)
          }
          if (contractRoomTypes?.[roomTypeId]?.discount_value) {
            setValue(`formAirlineContracts[${id}][${entity}][${roomTypeId}][discount_value]`, contractRoomTypes[roomTypeId].discount_value);
          }
        }
      } else {
        if (contract?.[`${entity}_discount_type`]) {
          setValue(`formAirlineContracts[${id}][${entity}_discount_type]`, contract[`${entity}_discount_type`]);
          setSelectedType(contract[`${entity}_discount_type`])
        }
        if (contract?.[`${entity}_discount_value`]) {
          setValue(`formAirlineContracts[${id}][${entity}_discount_value]`, contract[`${entity}_discount_value`]);
        }
      }
    }
  }, [contract, entity, id, roomTypeId, setValue])

  return (
    <Box sx={{
      ...sxProp,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Controller
        name={entity === 'roomTypes' ? `formAirlineContracts[${id}][${entity}][${roomTypeId}][discount_type]` : `formAirlineContracts[${id}][${entity}_discount_type]`}
        control={control}
        defaultValue={selectDefault}
        render={({ field }) => (
          <Select
            options={selectOptions}
            inputRef={field.ref}
            {...field}
            onChange={(e) => {
              onSelectChange(e.target.value)
              field?.onChange(e)
            }}
            sx={{
              backgroundColor: 'unset',
              border: 0,
              marginBottom: 0
            }}
            InputProps={{
              readOnly: (!permission?.edit),
              sx: {
                width: '85px',
                '& .MuiSelect-select.MuiInputBase-input': {
                  minHeight: 'auto',
                  ...theme.typography.textxxSmall
                },
                ...(permission?.edit ? {} : {
                  color: theme.palette.action.disabled
                })
              }
            }}
            formControlProps={{
              marginBottom: '0 !important'
            }}
            marginRightIcon='0 !important'
            paddingSelect='5px 5px 5px 0 !important'
          />
        )}
      />
      <Controller
        name={entity === 'roomTypes' ? `formAirlineContracts[${id}][${entity}][${roomTypeId}][discount_value]` : `formAirlineContracts[${id}][${entity}_discount_value]`}
        control={control}
        defaultValue={0}
        render={({ field }) => (
          <Input
            isError={entity === 'roomTypes' ? !!errors?.formAirlineContracts?.[id]?.[entity]?.[roomTypeId]?.discount_value : !!errors?.formAirlineContracts?.[id]?.[`${entity}_discount_value`]}
            errorMessage={((entity === 'roomTypes' && errors?.formAirlineContracts?.[id]?.[entity]?.[roomTypeId]?.discount_value) || (entity !== 'roomTypes' && errors?.formAirlineContracts?.[id]?.[`${entity}_discount_value`])) && 'Please enter valid number'}
            inputRef={field.ref}
            {...field}
            startAdornment={selectedType === 'FV' ? (
              <InputAdornment position='end'>
                {currency}
              </InputAdornment>
            ) : (null)}
            endAdornment={selectedType === 'PV' ? (
              <InputAdornment position='start'>
                %
              </InputAdornment>
            ) : (null)}
            sx={{
              '& .MuiInputBase-input': {
                padding: '5px',
                textAlign: selectedType === 'FV' ? 'left' : 'right'
              }
            }}
            formControlProps={{
              disabled: !permission?.edit,
              sx: {
                marginBottom: 0,
                width: '75px'
              }
            }}
          />
        )}
        rules={selectedType === 'PV' ? {
          required: true,
          pattern: /^(?:[1-9]\d?(?:\.\d{1,2})?|100(?:\.00?)?)$/
        } : {
          required: true,
          pattern: /^([1-9]\d*)(\.\d+)?$/
        }}
      />
    </Box>
  );
};

export default memo(AirlineContractPreNegotiated);