export const ColorPalette = {
  background: {
    paper: '#161616',
    default: '#161616'
  },
  common: {
    black: '#000000',
    white: '#ffffff'
  },
  menu: {
    main: '#cfdce4',
    light: '#88c4e8',
    dark: '#cfdce4',
    contrastText: '#88c4e8'
  },
  alert:{
    main: '#88c4e8', 
    light: 'rgb(28, 27, 27)',
    dark: '#bcc2c6',
    contrastText: '#bcc2c6'
  },
  time: {
    main: '#252525', 
    light: '#88c4e8',
    dark: '#88c4e8',
    contrastText: '#bcc2c6' 
  },
  filter: {
    main: 'rgb(28, 27, 27)',
  },
  primary: {
    main: '#1c1b1b', 
    light: '#88c4e8',
    light20: '#88c4e8',
    dark: '#182730',
    contrastText: 'rgba(28, 27, 27, .65)',
    light30: 'rgba(136, 196, 232, .3)' 
  },
  secondary: {
    main: '#ce93d8',
    light: '#f3e5f5',
    dark: '#ab47bc',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  error: {
    main: '#f44336',
    light: '#e57373',
    dark: '#d32f2f',
    contrastText: '#fff'
  },
  warning: {
    main: '#ffa726',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  info: {
    main: '#29b6f6',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  success: {
    main: '#66bb6a',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: 'rgba(0, 0, 0, 0.87)'
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#787c7f',
    650: '#787c7f',
    700: '#616161',
    800: '#474b4e',
    850: '#787878',
    900: '#292D32',
    A100: '#f5f5f5',
    A200: '#eeeeee',
    A400: '#bdbdbd',
    A700: '#616161',
    A900: '#252525'
  },
  red: {
    100: '#e18674',
    300: '#fd9686',
    500: '#e03434',
    500: '#e03434',
    800: '#a21005'
  },
  roseGold: {
    100: '#fa907b',
    200: '#ff9800'
  },
  contrastThreshold: '3',
  tonalOffset: '0.2',
  text: {
    primary: '#cfdce4',
    light: 'cfdce4',
    secondary: '#bcc2c6',
    dark: '#161616',
    disabled: 'rgba(255, 255, 255, 0.5)',
    icon: 'rgba(255, 255, 255, 0.5)'
  },
  divider: '#393939',
  backdrop: 'rgba(0, 0, 0, .8)',
  dashboardWidgetHeadline: '#6b8494',
  sidebarBg: '#255b79',
  sidebarActiveBg: '#1d516d',
  checkoutBoxBorder: '#7e7e7e',
  table: {
    header: '#838586',
    divider: 'rgba(107, 132, 148, 0.2)'
  },
  icon: {
    light: '#353C40'
  },
  rating: {
    light: '#ffe27c'
  },
  bg: {
    100: '#191c1e',
    200: '#1e2224',
    300: '#c0fba4',
    400: '#181c1d'
  },
  orange: {
    300: '#ffA500',
    500: '#fb5607',
  }
};