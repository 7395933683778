import React, {forwardRef, useImperativeHandle, useState} from 'react';
import { Backdrop, Box, LinearProgress, useTheme } from '@mui/material';
import Logo from '../../assets/images/logo.png';

function Loader(props, ref) {
  const theme = useTheme();
  const [loading, setLoading] = useState(0);

  useImperativeHandle(
    ref,
    () => ({
      start: () => {
        const loadingCount = loading + 1;
        setLoading(loadingCount);
      },
      stop: () => {
        const loadingCount = loading > 0 ? loading - 1 : 0;
        setLoading(loadingCount);
      },
      isLoading: () => loading >= 1,
    }),
    [loading]
  );

  if (!loading) {
    return null;
  }

  return (
    <Backdrop open={loading ? true : false} sx={{
      bgcolor: (theme) => theme.palette.backdrop,
      zIndex: (theme) => theme.zIndex.drawer + 100
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <img src={Logo} width={50} alt='Croowy Companion' />
        <LinearProgress sx={{
          bgcolor: theme.palette.text.primary,
          borderRadius: '50px',
          marginTop: theme.spacing(2),
          width: '80px',
          height: '2px'
        }}/>
      </Box>
    </Backdrop>
  );
}

export default forwardRef(Loader);